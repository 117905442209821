<ng-container [ngSwitch]="actualState">
  <ng-container *ngSwitchCase="'success'" [ngTemplateOutlet]="successTemplate"></ng-container>
  <ng-container *ngSwitchCase="'error'" [ngTemplateOutlet]="errorTemplate"></ng-container>
  <ng-container *ngSwitchDefault [ngTemplateOutlet]="pendingTemplate"></ng-container>
</ng-container>

<ng-template #successTemplate>
  <div class="container success" @appear>
    <div class="text">success</div>
    <mat-icon>check_circle</mat-icon>
  </div>
</ng-template>
<ng-template #errorTemplate>
  <div class="container error">
    <div class="text">error</div>
    <mat-icon color="warn">crisis_alert</mat-icon>
  </div>
</ng-template>
<ng-template #pendingTemplate>
  <div class="container">
    <div class="text">{{ message }}</div>
    <mat-spinner [diameter]="25"></mat-spinner>
  </div>
</ng-template>
