<form [formGroup]="form" *ngIf="!isLoading; else loader">
  <mat-form-field>
    <mat-label>
      {{ 'payments.invoices.company_name' | translate }}
    </mat-label>
    <input matInput [formControl]="company_name" type="text" required name="company_name" />
    <app-form-field-error-display matError></app-form-field-error-display>
  </mat-form-field>

  <mat-form-field>
    <mat-label>
      {{ 'payments.invoices.address_1' | translate }}
    </mat-label>

    <input matInput [formControl]="address_1" type="text" required name="address_1" />
    <app-form-field-error-display matError></app-form-field-error-display>
  </mat-form-field>

  <mat-form-field>
    <mat-label>
      {{ 'payments.invoices.address_2' | translate }}
    </mat-label>

    <input matInput [formControl]="address_2" type="text" name="address_2" />
    <app-form-field-error-display matError></app-form-field-error-display>
  </mat-form-field>

  <mat-form-field>
    <mat-label>
      {{ 'payments.invoices.postal_code' | translate }}
    </mat-label>

    <input matInput [formControl]="postal_code" type="text" required name="postal_code" />
    <app-form-field-error-display matError></app-form-field-error-display>
  </mat-form-field>

  <mat-form-field>
    <mat-label>
      {{ 'payments.invoices.city' | translate }}
    </mat-label>

    <input matInput [formControl]="city" type="text" required name="city" />
    <app-form-field-error-display matError></app-form-field-error-display>
  </mat-form-field>

  <mat-form-field>
    <mat-label>
      {{ 'payments.invoices.tax_id' | translate }}
    </mat-label>

    <input matInput placeholder="PL1234567841" [formControl]="tax_id" type="text" name="tax_id" />
    <app-form-field-error-display matError></app-form-field-error-display>
  </mat-form-field>
</form>

<ng-template #loader>
  <mat-spinner></mat-spinner>
</ng-template>
