import { Component, Inject, OnDestroy, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormApiValidationError } from '../../../../shared/model/errors/formApiError.model';
import { InvoiceDataModel } from '../../models/invoice.model';
import { InvoiceDataFormComponent } from '../invoice-data-form/invoice-data-form.component';
import { InvoiceService } from '../invoice.service';

@Component({
  selector: 'app-generate-invoice-popup',
  templateUrl: './generate-invoice-popup.component.html',
  styleUrls: ['./generate-invoice-popup.component.scss'],
})
export class GenerateInvoicePopupComponent implements OnDestroy {
  @ViewChild(InvoiceDataFormComponent) private invoiceDataForm!: InvoiceDataFormComponent;
  isLoading = false;
  private onDestroy$ = new Subject<void>();

  constructor(
    private invoiceService: InvoiceService,
    public dialogRef: MatDialogRef<GenerateInvoicePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: InvoiceDataModel,
  ) {}

  submit(): void {
    this.invoiceDataForm.form.markAllAsTouched();
    this.isLoading = true;
    if (this.invoiceDataForm.form.invalid) return;
    this.invoiceService
      .setInvoiceData(
        this.invoiceDataForm.company_name.value,
        this.invoiceDataForm.address_1.value,
        this.invoiceDataForm.address_2.value,
        this.invoiceDataForm.postal_code.value,
        this.invoiceDataForm.city.value,
        this.invoiceDataForm.tax_id.value,
      )
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (res) => {
          this.dialogRef.close(res);
          this.isLoading = false;
        },
        (er: FormApiValidationError) => {
          er.setOnForm && er.setOnForm(this.invoiceDataForm.form);
          this.isLoading = false;
        },
      );
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }
}
