<app-dialog [dialogRef]="dialogRef"></app-dialog>

<ng-container>
  <mat-dialog-content class="container">
    <div class="preview-area">
      <ng-container *ngIf="currentFile && isImage(currentFile); else nonImageTemplate">
        <img [src]="currentFile.path" alt="{{ currentFile.name }}" />
      </ng-container>
      <ng-template #nonImageTemplate>
        <div class="non-image-preview">
          <mat-icon>description</mat-icon>
          <div *ngIf="currentFile">
            <a [href]="currentFile.path" download="{{ currentFile.name }}">Download {{ currentFile.name }}</a>
          </div>
        </div>
      </ng-template>
      <button class="nav-btn prev" (click)="previous()">&#10094;</button>
      <button class="nav-btn next" (click)="next()">&#10095;</button>
    </div>
    <div class="thumbnails">
      <div *ngFor="let file of data.files; let i = index" class="thumbnail" [class.active]="i === currentIndex" (click)="currentIndex = i">
        <img *ngIf="isImage(file)" [src]="file.thumbnail" alt="{{ file.name }}" />
        <span *ngIf="!isImage(file)" class="no-image-thumb">
          <mat-icon>description</mat-icon>
          <div>{{ file.name }}</div>
        </span>
      </div>
    </div>
  </mat-dialog-content>
</ng-container>
