import { Pipe, PipeTransform } from '@angular/core';
import { ColumnValueTypesEnum } from '../sem-table/table-display/ColumnTypesEnum';

@Pipe({
  name: 'truncate',
})
export class TruncatePipe implements PipeTransform {
  transform(value: any, args: string | number, valueType?: ColumnValueTypesEnum): string {
    let newValue = value;

    if (valueType === ColumnValueTypesEnum.number) {
      const numValue = parseFloat(newValue);
      if (!isNaN(numValue)) {
        newValue = Number.isInteger(numValue) ? numValue.toString() : numValue.toFixed(2);
      }
    }

    const valueCopy = newValue ? newValue.toString() : '';
    const argsCopy = typeof args === 'string' ? parseInt(args, 10) : args;

    const limit = argsCopy || 10;
    const trail = '...';
    return valueCopy.length > limit ? valueCopy.substring(0, limit) + trail : valueCopy;
  }
}
