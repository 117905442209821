import { ColumnTypesEnum } from '../../table-display/ColumnTypesEnum';
import { FilterLogicOperatorEnum } from '../FilterLogicOperatorEnum';
import { FilterTypesEnum } from '../FilterTypesEnum';

export abstract class FilterModel {
  symbol!: FilterTypesEnum;
  translate_key?: string;

  constructor(
    public param: string,
    // eslint-disable-next-line @typescript-eslint/default-param-last
    public value: any = null,
    public columnType: ColumnTypesEnum,
    public operator: FilterLogicOperatorEnum,
    public case_sensitive?: boolean,
    public additional_sources?: any,
  ) {}

  abstract isValid(): boolean;

  // abstract returnForSend(): any;
}

export interface FilterGroupModel {
  operator: string | null;
  filters: FilterModel[];
}

export interface FilterInStorageModel {
  name: string;
  filters: FilterGroupModel[];
}

interface FilterParamsInterface {
  type: FilterTypesEnum;
  value: any;
  operator?: FilterLogicOperatorEnum;
  caseSensitive?: boolean;
  additionalSources?: any;
}

export interface FiltersGroupParamsInterface {
  [key: string]: FilterParamsInterface;
}

export interface RouteFiltersGroupParamsInterface {
  filters: FiltersGroupParamsInterface;
  storageName: string;
}
