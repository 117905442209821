<form id="form" [formGroup]="form" (ngSubmit)="onSubmit()" cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
  <mat-dialog-content>
    <div>
      <h5>
        {{ 'bing.create_account_form.label_company' | translate }}
      </h5>

      <div class="row">
        <mat-form-field>
          <input
            matInput
            [placeholder]="'bing.create_account_form.businessName' | translate"
            required
            [formControl]="businessNameCtrl"
            cdkFOcusInitial
          />
          <app-form-field-error-display matError></app-form-field-error-display>
        </mat-form-field>
      </div>

      <div class="row">
        <mat-form-field>
          <mat-select [formControl]="countryCodeCtrl" [placeholder]="'bing.create_account_form.countryCode' | translate" required>
            <mat-option *ngFor="let countryCode of countryCodes" [value]="countryCode">
              {{ countryCode }}
            </mat-option>
          </mat-select>
          <app-form-field-error-display matError></app-form-field-error-display>
        </mat-form-field>

        <mat-form-field>
          <input matInput [placeholder]="'bing.create_account_form.city' | translate" required [formControl]="cityCtrl" />
          <app-form-field-error-display matError></app-form-field-error-display>
        </mat-form-field>

        <mat-form-field>
          <input matInput [placeholder]="'bing.create_account_form.postalCode' | translate" required [formControl]="postalCodeCtrl" />
          <app-form-field-error-display matError></app-form-field-error-display>
        </mat-form-field>
      </div>

      <div class="row">
        <mat-form-field>
          <input matInput [placeholder]="'bing.create_account_form.addressLine1' | translate" required [formControl]="addressLine1Ctrl" />
          <app-form-field-error-display matError></app-form-field-error-display>
        </mat-form-field>
        <mat-form-field>
          <input matInput [placeholder]="'bing.create_account_form.addressLine2' | translate" [formControl]="addressLine2Ctrl" />
          <app-form-field-error-display matError></app-form-field-error-display>
        </mat-form-field>
      </div>

      <div class="row">
        <mat-form-field>
          <input matInput [placeholder]="'bing.create_account_form.addressLine3' | translate" [formControl]="addressLine3Ctrl" />
          <app-form-field-error-display matError></app-form-field-error-display>
        </mat-form-field>
        <mat-form-field>
          <input matInput [placeholder]="'bing.create_account_form.addressLine4' | translate" [formControl]="addressLine4Ctrl" />
          <app-form-field-error-display matError></app-form-field-error-display>
        </mat-form-field>
      </div>

      <div class="row">
        <mat-form-field>
          <input
            matInput
            [placeholder]="'bing.create_account_form.stateOrProvince' | translate"
            required
            [formControl]="stateOrProvinceCtrl"
            cdkFOcusInitial
          />
          <app-form-field-error-display matError></app-form-field-error-display>
        </mat-form-field>
      </div>
    </div>

    <hr />

    <div>
      <h5>
        {{ 'bing.create_account_form.label_microsoft_ads' | translate }}
      </h5>

      <div class="row">
        <mat-form-field>
          <mat-select [formControl]="languageCtrl" [placeholder]="'bing.create_account_form.language' | translate" required>
            <mat-option *ngFor="let language of languages" [value]="language">
              {{ language }}
            </mat-option>
          </mat-select>
          <app-form-field-error-display matError></app-form-field-error-display>
        </mat-form-field>
        <mat-form-field>
          <mat-select [formControl]="currencyCodeCtrl" [placeholder]="'bing.create_account_form.currencyCode' | translate" required>
            <mat-option *ngFor="let currency of currencies" [value]="currency">
              {{ currency }}
            </mat-option>
          </mat-select>
          <app-form-field-error-display matError></app-form-field-error-display>
        </mat-form-field>
      </div>

      <div class="row">
        <mat-form-field>
          <input matInput [placeholder]="'bing.create_account_form.accountName' | translate" required [formControl]="accountNameCtrl" />
          <app-form-field-error-display matError></app-form-field-error-display>
        </mat-form-field>
        <mat-form-field>
          <input matInput [placeholder]="'bing.create_account_form.customerName' | translate" required [formControl]="customerNameCtrl" />
          <app-form-field-error-display matError></app-form-field-error-display>
        </mat-form-field>
      </div>

      <div class="row">
        <mat-form-field>
          <mat-select [formControl]="industryCtrl" [placeholder]="'bing.create_account_form.industry' | translate" required>
            <mat-option *ngFor="let industry of industries" [value]="industry">
              {{ industry }}
            </mat-option>
          </mat-select>
          <app-form-field-error-display matError></app-form-field-error-display>
        </mat-form-field>
      </div>
    </div>

    <hr />

    <div>
      <h5>
        {{ 'bing.create_account_form.label_invoice' | translate }}
      </h5>

      <div class="row">
        <mat-form-field>
          <input matInput [placeholder]="'bing.create_account_form.email' | translate" required [formControl]="emailCtrl" />
          <app-form-field-error-display matError></app-form-field-error-display>
        </mat-form-field>
        <mat-form-field>
          <input matInput [placeholder]="'bing.create_account_form.name' | translate" required [formControl]="nameCtrl" />
          <app-form-field-error-display matError></app-form-field-error-display>
        </mat-form-field>
        <mat-form-field>
          <input matInput [placeholder]="'bing.create_account_form.surname' | translate" required [formControl]="surnameCtrl" />
          <app-form-field-error-display matError></app-form-field-error-display>
        </mat-form-field>
      </div>

      <div class="row">
        <mat-slide-toggle [formControl]="tagConversionSettingsCtrl">
          <span>
            {{ 'bing.create_account_form.tagConversion' | translate }}
          </span>
        </mat-slide-toggle>
      </div>
      <div class="row">
        <mat-slide-toggle [formControl]="manageAdsCtrl">
          <span>
            {{ 'bing.create_account_form.manageAds' | translate }}
          </span>
        </mat-slide-toggle>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button [disabled]="locked" class="submit-btn" color="yellow" mat-raised-button type="submit">
      {{ 'bing.create_account_form.submitBtn' | translate }}
    </button>
  </mat-dialog-actions>
</form>
