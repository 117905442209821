import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CompetitorsSource, RankTypes, ResultTypes } from 'src/app/dashboard/project/monitor/monitor.enum';
import { ProjectService } from 'src/app/dashboard/project/project.service';
import { MatchedProductsResponseModel } from 'src/app/shared/model/monitor.model';
import ApiUrls from '../../configs/api-urls.config';
import { AnalyticsDataModel } from '../modals/analytics-filters-modal/analytics-filters-modal.component';
import {
  CalculateResourcesModel,
  MonitoLocationsModel,
  MonitorKeyword,
  MonitorMassTaskFormModel,
  MonitorOrganicTaskModel,
  MonitorProductTaskModel,
  MonitorRestrictionsModel,
  MonitorResultsForPhraseTaskInterface,
  MonitorResultsForPhraseTasksInterface,
  MonitorResultsForProductTaskInterface,
  MonitorResultsForProductTasksInterface,
  MonitorTaskFormModel,
  MonitorTaskModel,
  ProcessedCsvDataModel,
  ProductCompetitorsModel,
  ResourcesModel,
  SelectedMatchInterface,
  VisibilityListModel,
} from '../model/monitor.model';
import { PaginationInterface, PaginationResourceInterface, ResponseV2Interface } from '../model/response.model';
import { FilterGroupModel } from '../sem-table/filters/models/filter.model';
import { TableChangeDataEmittedInterface } from '../sem-table/models/TableChangeDataEmitted.model';
import { HelperService } from './helper.service';

@Injectable({
  providedIn: 'root',
})
export class MonitorService {
  constructor(
    private http: HttpClient,
    private projectService: ProjectService,
    private helperService: HelperService,
  ) {}

  addTask(projectId: number, task: MonitorTaskFormModel): Observable<MonitorTaskModel> {
    return this.http
      .post<ResponseV2Interface<MonitorTaskModel>>(ApiUrls.projectMonitorTasks.prepareUrl({ project: projectId }), task)
      .pipe(map((res) => res.data));
  }

  addTasksFromCsv(projectId: number, file: FormData): Observable<MonitorTaskModel> {
    return this.http
      .post<ResponseV2Interface<MonitorTaskModel>>(ApiUrls.projectMonitorTasks.prepareUrl({ project: projectId }), file)
      .pipe(map((res) => res.data));
  }

  massAddTask(data: MonitorMassTaskFormModel): Observable<void> {
    const projectId = this.projectService.activeProject$.getValue()!.id;
    return this.http.post<void>(ApiUrls.projectMonitorMassAddTasks.prepareUrl({ project: projectId }), {
      ...data,
    });
  }

  massAddTaskByFilters(data: MonitorMassTaskFormModel): Observable<void> {
    const projectId = this.projectService.activeProject$.getValue()!.id;
    if (data.filterGroups) {
      data.filterGroups = this.helperService.flattenAdditionalSourcesFilters(data.filterGroups);
    }

    return this.http.post<void>(ApiUrls.projectMonitorMassAddFilterTasks.prepareUrl({ project: projectId }), {
      ...data,
    });
  }

  duplicateTasks(data: MonitorMassTaskFormModel): Observable<void> {
    const projectId = this.projectService.activeProject$.getValue()!.id;
    return this.http.post<void>(ApiUrls.projectMonitorDuplicateTasks.prepareUrl({ project: projectId }), {
      ...data,
    });
  }

  massRemoveTask(tasks: MonitorTaskModel[]): Observable<void> {
    const projectId = this.projectService.activeProject$.getValue()!.id;
    const ids = tasks.map((task) => task.id);
    return this.http.post<void>(ApiUrls.projectMonitorMassRemoveTasks.prepareUrl({ project: projectId }), { ids });
  }

  massRemoveTaskByFilters(filterGroups: FilterGroupModel[], type: ResultTypes, rank_type?: RankTypes): Observable<void> {
    const projectId = this.projectService.activeProject$.getValue()!.id;

    return this.http.post<void>(ApiUrls.projectMonitorMassRemoveFilterTasks.prepareUrl({ project: projectId }), {
      filterGroups,
      type,
      rank_type,
    });
  }

  matchProductsRequest(ids: number[]): Observable<void> {
    const projectId = this.projectService.activeProject$.getValue()!.id;
    return this.http.post<void>(ApiUrls.projectMonitorMatchProducts.prepareUrl({ project: projectId }), { ids });
  }

  getMatchedResults(ids: number[]): Observable<MatchedProductsResponseModel> {
    const projectId = this.projectService.activeProject$.getValue()!.id;
    return this.http
      .post<
        ResponseV2Interface<MatchedProductsResponseModel>
      >(ApiUrls.projectMonitorMatchProductsResults.prepareUrl({ project: projectId }), { ids })
      .pipe(map((response) => response.data));
  }

  setMatchedProducts(products: SelectedMatchInterface[]): Observable<void> {
    const projectId = this.projectService.activeProject$.getValue()!.id;
    return this.http.post<void>(ApiUrls.projectMonitorSetMatchedProducts.prepareUrl({ project: projectId }), { products });
  }

  deleteTask(taskId: number): Observable<void> {
    const projectId = this.projectService.activeProject$.getValue()!.id;
    return this.http.delete<void>(ApiUrls.projectMonitorTask.prepareUrl({ project: projectId, task: taskId }));
  }

  monitorBest(analyticsData: AnalyticsDataModel, language: string, location: string): Observable<void> {
    const projectId = this.projectService.activeProject$.getValue()!.id;
    return this.http.post<void>(ApiUrls.projectMonitorBest.prepareUrl({ project: projectId }), {
      additional_sources: analyticsData,
      language,
      location,
    });
  }

  getResultsForPhraseTask(
    taskId: number,
    date_from?: string,
    date_to?: string,
    rank_type?: string,
  ): Observable<MonitorResultsForPhraseTaskInterface> {
    const projectId = this.projectService.activeProject$.getValue()!.id;
    return this.http.post<MonitorResultsForPhraseTaskInterface>(
      ApiUrls.projectMonitorResultsPhrase.prepareUrl({ project: projectId, task: taskId }),
      {
        date_from,
        date_to,
        rank_type,
      },
    );
  }

  getResultsForPhraseTasks(
    tasks: Array<Number>,
    rank_type?: RankTypes,
    date_from?: string,
    date_to?: string,
  ): Observable<MonitorResultsForPhraseTasksInterface> {
    const projectId = this.projectService.activeProject$.getValue()!.id;
    return this.http.post<MonitorResultsForPhraseTasksInterface>(ApiUrls.projectMonitorResultsPhrases.prepareUrl({ project: projectId }), {
      date_from,
      date_to,
      tasks,
      rank_type,
    });
  }

  getResultsForProductTask(taskId: number, date_from?: string, date_to?: string): Observable<MonitorResultsForProductTaskInterface> {
    const projectId = this.projectService.activeProject$.getValue()!.id;
    return this.http.post<MonitorResultsForProductTaskInterface>(
      ApiUrls.projectMonitorResultsProduct.prepareUrl({ project: projectId, task: taskId }),
      {
        date_from,
        date_to,
      },
    );
  }

  getResultsForProductTasks(
    tasks: Array<Number>,
    date_from?: string,
    date_to?: string,
  ): Observable<MonitorResultsForProductTasksInterface> {
    const projectId = this.projectService.activeProject$.getValue()!.id;
    return this.http.post<MonitorResultsForProductTasksInterface>(
      ApiUrls.projectMonitorResultsProducts.prepareUrl({ project: projectId }),
      {
        date_from,
        date_to,
        tasks,
      },
    );
  }

  getNotMonitoredKeywords(
    projectId: number,
    dataToParse: TableChangeDataEmittedInterface,
  ): Observable<PaginationInterface<MonitorKeyword>> {
    const parsedData = this.helperService.parseBodyParamsFromTable(dataToParse);
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const filtersChanged: FilterGroupModel[] = this.helperService.parseProductFiltersForApi(parsedData.filterGroups!);
    return this.http
      .post<PaginationResourceInterface<MonitorKeyword>>(ApiUrls.projectMonitorNotMonitoredKeywords.prepareUrl({ project: projectId }), {
        ...parsedData,
        filterGroups: filtersChanged,
      })
      .pipe(
        map(({ data, links, meta }) => ({
          data,
          ...links,
          ...meta,
        })),
      );
  }

  getOrganicTasks(
    projectId: number,
    filterGroups: FilterGroupModel[] = [],
    per_page?: number,
    page?: number,
    order?: string,
    direction?: string,
    date_from?: string,
    date_to?: string,
    rank_type?: string,
  ): Observable<PaginationInterface<MonitorOrganicTaskModel>> {
    return this.http
      .post<PaginationResourceInterface<MonitorOrganicTaskModel>>(ApiUrls.projectMonitorOrganicTasks.prepareUrl({ project: projectId }), {
        per_page,
        page,
        filterGroups,
        order,
        direction,
        date_from,
        date_to,
        rank_type,
      })
      .pipe(
        map(({ data, links, meta }) => ({
          data,
          ...links,
          ...meta,
        })),
      );
  }

  getProductTasks(
    projectId: number,
    per_page?: number,
    page?: number,
    order?: string,
    direction?: string,
    filterGroups: FilterGroupModel[] = [],
    date_from?: string,
    date_to?: string,
  ): Observable<PaginationInterface<MonitorProductTaskModel>> {
    return this.http
      .post<PaginationResourceInterface<MonitorProductTaskModel>>(ApiUrls.projectMonitorProductTasks.prepareUrl({ project: projectId }), {
        per_page,
        page,
        filterGroups,
        order,
        direction,
        date_from,
        date_to,
      })
      .pipe(
        map(({ data, links, meta }) => ({
          data,
          ...links,
          ...meta,
        })),
      );
  }

  getProductCompetitors(): Observable<ProductCompetitorsModel[]> {
    const projectId = this.projectService.activeProject$.getValue()!.id;
    return this.http
      .post<ResponseV2Interface<ProductCompetitorsModel[]>>(ApiUrls.projectMonitorProductCompetitors.prepareUrl({ project: projectId }), {})
      .pipe(map((response) => response.data));
  }

  getMatchedProducts(
    projectId: number,
    seller: string,
    matched_monitor_products_ids: Array<Number>,
    source: CompetitorsSource,
    per_page?: number,
    page?: number,
    order?: string,
    direction?: string,
    filterGroups: FilterGroupModel[] = [],
    date_from?: string,
    date_to?: string,
    audit_id?: number,
  ): Observable<PaginationInterface<MonitorProductTaskModel>> {
    return this.http
      .post<PaginationResourceInterface<MonitorProductTaskModel>>(
        ApiUrls.projectMonitorMatchedProducts.prepareUrl({ project: projectId }),
        {
          per_page,
          page,
          filterGroups,
          order,
          direction,
          date_from,
          date_to,
          audit_id,
          seller,
          matched_monitor_products_ids,
          source,
        },
      )
      .pipe(
        map(({ data, links, meta }) => ({
          data,
          ...links,
          ...meta,
        })),
      );
  }

  getVisibilityList(resultType: ResultTypes, rankType?: RankTypes): Observable<VisibilityListModel> {
    const projectId = this.projectService.activeProject$.getValue()!.id;
    return this.http
      .post<ResponseV2Interface<VisibilityListModel>>(ApiUrls.projectMonitorPhrasesVisibility.prepareUrl({ project: projectId }), {
        rank_type: rankType,
        result_type: resultType,
      })
      .pipe(map((response) => response.data));
  }

  getLocations(countries: string[]): Observable<MonitoLocationsModel[]> {
    const projectId = this.projectService.activeProject$.getValue()!.id;
    return this.http.post<MonitoLocationsModel[]>(ApiUrls.projectMonitorLocations.prepareUrl({ project: projectId }), {
      countries,
    });
  }

  getRestrictions(): Observable<MonitorRestrictionsModel> {
    const projectId = this.projectService.activeProject$.getValue()!.id;
    return this.http
      .get<ResponseV2Interface<MonitorRestrictionsModel>>(ApiUrls.projectMonitorRestrictions.prepareUrl({ project: projectId }), {})
      .pipe(map((res) => res.data));
  }

  processCsv(fileToUpload: File): Observable<ProcessedCsvDataModel> {
    const formData = new FormData();
    formData.append('csv', fileToUpload, fileToUpload.name);
    const projectId = this.projectService.activeProject$.getValue()!.id;
    return this.http
      .post<ResponseV2Interface<ProcessedCsvDataModel>>(ApiUrls.projectMonitorProcessCsv.prepareUrl({ project: projectId }), formData)
      .pipe(map((res) => res.data));
  }

  calculateResources(data: CalculateResourcesModel): Observable<ResourcesModel> {
    const projectId = this.projectService.activeProject$.getValue()!.id;
    if (data.filterGroups) {
      data.filterGroups = this.helperService.flattenAdditionalSourcesFilters(data.filterGroups);
    }

    return this.http
      .post<ResponseV2Interface<ResourcesModel>>(ApiUrls.projectMonitorCalculateResources.prepareUrl({ project: projectId }), {
        ...data,
      })
      .pipe(map((response) => response.data));
  }

  getCsv(dataToParse: TableChangeDataEmittedInterface, fields: string[], resultType: ResultTypes, rankType?: RankTypes): Observable<null> {
    const projectId = this.projectService.activeProject$.getValue()!.id;
    const parsedData = this.helperService.parseBodyParamsFromTable(dataToParse);
    const filtersChanged: FilterGroupModel[] = this.helperService.parseProductFiltersForApi(parsedData.filterGroups!);
    const filters = {
      ...parsedData,
      filterGroups: filtersChanged,
      result_type: resultType,
      rank_type: rankType,
    };
    return this.http.post<null>(
      ApiUrls.projectMonitorCsvResults.prepareUrl({ project: projectId }),
      { ...filters, fields },
      { responseType: 'text' as 'json' },
    );
  }
}
