import { APP_BASE_HREF } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import CommonUrlsConfig from 'src/app/configs/common-urls.config';
import enFile from '../../../assets/i18n/en.json';
import { TableConfigurationInterface } from '../sem-table/models/TableConfigurationInterface.model';
import { ColumnModel } from '../sem-table/table-display/columns-switch/columns/column.model';
import { LocalStorageService, storageKey } from './local-storage.service';

export enum LangEnum {
  en = 'en',
  pl = 'pl',
}

const SUPPORTED_LANGS = [LangEnum.en, LangEnum.pl];

@Injectable({
  providedIn: 'root',
})
export class TranslationService {
  static file: any = enFile;
  storageLang: LangEnum = this.storageHelperService.get(storageKey.locale) ?? LangEnum.en;
  defaultLang = LangEnum.en;
  defaultLangFile = enFile;

  constructor(
    private http: HttpClient,
    private translateService: TranslateService,
    private storageHelperService: LocalStorageService,
    @Inject(APP_BASE_HREF) private baseHref: string,
  ) {}

  public static get semTableTranslation() {
    return TranslationService.file || {};
  }

  commonUrls(lang = this.storageLang) {
    return CommonUrlsConfig[lang || this.defaultLang] || CommonUrlsConfig[this.defaultLang];
  }

  hasTranslation(key: string): boolean {
    const translation = this.translateService.instant(key);
    return translation !== key && translation !== '';
  }

  parseConfig(config: TableConfigurationInterface): TableConfigurationInterface {
    Object.keys(config.columns).forEach((key) => {
      config.columns[key].alias = this.mapColumnAlias(key, config.columns[key]);
    });
    config.actionsButtonSlot &&
      config.actionsButtonSlot.forEach((action) => (action.alias = this.mapActionAlias(action.actionType) || action.alias));
    config.mainToolbarSlot && config.mainToolbarSlot.forEach((action) => (action.alias = this.mapActionAlias(action.alias)));
    config.selectedToolbarSlot &&
      config.selectedToolbarSlot.forEach((action) => (action.alias = this.mapActionAlias(action.actionType) || action.alias));
    config.selectedGloballyToolbarSlot &&
      config.selectedGloballyToolbarSlot.forEach((action) => (action.alias = this.mapActionAlias(action.actionType) || action.alias));

    return config;
  }

  initAppTranslation() {
    return new Promise<boolean>((resolve: (res: boolean) => void) => {
      if (this.storageLang && this.storageLang !== this.defaultLang && SUPPORTED_LANGS.includes(this.storageLang)) {
        this.getAppLang(this.storageLang)
          // eslint-disable-next-line @typescript-eslint/dot-notation
          .pipe(tap((translation) => translation && (TranslationService.file = translation['sem_table' as keyof typeof translation])))
          .subscribe(() => resolve(true));
      } else {
        this.setDefaultLang();
        resolve(true);
      }
    });
  }

  setCustomLang(lang: LangEnum) {
    this.storageLang = lang;
    this.storageHelperService.save(storageKey.locale, lang);
    this.translateService.currentLang = lang;
    this.initAppTranslation();
  }

  private mapColumnAlias(key: string, column: ColumnModel) {
    if (this.translateService.instant(`columns.${key}`) !== `columns.${key}`) {
      return this.translateService.instant(`columns.${key}`);
    }
    return column.alias;
  }

  private mapActionAlias(action: string) {
    if (this.translateService.instant(`actions.${action}`) !== `actions.${action}`) {
      return this.translateService.instant(`actions.${action}`);
    }
    return null;
  }

  private getAppLang(lang: string): Observable<unknown> {
    const translationsUrl = `${this.baseHref}assets/i18n`;

    const sufix = '.json';
    return this.http.get(`${translationsUrl}/${lang}${sufix}`).pipe(
      catchError(() => of(null)),
      tap(() => this.translateService.setTranslation(this.defaultLang, this.defaultLangFile || {})),
      tap((translation) => this.translateService.setTranslation(this.storageLang, translation || {}, true)),
      tap(() => this.translateService.setDefaultLang(this.defaultLang)),
      tap(() => this.translateService.use(this.storageLang)),
    );
  }

  private setDefaultLang() {
    this.translateService.setTranslation(this.defaultLang, this.defaultLangFile || {});
    this.translateService.setDefaultLang(this.defaultLang);
    this.translateService.use(this.defaultLang);
    TranslationService.file = this.defaultLangFile.sem_table;
  }
}
