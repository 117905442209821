<mat-card appearance="outlined">
  <mat-card-header>
    <mat-card-title>
      {{ 'payments.products.title' | translate }}
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <app-info-box *ngIf="canBuyMoreProducts && form"
      ><span>
        {{ 'payments.products.info' | translate: { part: stillAbleToChoose + ' / 20' } }}
      </span></app-info-box
    >
    <div *ngIf="!contentLoading; else loader">
      <form *ngIf="canBuyMoreProducts && form; else noItems">
        <form *ngFor="let key of availableProductKeys; index as id" [formGroup]="getFormGroup(key)" class="row">
          <mat-form-field>
            <mat-label>Select package for {{ 'payments.products.' + key | translate }}</mat-label>
            <mat-select #packageInputRef [formControlName]="'package'" (valueChange)="setCurrency(packageInputRef.value.currency)">
              <mat-option *ngFor="let product of additonalProductResponse[key]" [value]="product"
                >{{ product.amount | number }}({{ product.price / 100 }} {{ product.currency | uppercase }})</mat-option
              >
            </mat-select>
          </mat-form-field>

          <mat-form-field *ngIf="packageInputRef.value">
            <mat-label>{{ 'payments.products.' + key | translate }}(x{{ packageInputRef.value.amount | number }})</mat-label>
            <input
              #amountInputRef
              matInput
              name="additional-product"
              type="number"
              cdkFocusInitial
              [formControlName]="'amount'"
              min="0"
              [max]="+amountInputRef.value + stillAbleToChoose"
            />
          </mat-form-field>
        </form>

        <div class="summary-wrapper">
          <div class="section-title section">
            {{ 'payments.products.current_order' | translate }}
          </div>
          <div class="section">
            <div *ngFor="let key of availableProductKeys">
              <span>{{ ('payments.products.' + key | translate) + ': ' }}</span>
              <span *ngIf="form.value[key].package"
                >{{ (form.value[key].package.price * form.value[key].amount) / 100 }}
                {{ form.value[key].package.currency | uppercase }}</span
              >
            </div>
          </div>

          <div class="section">
            <span class="price">
              {{ 'payments.products.price' | translate }}
            </span>
            {{ currentAmount / 100 + ' ' + currency | uppercase }}
          </div>
        </div>

        <app-button
          (click)="onSubmit()"
          [loading]="isLoading"
          [text]="'payments.products.submit' | translate"
          color="primary"
          type="submit"
        ></app-button>
      </form>
    </div>
  </mat-card-content>
</mat-card>

<ng-template #noItems>
  <div class="browse-btn-wrapper">
    <div>
      {{ 'payments.current_package.free_package' | translate }}
    </div>
    <button mat-raised-button color="primary" appRouter="paymentsPlan">
      {{ 'payments.current_package.choose_plan' | translate }}
    </button>
  </div>
</ng-template>

<ng-template #loader>
  <div class="spinner-wrapper">
    <mat-spinner mode="indeterminate" [diameter]="40"></mat-spinner>
  </div>
</ng-template>
