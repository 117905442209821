import { CommonModule } from '@angular/common';
import { ComponentFactoryResolver, Directive, Input, NgModule, TemplateRef, ViewContainerRef } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { MatProgressSpinner, MatProgressSpinnerModule } from '@angular/material/progress-spinner';

/**
 * This directive swaps the attached template for a spinner based upon some expression
 * @example `<mat-icon *appSpinner="isLoading"></mat-icon>`
 */
@Directive({
  selector: '[appSpinner]',
})
export class SpinnerDirective {
  color: ThemePalette = 'accent';
  diameter: number = 60;
  isAbsolute: boolean = false;
  isSpinning: boolean | null = null; // unset by default, this forces it to evaluate upon first render.
  spinner: MatProgressSpinner | null = null;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
  ) {}

  @Input()
  set appSpinnerIsAbsolute(isAbsolute: boolean) {
    this.isAbsolute = isAbsolute;
    // if (isAbsolute && this.spinner) {
    //   this.spinner._elementRef.nativeElement.classList.add('spinner-absolute');
    // }
  }

  @Input()
  set appSpinnerColor(color: ThemePalette) {
    this.color = color;
    if (this.spinner) {
      this.spinner.color = color;
    }
  }

  @Input()
  set appSpinnerDiameter(diameter: number) {
    this.diameter = diameter;
    if (this.spinner) {
      this.spinner.diameter = diameter;
    }
  }

  @Input() set appSpinner(condition: boolean) {
    if (!!condition !== this.isSpinning) {
      this.spinner = null;
      this.viewContainer.clear();
      this.isSpinning = condition;
      if (!condition) {
        // Render the template
        this.viewContainer.createEmbeddedView(this.templateRef);
      } else if (condition) {
        setTimeout(() => this.addSpinner(), 0); // Sorry ale tak na szybko nie miałem innego pomysłu na to :P
      }
    }
  }

  private addSpinner() {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(MatProgressSpinner);
    const { instance } = this.viewContainer.createComponent<MatProgressSpinner>(componentFactory);
    instance.diameter = this.diameter;
    instance.color = this.color;
    instance.mode = 'indeterminate';

    const el = instance._elementRef.nativeElement;
    el.classList.add('spinner-instance');
    el.style.margin = '2px';
    el.style.minHeight = `${this.diameter}px`;

    this.isAbsolute &&
      (el.style.cssText = `
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        z-index: 9999;
      `);

    this.spinner = instance;
  }
}

@NgModule({
  imports: [CommonModule, MatProgressSpinnerModule],
  declarations: [SpinnerDirective],
  exports: [SpinnerDirective, MatProgressSpinnerModule],
})
export class SpinnerModule {}
