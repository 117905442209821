import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TranslationService } from '../service/translation.service';

const DEFAULT_DATE_FORMAT = 'HH:mm dd.MM.yyyy';

@Pipe({
  name: 'dateFormat',
})
export class DateFormatPipe extends DatePipe implements PipeTransform {
  constructor(
    private translateService: TranslateService,
    private translationService: TranslationService,
  ) {
    super(translationService.storageLang || translationService.defaultLang);
  }

  transform(value: any, format?: string): any {
    try {
      const fmt = format || this.translateService.instant('formats.date') || DEFAULT_DATE_FORMAT;
      return super.transform(value, fmt);
    } catch (error) {
      console.error(error);
      return null;
    }
  }
}
