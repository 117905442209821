/* eslint-disable max-len */
import { Renderer2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { GuidesAvailable } from '../GuidesAvailable.enum';
import { Guide } from '../models/Guide';
import { GuideStep } from '../models/steps/GuideStep';
import { GuideStepDone } from '../models/steps/GuideStepDone';
import { ClickSemTableInnerAction } from '../models/actions/ClickSemTableInnerAction';
import { ClickInnerAction } from '../models/actions/ClickInnerAction';
import { ClickSemTablePopupInnerAction } from '../models/actions/ClickSemTablePopupInnerAction';
import { RequestInnerAction } from '../models/actions/RequestInnerAction';

export function createProductOptimisationTutorial(
  renderer: Renderer2,
  translateService: TranslateService,
  bottomSheet: MatBottomSheet,
): Guide {
  const clickStep1 = new ClickInnerAction(
    translateService.instant('tutorials.product_optimisation.step_1_action_1'),
    renderer,
    'tutorial-project-topbar-btn',
  );
  const clickStep1b = new ClickInnerAction(
    translateService.instant('tutorials.product_optimisation.step_1_action_2'),
    renderer,
    'tutorial-projects-list',
  );
  const step1: GuideStep = new GuideStep(
    translateService.instant('tutorials.product_optimisation.step_1_title'),
    translateService.instant('tutorials.product_optimisation.step_1_description'),
    [clickStep1, clickStep1b],
  );

  const action2a = new ClickSemTableInnerAction(
    translateService.instant('tutorials.product_optimisation.step_2_action_1'),
    renderer,
    'columnToolButton',
  );
  const action2b = new ClickSemTableInnerAction(
    translateService.instant('tutorials.product_optimisation.step_2_action_2'),
    renderer,
    'add-new-column-btn',
  );
  const action2c = new ClickSemTableInnerAction(
    translateService.instant('tutorials.product_optimisation.step_2_action_3'),
    renderer,
    'display-column-in-choose-list',
  );
  const action2d = new ClickSemTableInnerAction(
    translateService.instant('tutorials.product_optimisation.step_2_action_4'),
    renderer,
    'close-tool-btn',
  );
  const step2: GuideStep = new GuideStep(
    translateService.instant('tutorials.product_optimisation.step_2_title'),
    translateService.instant('tutorials.product_optimisation.step_2_description'),
    [action2a, action2b, action2c, action2d],
  );

  const action3a = new ClickSemTableInnerAction(
    translateService.instant('tutorials.product_optimisation.step_3_action_1'),
    renderer,
    'tutorial-product-google-data-attach',
  );
  const action3b = new ClickSemTablePopupInnerAction(
    translateService.instant('tutorials.product_optimisation.step_3_action_2'),
    renderer,
    'tutorial-product-google-data-time-picker',
    translateService.instant('tutorials.product_optimisation.step_3_action_2'),
    bottomSheet,
  );
  const action3c = new ClickSemTablePopupInnerAction(
    translateService.instant('tutorials.product_optimisation.step_3_action_3'),
    renderer,
    'tutorial-product-google-data-submit-btn',
    translateService.instant('tutorials.product_optimisation.step_3_action_3'),
    bottomSheet,
  );

  const step3: GuideStep = new GuideStep(
    translateService.instant('tutorials.product_optimisation.step_3_title'),
    translateService.instant('tutorials.product_optimisation.step_3_description'),
    [action3a, action3b, action3c],
  );

  const action4a = new ClickSemTableInnerAction(
    translateService.instant('tutorials.product_optimisation.step_4_action_1'),
    renderer,
    'filterManageBtn',
  );
  const action4b = new ClickSemTablePopupInnerAction(
    translateService.instant('tutorials.product_optimisation.step_4_action_2'),
    renderer,
    'add-new-filter-btn',
    translateService.instant('tutorials.product_optimisation.step_4_action_2'),
    bottomSheet,
  );
  const action4c = new ClickSemTablePopupInnerAction(
    translateService.instant('tutorials.product_optimisation.step_4_action_3'),
    renderer,
    'description-column-btn-Default',
    translateService.instant('tutorials.product_optimisation.step_4_action_3'),
    bottomSheet,
  );
  const action4d = new ClickSemTablePopupInnerAction(
    translateService.instant('tutorials.product_optimisation.step_4_action_4'),
    renderer,
    'include',
    translateService.instant('tutorials.product_optimisation.step_4_action_4'),
    bottomSheet,
  );
  const action4e = new ClickSemTablePopupInnerAction(
    translateService.instant('tutorials.product_optimisation.step_4_action_5'),
    renderer,
    'filterValueChooser',
    translateService.instant('tutorials.product_optimisation.step_4_action_5'),
    bottomSheet,
  );
  const action4ee = new ClickSemTablePopupInnerAction(
    translateService.instant('tutorials.product_optimisation.step_4_action_6'),
    renderer,
    'submitNewFilterBtn',
    translateService.instant('tutorials.product_optimisation.step_4_action_6'),
    bottomSheet,
  );
  const action4f = new ClickSemTablePopupInnerAction(
    translateService.instant('tutorials.product_optimisation.step_4_action_7'),
    renderer,
    'add-btn',
    translateService.instant('tutorials.product_optimisation.step_4_action_7'),
    bottomSheet,
  );
  const action4g = new ClickSemTablePopupInnerAction(
    translateService.instant('tutorials.product_optimisation.step_4_action_8'),
    renderer,
    'title-column-btn-Default',
    translateService.instant('tutorials.product_optimisation.step_4_action_8'),
    bottomSheet,
  );
  const action4h = new ClickSemTablePopupInnerAction(
    translateService.instant('tutorials.product_optimisation.step_4_action_9'),
    renderer,
    'include',
    translateService.instant('tutorials.product_optimisation.step_4_action_9'),
    bottomSheet,
  );
  const action4i = new ClickSemTablePopupInnerAction(
    translateService.instant('tutorials.product_optimisation.step_4_action_10'),
    renderer,
    'filterValueChooser',
    translateService.instant('tutorials.product_optimisation.step_4_action_10'),
    bottomSheet,
  );
  const action4ii = new ClickSemTablePopupInnerAction(
    translateService.instant('tutorials.product_optimisation.step_4_action_11'),
    renderer,
    'submitNewFilterBtn',
    translateService.instant('tutorials.product_optimisation.step_4_action_11'),
    bottomSheet,
  );
  const action4j = new ClickSemTablePopupInnerAction(
    translateService.instant('tutorials.product_optimisation.step_4_action_12'),
    renderer,
    'operator',
    translateService.instant('tutorials.product_optimisation.step_4_action_12'),
    bottomSheet,
  );
  const action4k = new ClickSemTablePopupInnerAction(
    translateService.instant('tutorials.product_optimisation.step_4_action_13'),
    renderer,
    'submitFiltersBtn',
    translateService.instant('tutorials.product_optimisation.step_4_action_13'),
    bottomSheet,
  );
  const step4: GuideStep = new GuideStep(
    translateService.instant('tutorials.product_optimisation.step_4_title'),
    translateService.instant('tutorials.product_optimisation.step_4_description'),
    [action4a, action4b, action4c, action4d, action4e, action4ee, action4f, action4g, action4h, action4i, action4ii, action4j, action4k],
  );

  const action5 = new RequestInnerAction('productsDownload');
  const action5a = new ClickSemTableInnerAction(
    translateService.instant('tutorials.product_optimisation.step_5_action_1'),
    renderer,
    'title-column-display',
    'keyup',
  );
  const action5b = new ClickSemTableInnerAction(
    translateService.instant('tutorials.product_optimisation.step_5_action_2'),
    renderer,
    'title-column-display',
    'focusout',
  );
  const action5c = new ClickSemTableInnerAction(
    translateService.instant('tutorials.product_optimisation.step_5_action_3'),
    renderer,
    'quick-actions-btn tutorial-on',
  );
  const step5: GuideStep = new GuideStep(
    translateService.instant('tutorials.product_optimisation.step_5_title'),
    translateService.instant('tutorials.product_optimisation.step_5_description'),
    [action5, action5a, action5b, action5c],
  );

  const action6a = new ClickSemTableInnerAction(
    translateService.instant('tutorials.product_optimisation.step_6_action_1'),
    renderer,
    'filterManageBtn',
  );

  const action6b = new ClickSemTableInnerAction(
    translateService.instant('tutorials.product_optimisation.step_6_action_2'),
    renderer,
    'close-button',
  );
  const action6c = new ClickSemTableInnerAction(
    translateService.instant('tutorials.product_optimisation.step_6_action_3'),
    renderer,
    'tutorials-create-button',
  );
  const action6cc = new ClickSemTablePopupInnerAction(
    translateService.instant('tutorials.product_optimisation.step_6_action_4'),
    renderer,
    'column-chooser',
    translateService.instant('tutorials.product_optimisation.step_6_action_4'),
    bottomSheet,
  );
  const action6ccc = new ClickSemTablePopupInnerAction(
    translateService.instant('tutorials.product_optimisation.step_6_action_5'),
    renderer,
    'visibility-column-btn-Default',
    translateService.instant('tutorials.product_optimisation.step_6_action_5'),
    bottomSheet,
  );

  const action6d = new ClickSemTablePopupInnerAction(
    translateService.instant('tutorials.product_optimisation.step_6_action_6'),
    renderer,
    'valueActionContainer',
    translateService.instant('tutorials.product_optimisation.step_6_action_6'),
    bottomSheet,
  );

  const action6dd = new ClickSemTablePopupInnerAction(
    translateService.instant('tutorials.product_optimisation.step_6_action_7'),
    renderer,
    'submitActionBtn',
    translateService.instant('tutorials.product_optimisation.step_6_action_7'),
    bottomSheet,
  );

  const action6e = new ClickInnerAction(
    translateService.instant('tutorials.product_optimisation.step_6_action_8'),
    renderer,
    'tutorial-sidemenu-rules',
  );
  const action6f = new ClickInnerAction(
    translateService.instant('tutorials.product_optimisation.step_6_action_9'),
    renderer,
    'removeRuleBtn',
  );
  const step6: GuideStep = new GuideStep(
    translateService.instant('tutorials.product_optimisation.step_6_title'),
    translateService.instant('tutorials.product_optimisation.step_6_description'),
    [action6a, action6b, action6c, action6cc, action6ccc, action6d, action6dd, action6e, action6f],
  );
  const guide: Guide = new Guide(GuidesAvailable.optimise, translateService.instant('tutorials.product_optimisation.guide_desc'), [
    step1,
    step2,
    step3,
    step4,
    step5,
    step6,
  ]);
  const doneStep = new GuideStepDone(
    translateService.instant('tutorials.product_optimisation.step_done_title'),
    translateService.instant('tutorials.product_optimisation.step_done_description'),
  );
  guide.guideSteps.push(doneStep);
  return guide;
}
