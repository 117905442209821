<div class="package-container">
  <div class="header">
    <div class="package-name">
      {{ package.package_name | titlecase }}
      <div class="chosen-container" *ngIf="packageIsBought">
        <span>
          {{ 'payments.packages.choosen' | translate }}
        </span>
        <mat-icon>star</mat-icon>
      </div>
    </div>

    <div class="price-wrapper">
      <div class="package-price">
        <div class="price">{{ package.price / 100 }}.00 {{ package.currency | uppercase }}</div>
        <div class="tax" *ngIf="package.tax">+ {{ package.tax }}% VAT</div>
      </div>
    </div>

    <ng-container *ngIf="package.id">
      <ng-container *ngTemplateOutlet="!currentPackageIsInProgress ? selectPackageButtonRef : changePackageButtonRef"> </ng-container>
    </ng-container>
  </div>

  <div class="section" *ngFor="let element of paymentElements; let index = index" [ngClass]="{ highlight: index % 2 === 0 }">
    <ng-container [ngTemplateOutlet]="elementRef" [ngTemplateOutletContext]="{ element: package.elements[element] }"></ng-container>
  </div>

  <ng-container *ngIf="package.id">
    <ng-container *ngTemplateOutlet="!currentPackageIsInProgress ? selectPackageButtonRef : changePackageButtonRef"> </ng-container>
  </ng-container>
</div>

<ng-template #selectPackageButtonRef>
  <button mat-button color="primary" [disabled]="buyingInProgress" (click)="selectPackage()">
    <span>
      {{ package.trial ? ('payments.packages.select_package_trial' | translate) : ('payments.packages.select_package' | translate) }}
      <mat-spinner *ngIf="buyingInProgress" [diameter]="15"></mat-spinner>
    </span>
  </button>
</ng-template>

<ng-template #changePackageButtonRef>
  <button [disabled]="packageIsBought || buyingInProgress" mat-button color="primary" (click)="changePackage()">
    <span>
      {{
        packageIsBought
          ? ('payments.packages.choosen' | translate | titlecase)
          : package.trial
            ? ('payments.packages.select_package_trial' | translate | titlecase)
            : ('payments.packages.change_package' | translate | titlecase)
      }}
      <mat-spinner *ngIf="buyingInProgress" [diameter]="15"></mat-spinner>
    </span>
  </button>
</ng-template>

<ng-template #elementRef let-element="element">
  <ng-container *ngIf="element; else emptyElement">
    <span *ngIf="element.type === 'string'">
      <div>{{ element.value }}</div>
      <div *ngIf="element.amount" class="amount">{{ element.amount }}</div>
    </span>
    <span *ngIf="element.type === 'boolean'">
      <mat-icon *ngIf="element.value">check</mat-icon>
      <mat-icon *ngIf="!element.value">close</mat-icon>
    </span>
  </ng-container>

  <ng-template #emptyElement>
    <span>
      <mat-icon>close</mat-icon>
    </span>
  </ng-template>
</ng-template>
