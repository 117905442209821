import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { delay, filter, tap } from 'rxjs/operators';
import CommonUrlsConfig from '../configs/common-urls.config';
import { NotificationService } from '../notification/notification.service';
import { RouterService, routerConfig } from '../shared/service/router.service';
import { MaintenanceService } from './maintenance.service';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss'],
})
export class MaintenanceComponent implements OnInit {
  protected endDate!: string;
  protected message!: string;
  protected isLoading = false;
  protected sembotWebsiteUrl: string = CommonUrlsConfig.sembotWebsite;
  protected rawSembotWebsiteUrl: string = this.extractDomain(this.sembotWebsiteUrl);
  private maintenanceService = inject(MaintenanceService);
  private notificationService = inject(NotificationService);
  private routerService = inject(RouterService);
  private translateService = inject(TranslateService);
  private route = inject(ActivatedRoute);

  ngOnInit(): void {
    this.refresh();
  }

  refresh() {
    this.isLoading = true;

    this.maintenanceService
      .check()
      .pipe(
        delay(1000),
        tap(() => (this.isLoading = false)),
        tap((res) => (this.message = res.message || this.translateService.instant('maintenance.default_info'))),
        tap((res) => (this.endDate = res.enddate ? res.enddate.toString() : null!)),
        tap(
          () => (this.isLoading = false),
          () => (this.isLoading = false),
        ),
        filter((res) => !res.maintenance),
      )
      .subscribe(() => {
        this.notificationService.info('maintenance_completed');
        this.routerService.navigate(routerConfig.home);
      });
  }

  private extractDomain(url: string) {
    const parsedUrl = new URL(url);
    return parsedUrl.hostname;
  }
}
