/* eslint-disable no-underscore-dangle */
import { ElementRef, Renderer2 } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { TutorialElementDirective } from '../../tutorial-element.directive';
import { TutorialService } from '../../tutorial.service';
import { ClickSemTableInnerAction } from './ClickSemTableInnerAction';

export class ClickSemTablePopupInnerAction extends ClickSemTableInnerAction {
  constructor(
    text: string,
    renderer: Renderer2,
    idToHighlight: string,
    public textOnTooltip: string,
    public bottomSheet: MatBottomSheet,
    eventListened: string = 'click',
  ) {
    super(text, renderer, idToHighlight, eventListened);
  }

  protected attachDirectiveToElement(el: ElementRef, tutorialService: TutorialService) {
    this.tutorialDirective = new TutorialElementDirective(el, this.renderer!, this.bottomSheet);
    this.tutorialDirective.actionId = this.idToHighlight!;
    this.tutorialDirective.activatedAction$ = tutorialService.activatedAction$;
    this.tutorialDirective.ngOnInit();
  }
}
