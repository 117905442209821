import { ColumnTypesEnum } from '../../table-display/ColumnTypesEnum';
import { FilterLogicOperatorEnum } from '../FilterLogicOperatorEnum';
import { FilterTypesEnum } from '../FilterTypesEnum';
import { FilterModel } from './filter.model';

export class FieldNotIncludeFieldFilterModel extends FilterModel {
  symbol: FilterTypesEnum = FilterTypesEnum.field_not_include_field;

  constructor(
    param: string,
    // eslint-disable-next-line @typescript-eslint/default-param-last
    value: any = null,
    columnType: ColumnTypesEnum,
    operator: FilterLogicOperatorEnum,
    case_sensitive?: boolean,
    additional_sources?: any,
  ) {
    super(param, value, columnType, operator, case_sensitive, additional_sources);
    this.value = value && this.columnType === ColumnTypesEnum.SIMPLE ? value.toString() : value;
  }

  isValid() {
    return !!this.value;
  }
}
