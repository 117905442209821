<ng-template #promptNotification let-notificationData="notification">
  <app-prompt-template
    type="notificationData.type"
    [id]="notificationData.id"
    [message]="notificationData.message"
    (responsed)="handlePromptCallback(notificationData.id, $event)"
  ></app-prompt-template>
</ng-template>

<ng-template #defaultNotification let-notificationData="notification">
  <app-default-template type="notificationData.type" [id]="notificationData.id" [message]="notificationData.message"></app-default-template>
</ng-template>

<ng-template #asyncNotification let-notificationData="notification">
  <app-async-template
    type="notificationData.type"
    [id]="notificationData.id"
    [message]="notificationData.message"
    [state]="asyncsResults"
  ></app-async-template>
</ng-template>

<notifier-container></notifier-container>
