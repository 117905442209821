<div class="row">
  <ng-container *ngIf="isWelcomeScreenVisible && source; else confirmView">
    <app-welcome-screen [mode]="source"></app-welcome-screen>
  </ng-container>

  <ng-template #confirmView>
    <div class="col">
      <div class="confirmation__container">
        <div class="top">
          <h1 class="confirmation__title">{{ 'auth.account_confirm.title' | translate }}</h1>
        </div>

        <div class="main-content">
          <div [ngSwitch]="confirmStatus">
            <section *ngSwitchCase="confirmStatusesEnum.error">
              <p class="confirmation__description">{{ 'auth.account_confirm.error.txt1' | translate }}</p>
              <p class="confirmation__description">{{ 'auth.account_confirm.error.txt2' | translate }}</p>
            </section>
            <section *ngSwitchCase="confirmStatusesEnum.loading">
              <p class="confirmation__description">{{ 'auth.account_confirm.loading.txt' | translate }}</p>
            </section>
            <section *ngSwitchCase="confirmStatusesEnum.verified">
              <p class="confirmation__description">{{ 'auth.account_confirm.verified.txt' | translate }}</p>
            </section>
          </div>
        </div>

        <button (click)="redirect()" [disabled]="!isButtonUnlocked" class="confirmation__redirect" color="primary" mat-raised-button>
          {{ isButtonUnlocked ? ('auth.account_confirm.redirect_btn_txt' | translate) : timeToUnlock }}
        </button>
      </div>

      <app-brand-logos></app-brand-logos>
    </div>
  </ng-template>
</div>
