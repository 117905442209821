import { Component, ComponentFactoryResolver, ViewChild, ViewContainerRef } from '@angular/core';
import { ConnectionModel } from 'src/app/shared/model/connections.model';
import { ServicesSlugsEnum } from '../../connections.model';
import { AdwordsServiceComponent } from '../../services-components/adwords-service/adwords-service.component';
import { AnalyticsServiceComponent } from '../../services-components/analytics-service/analytics-service.component';
import { Analytics4ServiceComponent } from '../../services-components/analytics4-service/analytics4-service.component';
import { BingServiceComponent } from '../../services-components/bing-service/bing-service.component';
import { GoogleMerchantCenterServiceComponent } from '../../services-components/google-merchant-center-service/google-merchant-center-service.component';
import { GscServiceComponent } from '../../services-components/gsc-service/gsc-service.component';
import { ShopifyServiceComponent } from '../../services-components/shopify-service/shopify-service.component';
import { FacebookBusinessServiceComponent } from '../../services-components/facebook-business-service/facebook-business-service.component';
import { FacebookServiceComponent } from '../../services-components/facebook-service/facebook-service.component';

const AVAILABLE_SERVICES_COMPONENTS = {
  [ServicesSlugsEnum.adwords]: AdwordsServiceComponent,
  [ServicesSlugsEnum.analytics4]: Analytics4ServiceComponent,
  [ServicesSlugsEnum.analytics]: AnalyticsServiceComponent,
  [ServicesSlugsEnum.gmc]: GoogleMerchantCenterServiceComponent,
  [ServicesSlugsEnum.gsc]: GscServiceComponent,
  [ServicesSlugsEnum.bing]: BingServiceComponent,
  [ServicesSlugsEnum.facebook]: FacebookServiceComponent,
  [ServicesSlugsEnum.facebookBusiness]: FacebookBusinessServiceComponent,
  [ServicesSlugsEnum.shopify]: ShopifyServiceComponent,
};

@Component({
  template: '',
})
export class ServicesFactoryComponent {
  @ViewChild('serviceComponentRef', { read: ViewContainerRef, static: true }) vcRef!: ViewContainerRef;

  // TODO:
  // AdwordsServiceComponent | AnalyticsServiceComponent | Analytics4ServiceComponent | FacebookServiceComponent | BingServiceComponent;
  serviceComponent: any = null;
  integrationSectionVisible = true;

  constructor(public resolver: ComponentFactoryResolver) {}

  get isFormValid() {
    return (this.serviceComponent.form && !this.serviceComponent.form.invalid) || false;
  }

  get areConnections() {
    return this.serviceComponent?.connections && this.serviceComponent.connections.length;
  }

  get isLoading() {
    return this.serviceComponent?.isLoading || false;
  }

  createServiceComponent(serviceSlug: ServicesSlugsEnum, connections: ConnectionModel[]) {
    let serviceComponent: any = null;

    if (serviceSlug && AVAILABLE_SERVICES_COMPONENTS[serviceSlug as keyof typeof AVAILABLE_SERVICES_COMPONENTS]) {
      const factory = this.resolver.resolveComponentFactory(
        AVAILABLE_SERVICES_COMPONENTS[serviceSlug as keyof typeof AVAILABLE_SERVICES_COMPONENTS] as any,
      );
      const componentRef = this.vcRef.createComponent(factory);

      if (componentRef && componentRef.instance) {
        serviceComponent = componentRef.instance;

        if (connections) {
          serviceComponent.connections = connections;
          this.integrationSectionVisible = false;
        }
        !serviceComponent.service && (serviceComponent.service = null);
      }
    }

    this.serviceComponent = serviceComponent;
  }
}
