import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { SetupIntent, StripeError } from '@stripe/stripe-js';
import { StripeCardComponent, StripeService } from 'ngx-stripe';
import { Observable, Subject, of, throwError } from 'rxjs';
import { catchError, filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import stripeConfig from 'src/app/configs/stripe.config';
import { NotificationService } from 'src/app/notification/notification.service';
import { ConfirmModalComponent } from 'src/app/shared/modals/confirm-modal/confirm-modal.component';
import { FormApiValidationError } from 'src/app/shared/model/errors/formApiError.model';
import { InvoiceService } from '../../invoice/invoice.service';
import { PaymentsService } from '../../payments.service';
import { StripeKeyService } from '../../stripe-key.service';

export type CardSetup = {
  coupon?: string;
  error?: StripeError;
  setupIntent?: SetupIntent;
};

@Component({
  selector: 'app-card-attach-form',
  templateUrl: './card-attach-form.component.html',
  styleUrls: ['./card-attach-form.component.scss'],
})
export class CardAttachFormComponent implements OnInit, OnDestroy {
  @ViewChild(StripeCardComponent) card!: StripeCardComponent;
  @Output() cardSetupCreated: EventEmitter<CardSetup> = new EventEmitter();
  @Output() includeDiscount: EventEmitter<string> = new EventEmitter();
  @Input() invoiceAvailable = false;
  @Input() packageId!: number;
  @Input() submitTranslationText!: string;

  private onDestroy$ = new Subject<void>();
  private invoiceCanceled$ = new Subject<void>();

  invoiceForm!: UntypedFormGroup;
  isCouponVisible = new UntypedFormControl(false);
  isCurrentCouponChecked: boolean = false;
  isLoading = false;
  needInvoiceCtrl = new UntypedFormControl(true);
  stripeConfig = stripeConfig;
  submitDisabled!: boolean;
  stripeForm: UntypedFormGroup = new UntypedFormGroup({
    coupon: new UntypedFormControl('', []),
    name: new UntypedFormControl('', [Validators.required]),
    invoice: new UntypedFormControl(false, []),
  });

  get couponCtrl() {
    return this.stripeForm.get('coupon') as UntypedFormControl;
  }

  get nameCtrl() {
    return this.stripeForm.get('name') as UntypedFormControl;
  }

  constructor(
    private dialog: MatDialog,
    private invoiceService: InvoiceService,
    private notificationService: NotificationService,
    private paymentsService: PaymentsService,
    private stripeKeyService: StripeKeyService,
    private stripeService: StripeService,
  ) {
    this.paymentsService.getAccountCoupon().subscribe((coupon) => {
      if (coupon) {
        this.couponCtrl.setValue(coupon);
        this.isCouponVisible.setValue(true);
        this.validateCoupon();
      }
    });
  }

  ngOnInit(): void {
    this.stripeForm.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(() => this.checkIfSubmitShouldBeDisabled());

    this.needInvoiceCtrl.valueChanges
      .pipe(
        takeUntil(this.onDestroy$),
        filter((value) => !value),
        tap(() => (this.invoiceForm = null!)),
        tap(() => this.checkIfSubmitShouldBeDisabled()),
      )
      .subscribe(() => this.invoiceCanceled$.next());

    this.checkIfSubmitShouldBeDisabled();

    this.isCurrentCouponChecked = this.couponCtrl.value ? false : true;
  }

  watchForInvoice(form: UntypedFormGroup) {
    this.submitDisabled = form.invalid && this.stripeForm.invalid;
    this.invoiceForm = form;
    this.checkIfSubmitShouldBeDisabled();

    form.valueChanges
      .pipe(takeUntil(this.onDestroy$), takeUntil(this.invoiceCanceled$))
      .subscribe(() => this.checkIfSubmitShouldBeDisabled());
  }

  changeCouponVisible() {
    this.couponCtrl.setValue(null);
  }

  checkIfSubmitShouldBeDisabled() {
    const needInvoiceButInvalid = this.invoiceAvailable && this.needInvoiceCtrl.value && this.invoiceForm && this.invoiceForm.invalid;
    this.submitDisabled = this.stripeForm.invalid || needInvoiceButInvalid;
  }

  keyupCoupon() {
    this.couponCtrl.value && (this.isCurrentCouponChecked = false);
  }

  submit() {
    if (this.stripeForm.invalid) {
      return;
    }
    this.confirmBuying();
  }

  validateCoupon() {
    const coupon = this.couponCtrl.value;
    this.isCurrentCouponChecked = true;
    this.includeDiscount.emit(coupon);
  }

  private confirmBuying() {
    const config = {
      data: { key: 'confirmations.buy_package' },
      panelClass: 'no-dialog-spinner',
    } as MatDialogConfig;

    const dialog = this.dialog.open(ConfirmModalComponent, config);

    dialog
      .afterClosed()
      .pipe(
        filter((res) => !!res),
        tap(() => (this.isLoading = true)),
        switchMap(() => (this.needInvoiceCtrl.value && this.invoiceAvailable ? this.saveInvoiceData(this.invoiceForm) : of(true))),
        switchMap(() => this.stripeKeyService.getClientSecret()),
        switchMap((clientSecret) => this.getCardSetup(clientSecret)),
      )
      .subscribe((cardSetup) => {
        this.couponCtrl.value && (cardSetup.coupon = this.couponCtrl.value);
        this.cardSetupCreated.emit(cardSetup);
      });
  }

  private getCardSetup(clientSecret: string): Observable<CardSetup> {
    return this.stripeService
      .confirmCardSetup(clientSecret, {
        payment_method: {
          card: this.card.element,
          billing_details: {
            name: this.nameCtrl.value,
          },
        },
      })
      .pipe(
        takeUntil(this.onDestroy$),
        tap((res) => {
          if (res.error) {
            this.notificationService.error('subscription_confirmation_error', { error: res.error.message });
            this.isLoading = false;
            throw new Error(res.error.message);
          }

          return res;
        }),
      );
  }

  private saveInvoiceData(form: UntypedFormGroup) {
    const data = form.value;
    return this.invoiceService
      .setInvoiceData(data.company_name, data.address_1, data.address_2, data.postal_code, data.city, data.tax_id)
      .pipe(
        takeUntil(this.onDestroy$),
        catchError((er: FormApiValidationError) => {
          er.setOnForm && er.setOnForm(form);
          this.isLoading = false;
          return throwError(er);
        }),
      );
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
