import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { ComparatorService } from 'src/app/dashboard/comparator/comparator.service';
import { ComparatorModel } from 'src/app/dashboard/comparator/models/comparator.model';

@Injectable()
export class ComparatorResolver implements Resolve<ComparatorModel> {
  comparatorModel!: ComparatorModel;

  constructor(
    private router: Router,
    private comparatorService: ComparatorService,
  ) {}

  resolve(route: ActivatedRouteSnapshot) {
    return this.comparatorService.getComparator(route.params.id).pipe(first());
  }
}
