<div class="auth-container">
  <a appRouter="/" class="logo">
    <img class="logo-image" src="assets/images/logo-black-sm.png" />
  </a>

  <section>
    <router-outlet></router-outlet>
  </section>

  <app-auth-change-language></app-auth-change-language>
</div>
