<form [formGroup]="form">
  <!-- Connections -->

  <mat-form-field>
    <mat-label>
      {{ 'projects.connections.form.fields.connection.label' | translate }}
    </mat-label>
    <mat-select [formControl]="connection" name="connection" (selectionChange)="changeConnection()">
      <mat-option *ngFor="let connection of connections" [value]="connection">
        {{ connection | connectionLabel }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <!-- Customer -->

  <mat-form-field *ngIf="!searching">
    <mat-label>
      {{ 'projects.connections.form.fields.customer.placeholder' | translate }}
    </mat-label>
    <mat-select [disabled]="!connection.value" [formControl]="customer" name="adwordsCustomer">
      <mat-option>
        <ngx-mat-select-search
          name="adsCustomerAutocomplete"
          [placeholderLabel]="'projects.connections.form.fields.customer.search.placeholder' | translate"
          [noEntriesFoundLabel]="'projects.connections.form.fields.customer.search.no_data_info' | translate"
          [searching]="searching"
          [formControl]="customerFilter"
        >
        </ngx-mat-select-search>
      </mat-option>

      <mat-option *ngFor="let customer of filteredCustomersList" [value]="customer">
        {{ customer.id }} {{ customer.name ? '(' + customer.name + ')' : '' }}
      </mat-option>
    </mat-select>
    <app-form-field-error-display matError></app-form-field-error-display>
  </mat-form-field>
  <mat-progress-spinner *ngIf="searching" mode="indeterminate"></mat-progress-spinner>

  <!-- Inner Customer -->

  <mat-form-field *ngIf="customer.value && customer.value.accounts && customer.value.accounts[0]">
    <mat-label>
      {{ 'projects.connections.form.fields.customer.placeholderV2' | translate }}
    </mat-label>
    <mat-select [disabled]="!customer.value" [formControl]="innerCustomer" name="adwordsInnerCustomer">
      <mat-option>
        <ngx-mat-select-search
          name="adsCustomerAutocomplete"
          [placeholderLabel]="'projects.connections.form.fields.customer.search.placeholder' | translate"
          [noEntriesFoundLabel]="'projects.connections.form.fields.customer.search.no_data_info' | translate"
          [searching]="searching"
          [clearSearchInput]="false"
          [formControl]="innerCustomersFilter"
        >
        </ngx-mat-select-search>
      </mat-option>

      <mat-option *ngFor="let customer of filteredInnerCustomersList$ | async" [value]="customer">
        {{ customer.id }} ({{ customer.name }})
      </mat-option>
    </mat-select>
    <app-form-field-error-display matError></app-form-field-error-display>
  </mat-form-field>
</form>
