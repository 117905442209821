<div [ngSwitch]="service">
  <section *ngSwitchCase="connectionsServiceEnum.Shopify">
    <app-connection-form-shopify (connected)="onConnected()"></app-connection-form-shopify>
  </section>

  <section *ngSwitchDefault>
    <ng-container *ngIf="url">
      <app-button-brand
        *ngIf="url.brand; else integrateLink"
        (click)="goToUrl()"
        [activatedAction$]="tutorialService.activatedAction$"
        [brand]="url.brand"
        [isDisabled]="isIntegrateDisabled"
        appTutorialElement="tutorial-new-connection-connect-btn"
        class="integrate-link"
        color="yellow"
        mat-raised-button
      ></app-button-brand>

      <ng-template #integrateLink>
        <a
          (click)="goToUrl()"
          [activatedAction$]="tutorialService.activatedAction$"
          [disabled]="isIntegrateDisabled"
          appTutorialElement="tutorial-new-connection-connect-btn"
          class="integrate-link"
          color="yellow"
          mat-raised-button
        >
          {{ 'connections.form.connect_btn_txt' | translate }}
        </a>
      </ng-template>
    </ng-container>
  </section>
</div>
