<mat-card appearance="outlined">
  <div class="header">
    <div class="title-container">
      <button mat-icon-button (click)="back()">
        <mat-icon>arrow_back</mat-icon>
      </button>
      <div class="title">
        {{ 'payments.current_package.payment_settings' | translate }}
      </div>
    </div>
  </div>
  <mat-card-content>
    <app-card-attach-form (cardSetupCreated)="reconnectCard($event)"></app-card-attach-form>
  </mat-card-content>
</mat-card>
