<div [cdkTrapFocusAutoCapture]="true" cdkTrapFocus class="row">
  <div class="col">
    <div class="forgot__container">
      <div class="top">
        <h1 class="forgot__title">{{ 'auth.forgot_password.title' | translate }}</h1>
        <div appWhiteLabelRestrictions class="bottom">
          <p class="forgot__description">{{ 'auth.forgot_password.description' | translate }}</p>
        </div>
      </div>

      <form (ngSubmit)="onSubmit()" [formGroup]="form" id="form">
        <div class="main-content">
          <div *ngIf="form.errors && form.errors['serverError']" class="errorMessage">
            {{ form.errors['serverError'] }}
          </div>

          <label class="forgot__form-label">{{ 'auth.register.form.fields.email.label' | translate }}</label>
          <mat-form-field appearance="outline">
            <input [formControl]="email" [placeholder]="'auth.register.form.fields.email.placeholder' | translate" matInput type="email" />
            <app-form-field-error-display matError></app-form-field-error-display>
          </mat-form-field>
        </div>

        <app-button
          [fullWidth]="true"
          [loading]="isLoading"
          [text]="'auth.login.form.submit_btn_txt' | translate"
          color="primary"
          size="large"
          type="submit"
        ></app-button>
      </form>
    </div>
    <app-brand-logos></app-brand-logos>
  </div>
</div>
