<mat-card appearance="outlined" *ngIf="isLoaded; else loader">
  <mat-card-header>
    <mat-card-title
      >Unfortunately, the problem could not be fixed automatically - please contact us or
      <button color="primary" mat-raised-button (click)="goToHepCenter()" class="submit-button">go to Help Center</button></mat-card-title
    >
  </mat-card-header>

  <mat-card-content *ngIf="errorMessage">
    <h4>Error:</h4>
    <p class="error-msg">{{ errorMessage }}</p>
  </mat-card-content>
</mat-card>

<ng-template #loader>
  <mat-spinner class="loader" diameter="50"></mat-spinner>
</ng-template>
