import { Component, Input } from '@angular/core';
import { ClipboardService } from 'ngx-clipboard';
import { AuditStatusEnum } from 'src/app/dashboard/project/audit/audit.enum';
import { AuditModel } from 'src/app/dashboard/project/audit/audit.model';
import { CompetitorsSource, RankTypes } from 'src/app/dashboard/project/monitor/monitor.enum';
import { NotificationService } from 'src/app/notification/notification.service';
import { AdsCompetitorsTypeModel, MatchedProductsInputDataModel, MonitorAuditModel } from '../../model/monitor.model';

@Component({
  selector: 'app-audit',
  templateUrl: './audit.component.html',
  styleUrls: ['./audit.component.scss'],
})
export class AuditComponent {
  private _audit!: AuditModel;

  @Input() set audit(data: AuditModel) {
    this._audit = data;
    data && this.prepareMonitorAuditResults();
  }

  get audit(): AuditModel {
    return this._audit;
  }

  constructor(
    private clipboardService: ClipboardService,
    private notificationService: NotificationService,
  ) {}

  auditStatuses = AuditStatusEnum;
  auditUrl!: string;
  isLoaded = false;
  monitorAudit!: MonitorAuditModel;
  organicCompetitors!: { type: RankTypes; data: AdsCompetitorsTypeModel[] };
  paidCompetitors!: { type: RankTypes; data: AdsCompetitorsTypeModel[] };
  restCompetitorsDataSource: MatchedProductsInputDataModel = {};
  shoppingCompetitors!: { type: RankTypes; data: AdsCompetitorsTypeModel[] };
  topCompetitorsDataSource: MatchedProductsInputDataModel = {};

  //charts
  doughnutTextAdsDataSource: any;
  doughnutShoppingAdsDataSource: any;
  doughnutOrganicDataSource: any;

  prepareMonitorAuditResults() {
    if (!this.audit) {
      return false;
    }

    // TODO: do składania urli publicznych zasobów dać jakaś metodę:
    this.auditUrl = `${window.location.origin}/public/audit/${this.audit.project_id}/${this.audit.uuid}`;
    this.monitorAudit = this.audit.monitor_audit || null!;
    this.monitorAudit.product_competitors!.sort((a, b) => b.product_count! - a.product_count! || b.percentage_avg! - a.percentage_avg!);
    this.restCompetitorsDataSource.competitors = this.monitorAudit.product_competitors!.slice(3, 13);
    this.topCompetitorsDataSource.audit = this.restCompetitorsDataSource.audit = this.audit;
    this.topCompetitorsDataSource.competitors = this.monitorAudit.product_competitors!.slice(0, 3);
    this.topCompetitorsDataSource.publicView = this.restCompetitorsDataSource.publicView = this.audit.public;
    this.topCompetitorsDataSource.source = this.restCompetitorsDataSource.source = CompetitorsSource.audit;

    this.organicCompetitors = {
      type: RankTypes.organic,
      data: this.monitorAudit
        .ads_competitors!.map(({ organic }) => organic!)
        .sort(function (a, b) {
          return b!.matched! - a!.matched!;
        })
        .slice(0, 5),
    };

    this.paidCompetitors = {
      type: RankTypes.paid,
      data: this.monitorAudit
        .ads_competitors!.map(({ paid }) => paid!)
        .sort(function (a, b) {
          return b!.matched! - a!.matched!;
        })
        .slice(0, 5),
    };

    this.shoppingCompetitors = {
      type: RankTypes.shopping,
      data: this.monitorAudit
        .ads_competitors!.map(({ shopping }) => shopping!)
        .sort(function (a, b) {
          return b!.matched! - a!.matched!;
        })
        .slice(0, 5),
    };

    this.doughnutTextAdsDataSource = {
      chart: {
        showpercentvalues: 1,
        defaultcenterlabel:
          'Not displayed<br><b>' +
          (
            ((this.monitorAudit.total_product_phrases! - this.monitorAudit.visible_product_phrases_text!) /
              this.monitorAudit.total_product_phrases!) *
            100
          ).toFixed() +
          '%</b>',
        centerlabel: '$label<br><b>$percentValue</b> ',
        showLabels: 0,
        aligncaptionwithcanvas: 0,
        captionpadding: 0,
        plottooltext: '<b>$percentValue</b> of product in text ads are <b>$label</b>',
        theme: 'gammel',
        doughnutRadius: '80%',
        showLegend: 0,
      },
      data: [
        {
          label: 'Displayed',
          value: this.monitorAudit.visible_product_phrases_text,
          color: '#EAEAEA',
        },
        {
          label: 'Not displayed',
          value: this.monitorAudit.total_product_phrases! - this.monitorAudit.visible_product_phrases_text!,
          color: '#D93A31',
        },
      ],
    };

    this.doughnutShoppingAdsDataSource = {
      chart: {
        showpercentvalues: 1,
        defaultcenterlabel:
          'Not displayed<br><b>' +
          (
            ((this.monitorAudit.total_product_phrases! - this.monitorAudit.visible_product_phrases_shopping!) /
              this.monitorAudit.total_product_phrases!) *
            100
          ).toFixed() +
          '%</b>',
        centerlabel: '$label<br><b>$percentValue</b>',
        showLabels: 0,
        aligncaptionwithcanvas: 0,
        captionpadding: 0,
        plottooltext: '<b>$percentValue</b> of product in shopping ads are <b>$label</b>',
        theme: 'gammel',
        doughnutRadius: '80%',
        showLegend: 0,
      },
      data: [
        {
          label: 'Displayed',
          value: this.monitorAudit.visible_product_phrases_shopping,
          color: '#EAEAEA',
        },
        {
          label: 'Not displayed',
          value: this.monitorAudit.total_product_phrases! - this.monitorAudit.visible_product_phrases_shopping!,
          color: '#D93A31',
        },
      ],
    };

    this.doughnutOrganicDataSource = {
      chart: {
        showpercentvalues: 1,
        defaultcenterlabel:
          'Not displayed<br><b>' +
          (
            ((this.monitorAudit.total_product_phrases! - this.monitorAudit.visible_product_phrases_ogranic!) /
              this.monitorAudit.total_product_phrases!) *
            100
          ).toFixed() +
          '%</b>',
        centerlabel: '$label<br><b>$percentValue</b>',
        showLabels: 0,
        aligncaptionwithcanvas: 0,
        //captionpadding: 0,
        plottooltext: '<b>$percentValue</b> of product in organic results  are <b>$label</b>',
        theme: 'gammel',
        doughnutRadius: '80%',
        showLegend: 0,
      },
      data: [
        {
          label: 'Displayed',
          value: this.monitorAudit.visible_product_phrases_ogranic,
          color: '#EAEAEA',
        },
        {
          label: 'Not displayed',
          value: this.monitorAudit.total_product_phrases! - this.monitorAudit.visible_product_phrases_ogranic!,
          color: '#D93A31',
        },
      ],
    };

    this.markLoaded();
  }

  private markLoaded() {
    setTimeout(() => (this.isLoaded = true), 1000);
  }

  copyLink() {
    this.clipboardService.copy(this.auditUrl);
    this.notificationService.success('link_copied');
  }
}
