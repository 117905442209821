import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { MatchedPopupComponent } from '../../../dashboard/project/monitor/matched-popup/matched-popup.component';
import { MatchedProductsInputDataModel, ProductCompetitorsModel } from '../../model/monitor.model';

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

@Component({
  selector: 'app-competitors-table',
  templateUrl: './competitors-table.component.html',
  styleUrls: ['./competitors-table.component.scss'],
})
export class CompetitorsTableComponent implements OnInit {
  @Input() data: MatchedProductsInputDataModel = {};

  tableDataSource!: ProductCompetitorsModel[];
  displayedColumns: string[] = [
    'seller',
    'product_count',
    'percentage_avg',
    'cheaper_products',
    'more_expensive_products',
    'same_price_products',
  ];

  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {
    this.tableDataSource = this.data.competitors!;
  }

  sortData(sort: Sort) {
    const data = this.tableDataSource.slice();
    if (!sort.active || sort.direction === '') {
      this.tableDataSource = data;
      return;
    }

    this.tableDataSource = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'seller':
          return compare(a.seller!, b.seller!, isAsc);
        case 'product_count':
          return compare(a.product_count!, b.product_count!, isAsc);
        case 'percentage_avg':
          return compare(a.percentage_avg!, b.percentage_avg!, isAsc);
        case 'cheaper_products':
          return compare(a.cheaper_products!, b.cheaper_products!, isAsc);
        case 'more_expensive_products':
          return compare(a.more_expensive_products!, b.more_expensive_products!, isAsc);
        case 'same_price_products':
          return compare(a.same_price_products!, b.same_price_products!, isAsc);
        default:
          return 0;
      }
    });
  }

  openMatchedProductsPopup(monitor_products_ids: Array<Number>, seller: string) {
    const { source, audit, publicView } = this.data;
    this.dialog
      .open(MatchedPopupComponent, {
        data: {
          monitor_products_ids,
          seller,
          source,
          audit,
          publicView,
        },
      })
      .afterClosed();
  }
}
