import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-summarized-list',
  templateUrl: './summarized-list.component.html',
  styleUrls: ['./summarized-list.component.scss'],
})
export class SummarizedListComponent {
  @Input() items!: []; // TODO: type

  displayedColumns: string[] = ['key', 'restrictions', 'used', 'actions'];
}
