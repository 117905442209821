import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import ApiUrls from 'src/app/configs/api-urls.config';
import { ResponseV2Interface } from '../../../shared/model/response.model';
import { InvoiceDataModel, InvoiceModel } from '../models/invoice.model';
import { CurrentPackage, PaymentModel } from '../models/payments.model';

@Injectable({
  providedIn: 'root',
})
export class InvoiceService {
  static stripePublicKey = '';
  currentPackage$ = new BehaviorSubject<CurrentPackage | null>(null);
  availablePackage!: PaymentModel[];

  private readonly http = inject(HttpClient);

  getInvoices(): Observable<InvoiceModel[]> {
    return this.http.get<ResponseV2Interface<InvoiceModel[]>>(ApiUrls.paymentsInvoice).pipe(map((res) => res.data));
  }

  getInvoiceData(): Observable<InvoiceDataModel> {
    return this.http.get<InvoiceDataModel>(ApiUrls.paymentsInvoiceData);
  }

  setInvoiceData(
    company_name: string,
    address_1: string,
    address_2: string,
    postal_code: string,
    city: string,
    tax_id: string,
  ): Observable<any> {
    return this.http.post<any>(ApiUrls.paymentsInvoiceData, { company_name, address_1, address_2, postal_code, city, tax_id });
  }
}
