import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Moment } from 'moment';
import { ProjectService } from 'src/app/dashboard/project/project.service';
import { NotificationService } from 'src/app/notification/notification.service';
import { ConnectionParmsInterface, ConnectionsService } from '../../service/connections.service';
import { RouterService, routerConfig } from '../../service/router.service';
import { ConnectionModel } from '../../model/connections.model';
import { map } from 'rxjs/operators';
import { PaginationInterface } from '../../model/response.model';
import { groupBy } from 'lodash';

export enum TimeTypeEnum {
  range = 'range',
  period = 'period',
}

export enum TranslateKeyEnum {
  connectionRange = 'connection_range',
  connectionPeriod = 'connection_period',
}

export class AnalyticsDataModel {
  end_date?: string;
  project_connection_id?: number;
  project_connection_ids?: number[];
  start_date?: string;
  sub_days?: number;
  translate_key!: string;
}

@Component({
  selector: 'app-analytics-filters-modal',
  templateUrl: './analytics-filters-modal.component.html',
  styleUrls: ['./analytics-filters-modal.component.scss'],
})
export class AnalyticsFiltersModalComponent implements OnInit {
  currentProjectId!: number;
  form!: UntypedFormGroup;
  timeTypeEnum = TimeTypeEnum;
  connectionsGroups: { [key: string]: ConnectionModel[] } = {};

  timeTypes = [TimeTypeEnum.range, TimeTypeEnum.period];
  readonly daysRange = [1, 7, 30, 90];

  get isFormValid() {
    return (this.form && !this.form.invalid) || false;
  }

  get areConnections$() {
    return this.connectionsService.areAvailableConnections$;
  }

  get endDate() {
    return this.form.get('end_date') as UntypedFormControl;
  }

  get startDate() {
    return this.form.get('start_date') as UntypedFormControl;
  }

  get subDays() {
    return this.form.get('sub_days') as UntypedFormControl;
  }

  get timeType() {
    return this.form.get('time_type') as UntypedFormControl;
  }

  get projectConnectionIds() {
    return this.form.get('project_connection_ids') as UntypedFormControl;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { isMultiselect: boolean; data: AnalyticsDataModel; params?: ConnectionParmsInterface },
    private connectionsService: ConnectionsService,
    private formBuilder: UntypedFormBuilder,
    private notificationService: NotificationService,
    private routerService: RouterService,
    public dialogRef: MatDialogRef<AnalyticsFiltersModalComponent>,
    public projectService: ProjectService,
  ) {}

  ngOnInit(): void {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { end_date, project_connection_id, project_connection_ids, start_date, sub_days } = this.data.data || {};
    const timeType = sub_days ? TimeTypeEnum.period : TimeTypeEnum.range;

    this.form = this.formBuilder.group({
      end_date: [end_date || null, Validators.required],
      project_connection_ids: [project_connection_ids || project_connection_id || null, Validators.required],
      start_date: [start_date || null, Validators.required],
      sub_days: [sub_days || 7, Validators.required],
      time_type: [timeType, Validators.required],
    });

    this.currentProjectId = this.projectService.activeProject$.getValue()!.id;

    this.initValidators();
    this.getConnections();
  }

  dateRangeChanged(date: { dateEnd: Moment; dateStart: Moment }) {
    const dateEnd = date.dateEnd && date.dateEnd.isValid() ? date.dateEnd.format('YYYY-MM-DD') : null;
    const dateStart = date.dateStart && date.dateStart?.isValid() ? date.dateStart.format('YYYY-MM-DD') : null;

    this.endDate.setValue(dateEnd);
    this.startDate.setValue(dateStart);
  }

  goToConnectionsPage() {
    this.routerService.navigate(routerConfig.projectConnections, { project: this.currentProjectId })!.then(() => {
      window.location.reload();
    });
  }

  setRange(selected: number): void {
    const selectedRange = this.daysRange.find((item) => item === selected);
    selectedRange && this.subDays.setValue(selectedRange);
  }

  initValidators() {
    if (this.timeType.value === TimeTypeEnum.range) {
      this.endDate.setValidators([Validators.required]);
      this.startDate.setValidators([Validators.required]);

      this.subDays.setValidators(null);
    } else {
      this.endDate.setValidators(null);
      this.startDate.setValidators(null);

      this.subDays.setValidators([Validators.required]);
    }

    this.endDate.updateValueAndValidity();
    this.startDate.updateValueAndValidity();
    this.subDays.updateValueAndValidity();
  }

  getConnections(): void {
    const params: ConnectionParmsInterface = this.data.params || {};
    this.connectionsService
      .getProjectConnections(this.currentProjectId, params)
      .pipe(map((res: PaginationInterface<ConnectionModel>) => res.data))
      .subscribe((connections: ConnectionModel[]) => {
        this.connectionsGroups = groupBy(connections, 'services');
        this.setConnection();
      });
  }

  setConnection(): void {
    if (this.data.isMultiselect) {
      if (!this.projectConnectionIds.value) {
        this.projectConnectionIds.setValue([this.connectionsService.getSelectValue()[0].id] || null);
      }
    } else {
      const defaultConnectionsId = Object.values(this.connectionsGroups)
        .map((group: ConnectionModel[]) => group[0]?.id)
        .filter((id: number) => id !== undefined);
      this.projectConnectionIds.setValue(defaultConnectionsId[0] || null);
    }
  }

  reset() {
    this.dialogRef.close(null);
  }

  submitForm() {
    if (!this.isFormValid) {
      this.notificationService.error('invalid_form');
      return;
    }

    let emitData: AnalyticsDataModel;

    const projectConnectionIds = this.projectConnectionIds.value;

    if (this.data.isMultiselect) {
      emitData = {
        project_connection_ids: projectConnectionIds,
        translate_key: TranslateKeyEnum.connectionRange,
      };
    } else {
      emitData = {
        project_connection_id: projectConnectionIds,
        translate_key: TranslateKeyEnum.connectionRange,
      };
    }

    if (this.timeType.value === TimeTypeEnum.range) {
      emitData.end_date = this.endDate.value;
      emitData.start_date = this.startDate.value;
    } else {
      emitData.sub_days = this.subDays.value;
      emitData.translate_key = TranslateKeyEnum.connectionPeriod;
    }
    this.dialogRef.close(emitData);
  }

  clearFilters(): void {
    this.form.reset();
    this.dialogRef.close(null);
  }
}
