import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { NotificationService } from 'src/app/notification/notification.service';
import { AuthService } from 'src/app/shared/service/auth.service';
import { RouterService, routerConfig } from 'src/app/shared/service/router.service';
import { ProjectService } from '../dashboard/project/project.service';
import { FormApiValidationError } from '../shared/model/errors/formApiError.model';
import { LocalStorageService } from '../shared/service/local-storage.service';

@Injectable()
export class ErrorIntercecptorService implements HttpInterceptor {
  private tokenExpired$: Subject<any> = new Subject();

  constructor(
    private dialogRef: MatDialog,
    private notificationService: NotificationService,
    private projectService: ProjectService,
    private routerService: RouterService,
    private authService: AuthService,
    private storageHelperService: LocalStorageService,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      takeUntil(this.tokenExpired$),
      catchError((error: HttpErrorResponse) => this.handleHttpError(error)),
    );
  }

  private handleHttpError(error: HttpErrorResponse) {
    const errorMessage = error.error.message || null;

    switch (error.status) {
      case 400:
        return throwError(error.error);

      case 401:
        this.logout();
        this.tokenExpired$.next(null);
        return throwError(error.error);

      case 403:
      case 404:
        this.notificationService.error(errorMessage, {}, true);
        return throwError(error.error);

      case 422: {
        return throwError(new FormApiValidationError(error.error, this.notificationService));
      }
      case 455: // Maintenance
        this.notificationService.error('maintenance_start');
        this.routerService.navigate(routerConfig.maintenance);
        this.tokenExpired$.next(null);
        return throwError(error.error);
      case 460:
        this.notificationService.error(errorMessage, {}, true);
        return throwError(error.error);

      case 461:
        this.notificationService.error(errorMessage, {}, true);
        return throwError(error.error);

      case 462: // API bussines error
        this.notificationService.error(errorMessage, {}, true);
        return throwError(error.error);
      case 463:
        return throwError(error.error);
      case 464: // RESTRICTION_ERROR
        if (this.authService.authUser!.permissions!.some((perm) => perm === 'manage payment')) {
          this.routerService.navigate(routerConfig.paymentsAddProducts);
        }
        this.notificationService.error(errorMessage, {}, true);
        return throwError(error.error);
      case 465: // NO_SUBSCRIPTION_ERROR
        this.routerService.navigate(routerConfig.paymentsPlan);
        this.notificationService.error(errorMessage, {}, true);
        return throwError(error.error);
      default:
        this.notificationService.error('unknown_error_occurred');
        return throwError(error.error);
    }
  }

  private logout(errorTranslateKey = 'session_expired', route = routerConfig['/']) {
    this.dialogRef.closeAll();
    this.authService.removeToken();
    this.projectService.activeProject$.next(null!);
    this.routerService.navigate(route);
    this.notificationService.error(errorTranslateKey);
  }
}
