import { ItemModel } from '../../item.model';
import { ActionTypesEnum } from '../ActionTypesEnum';
import { ActionModel } from './ActionModel';

export class AddAction extends ActionModel {
  type = ActionTypesEnum.add;
  value!: number;

  isValid(): boolean {
    return !!this.columnName && (!!this.value || Number(this.value) > 0);
  }

  use(item: ItemModel) {
    return this.returnValueForUse(item);
  }

  private returnValueForUse(item: ItemModel): number | null {
    if (item[this.columnName as keyof typeof item]) {
      // eslint-disable-next-line no-restricted-globals
      if (!isNaN(item[this.columnName as keyof typeof item] as number)) {
        return (+item[this.columnName as keyof typeof item]! as number) + +this.value;
      }
      return +this.value;
    }
    return null;
  }

  returnForSend() {
    return {
      action: this.type,
      param: this.columnName,
      value: this.value,
      sequence: this.sequence,
    };
  }

  copyValue(item: this, overrideManualChanges: boolean): void {
    this.columnName = item.columnName;
    this.override_manual_changes = overrideManualChanges;
  }
}
