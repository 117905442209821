<div class="chart">
  <mat-form-field class="select">
    <mat-label>
      {{ 'projects.monitor.list.chart_results_type' | translate }}
    </mat-label>
    <mat-select [(value)]="selected" (selectionChange)="updateChart()">
      <mat-option *ngFor="let resultType of resultTypes" [value]="resultType.value">
        {{ resultType.dropdownLabel ?? resultType.yAxisName }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <app-chart type="timeseries" [dataSource]="dataSource"></app-chart>
</div>
