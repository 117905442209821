import { DecimalPipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { cloneDeep } from 'lodash';
import { CurrentPackage, PackageItemModel, Restrictions } from '../../../models/payments.model';

@Component({
  selector: 'app-summarized-restrictions',
  templateUrl: './summarized-restrictions.component.html',
  styleUrls: ['./summarized-restrictions.component.scss'],
  providers: [DecimalPipe],
})
export class SummarizedRestrictionsComponent implements OnInit {
  @Input() currentPackage!: CurrentPackage;
  @Input() mainPackage!: PackageItemModel;
  displayedColumns: string[] = ['output_products', 'projects', 'monitor_phrases', 'monitor_products'];
  summarizedRestrictions: Restrictions = {
    output_products: '0 / 0',
    projects: '0 / 0',
    monitor_phrases: '0 / 0',
    monitor_products: '0 / 0',
  };

  constructor(private decimalPipe: DecimalPipe) {}

  ngOnInit() {
    if (this.mainPackage) {
      this.mapMainPackage();
    } else {
      this.mapSummarizedRestrictions();
    }
  }

  mapSummarizedRestrictions() {
    const { restrictions, used } = this.currentPackage;

    this.summarizedRestrictions.output_products = `${this.decimalPipe.transform(used!.output_products)} / ${this.decimalPipe.transform(
      restrictions.output_products,
    )}`;
    this.summarizedRestrictions.projects = `${used!.projects} / ${restrictions.projects}`;
    this.summarizedRestrictions.monitor_phrases = `${used!.monitor_phrases} / ${restrictions.monitor_phrases}`;
    this.summarizedRestrictions.monitor_products = `${used!.monitor_products} / ${restrictions.monitor_products}`;
  }

  mapMainPackage() {
    this.summarizedRestrictions = cloneDeep(this.mainPackage.restrictions);
    this.summarizedRestrictions.output_products = this.decimalPipe.transform(this.summarizedRestrictions.output_products)!;
    this.summarizedRestrictions.projects = this.decimalPipe.transform(this.summarizedRestrictions.projects)!;
  }
}
