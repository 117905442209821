import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { delay, tap } from 'rxjs/operators';
import { NotificationService } from 'src/app/notification/notification.service';
import { AuthService } from 'src/app/shared/service/auth.service';
import { RouterService, routerConfig } from 'src/app/shared/service/router.service';

const SOURCES = {
  chat: 'chat_gpt',
};

enum ConfirmStatusesEnum {
  error,
  loading,
  verified,
}

@Component({
  selector: 'app-account-confirm',
  templateUrl: './account-confirm.component.html',
  styleUrls: ['./account-confirm.component.scss'],
})
export class AccountConfirmComponent implements OnInit {
  confirmStatusesEnum = ConfirmStatusesEnum;
  isButtonUnlocked = false;
  isLoading = true;
  isWelcomeScreenVisible!: boolean;
  source!: string; // TODO: jak dojdą inne poza chat to dać enuma
  timeToUnlock = 5;
  token!: string;
  countDown = setInterval(() => {
    if (this.timeToUnlock <= 0) {
      clearInterval(this.countDown);
      this.isButtonUnlocked = true;
    } else {
      this.timeToUnlock -= 1;
    }
  }, 1000);

  isTokenError = false;
  private authService = inject(AuthService);
  private notificationService = inject(NotificationService);
  private route = inject(ActivatedRoute);
  private routerService = inject(RouterService);

  get confirmStatus(): ConfirmStatusesEnum {
    // eslint-disable-next-line no-nested-ternary
    return this.isTokenError ? ConfirmStatusesEnum.error : this.isLoading ? ConfirmStatusesEnum.loading : ConfirmStatusesEnum.verified;
  }

  ngOnInit(): void {
    this.token = this.route.snapshot.paramMap.get('token')!;
    this.authService
      .confirmEmail(this.token)
      .pipe(
        delay(3000),
        tap(() => (this.isLoading = false)),
      )
      .subscribe(
        () => {
          this.countDown;
          this.notificationService.success('email_verification');
          // this.redirectToLogin();
        },
        () => {
          clearInterval(this.countDown);
          this.isButtonUnlocked = true;
          this.notificationService.error('email_verification');
          this.isTokenError = true;
          this.timeToUnlock = 0;
        },
      );

    const source = String(this.route.snapshot.queryParamMap.get('source'));
    if (source && SOURCES[source as keyof typeof SOURCES]) {
      this.source = SOURCES[source as keyof typeof SOURCES];
    }
  }

  redirect(): void {
    if (this.source) {
      this.isWelcomeScreenVisible = true;
    } else {
      // this.storageHelperService.save('welcomeScreen', 'true'); // TODO: set to const storageKey
      this.routerService.navigate(routerConfig.home);
    }
  }
}
