import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/shared/service/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-catch-token',
  templateUrl: './catch-token.component.html',
  styleUrls: ['./catch-token.component.scss'],
})
export class CatchTokenComponent implements OnInit {
  isAfterInstall = false;
  isAfterCharge = false;
  isAfterConnection = false;
  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params;
    const { token } = this.activatedRoute.snapshot.params;
    const { shop } = this.activatedRoute.snapshot.queryParams;
    this.authService.saveToken(token);

    if (shop) {
      this.handleShopifyLogic();
    }
  }

  private handleShopifyLogic() {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { status, shop, project_id } = this.activatedRoute.snapshot.queryParams;

    switch (status) {
      case 'after_install':
        this.isAfterInstall = true;
        window.location.href = `https://${shop}/admin/apps/${environment.shopifyName}`;
        break;
      case 'after_charge':
        this.isAfterCharge = true;
        window.location.href = `https://${shop}/admin/apps/${environment.shopifyName}`;
        break;
      case 'after_connect':
        this.isAfterConnection = true;
        window.location.href = `https://${shop}/admin/apps/${environment.shopifyName}/front/dashboard/project/${project_id}/connections`;
        break;
      case 'after_login':
        this.router.navigate(['/dashboard/home']);
        break;
      default:
        break;
    }
  }

  redirectToShopify() {
    const { shop } = this.activatedRoute.snapshot.queryParams;
    window.location.href = `https://${shop}/admin/apps/${environment.shopifyName}`;
  }
}
