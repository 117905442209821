/* eslint-disable no-underscore-dangle */
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { TranslateService } from '@ngx-translate/core';
import { Guide } from './models/Guide';
import { createProductOptimisationTutorial } from './tutorials/ProductOptimisation';
import { createAddProjectTutorial } from './tutorials/addProject';
import { createAddProductTutorial } from './tutorials/addingProductData';
import { createConnectionsTutorial } from './tutorials/creatingConnection';

@Injectable({
  providedIn: 'root',
})
export class TutorialFactoryService {
  guidesAvailable!: Guide[];
  private renderer: Renderer2;

  constructor(
    @Inject(RendererFactory2) rendererFactory: RendererFactory2,
    private translateService: TranslateService,
    private bottomSheet: MatBottomSheet,
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
    this.createTutorials();
  }

  createTutorials() {
    this.guidesAvailable = [
      createAddProjectTutorial(this.renderer, this.translateService),
      createAddProductTutorial(this.renderer, this.translateService),
      createConnectionsTutorial(this.renderer, this.translateService),
      createProductOptimisationTutorial(this.renderer, this.translateService, this.bottomSheet),
    ];
  }
}
