import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replace',
})
export class ReplacePipe implements PipeTransform {
  transform(text: string, replacements: { [key: string]: string }): string {
    let modifiedText = text;
    Object.keys(replacements).forEach((key) => {
      modifiedText = modifiedText.replace(new RegExp(`{{${key}}}`, 'g'), replacements[key]);
    });

    return modifiedText;
  }
}
