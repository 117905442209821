<mat-card appearance="outlined">
  <div class="header">
    <div class="title-container">
      <button mat-icon-button (click)="back()">
        <mat-icon>arrow_back</mat-icon>
      </button>
      <div class="title">
        {{ 'payments.buy.fill_data' | translate }}
      </div>
    </div>
    <div class="chosen-package" *ngIf="chosenPackage">
      <div class="name">{{ chosenPackage.package_name | uppercase }}</div>
      <div class="price" [ngClass]="{ discount: discountPrice >= 0 }">
        <span class="basic-price">{{ chosenPackage.price / 100 | number: '1.2-2' }}</span>
        <span class="discount-price">{{ discountPrice / 100 | number: '1.2-2' }}</span> {{ chosenPackage.currency | uppercase }} +
        {{ chosenPackage.tax }}% VAT
      </div>
      <div class="period">
        {{ 'payments.packages.period' | translate }}
      </div>
    </div>
  </div>

  <mat-card-content>
    <span class="form-header">
      {{ 'payments.buy.card_data' | translate }}
    </span>
    <app-card-attach-form
      *ngIf="chosenPackage && chosenPackage.id"
      [packageId]="chosenPackage.id"
      [invoiceAvailable]="true"
      (cardSetupCreated)="buyPackage($event)"
      (includeDiscount)="includeDiscount($event)"
    ></app-card-attach-form>
  </mat-card-content>
</mat-card>
