import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import ApiUrls from '../../configs/api-urls.config';
import { ResponseInterface, ResponseV2Interface } from '../model/response.model';

export type BingData = {
  customerName: string;
  customerNumber: string;
  accountName: string;
  accountNumber: string;
};

@Injectable({
  providedIn: 'root',
})
export class BingService {
  constructor(private http: HttpClient) {}

  createAccount(bingAccount: any, projectId: number) {
    return this.http.post<ResponseInterface<any>>(ApiUrls.bingCreateAccount.prepareUrl({ project: projectId }), bingAccount);
  }

  callback() {
    return this.http.get<ResponseInterface<any>>(ApiUrls.bingCallback);
  }

  currencies() {
    return this.http.get<ResponseV2Interface<string[]>>(ApiUrls.bingCurrencies);
  }

  languages() {
    return this.http.get<ResponseV2Interface<string[]>>(ApiUrls.bingLanguageTypes);
  }

  industries() {
    return this.http.get<ResponseV2Interface<string[]>>(ApiUrls.bingIndustries);
  }

  getSentData(projectId: number): Observable<BingData> {
    return this.http.get<ResponseV2Interface<BingData>>(ApiUrls.bingData.prepareUrl({ project: projectId })).pipe(map((r) => r.data));
  }
}
