import { Directive, ElementRef, HostListener, Input } from '@angular/core';

// tslint:disable: deprecation
@Directive({
  selector: '[appLimitLetter]',
})
export class LimmitLetterDirective {
  @Input('appLimitLetter') limit!: number;

  constructor(private el: ElementRef) {}

  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    if (!this.limit) {
      return;
    }
    const e = event as KeyboardEvent;
    if (this.el.nativeElement.innerText.length === this.limit && event.keyCode >= 48 && event.keyCode <= 90 && !event.ctrlKey) {
      e.preventDefault();
    }
  }
}
