import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ProjectModel } from 'src/app/dashboard/project/project.model';

@Component({
  selector: 'app-bing-create-account-popup',
  templateUrl: './bing-create-account-popup.component.html',
  styleUrls: ['./bing-create-account-popup.component.scss'],
})
export class BingCreateAccountPopupComponent implements OnInit {
  isSent = false;
  constructor(
    public dialogRef: MatDialogRef<BingCreateAccountPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public project: ProjectModel,
  ) {}

  ngOnInit(): void {
    this.isSent = this.project.bing_request_sent!;
  }

  close() {
    this.dialogRef.close(true);
  }
}
