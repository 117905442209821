<div class="container">
  <a appRouter="/" class="logo">
    <span></span>
  </a>

  <div [cdkTrapFocusAutoCapture]="true" cdkTrapFocus class="row">
    <div class="col">
      <div class="maintenance__container">
        <div class="top">
          <h1 class="maintenance__title">{{ 'maintenance.title' | translate }}</h1>

          <div class="maintenance-info">
            <mat-spinner *ngIf="isLoading; else content" [diameter]="40" mode="indeterminate"></mat-spinner>

            <ng-template #content>
              <p *ngIf="message" [innerHtml]="message" class="maintenance__description"></p>
              <p
                *ngIf="endDate"
                [innerHtml]="'maintenance.end_date_info' | translate: { time: endDate }"
                class="maintenance__description"
              ></p>
              <p [innerHtml]="'maintenance.thanks' | translate" class="maintenance__description"></p>
              <button (click)="refresh()" class="maintenance__refresh-btn" color="primary" mat-raised-button type="button">
                {{ 'maintenance.check' | translate }}
              </button>
              <a [href]="sembotWebsiteUrl" class="maintenance__redirect-website" target="_blank"
                >{{ 'maintenance.back_to_website' | translate }}<span>{{ rawSembotWebsiteUrl }}</span></a
              >
            </ng-template>
          </div>
        </div>
      </div>
      <app-brand-logos></app-brand-logos>
    </div>
    <app-auth-change-language></app-auth-change-language>
  </div>
</div>
