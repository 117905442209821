import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root',
})
export class HelperService {
  returnIndexInArray(data: any, array: any[]): number {
    return array ? array.findIndex((item) => item.id === data.id) : -1;
  }

  isInArray(data: any, array: any[]): boolean {
    return !!array.find((item) => item.id === data.id);
  }

  removeElementFromArray(data: any, array: any[]) {
    const arrayOfId = array.map((item) => item.id);
    const index = arrayOfId.indexOf(data.id);
    if (index > -1) {
      array.splice(index, 1);
    }
  }

  search<T>(query: string | null, array: T[], propertyOfObject: string | null = null): T[] {
    if (!query) {
      return array;
    }

    if (propertyOfObject) {
      return array.filter((el) => (el[propertyOfObject as keyof typeof el] as string).toLowerCase().indexOf(query.toLowerCase()) > -1);
    }

    return array.filter((el: any) => el.toLowerCase().indexOf(query.toLowerCase()) > -1);
  }
}
