import { Component } from '@angular/core';

@Component({
  selector: 'app-tips-carousel',
  templateUrl: './tips-carousel.component.html',
  styleUrls: ['./tips-carousel.component.scss'],
})
export class TipsCarouselComponent {
  selectedIndex = 0;
  tips = ['spac_campaign', 'bestsellers_labeling', 'feed', 'monitor'];

  next() {
    this.selectedIndex = (this.selectedIndex + 1) % this.tips.length;
  }

  previous() {
    this.selectedIndex = (this.selectedIndex - 1 + this.tips.length) % this.tips.length;
  }
}
