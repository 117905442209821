import { ChangeDetectionStrategy, Component } from '@angular/core';
import RouterConfig from 'src/app/configs/router.config';
// import { Location } from '@angular/common';
import { RouterService } from '../../service/router.service';

@Component({
  selector: 'app-navigate-back',
  templateUrl: './navigate-back.component.html',
  styleUrls: ['./navigate-back.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigateBackComponent {
  // private location: Location
  constructor(private routerService: RouterService) {}

  navigateBack() {
    this.routerService.navigate(RouterConfig.home);
    // this.location.back();
  }
}
