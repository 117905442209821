import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import ApiUrls from '../../configs/api-urls.config';
import { ResponseInterface } from '../model/response.model';

type Country = { id: string; name: string };
@Injectable({
  providedIn: 'root',
})
export class CountriesService {
  private countries: Country[] = [];
  constructor(private http: HttpClient) {}

  getCountries(): Observable<Country[]> {
    if (this.countries[0]) {
      return of(this.countries);
    }
    return this.http.get<ResponseInterface<{ [key: string]: string }>>(ApiUrls.dictsCountries).pipe(
      map((res) => res.data.items!),
      map((data) => Object.entries(data!).map(([key, value]) => ({ id: key, name: value }))),
      tap((countries) => (this.countries = countries)),
    );
  }

  getLanguages(): Observable<Country[]> {
    return this.http.get<ResponseInterface<{ [key: string]: string }>>(ApiUrls.dictsGoogleLanguages).pipe(
      map((res) => res.data.items),
      map((data) => Object.entries(data!).map(([key, value]) => ({ id: key, name: value }))),
    );
  }

  getCountriesKeysAlpha2(query: string): Observable<string[]> {
    const countries = query ? this.getCountries().pipe(map(() => this.filter(query))) : this.getCountries();
    return countries.pipe(map((data) => data.map((country) => country.id)));
  }

  private filter(query: string, limit: number = 10): Country[] {
    const res: Country[] = [];
    Object.values(this.countries).reduce((acc, country) => {
      if (acc.length > limit) {
        return acc;
      }
      const index = country.id.toLowerCase().indexOf(query.toLowerCase());

      if (index > -1) {
        acc.push(country);
      }

      return acc;
    }, res);
    return res;
  }
}
