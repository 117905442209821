import { ColumnTypesEnum } from '../../table-display/ColumnTypesEnum';
import { FilterLogicOperatorEnum } from '../FilterLogicOperatorEnum';
import { FilterTypesEnum } from '../FilterTypesEnum';
import { FilterModel } from './filter.model';

export class IncludeOneOfFilterModel extends FilterModel {
  symbol: FilterTypesEnum = FilterTypesEnum.include_one_of;

  constructor(
    param: string,
    // eslint-disable-next-line @typescript-eslint/default-param-last
    value: any = null,
    columnType: ColumnTypesEnum,
    operator: FilterLogicOperatorEnum,
    case_sensitive?: boolean,
    additional_sources?: any,
  ) {
    super(param, value, columnType, operator, case_sensitive, additional_sources);
    this.value = typeof value === 'string' ? value.split(/\r?\n|,|;/).filter((s: string) => !!s) : value || [];
  }

  isValid() {
    return !!this.value && this.value.length;
  }
}
