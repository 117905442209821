import { GuidesAvailable } from '../GuidesAvailable.enum';
import { GuideStep } from './steps/GuideStep';

export class Guide {
  guideSteps: GuideStep[];
  currentActiveStepIndex = 0;
  currentActiveStep!: GuideStep;
  get id(): GuidesAvailable {
    return this.guideId;
  }

  constructor(
    private guideId: GuidesAvailable,
    public description: string,
    guideSteps: GuideStep[],
  ) {
    if (!description) {
      this.description = '';
    }

    if (guideSteps.length < 1) {
      throw Error('Guide params incorect');
    }
    this.guideSteps = guideSteps;
  }
}
