import { Pipe, PipeTransform, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'timeAgo',
  pure: true,
})
export class TimeAgoPipe implements PipeTransform {
  private translate = inject(TranslateService);

  transform(value: string | number): string | undefined {
    if (!value) {
      return this.translate.instant('time_ago.invalid_date');
    }

    if (value) {
      const now = new Date();
      const date = new Date(value);
      const seconds = Math.floor((now.getTime() - date.getTime()) / 1000);

      if (seconds < 60) {
        return this.translate.instant('notifications.time_ago.just_now');
      } else if (seconds < 3600) {
        return this.translate.instant('notifications.time_ago.minutes_ago', { minutes: Math.floor(seconds / 60) });
      } else if (seconds < 86400) {
        return this.translate.instant('notifications.time_ago.hours_ago', { hours: Math.floor(seconds / 3600) });
      } else if (seconds < 604800) {
        return this.translate.instant('notifications.time_ago.days_ago', { days: Math.floor(seconds / 86400) });
      } else if (seconds < 2592000) {
        return this.translate.instant('notifications.time_ago.weeks_ago', { weeks: Math.floor(seconds / 604800) });
      } else if (seconds < 31536000) {
        return this.translate.instant('notifications.time_ago.months_ago', { months: Math.floor(seconds / 2592000) });
      } else {
        return this.translate.instant('notifications.time_ago.years_ago', { years: Math.floor(seconds / 31536000) });
      }
    }
  }
}
