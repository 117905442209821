import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SubscriptionPriceModel } from '../../models/payments.model';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss'],
})
export class SummaryComponent {
  @Input() isTrial!: boolean;
  @Input() price!: SubscriptionPriceModel;
  @Input() isLoadingPlan!: boolean;

  @Output() buy = new EventEmitter<void>();

  submit() {
    this.buy.emit();
  }
}
