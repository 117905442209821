import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { InfiniteScrollModule as ngxInfiniteScrollModule } from 'ngx-infinite-scroll';
import { SpinnerModule } from '../../directives/spinner.directive';
import { InfiniteScrollComponent } from './infinite-scroll.component';

@NgModule({
  declarations: [InfiniteScrollComponent],
  imports: [CommonModule, ngxInfiniteScrollModule, SpinnerModule],
  exports: [ngxInfiniteScrollModule, InfiniteScrollComponent],
})
export class InfiniteScrollModule {}
