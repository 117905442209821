<div class="container">
  <span class="subtitle">
    {{ (mainPackage ? 'payments.current_package.main_package' : 'payments.current_package.summarized_restrictions') | translate }}
  </span>

  <table mat-table [dataSource]="[summarizedRestrictions]">
    <ng-container matColumnDef="output_products">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'payments.current_package.output_products' | translate }}
      </th>
      <td mat-cell *matCellDef="let element">{{ element.output_products }}</td>
    </ng-container>

    <ng-container matColumnDef="projects">
      <th mat-header-cell *matHeaderCellDef appPluginDisplayHandler>
        {{ 'payments.current_package.projects' | translate }}
      </th>
      <td mat-cell *matCellDef="let element" appPluginDisplayHandler>{{ element.projects }}</td>
    </ng-container>

    <ng-container matColumnDef="monitor_phrases">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'payments.current_package.monitor_phrases' | translate }}
      </th>
      <td mat-cell *matCellDef="let element">{{ element.monitor_phrases }}</td>
    </ng-container>

    <ng-container matColumnDef="monitor_products">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'payments.current_package.monitor_products' | translate }}
      </th>
      <td mat-cell *matCellDef="let element">{{ element.monitor_products }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
