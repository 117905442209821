<form [formGroup]="form" id="form">
  <mat-form-field>
    <mat-label>
      {{ 'profile.settings.username.label' | translate }}
    </mat-label>
    <input [formControl]="username" cdkFocusInitial matInput name="username" />
    <app-form-field-error-display matError></app-form-field-error-display>
  </mat-form-field>

  <button (click)="saveChangeUsername()" [disabled]="isLocked || !form.valid" color="primary" mat-raised-button>
    {{ 'general.save' | translate }}
  </button>
</form>
