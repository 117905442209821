import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { ConnectionsServiceEnum } from 'src/app/shared/enums/connections-service.enum';
import { ConnectionModel } from 'src/app/shared/model/connections.model';
import { FormApiValidationError } from 'src/app/shared/model/errors/formApiError.model';
import { ConnectionsService } from 'src/app/shared/service/connections.service';
import { ProjectService } from '../../../project.service';
import { MerchantCenterAccount } from '../../connections.model';

@Component({
  selector: 'app-google-merchant-center-service',
  templateUrl: './google-merchant-center-service.component.html',
})
export class GoogleMerchantCenterServiceComponent implements OnInit {
  @Input() connections!: ConnectionModel[];
  @Output() connectionAttached = new EventEmitter<boolean>();

  parentAccountsList: MerchantCenterAccount[] | null = null;
  childAccountsList: MerchantCenterAccount[] | null = null;

  currentAccount: MerchantCenterAccount | null = null;
  form!: UntypedFormGroup;
  isLoading = false;
  service = ConnectionsServiceEnum.Google;

  get connection(): UntypedFormControl {
    return this.form.get('connection') as UntypedFormControl;
  }

  get parentAccount(): UntypedFormControl {
    return this.form.get('parent_account') as UntypedFormControl;
  }

  get childAccount(): UntypedFormControl {
    return this.form.get('child_account') as UntypedFormControl;
  }

  private onDestroy$ = new Subject<void>();

  constructor(
    private connectionsService: ConnectionsService,
    private projectService: ProjectService,
    private fb: UntypedFormBuilder,
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      connection: [null, [Validators.required]],
      parent_account: [null, [Validators.required]],
      child_account: [null],
    });
    this.connection.setValue(this.connections[0]);
    this.changeConnection();
  }

  changeConnection() {
    this.parentAccount.setValue(null);
    this.childAccount.setValue(null);

    this.parentAccountsList = null!;
    this.childAccountsList = null!;

    const { id } = this.connection.value;
    id &&
      this.connectionsService.getMerchantCenterAccounts(this.projectService.activeProject$.getValue()!.id, id).subscribe(
        (accounts) => (this.parentAccountsList = accounts),
        () => this.parentAccount.setValue(null),
      );
  }

  changeParentAccount(account: MerchantCenterAccount) {
    const { id } = this.connection.value;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { account_id, sub_accounts } = account;

    if (sub_accounts && id && account_id) {
      this.childAccount.setValidators([Validators.required]);
      this.childAccount.setValue(null);
      this.currentAccount = null;

      this.connectionsService.getMerchantCenterAccounts(this.projectService.activeProject$.getValue()!.id, id, account_id).subscribe(
        (accounts) => (this.childAccountsList = accounts),
        () => this.childAccount.setValue(null),
      );
    } else {
      this.childAccount.setValidators(null);
      this.childAccount.setValue(null);
      this.currentAccount = account;
    }
  }

  changeChildAccount(account: MerchantCenterAccount) {
    this.currentAccount = account;
  }

  submit() {
    if (this.form.invalid) {
      return false;
    }

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { account_id, name } = this.currentAccount!;
    this.isLoading = true;
    this.connectionsService
      .attachGoogleMerchantCenterProject(this.projectService.activeProject$.getValue()!.id, this.connection.value.id, account_id, name)
      .pipe(
        tap(() => {
          this.form.reset();
          this.form.markAsUntouched();
          this.isLoading = false;
        }),
        takeUntil(this.onDestroy$),
      )
      .subscribe(
        () => this.connectionAttached.emit(true),
        (er: FormApiValidationError) => {
          er.setOnForm && er.setOnForm(this.form);
          this.connectionAttached.emit(false);
        },
      );
  }
}
