import { BrandEnum } from './brand.enum';

export enum ConnectionsServiceEnum {
  Google = 'GOOGLE',
  Bing = 'BING',
  Shopify = 'SHOPIFY',
  Facebook = 'FACEBOOK',
}

export enum ConnectionsServiceSlugEnum {
  FACEBOOK_BUSINESS = 'Facebook Business',
  GOOGLE_ADWORDS = 'Google Ads',
  GOOGLE_ANALYTICS = 'Universal Analytics',
  GOOGLE_ANALYTICS_4 = 'Google Analytics 4',
  GOOGLE_SEARCH_CONSOLE = 'Google Search Console',
  GOOGLE_MERCHANT_CENTER = 'Google Merchant Center',
  MICROSOFT_ADVERTISING = 'Microsoft Advertising',
  SHOPIFY_ADMIN = 'Shopify Admin',
}

export enum ConnectionsServiceSlugIconEnum {
  FACEBOOK_BUSINESS = 'facebook',
  GOOGLE_ADWORDS = 'google_ads',
  GOOGLE_ANALYTICS = 'google_analytics',
  GOOGLE_ANALYTICS_4 = 'google_analytics',
  GOOGLE_SEARCH_CONSOLE = 'google_analytics',
  GOOGLE_MERCHANT_CENTER = 'google_merchant_center',
  MICROSOFT_ADVERTISING = 'microsoft',
  SHOPIFY_ADMIN = 'shopify',
}

export enum ConnectionsBrandEnum {
  BING = BrandEnum.MICROSOFT,
  FACEBOOK = BrandEnum.FACEBOOK,
  FACEBOOK_BUSINESS = BrandEnum.FACEBOOK,
  GOOGLE = BrandEnum.GOOGLE,
  GOOGLE_ADWORDS = BrandEnum.GOOGLE,
  GOOGLE_ANALYTICS = BrandEnum.GOOGLE,
  GOOGLE_ANALYTICS_4 = BrandEnum.GOOGLE,
  GOOGLE_SEARCH_CONSOLE = BrandEnum.GOOGLE,
  MICROSOFT = BrandEnum.MICROSOFT,
  MICROSOFT_ADVERTISING = BrandEnum.MICROSOFT,
  SHOPIFY = BrandEnum.SHOPIFY,
  SHOPIFY_ADMIN = BrandEnum.SHOPIFY,
}
