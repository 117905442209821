import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { PaginationResourceInterface } from '../../model/response.model';

@Component({
  selector: 'app-infinite-scroll',
  templateUrl: './infinite-scroll.component.html',
  styleUrls: ['./infinite-scroll.component.scss'],
})
export class InfiniteScrollComponent implements OnDestroy {
  @Output() scrolled = new EventEmitter<number>();
  @Output() values: EventEmitter<any[]> = new EventEmitter<any[]>();

  @Input() set data(res: PaginationResourceInterface<any>) {
    this.isNewData = true;

    if (res) {
      const { current, data, meta } = res;

      // @TODO: REFAKTOR - isNoCurrentParam!!!
      if (this.isNoCurrentParam && current) {
        data.unshift(current); // Current item to start the listing
        this.isNoCurrentParam = false;
      }

      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { current_page, last_page } = meta;

      data && this.items$.next(data);
      current_page && (this.currentPage = current_page);
      last_page && (this.lastPage = last_page);
    }

    this.isLoading = true;
  }

  private isNewData = false;
  private isNoCurrentParam = true;
  private items$: Subject<any[]> = new Subject();
  private items: any[] = [];
  private onDestroy$: Subject<void> = new Subject();

  currentPage: number = 1;
  lastPage!: number;
  isLoading = true;

  onScroll = () => {
    if (this.lastPage === this.currentPage) {
      // @TODO: TMP??
      this.isLoading = false;
      return false;
    }

    if (this.isNewData) {
      this.isNewData = false;
      this.isLoading = true;

      const nextPage = this.currentPage + 1;
      this.scrolled.emit(nextPage);
    }
  };

  constructor() {
    this.items$
      .pipe(
        tap((items) => items && (this.items = this.items.concat(items))),
        takeUntil(this.onDestroy$),
      )
      .subscribe(() => this.values.emit(this.items));
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }
}
