<div class="date-range-picker-container">
  <div *ngIf="title" class="title">{{ title }}</div>
  <div class="form-wrapper" *ngIf="isRange">
    <mat-label class="grey-label">{{ 'components.date_range_picker.date_range' | translate }}</mat-label>
    <mat-form-field class="time-type small-select" appearance="outline">
      <mat-select [formControl]="selectedDays" (selectionChange)="setDays($event.value)" [panelClass]="'small-select'">
        <mat-option *ngFor="let day of daysRange" [value]="day">
          {{ day }} {{ 'components.analystics_filters_modal.' + (day === 1 ? 'day' : 'days') | translate }}
        </mat-option>
      </mat-select>
      <app-form-field-error-display matError></app-form-field-error-display>
    </mat-form-field>

    <app-date-range-badge [isFutureTime]="isFutureTime" (selectRange)="setDays($event)" class="range-picker-badge"></app-date-range-badge>
  </div>
  <div class="date-range-picker-wrapper">
    <div class="form-wrapper">
      <mat-label class="grey-label">{{ 'components.date_range_picker.enter_date_range' | translate }}</mat-label>
      <mat-form-field appearance="outline" class="small-input">
        <mat-date-range-input [rangePicker]="picker">
          <input matStartDate [formControl]="dateStart" placeholder="Start date" />
          <input matEndDate [formControl]="dateEnd" placeholder="End date" />
        </mat-date-range-input>
        <mat-datepicker-toggle matIconSuffix [for]="picker" [disableRipple]="true">
          <mat-icon matDatepickerToggleIcon class="calendar-icon">calendar_month</mat-icon>
        </mat-datepicker-toggle>
        <mat-date-range-picker #picker panelClass="date-range-picker-panel"></mat-date-range-picker>
      </mat-form-field>
    </div>
  </div>
</div>
