import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProjectService } from 'src/app/dashboard/project/project.service';
import { PaginationResourceInterface, ResponseV2Interface } from 'src/app/shared/model/response.model';
import ApiUrls from '../../../configs/api-urls.config';
import { CompetitorsSource } from '../monitor/monitor.enum';
import { AuditFormModel, AuditModel } from './audit.model';

import { MonitorProductTaskModel } from 'src/app/shared/model/monitor.model';
import { FilterGroupModel } from '../../../shared/sem-table/filters/models/filter.model';
import { PaginationInterface } from '../../../shared/sem-table/models/entryPagination.model';

@Injectable({
  providedIn: 'root',
})
export class AuditService {
  constructor(
    private http: HttpClient,
    private projectService: ProjectService,
  ) {}

  getAudit(): Observable<AuditModel> {
    const projectId = this.projectService.activeProject$.getValue()!.id;
    return this.http.get<AuditModel>(ApiUrls.projectAuditGet.prepareUrl({ project: projectId }), {}).pipe(map((res) => res));
  }

  addAutit(audit: AuditFormModel): Observable<AuditModel> {
    const projectId = this.projectService.activeProject$.getValue()!.id;
    return this.http
      .post<ResponseV2Interface<AuditModel>>(ApiUrls.projectAuditStore.prepareUrl({ project: projectId }), audit)
      .pipe(map((res) => res.data));
  }

  getAuditByUuid(projectId: number, uuid: string): Observable<AuditModel> {
    return this.http
      .get<AuditModel>(
        ApiUrls.projectAuditByUuidGet.prepareUrl({
          project: projectId,
          uuid,
        }),
        {},
      )
      .pipe(map((res) => res));
  }

  getAuditMatchedProductsByUuid(
    projectId: number,
    uuid: string,
    seller: string,
    matched_monitor_products_ids: Array<Number>,
    source: CompetitorsSource,
    per_page?: number,
    page?: number,
    order?: string,
    direction?: string,
    filterGroups: FilterGroupModel[] = [],
    date_from?: string,
    date_to?: string,
    audit_id?: number,
  ): Observable<PaginationInterface<MonitorProductTaskModel>> {
    return this.http
      .post<PaginationResourceInterface<MonitorProductTaskModel>>(
        ApiUrls.projectAuditMatchedByUuidGet.prepareUrl({ project: projectId, uuid }),
        {
          per_page,
          page,
          filterGroups,
          order,
          direction,
          date_from,
          date_to,
          audit_id,
          seller,
          matched_monitor_products_ids,
          source,
        },
      )
      .pipe(
        map(({ data, links, meta }) => ({
          data,
          ...links,
          ...meta,
        })),
      );
  }
}
