import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
import ApiUrls from 'src/app/configs/api-urls.config';
import { PaginationResourceInterface, ResponseV2Interface } from '../model/response.model';
import { AccountInterface, AccountSearchInterface } from '../model/user.model';

@Injectable({
  providedIn: 'root',
})
export class AccountsService {
  constructor(private http: HttpClient) {}

  list(page: number): Observable<PaginationResourceInterface<AccountInterface>> {
    let params = new HttpParams();
    params = page ? params.set('page', page.toString()) : params;

    return this.http.get<PaginationResourceInterface<AccountInterface>>(ApiUrls.profileAccounts, { params });
  }

  search(search: string): Observable<AccountSearchInterface> {
    const params = search ? new HttpParams().set('search', search) : new HttpParams();

    return this.http
      .get<ResponseV2Interface<AccountSearchInterface>>(ApiUrls.profileAccountsSearch, { params })
      .pipe(map((response) => response.data));
  }
}
