<table mat-table [dataSource]="items">
  <ng-container matColumnDef="key">
    <th mat-header-cell *matHeaderCellDef>
      {{ 'payments.current_package.summarized_list.items.name' | translate }}
    </th>
    <td mat-cell *matCellDef="let element">{{ 'payments.current_package.' + element.key | translate }}</td>
  </ng-container>

  <ng-container matColumnDef="restrictions">
    <th mat-header-cell *matHeaderCellDef>
      {{ 'payments.current_package.summarized_list.items.restrictions' | translate }}
    </th>
    <td mat-cell *matCellDef="let element">{{ element.restrictions }}</td>
  </ng-container>

  <ng-container matColumnDef="used">
    <th mat-header-cell *matHeaderCellDef>
      {{ 'payments.current_package.summarized_list.items.used' | translate }}
    </th>
    <td mat-cell *matCellDef="let element">{{ element.used }}</td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef class="actions">
      {{ 'general.actions' | translate }}
    </th>
    <td mat-cell *matCellDef="let element">
      <button mat-raised-button color="primary" appRouter="paymentsPlan">
        {{ 'payments.current_package.summarized_list.action_btn_txt' | translate }}
      </button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
