<form [formGroup]="form">
  <mat-form-field>
    <mat-label>
      {{ 'projects.connections.form.fields.connection.label' | translate }}
    </mat-label>
    <mat-select [formControl]="connection" (selectionChange)="changeConnection()" name="connection">
      <mat-option *ngFor="let connection of connections" [value]="connection">
        {{ connection | connectionLabel }}
      </mat-option>
    </mat-select>
    <app-form-field-error-display matError></app-form-field-error-display>
  </mat-form-field>

  <ng-container *ngIf="connection.value">
    <mat-form-field *appSpinner="!parentAccountsList; diameter: 25">
      <mat-label>
        {{ 'projects.connections.form.fields.account.label' | translate }}
      </mat-label>
      <mat-select [formControl]="parentAccount" (selectionChange)="changeParentAccount($event.value)" [disabled]="!connection.value">
        <mat-option *ngFor="let account of parentAccountsList" [value]="account">
          {{ account.name }} ({{ account.account_id }})
        </mat-option>
      </mat-select>
      <app-form-field-error-display matError></app-form-field-error-display>
    </mat-form-field>

    <ng-container *ngIf="parentAccount.value?.sub_accounts">
      <mat-form-field *appSpinner="!childAccountsList; diameter: 25">
        <mat-label>
          {{ 'projects.connections.form.fields.subaccount.label' | translate }}
        </mat-label>
        <mat-select [formControl]="childAccount" (selectionChange)="changeChildAccount($event.value)" [disabled]="!connection.value">
          <mat-option *ngFor="let account of childAccountsList! | sortBy: 'name'" [value]="account">
            {{ account.name }} ({{ account.account_id }})
          </mat-option>
        </mat-select>
        <app-form-field-error-display matError></app-form-field-error-display>
      </mat-form-field>
    </ng-container>
  </ng-container>
</form>
