import { FilterTypesEnum } from '../FilterTypesEnum';
import { FilterModel } from './filter.model';

export class NumberBiggerFilterModel extends FilterModel {
  symbol: FilterTypesEnum = FilterTypesEnum.num_bigger;

  isValid() {
    return !!this.value;
  }
}
