export enum PaymentPlanSupportEnum {
  activeService = 'active_service',
  free = 'free',
}

export enum PaymentPlanCssEnum {
  whitelabel = 'css_whitelabel',
}

export enum PaymentPlanSembotEnum {
  // chatGptRequests = 'chat_gpt_requests',
  monitorPhrases = 'monitor_phrases',
  monitorProducts = 'monitor_products',
  outputProducts = 'output_products',
  projects = 'projects',
}
