import { Component, Input, OnInit } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { RankTypes } from 'src/app/dashboard/project/monitor/monitor.enum';
import { AdsCompetitorsTypeModel } from '../../model/monitor.model';

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

@Component({
  selector: 'app-ads-competitors-table',
  templateUrl: './ads-competitors-table.component.html',
  styleUrls: ['./ads-competitors-table.component.scss'],
})
export class AdsCompetitorsTableComponent implements OnInit {
  @Input() data!: { type: RankTypes; data: AdsCompetitorsTypeModel[] };
  tableDataSource!: AdsCompetitorsTypeModel[];
  displayedColumns!: string[];

  ngOnInit(): void {
    this.tableDataSource = this.data.data;
    switch (this.data.type) {
      case RankTypes.paid:
        this.displayedColumns = ['domain', 'matched', 'top1', 'top', 'all'];
        break;
      case RankTypes.shopping:
        this.displayedColumns = ['domain', 'matched', 'top3', 'top10', 'top30'];
        break;
      case RankTypes.organic:
        this.displayedColumns = ['domain', 'matched', 'top3', 'top10', 'top50'];
        break;
    }
  }

  sortData(sort: Sort) {
    const data = this.tableDataSource.slice();
    if (!sort.active || sort.direction === '') {
      this.tableDataSource = data;
      return;
    }

    this.tableDataSource = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      return compare(a[sort.active as keyof typeof a]!, b[sort.active as keyof typeof b]!, isAsc);
    });
  }
}
