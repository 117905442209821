import { Component, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { cloneDeep, findIndex, trim } from 'lodash';
import { concat } from 'rxjs';
import { ProductModel } from 'src/app/dashboard/project/products/product.model';
import { ProductService } from 'src/app/dashboard/project/products/products.service';
import { ProjectService } from 'src/app/dashboard/project/project.service';
import { NotificationService } from 'src/app/notification/notification.service';
import { ColumnModel } from '../../sem-table/table-display/columns-switch/columns/column.model';
import { AiService } from '../../service/ai.service';

@Component({
  selector: 'app-product-ai-modal',
  templateUrl: './product-ai-modal.component.html',
  styleUrls: ['./product-ai-modal.component.scss'],
})
export class ProductAiModalComponent {
  columns: ColumnModel[];
  currentColumnName: string;
  form: UntypedFormGroup;
  isLoading = false;
  item: ProductModel;
  previouslyGeneratedTexts = {};
  selectedTabIndex = 0;

  constructor(
    public dialogRef: MatDialogRef<ProductAiModalComponent>,
    @Inject(MAT_DIALOG_DATA) private data: { columnName: string; columns: ColumnModel[]; item: ProductModel },
    private aiService: AiService,
    private notificationService: NotificationService,
    private productService: ProductService,
    public projectService: ProjectService,
  ) {
    const { columnName, columns, item } = this.data;

    const formGroup = {};
    columns.forEach((col) => {
      const key = col._key;
      key && ((formGroup[key as keyof typeof formGroup] as {}) = new UntypedFormControl(item[key as keyof typeof item] || ''));
    });

    this.form = new UntypedFormGroup(formGroup);

    columnName && (this.selectedTabIndex = findIndex(columns, { _key: columnName }) || 0);

    this.currentColumnName = columnName;
    this.columns = columns;
    this.item = item;
  }

  get valueChanged() {
    return (
      this.item && ((this.item[this.currentColumnName as keyof typeof this.item] as string) || '').localeCompare(this.formControl().value)
    );
  }

  disable() {
    this.isLoading = true;
    this.formControl().disable();
  }

  enable() {
    setTimeout(() => {
      this.isLoading = false;
      this.formControl().enable();
    }, 500);
  }

  formControl(key = cloneDeep(this.currentColumnName)): UntypedFormControl {
    return this.form.get(key) as UntypedFormControl;
  }

  generate() {
    this.disable();
    const projectId = this.projectService.activeProject$.getValue()!.id;

    this.aiService.generateForProduct(projectId, this.item.id, this.currentColumnName).subscribe(
      (res) => {
        if (res && res[0]) {
          const generatedText = trim(res[0], ' "');
          const currentValue = cloneDeep(this.formControl());
          (this.previouslyGeneratedTexts[this.currentColumnName as keyof typeof this.previouslyGeneratedTexts] as any) = cloneDeep(
            currentValue.value,
          );
          this.formControl().setValue(generatedText);
          this.enable();
        } else {
          this.formControl().setValue('');
          this.enable();
        }

        this.formControl().markAsDirty();
      },
      () => {
        this.notificationService.error('ai_generate');
        this.formControl().setValue('');
        this.enable();
      },
    );
  }

  setDefault() {
    this.disable();
    this.formControl().setValue(this.item[this.currentColumnName as keyof typeof this.item]);
    this.enable();
  }

  submit() {
    if (this.form.invalid) {
      return;
    }

    concat(
      ...Object.entries(this.form.controls)
        .filter(([, control]) => control.dirty)
        .map(([key, control]) => this.productService.editProductParam(this.item.id, { param: key, value: control.value })),
    ).subscribe({
      complete: () => {
        this.notificationService.success('edit_product');
        this.dialogRef.close();
      },
    });
  }

  undo() {
    if (this.previouslyGeneratedTexts[this.currentColumnName as keyof typeof this.previouslyGeneratedTexts]) {
      this.disable();
      this.formControl().setValue(this.previouslyGeneratedTexts[this.currentColumnName as keyof typeof this.previouslyGeneratedTexts]);
      delete this.previouslyGeneratedTexts[this.currentColumnName as keyof typeof this.previouslyGeneratedTexts];
      this.enable();
    }
  }

  tabChanged(tabIndex: number) {
    this.selectedTabIndex = tabIndex;
    this.currentColumnName = this.columns[tabIndex]?._key || null!;
  }
}
