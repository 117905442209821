import { ColumnModel } from './column.model';

export class ColumnGroupModel {
  constructor(
    public alias: string,
    public preventInColumn: {
      ableToFilter?: boolean;
      editable?: boolean;
    } = {},
  ) {}

  // TODO: ERROR TypeError: Cannot read properties of undefined (reading 'filter')
  getColumnsFromAllColumn(columns: ColumnModel[]): ColumnModel[] {
    return columns.filter((column) => column.group === this.alias);
  }

  applyOnColumn(column: ColumnModel) {
    if (this.preventInColumn) {
      column.editable = this.preventInColumn.editable ? null! : column.editable;
      column.ableToFilter = this.preventInColumn.ableToFilter ? false : column.ableToFilter;
    }
  }
}
