<div
  class="search-results"
  infiniteScroll
  [infiniteScrollDistance]="2"
  [infiniteScrollThrottle]="500"
  (scrolled)="onScroll()"
  [scrollWindow]="false"
>
  <div class="content">
    <ng-content></ng-content>
  </div>

  <div *appSpinner="isLoading; diameter: 18"></div>
</div>
