import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { debounceTime, map, startWith, takeUntil } from 'rxjs/operators';
import { NotificationService } from 'src/app/notification/notification.service';
import { TreeItemInterface } from 'src/app/shared/components/tree-list/tree-list.component';
import { PaginationResourceInterface } from 'src/app/shared/model/response.model';
import { AccountInterface } from 'src/app/shared/model/user.model';
import { AccountsService } from 'src/app/shared/service/accounts.service';
import { AuthService } from 'src/app/shared/service/auth.service';
import { LoaderService } from 'src/app/shared/service/loader.service';
import { RouterService, routerConfig } from 'src/app/shared/service/router.service';
import { ProjectModel } from '../../project/project.model';
import { ProjectService } from '../../project/project.service';

enum ItemListingTypeEnum {
  paginatedList,
  searchList,
}

enum ItemTypeEnum {
  account,
  project,
}

@Component({
  selector: 'app-accounts-list',
  templateUrl: './accounts-list.component.html',
  styleUrls: ['./accounts-list.component.scss'],
})
export class AccountsListComponent implements OnDestroy {
  @Input() isModalMode: boolean = false;
  @Output() selected = new EventEmitter<boolean>();

  get searchCtrl() {
    return this.form.get('searchCtrl') as UntypedFormControl;
  }

  private onDestroy$: Subject<void> = new Subject();
  accountsList: AccountInterface[] = [];
  accountsListData!: PaginationResourceInterface<AccountInterface>;
  form: UntypedFormGroup;
  itemListingType: ItemListingTypeEnum = ItemListingTypeEnum.paginatedList;
  itemListingTypeEnum = ItemListingTypeEnum;
  items: TreeItemInterface[] | null = null;

  constructor(
    private accountsService: AccountsService,
    private authService: AuthService,
    private formBuilder: UntypedFormBuilder,
    private loaderService: LoaderService,
    private notificationService: NotificationService,
    private projectService: ProjectService,
    private routerService: RouterService,
    private translateService: TranslateService,
  ) {
    this.form = this.formBuilder.group({
      searchCtrl: [],
    });

    this.searchCtrl.valueChanges
      .pipe(debounceTime(400), startWith(''), takeUntil(this.onDestroy$))
      .subscribe((value) => (value ? this.search(value) : this.getAccountsList()));
  }

  addNewProject() {
    this.selected.emit(true);
    this.routerService.navigate(routerConfig.projectAdd);
  }

  accountsNextPage(page: number) {
    this.getAccountsList(page);
  }

  getAccountsList(page: number | null = null) {
    !page && (this.items = null);

    this.accountsListData = null!;
    this.itemListingType = ItemListingTypeEnum.paginatedList;

    const currentProjectId = this.projectService.activeProject$?.getValue()?.id || null;

    return this.accountsService
      .list(page!)
      .pipe(
        map((res) => ({
          ...res,
          data: this.mapLabels(res.data, ItemTypeEnum.account),
          current: this.mapLabels(res.current, ItemTypeEnum.account),
        })),
        map((res) => {
          res.current.isExpanded = true;
          if (currentProjectId) {
            const currentChild = res.current.children.find((child: any) => child.id === currentProjectId);
            currentChild && (currentChild.isActive = true);
          }
          return res;
        }),
      )
      .subscribe(
        (data) => (this.accountsListData = data),
        () => this.notificationService.error('accounts_list'),
      );
  }

  search(value: string) {
    this.items = null;
    this.itemListingType = ItemListingTypeEnum.searchList;

    return this.accountsService.search(value).subscribe(
      (res) => {
        const items = [
          {
            children: this.mapLabels(res.accounts, ItemTypeEnum.account) || [],
            isExpanded: false,
            label: this.translateService.instant('components.change_account.list.accounts'),
            showChildCounter: true,
          },
          {
            children: this.mapLabels(res.projects, ItemTypeEnum.project) || [],
            isExpanded: false,
            label: this.translateService.instant('components.change_account.list.projects'),
            showChildCounter: true,
          },
        ]
          .filter((item) => item.children.length)
          .map((item) => {
            // Domyślne rozwijanie kategorii jeśli jej pozycji jest mniej (max 3):
            item.children.length <= 3 && (item.isExpanded = true);
            return item;
          });

        // Domyślne rozwijanie jeśli jest tylko 1 kategoria (konta lub projekty)
        if (items.length === 1) {
          items[0].isExpanded = true;
        }

        this.items = items;
      },
      () => this.notificationService.error('accounts_list'),
    );
  }

  selectedItem(data: { accountId: number; project: ProjectModel }) {
    this.selected.emit(true);
    this.loaderService.on();

    data.accountId &&
      this.authService.changeActiveAccount(data.accountId).subscribe(() => {
        this.notificationService.success('change_active_account');

        data.project ? this.goToProject(data.project) : this.routerService.navigate(routerConfig.home);
      });
  }

  private mapLabels(data: any, type: ItemTypeEnum) {
    let returnData = data;

    const mapProjectsLabel = (item: any, accountId: number | null = null) =>
      item.map((project: any) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { account, account_id, id, name } = project;
        let subLabel = null;

        id && (subLabel = `#${id}${account ? `, ${account}` : ''}`);

        return {
          ...project,
          ...{
            label: name,
            subLabel,
            emitData: { accountId: account_id || accountId || null, project },
          },
        };
      });

    const mapItem = (item: any) => {
      const accountId = item.id || null;
      item.label = item.email;
      item.name && (item.subLabel = item.name);
      item.emitData = { accountId };

      if (item.projects) {
        item.children = mapProjectsLabel(item.projects, accountId);

        delete item.projects;
      }

      return item;
    };

    switch (type) {
      case ItemTypeEnum.account:
        Array.isArray(returnData) ? returnData.map((item) => mapItem(item)) : (returnData = mapItem(returnData));

        break;
      case ItemTypeEnum.project:
        returnData = mapProjectsLabel(returnData);
        break;
    }

    return returnData;
  }

  private goToProject(project: ProjectModel) {
    if (this.authService.authUser!.permissions!.includes('view project') && project && project.id) {
      this.projectService.setActiveProject(project);
      this.routerService.navigateToProject(project.id);
    } else {
      this.notificationService.error('project_require_permission');
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }
}
