<div class="container" [ngClass]="{ 'modal-mode': isModalMode }">
  <div class="top">
    <mat-form-field appearance="outline" subscriptSizing="dynamic">
      <button mat-icon-button matIconPrefix><mat-icon matPrefix>search</mat-icon></button>
      <input
        matInput
        [formControl]="searchCtrl"
        cdkFocusInitial
        [placeholder]="'components.change_account.form.fields.search.label' | translate"
      />
      <app-form-field-error-display matError></app-form-field-error-display>
    </mat-form-field>
  </div>
  <div class="list">
    <app-infinite-scroll
      *ngIf="itemListingType === itemListingTypeEnum.paginatedList; else searchList"
      (values)="items = $event"
      [data]="accountsListData"
      (scrolled)="accountsNextPage($event)"
    >
      <app-tree-list [items]="items!" (selected)="selectedItem($event)"></app-tree-list>
    </app-infinite-scroll>

    <ng-template #searchList>
      <app-tree-list [items]="items!" (selected)="selectedItem($event)"></app-tree-list>
    </ng-template>
  </div>

  <div *ngIf="!isModalMode" class="bottom">
    <button (click)="addNewProject()" mat-button>
      <mat-icon>add_circle</mat-icon> {{ 'components.change_account.add_project_btn_txt' | translate }}
    </button>
  </div>
</div>
