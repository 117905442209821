/* eslint-disable max-len */
import { Renderer2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GuidesAvailable } from '../GuidesAvailable.enum';
import { Guide } from '../models/Guide';
import { GuideStep } from '../models/steps/GuideStep';
import { ClickInnerAction } from '../models/actions/ClickInnerAction';
import { GuideStepDone } from '../models/steps/GuideStepDone';

export function createAddProductTutorial(renderer: Renderer2, translateService: TranslateService): Guide {
  const clickStep1 = new ClickInnerAction(
    translateService.instant('tutorials.add_feed.step_1_action_1'),
    renderer,
    'tutorial-project-topbar-btn',
  );
  const clickStep1b = new ClickInnerAction(
    translateService.instant('tutorials.add_feed.step_1_action_2'),
    renderer,
    'tutorial-projects-list',
  );
  const clickStep1c = new ClickInnerAction(
    translateService.instant('tutorials.add_feed.step_1_action_3'),
    renderer,
    'tutorial-sidemenu-feeds',
  );
  const clickStep1d = new ClickInnerAction(
    translateService.instant('tutorials.add_feed.step_1_action_4'),
    renderer,
    'tutorial-add-new-feed-btn',
  );
  const step1: GuideStep = new GuideStep(
    translateService.instant('tutorials.add_feed.step_1_title'),
    translateService.instant('tutorials.add_feed.step_1_description'),
    [clickStep1, clickStep1b, clickStep1c, clickStep1d],
  );

  const clickStep2a = new ClickInnerAction(
    translateService.instant('tutorials.add_feed.step_2_action_1'),
    renderer,
    'tutorial-add-feed-form-url',
  );
  const clickStep2b = new ClickInnerAction(
    translateService.instant('tutorials.add_feed.step_2_action_2'),
    renderer,
    'tutorial-add-feed-form-hour',
  );
  const clickStep2c = new ClickInnerAction(
    translateService.instant('tutorials.add_feed.step_2_action_3'),
    renderer,
    'tutorial-add-feed-form-credentials',
  );
  const clickStep2d = new ClickInnerAction(
    translateService.instant('tutorials.add_feed.step_2_action_4'),
    renderer,
    'tutorial-add-feed-form-format',
  );
  const clickStep2e = new ClickInnerAction(
    translateService.instant('tutorials.add_feed.step_2_action_5'),
    renderer,
    'tutorial-add-feed-form-submit',
  );
  const step2: GuideStep = new GuideStep(
    translateService.instant('tutorials.add_feed.step_2_title'),
    translateService.instant('tutorials.add_feed.step_2_description'),
    [clickStep2a, clickStep2b, clickStep2c, clickStep2d, clickStep2e],
  );

  const clickStep3a = new ClickInnerAction(
    translateService.instant('tutorials.add_feed.step_3_action_1'),
    renderer,
    'input-feed-source-tutorial',
  );
  const clickStep3b = new ClickInnerAction(
    translateService.instant('tutorials.add_feed.step_3_action_2'),
    renderer,
    'input-feed-target-tutorial',
  );

  const clickStep3c = new ClickInnerAction(
    translateService.instant('tutorials.add_feed.step_3_action_3'),
    renderer,
    'input-feed-source-new-column-tutorial',
  );

  const clickStep3d = new ClickInnerAction(
    translateService.instant('tutorials.add_feed.step_3_action_4'),
    renderer,
    'input-feed-target-new-column-tutorial',
  );

  const clickStep3e = new ClickInnerAction(
    translateService.instant('tutorials.add_feed.step_3_action_5'),
    renderer,
    'tutorial-add-feed-form-set-mapping',
  );
  const step3: GuideStep = new GuideStep(
    translateService.instant('tutorials.add_feed.step_3_title'),
    translateService.instant('tutorials.add_feed.step_3_description'),
    [clickStep3a, clickStep3b, clickStep3c, clickStep3d, clickStep3e],
  );

  const clickStep4 = new ClickInnerAction(
    translateService.instant('tutorials.add_feed.step_4_action_1'),
    renderer,
    'tutorial-feed-parse-info',
  );
  const clickStep4a = new ClickInnerAction(
    translateService.instant('tutorials.add_feed.step_4_action_2'),
    renderer,
    'tutorial-feed-parse-info',
  );
  const clickStep4b = new ClickInnerAction(
    translateService.instant('tutorials.add_feed.step_4_action_3'),
    renderer,
    'tutorial-feed-output',
  );
  const step4: GuideStep = new GuideStep(
    translateService.instant('tutorials.add_feed.step_4_title'),
    translateService.instant('tutorials.add_feed.step_4_description'),
    [clickStep4, clickStep4a, clickStep4b],
  );

  const guide: Guide = new Guide(GuidesAvailable.addingProductData, translateService.instant('tutorials.add_feed.guide_desc'), [
    step1,
    step2,
    step3,
    step4,
  ]);

  const doneStep = new GuideStepDone(
    translateService.instant('tutorials.add_feed.step_done_title'),
    translateService.instant('tutorials.add_feed.step_done_description'),
  );
  guide.guideSteps.push(doneStep);
  return guide;
}
