import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { SettingsService } from 'src/app/shared/service/settings.service';
import ApiUrls from '../../configs/api-urls.config';
import { ProfileModel } from '../../shared/model/profile.model';
import { ResponseV2Interface } from '../../shared/model/response.model';
import { AuthService } from '../../shared/service/auth.service';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  constructor(
    private authService: AuthService,
    private http: HttpClient,
    private settingsService: SettingsService,
  ) {}

  editProfile(name: string): Observable<ProfileModel> {
    return this.http
      .post<ResponseV2Interface<ProfileModel>>(ApiUrls.profile, {
        name,
      })
      .pipe(map((res) => res.data));
  }

  profile(): Observable<ProfileModel> {
    return this.http.get<ResponseV2Interface<ProfileModel>>(ApiUrls.profile).pipe(
      map((res) => res.data),
      tap((res) => this.settingsService.changeSettingsInApp(res.settings)),
      tap((res) => this.authService.authUser$.next(res.user)),
      catchError((error: HttpErrorResponse) => throwError(error.error)),
    );
  }
}
