import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'userTagHolidayUntilLabel',
})
export class UserTagHolidayUntilLabelPipe implements PipeTransform {
  transform(dateTo?: string) {
    if (!dateTo) {
      return;
    }

    const momentTo = moment(dateTo);
    const momentToday = moment().startOf('day');

    if (momentTo.startOf('day').isSame(momentToday)) {
      return 'today';
    } else if (momentTo.startOf('day').isSame(momentToday.clone().add(1, 'd'))) {
      return 'tomorrow';
    }

    return 'date';
  }
}
