import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { PRODUCT_ADDITIONAL_ATTR_PREFIX } from 'src/app/dashboard/project/products/productsAdditionalField.model';
import { AnalyticsDataModel } from 'src/app/shared/modals/analytics-filters-modal/analytics-filters-modal.component';
import ApiUrls from '../../../configs/api-urls.config';
import {
  PaginationInterface,
  PaginationResourceInterface,
  ResponseInterface,
  ResponseV2Interface,
} from '../../../shared/model/response.model';
import { ActionModel } from '../../../shared/sem-table/action/models/ActionModel';
import { FilterLogicOperatorEnum } from '../../../shared/sem-table/filters/FilterLogicOperatorEnum';
import { FilterTypesEnum } from '../../../shared/sem-table/filters/FilterTypesEnum';
import { FilterFactoryService } from '../../../shared/sem-table/filters/filtersFactory.service';
import { FilterGroupModel } from '../../../shared/sem-table/filters/models/filter.model';
import { TableChangeDataEmittedInterface } from '../../../shared/sem-table/models/TableChangeDataEmitted.model';
import { TableConfigurationInterface } from '../../../shared/sem-table/models/TableConfigurationInterface.model';
import { GoogleCategoryService } from '../../../shared/service/google-category.service';
import { HelperService } from '../../../shared/service/helper.service';
import { ProjectService } from '../project.service';
import { ImageProductModel } from './product-photo-popup/image-product.model';
import { ProductGooglePhrasesModel, ProductModel } from './product.model';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  activeProduct!: ProductModel;
  activeProduct$: Subject<ProductModel> = new Subject();
  dataRefresh$: Subject<any> = new Subject();
  newProductInit$: Subject<any> = new Subject();

  constructor(
    private http: HttpClient,
    private googleCategoryService: GoogleCategoryService,
    private projectService: ProjectService,
    private helperService: HelperService,
    @Inject(FilterFactoryService) private filterFactoryService: FilterFactoryService,
  ) {}

  getProducts(
    idProject: number,
    dataToParse: TableChangeDataEmittedInterface,
    analyticsData?: AnalyticsDataModel | null,
    apply_visibility_settings?: boolean,
  ): Observable<PaginationInterface<ProductModel>> {
    const parsedData = this.helperService.parseBodyParamsFromTable(dataToParse);
    const filtersChanged: FilterGroupModel[] = this.helperService.parseProductFiltersForApi(parsedData.filterGroups!);
    const filters = {
      ...parsedData,
      filterGroups: filtersChanged,
      apply_visibility_settings: apply_visibility_settings || false,
    };

    // eslint-disable-next-line @typescript-eslint/dot-notation
    analyticsData && ((filters['additional_sources' as keyof typeof filters] as any) = analyticsData);

    return this.http
      .post<PaginationResourceInterface<ProductModel>>(ApiUrls.projectProductsSearch.prepareUrl({ project: idProject }), filters)
      .pipe(
        tap((products) => (products.data = this.helperService.parseProductsFromApi(products.data))),
        map(({ data, links, meta }) => ({
          data,
          ...links,
          ...meta,
        })),
        tap((data) =>
          data.data.forEach((prod) =>
            prod.product_additional_attributes.forEach(
              (val) => ((prod[`${PRODUCT_ADDITIONAL_ATTR_PREFIX}${val.name}` as keyof typeof prod] as string) = val.value),
            ),
          ),
        ),
      );
  }

  getOldProducts(
    idProject: number,
    option: {
      page: number;
      filterGroups?: FilterGroupModel[];
      order?: string;
      direction?: string;
      per_page?: number;
    },
  ): Observable<PaginationInterface<ProductModel>> {
    option.per_page = option.per_page ? option.per_page : 5;
    return this.http
      .post<PaginationResourceInterface<ProductModel>>(ApiUrls.projectProductsOld.prepareUrl({ project: idProject }), {
        ...option,
      })
      .pipe(
        tap((products) => (products.data = this.helperService.parseProductsFromApi(products.data))),
        map(({ data, links, meta }) => ({
          data,
          ...links,
          ...meta,
        })),
      );
  }

  getProductImages(productId: number): Observable<PaginationInterface<ImageProductModel>> {
    const projectId = this.projectService.activeProject$.getValue()!.id;
    return this.http
      .get<ResponseV2Interface<ImageProductModel[]>>(
        ApiUrls.projectProductImages.prepareUrl({
          project: projectId,
          product: productId,
        }),
      )
      .pipe();
  }

  uploadProductImage(fileToUpload: File, productId: number): Observable<ImageProductModel> {
    const formData = new FormData();
    formData.append('image', fileToUpload, fileToUpload.name);
    formData.set('main', '0');
    const projectId = this.projectService.activeProject$.getValue()!.id;

    return this.http
      .post<
        ResponseInterface<ImageProductModel>
      >(ApiUrls.projectProductImages.prepareUrl({ project: projectId, product: productId }), formData)
      .pipe(map((response) => response.data.item!));
  }

  productImageChangeActive(image: ImageProductModel, active: boolean, productId: number) {
    const projectId = this.projectService.activeProject$.getValue()!.id;
    return this.http.patch<ResponseInterface<ProductModel>>(
      ApiUrls.projectProductImageSetActive.prepareUrl({ project: projectId, product: productId, image: image.id }),
      { active },
    );
  }

  deleteProductImage(image: ImageProductModel, productId: number) {
    const projectId = this.projectService.activeProject$.getValue()!.id;
    return this.http.delete<ResponseInterface<ProductModel>>(
      ApiUrls.projectProductImage.prepareUrl({ project: projectId, product: productId, image: image.id }),
    );
  }

  setImageAsMain(image: ImageProductModel, productId: number) {
    const projectId = this.projectService.activeProject$.getValue()!.id;
    return this.http.patch<ResponseInterface<ProductModel>>(
      ApiUrls.projectProductImageSetMain.prepareUrl({ project: projectId, product: productId, image: image.id }),
      {},
    );
  }

  editProductParam(productId: number, data: { param: string; value: string }) {
    const projectId = this.projectService.activeProject$.getValue()!.id;

    return this.http.patch<ResponseInterface<ProductModel>>(
      ApiUrls.projectProduct.prepareUrl({ project: projectId, product: productId }),
      data,
    );
  }

  updateProduct(productToChange: ProductModel) {
    const product = this.mapProductForApi(productToChange);
    const projectId = this.projectService.activeProject$.getValue()!.id;
    return this.http
      .put<ResponseInterface<ProductModel>>(
        ApiUrls.projectProduct.prepareUrl({
          project: projectId,
          product: product.id,
        }),
        {
          ...product,
        },
      )
      .pipe();
  }

  updateAll(products: ProductModel[]): Observable<ResponseInterface<null>> {
    const data = products.map((product) => this.mapProductForApi(product));
    const projectId = this.projectService.activeProject$.getValue()!.id;

    return this.http.put<ResponseInterface<null>>(ApiUrls.projectProductsMassUpdate.prepareUrl({ project: projectId }), { data }).pipe();
  }

  addProduct(productToChange: ProductModel) {
    const product = this.mapProductForApi(productToChange);
    const projectId = this.projectService.activeProject$.getValue()!.id;
    return this.http.post<ResponseInterface<ProductModel>>(ApiUrls.projectProducts.prepareUrl({ project: projectId }), product);
  }

  removeDuplicatesByFilter(filterGroups: FilterGroupModel[], project: number) {
    const options = {
      body: {
        filterGroups,
      },
    };
    return this.http.request<{ message: string }>('delete', ApiUrls.projectProducts.prepareUrl({ project }), options);
  }

  removeOldProducts(filterGroups: FilterGroupModel[], project: number) {
    const options = {
      body: {
        filterGroups,
      },
    };
    return this.http.request<{ message: string }>('delete', ApiUrls.projectProductsOld.prepareUrl({ project }), options);
  }

  removeOverride(field: string, filterGroups: FilterGroupModel[] = []) {
    const projectId = this.projectService.activeProject$.getValue()!.id;

    return this.http.post<ResponseInterface<ProductModel>>(ApiUrls.projectProductsRemoveOverride.prepareUrl({ project: projectId }), {
      field,
      filterGroups,
    });
  }

  duplicateProduct(product: ProductModel): Observable<ProductModel> {
    const duplicatedProduct = this.mapProductForApi(product);
    const projectId = this.projectService.activeProject$.getValue()!.id;

    return this.http
      .post<ProductModel>(ApiUrls.projectProductCopy.prepareUrl({ project: projectId, product: product.id }), duplicatedProduct)
      .pipe();
  }

  multiDuplicateProduct(products: ProductModel[], change?: { param: string; value: any }): Observable<null> {
    const projectId = this.projectService.activeProject$.getValue()!.id;
    const ids = products.map((product) => product.id);
    return this.http.post<null>(ApiUrls.projectProductsMassCopy.prepareUrl({ project: projectId }), { ids, change }).pipe();
  }

  multiDuplicateProductByFilters(
    filterGroups: FilterGroupModel[],
    change?: {
      param: string;
      value: any;
    },
  ): Observable<null> {
    const projectId = this.projectService.activeProject$.getValue()!.id;

    return this.http
      .post<null>(ApiUrls.projectProductsFilterCopy.prepareUrl({ project: projectId }), {
        filterGroups,
        change,
      })
      .pipe();
  }

  duplicateProductBy(product: ProductModel, data: { data: any[]; columnName: string }): Observable<null> {
    const projectId = this.projectService.activeProject$.getValue()!.id;

    return this.http.post<null>(ApiUrls.projectProductMassCopy.prepareUrl({ project: projectId, product: product.id }), {
      values: data.data,
      param: data.columnName,
    });
  }

  sendAction(projectId: number, action: ActionModel) {
    const filterGroups: FilterGroupModel[] = this.helperService.parseProductFiltersForApi(action.filterGroups);
    this.mapActionForApi(action);

    return this.http.post<ResponseInterface<ProductModel>>(ApiUrls.projectProductsMassActions.prepareUrl({ project: projectId }), {
      action: action.returnForSend(),
      filterGroups,
    });
  }

  mapActionForApi(action: ActionModel): ActionModel {
    switch (action.columnName) {
      case 'google_product_category':
        action.value = this.googleCategoryService.pluckIdFromCategory(action.value);
        break;
      case 'sale_price_effective_date':
        if (action.value && action.value[0] && action.value[1]) {
          const startDate = moment(action.value[0]);
          const endDate = moment(action.value[1]);
          const format = 'YYYY-MM-DDTHH:mm:ss';
          action.value = `${startDate.format(format)}/${endDate.format(format)}`;
        }
        break;
      case 'visibility':
        action.value = action.value.toString();
        break;
      case 'is_bundle':
        action.value = action.value ? '1' : '0';
        break;
    }

    return action;
  }

  mapProductForApi(product: ProductModel): ProductModel {
    !product.product_additional_attributes && (product.product_additional_attributes = []); // TODO: ISSUE#1056

    const mappedProduct = cloneDeep(product);

    if (mappedProduct.sale_price_effective_date?.length) {
      const format = 'YYYY-MM-DDTHH:mm:ss';
      let salePriceEffectiveDateStr = moment(mappedProduct.sale_price_effective_date[0]).format(format);

      if (mappedProduct.sale_price_effective_date[1]) {
        const endDate = moment(mappedProduct.sale_price_effective_date[1]).format(format);
        salePriceEffectiveDateStr = `${salePriceEffectiveDateStr}/${endDate}`;
      }

      mappedProduct.sale_price_effective_date = salePriceEffectiveDateStr;
    } else {
      mappedProduct.sale_price_effective_date = null;
    }

    mappedProduct.product_additional_attributes = mappedProduct.product_additional_attributes
      ? [...product.product_additional_attributes]
      : [];
    Object.keys(mappedProduct).forEach((columnName) => {
      if (columnName.startsWith(PRODUCT_ADDITIONAL_ATTR_PREFIX)) {
        // TODO: ISSUE#1056
        const paramName = columnName.replace(PRODUCT_ADDITIONAL_ATTR_PREFIX, '');
        const indexAdditionalParamTarget = mappedProduct.product_additional_attributes.findIndex((attr) => attr.name === paramName);
        if (indexAdditionalParamTarget > -1) {
          mappedProduct.product_additional_attributes[indexAdditionalParamTarget].value = product[columnName as keyof typeof product];
        } else {
          mappedProduct.product_additional_attributes.push({ value: product[columnName as keyof typeof product], name: paramName });
        }
      }
    });

    mappedProduct.google_product_category = this.googleCategoryService.pluckIdFromCategory(mappedProduct.google_product_category);
    return mappedProduct;
  }

  mapProductsForFiltersById(products: ProductModel[], config: TableConfigurationInterface): FilterGroupModel[] {
    const filterGroups: FilterGroupModel = { filters: [], operator: FilterLogicOperatorEnum.none };
    products.forEach((product, i) => {
      const operator = i === 0 ? FilterLogicOperatorEnum.none : FilterLogicOperatorEnum.or;
      filterGroups.filters.push(this.filterFactoryService.createFilter(config.columns.id, FilterTypesEnum.equal, product.id, operator));
    });

    return [filterGroups];
  }

  editOveridenParams(item: ProductModel, fields: string[]): Observable<ProductModel> {
    const projectId = this.projectService.activeProject$.getValue()!.id;

    return this.http.patch<ProductModel>(ApiUrls.projectProductOverride.prepareUrl({ project: projectId, product: item.id }), {
      fields,
    });
  }

  changeProductPhrasesStatus(productId: number, google_phrases: ProductGooglePhrasesModel[]) {
    const projectId = this.projectService.activeProject$.getValue()!.id;

    return this.http.put<null>(
      ApiUrls.projectProductPhrases.prepareUrl({
        project: projectId,
        product: productId,
      }),
      { google_phrases },
    );
  }

  getNotMonitoredProducts(
    idProject: number,
    dataToParse: TableChangeDataEmittedInterface,
    analyticsData?: AnalyticsDataModel,
    apply_visibility_settings?: boolean,
    type?: string,
  ): Observable<PaginationInterface<ProductModel>> {
    const parsedData = this.helperService.parseBodyParamsFromTable(dataToParse);
    const filtersChanged: FilterGroupModel[] = this.helperService.parseProductFiltersForApi(parsedData.filterGroups!);
    const filters = {
      ...parsedData,
      filterGroups: filtersChanged,
      apply_visibility_settings: apply_visibility_settings || false,
      type,
    };

    // eslint-disable-next-line @typescript-eslint/dot-notation
    analyticsData && ((filters['additional_sources' as keyof typeof filters] as any) = analyticsData);

    return this.http
      .post<
        PaginationResourceInterface<ProductModel>
      >(ApiUrls.projectMonitorNotMonitoredProducts.prepareUrl({ project: idProject }), filters)
      .pipe(
        tap((products) => (products.data = this.helperService.parseProductsFromApi(products.data))),
        map(({ data, links, meta }) => ({
          data,
          ...links,
          ...meta,
        })),
        tap((data) =>
          data.data.forEach((prod) =>
            prod.product_additional_attributes.forEach(
              (val) => ((prod[`${PRODUCT_ADDITIONAL_ATTR_PREFIX}${val.name}` as keyof typeof prod] as string) = val.value),
            ),
          ),
        ),
      );
  }

  getProductsCsv(dataToParse: TableChangeDataEmittedInterface, fields: string[], analyticsData?: AnalyticsDataModel): Observable<null> {
    const projectId = this.projectService.activeProject$.getValue()!.id;
    const parsedData = this.helperService.parseBodyParamsFromTable(dataToParse);
    const filtersChanged: FilterGroupModel[] = this.helperService.parseProductFiltersForApi(parsedData.filterGroups!);
    const filters = {
      ...parsedData,
      filterGroups: filtersChanged,
    };
    // eslint-disable-next-line @typescript-eslint/dot-notation
    analyticsData && ((filters['additional_sources' as keyof typeof filters] as any) = analyticsData);
    return this.http.post<null>(
      ApiUrls.projectProductsCsv.prepareUrl({ project: projectId }),
      { ...filters, fields },
      { responseType: 'text' as 'json' },
    );
  }
}
