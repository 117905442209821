import { Injectable, OnDestroy, inject } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, map, takeUntil, tap } from 'rxjs/operators';
import { ItemModel } from '../item.model';
import { TableConfigurationModel } from '../models/TableConfiguration.model';
import { ColumnTypesEnum, innerColumnName } from '../table-display/ColumnTypesEnum';
import { ColumnsSetModel, ColumnsStorageSetModel } from '../table-display/columns-switch/columns/column.model';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root',
})
export class ColumnsService implements OnDestroy {
  configuration!: TableConfigurationModel;
  localStorageName = '';
  isConfigurated = false;
  columnsAbleToHighlight: string[] = [];
  allColumns: string[] = [];
  columnChosenToDisplay: string[] = [];
  columnsToChoose$: BehaviorSubject<string[]> = new BehaviorSubject(this.columnChosenToDisplay);
  storageColumns: ColumnsStorageSetModel[] = [];
  storageColumns$: BehaviorSubject<ColumnsStorageSetModel[]> = new BehaviorSubject(this.storageColumns);
  activeStorageColumns$: Subject<ColumnsStorageSetModel> = new Subject();
  private unSubscribe$ = new Subject<void>();
  private columnChosenToDisplay$: BehaviorSubject<string[]> = new BehaviorSubject(this.columnChosenToDisplay);
  displayedColumns$: Observable<string[]> = this.columnChosenToDisplay$.pipe(
    takeUntil(this.unSubscribe$),
    map((cols) => (this.configuration?.isInnerColumnIncluded() ? [innerColumnName, ...cols] : cols)),
  );

  private columnsToChoose: string[] = [];
  private activeStorageColumns!: ColumnsStorageSetModel;
  private configService = inject(ConfigService);

  constructor() {
    this.columnChosenToDisplay$
      .pipe(
        takeUntil(this.unSubscribe$),
        filter(() => !!this.configuration),
        tap((columns) => (this.configuration.displayedColumns = columns)),
        tap((columns) => (this.columnsAbleToHighlight = columns.filter((column) => this.configuration.columns[column]))),
        tap(() => this.synchronizeColumnsToChoose()),
      )
      .subscribe();
    this.getStorageData(this.configService.config);
  }

  configurate(config: TableConfigurationModel, items: ItemModel[]) {
    this.configuration = config;
    this.columnChosenToDisplay = this.configuration.displayedColumns.filter((column) => this.configuration.columns[column]);
    this.columnChosenToDisplay$.next(this.columnChosenToDisplay);

    this.localStorageName = `${this.configuration.configuration.storageName}`;
    if (items.length > 0) {
      this.allColumns = Object.keys(this.configuration.columns).filter(
        (key) => this.configuration.columns[key].type !== ColumnTypesEnum.INNER,
      );
      this.synchronizeColumnsToChoose();
      this.isConfigurated = true;
    }
  }

  getStorageData(config: TableConfigurationModel) {
    if (!config) {
      return;
    }
    this.storageColumns = config.memory!.columnsSets!;
    this.storageColumns$.next(this.storageColumns);
  }

  addColumn(column: string) {
    if (!this.columnChosenToDisplay.includes(column)) {
      this.columnChosenToDisplay.push(column);
      this.sendChoosenColumnsToConfig();
    }
  }

  removeColumn(column: string) {
    const index = this.columnChosenToDisplay.indexOf(column);
    if (index > -1) {
      this.columnChosenToDisplay.splice(index, 1);
    }
    this.sendChoosenColumnsToConfig();
  }

  synchronizeColumnsToChoose() {
    this.columnsToChoose = [...this.allColumns];
    this.columnChosenToDisplay.forEach((column) => {
      const index = this.columnsToChoose.indexOf(column);
      if (index > -1) {
        this.columnsToChoose.splice(index, 1);
      }
    });
    this.columnsToChoose$.next(this.columnsToChoose);
  }

  changeColumnsOrder(actualIndex: number, previousIndex: number) {
    const temp = this.columnChosenToDisplay[previousIndex];

    if (actualIndex > previousIndex) {
      this.columnChosenToDisplay.splice(previousIndex, 1);
      this.columnChosenToDisplay.splice(actualIndex, 0, temp);
    } else if (actualIndex < previousIndex) {
      this.columnChosenToDisplay.splice(actualIndex, 0, temp);
      this.columnChosenToDisplay.splice(previousIndex + 1, 1);
    }
    this.sendChoosenColumnsToConfig();
  }

  saveColumnsToStorage(name: string, columns: string[]) {
    this.storageColumns.push({
      name,
      columnNames: [...columns],
      active: false,
    } as any);
    this.configService.config.memory!.columnsSets = this.storageColumns;
    this.storageColumns$.next(this.storageColumns);
  }

  deleteColumnFromStorage(columns: ColumnsSetModel | ColumnsStorageSetModel) {
    const index = this.storageColumns.indexOf(columns as any);
    if (index > -1) {
      this.storageColumns.splice(index, 1);
    }
    this.storageColumns$.next(this.storageColumns);
  }

  setActiveStorage(columnSet: ColumnsSetModel | ColumnsStorageSetModel) {
    const index = this.storageColumns.indexOf(columnSet as any);
    this.activeStorageColumns = this.storageColumns[index];

    this.activeStorageColumns$.next(this.activeStorageColumns);
    this.columnChosenToDisplay = [...this.activeStorageColumns.columnNames];
    this.sendChoosenColumnsToConfig();
  }

  ngOnDestroy() {
    this.unSubscribe$.next();
  }

  private sendChoosenColumnsToConfig() {
    this.columnChosenToDisplay$.next(this.columnChosenToDisplay);
    this.configService.config.displayedColumns = this.columnChosenToDisplay;
    this.configService.config$.next(this.configService.config);
  }
}
