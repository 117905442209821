<app-dialog [dialogRef]="dialogRef">Reduce</app-dialog>

<ng-container>
  <mat-dialog-content>
    <h5>Remove additional resource from your subscription</h5>

    <div class="row">
      <mat-form-field>
        <mat-label>Amount to remove</mat-label>
        <input matInput name="additional-product" type="number" cdkFocusInitial [formControl]="countCtrl" min="1" [max]="data.count" />
      </mat-form-field>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-raised-button color="warn" (click)="openRemove()" [disabled]="disabled">Remove</button>
  </mat-dialog-actions>
</ng-container>
