import { Pipe, PipeTransform } from '@angular/core';
import { startCase, toLower } from 'lodash';
import { ConnectionsServiceSlugEnum } from 'src/app/shared/enums/connections-service.enum';
import { ServiceModel } from 'src/app/shared/model/connections.model';

@Pipe({
  name: 'connectionServiceLabel',
})
export class ConnectionServicePipe implements PipeTransform {
  transform(service: ServiceModel): string {
    let { slug } = service;
    return (ConnectionsServiceSlugEnum as any)[slug!] || startCase(toLower(slug!.replace('_', ' ')));
  }
}
