import { NgModule } from '@angular/core';
import { FusionChartsModule, FusionChartsStatic } from 'angular-fusioncharts';
// Import FusionCharts library and chart modules - TODO
import * as FusionCharts from 'fusioncharts';
import * as Charts from 'fusioncharts/fusioncharts.charts';
import * as PowerCharts from 'fusioncharts/fusioncharts.powercharts';
// import * as Widgets from 'fusioncharts/fusioncharts.widgets.js';
// import * as Gantt from 'fusioncharts/fusioncharts.gantt.js';
import * as TimeSeries from 'fusioncharts/fusioncharts.timeseries';
import * as Widgets from 'fusioncharts/fusioncharts.widgets';
import * as ZoomLine from 'fusioncharts/fusioncharts.zoomline';
import * as FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import * as GammelTheme from 'fusioncharts/themes/fusioncharts.theme.gammel';
import { FusionChartsConfig } from 'src/app/configs/fusion-charts.config';
import { ChartComponent } from './chart.component';

// eslint-disable-next-line @typescript-eslint/dot-notation
(FusionCharts.options as any)['license'](FusionChartsConfig.license);

// Pass the fusioncharts library and chart modules
FusionChartsModule.fcRoot(FusionCharts, Charts, PowerCharts, ZoomLine, FusionTheme, TimeSeries, GammelTheme, Widgets);

@NgModule({
  declarations: [ChartComponent],
  imports: [FusionChartsModule.forRoot(FusionChartsStatic)],
  exports: [ChartComponent],
})
export class ChartModule {}
