import { AbstractControl } from '@angular/forms';

export class PasswordValidation {
  static matchPassword(AC: AbstractControl) {
    if (AC) {
      const password = AC.get('password');
      const passwordConfirmation = AC.get('password_confirmation');

      if (password && passwordConfirmation && password.value !== passwordConfirmation.value) {
        passwordConfirmation.setErrors({ matchPassword: true });
      }
    }

    return null;
  }
}
