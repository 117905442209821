<app-dialog [dialogRef]="dialogRef">{{ 'components.change_account.title' | translate }}</app-dialog>
<mat-dialog-content>
  <app-accounts-list (selected)="close()" [isModalMode]="true"></app-accounts-list>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-raised-button (click)="close()">
    {{ 'general.cancel' | translate }}
  </button>
</mat-dialog-actions>
