import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-account-change-popup',
  templateUrl: './account-change-popup.component.html',
  styleUrls: ['./account-change-popup.component.scss'],
})
export class AccountChangePopupComponent {
  constructor(public dialogRef: MatDialogRef<AccountChangePopupComponent>) {}

  close() {
    this.dialogRef.close();
  }
}
