import { ActionTypesEnum } from '../ActionTypesEnum';
import { ActionModel } from './ActionModel';

export class SetEmptyAction extends ActionModel {
  type = ActionTypesEnum.setEmpty;
  value = null;

  isValid(): boolean {
    return !!this.columnName;
  }

  use() {
    return null;
  }

  returnForSend() {
    return {
      // value: this.value,
      action: this.type,
      param: this.columnName,
      sequence: this.sequence,
    };
  }

  copyValue(item: this, overrideManualChanges: boolean): void {
    this.columnName = item.columnName;
    this.override_manual_changes = overrideManualChanges;
  }
}
