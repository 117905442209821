<form [formGroup]="form">
  <!-- Connections -->

  <mat-form-field>
    <mat-label>
      {{ 'projects.connections.form.fields.connection.label' | translate }}
    </mat-label>
    <mat-select [formControl]="connectionCtrl" name="connection" (selectionChange)="changeConnection($event)">
      <mat-option *ngFor="let connection of connections" [value]="connection">
        {{ connection | connectionLabel }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <!-- Customer -->

  <mat-form-field>
    <mat-label>
      {{ 'projects.connections.form.fields.customer.label' | translate }}
    </mat-label>
    <mat-select
      (selectionChange)="changeCustomer($event)"
      [formControl]="customerCtrl"
      [placeholder]="'projects.connections.form.fields.customer.placeholder' | translate"
      name="adwordsCustomer"
    >
      <mat-option>
        <ngx-mat-select-search
          name="adsCustomerAutocomplete"
          [placeholderLabel]="'projects.connections.form.fields.customer.search.placeholder' | translate"
          [noEntriesFoundLabel]="'projects.connections.form.fields.customer.search.no_data_info' | translate"
          [searching]="searching"
          [formControl]="customerFilterCtrl"
        >
        </ngx-mat-select-search>
      </mat-option>

      <mat-option *ngFor="let customer of filteredCustomersList" [value]="customer">
        {{ customer.id }} {{ customer.name ? '(' + customer.name + ')' : '' }}
      </mat-option>
    </mat-select>
    <app-form-field-error-display matError></app-form-field-error-display>
  </mat-form-field>

  <!-- Account -->

  <mat-form-field *ngIf="customerCtrl.value">
    <mat-label>
      {{ 'projects.connections.form.fields.customer.label' | translate }}
    </mat-label>
    <mat-select
      [formControl]="accountCtrl"
      [placeholder]="'projects.connections.form.fields.customer.placeholder' | translate"
      name="adwordsInnerCustomer"
    >
      <mat-option>
        <ngx-mat-select-search
          name="adsCustomerAutocomplete"
          [placeholderLabel]="'projects.connections.form.fields.customer.search.placeholder' | translate"
          [noEntriesFoundLabel]="'projects.connections.form.fields.customer.search.no_data_info' | translate"
          [searching]="searching"
          [formControl]="accountFilterCtrl"
        >
        </ngx-mat-select-search>
      </mat-option>

      <mat-option *ngFor="let account of filteredAccountsList" [value]="account"> {{ account.id }} ({{ account.name }}) </mat-option>
    </mat-select>
    <app-form-field-error-display matError></app-form-field-error-display>
  </mat-form-field>
</form>
