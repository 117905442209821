import { Injectable, inject } from '@angular/core';
import { cloneDeep } from 'lodash';
import { FilterService } from './filters/filters.service';
import { TableConfigurationModel } from './models/TableConfiguration.model';
import { TableConfigurationInterface } from './models/TableConfigurationInterface.model';
import { RouteFiltersGroupParamsInterface } from './public-api';
import { ColumnsService } from './services/columns.service';

@Injectable({
  providedIn: 'root',
})
export class ConfigGeneratorService {
  routeInitFiltersParams: RouteFiltersGroupParamsInterface | null = null;

  private readonly columnService = inject(ColumnsService);
  private readonly filterService = inject(FilterService);

  getParsedConfig(config: TableConfigurationInterface): TableConfigurationModel {
    if (!config) {
      throw Error('Table config is not passed to Table Component');
    }

    const { filters: routeInitFiltersParams, storageName } = cloneDeep(this.routeInitFiltersParams) || {};

    if (storageName && routeInitFiltersParams && config.configuration?.storageName === storageName) {
      this.filterService.setInitFiltersForConfig(config, routeInitFiltersParams);
      this.routeInitFiltersParams = null;
    } else if (config.initFiltersParams) {
      this.filterService.setInitFiltersForConfig(config, config.initFiltersParams);
    }

    const initFilters = config.activeFilters && config.activeFilters.length ? config.activeFilters : config.initFilters;
    config.activeFilters = initFilters || [];
    const resConfig = new TableConfigurationModel(config);
    this.columnService.getStorageData(resConfig);

    Object.keys(resConfig.columns).forEach((key) => {
      config.columns[key].param = key;
    });

    return resConfig;
  }
}
