<mat-card appearance="outlined">
  <mat-card-header>
    <mat-card-title>
      {{ 'profile.settings.title' | translate }}
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <mat-accordion>
      <mat-expansion-panel #username>
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ 'profile.settings.username.title' | translate }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <app-change-username (closePanel)="username.close()"></app-change-username>
      </mat-expansion-panel>

      <mat-expansion-panel #language>
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ 'profile.settings.language.title' | translate }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <app-change-language (closePanel)="language.close()"></app-change-language>
      </mat-expansion-panel>

      <mat-expansion-panel #password>
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ 'profile.settings.password.title' | translate }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <app-change-password (closePanel)="password.close()"></app-change-password>
      </mat-expansion-panel>

      <mat-expansion-panel #holiday>
        <mat-expansion-panel-header>
          <mat-panel-title>{{ 'profile.settings.holiday.title' | translate }}</mat-panel-title>
        </mat-expansion-panel-header>
        <app-set-holiday></app-set-holiday>
      </mat-expansion-panel>
    </mat-accordion>
  </mat-card-content>
</mat-card>
