<form [formGroup]="form">
  <!-- Connections -->

  <mat-form-field>
    <mat-label>
      {{ 'projects.connections.form.fields.connection.label' | translate }}
    </mat-label>
    <mat-select [formControl]="connection" (selectionChange)="changeConnection()" name="connection">
      <mat-option *ngFor="let connection of connections" [value]="connection">
        {{ connection | connectionLabel }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field>
    <mat-label>
      {{ 'projects.connections.form.fields.account.label' | translate }}
    </mat-label>
    <mat-select [formControl]="account" (selectionChange)="changeAccount($event)" [disabled]="!connection.value" name="analyticsAccount">
      <mat-option *ngFor="let account of accountsList" [value]="account">
        {{ account.name }}
      </mat-option>
    </mat-select>
    <app-form-field-error-display matError></app-form-field-error-display>
  </mat-form-field>

  <mat-form-field>
    <mat-label>
      {{ 'projects.connections.form.fields.property.label' | translate }}
    </mat-label>
    <mat-select [disabled]="!account.value" [formControl]="property" (selectionChange)="changeProperty($event)" name="analyticsProperty">
      <mat-option *ngFor="let property of propertiesList" [value]="property">
        {{ property.name }}
      </mat-option>
    </mat-select>
    <app-form-field-error-display matError></app-form-field-error-display>
  </mat-form-field>

  <mat-form-field>
    <mat-label>
      {{ 'projects.connections.form.fields.profile.label' | translate }}
    </mat-label>
    <mat-select [disabled]="!property.value" [formControl]="profile" name="analyticsProfile">
      <mat-option *ngFor="let profile of profilesList" [value]="profile">
        {{ profile.name }}
      </mat-option>
    </mat-select>
    <app-form-field-error-display matError></app-form-field-error-display>
  </mat-form-field>
</form>
