<form [formGroup]="form">
  <!-- Connections -->
  <mat-form-field>
    <mat-label>
      {{ 'projects.connections.form.fields.connection.label' | translate }}
    </mat-label>
    <mat-select [formControl]="connection" name="connection" (selectionChange)="changeConnection()">
      <mat-option *ngFor="let connection of connections" [value]="connection">
        {{ connection | connectionLabel }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <!-- Business -->
  <mat-form-field>
    <mat-label>
      {{ 'projects.connections.form.fields.business.label' | translate }}
    </mat-label>
    <mat-select [formControl]="business" name="business" (selectionChange)="changeBusiness()">
      <mat-option *ngFor="let business of businessesList" [value]="business"> {{ business.name }} - {{ business.id }} </mat-option>
    </mat-select>
  </mat-form-field>

  <!-- Pages -->
  <mat-form-field>
    <mat-label>
      {{ 'projects.connections.form.fields.page.label' | translate }}
    </mat-label>
    <mat-select [formControl]="page" name="pages">
      <mat-option *ngFor="let page of pagesList" [value]="page"> {{ page.name }} - {{ page.id }} </mat-option>
    </mat-select>
  </mat-form-field>

  <!-- Accounts -->
  <mat-form-field>
    <mat-label>
      {{ 'projects.connections.form.fields.account.label' | translate }}
    </mat-label>
    <mat-select [formControl]="account" name="account">
      <mat-option *ngFor="let account of accountsList" [value]="account"> {{ account.name }} - {{ account.id }} </mat-option>
    </mat-select>
  </mat-form-field>
</form>
