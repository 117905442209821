import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PreviewFile } from '../../model/file.model';
import { ImageUtils } from '../../helpers/image.helper';
import { SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-files-preview-popup',
  templateUrl: './files-preview-popup.component.html',
  styleUrl: './files-preview-popup.component.scss',
})
export class FilesPreviewPopupComponent implements OnInit {
  currentIndex: number = 0;

  constructor(
    public dialogRef: MatDialogRef<FilesPreviewPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { files: PreviewFile[]; current: SafeUrl | string },
  ) {}

  ngOnInit(): void {
    if (this.data.files.length === 0) {
      throw new Error('No files provided for the lightbox.');
    }

    if (this.data.current) {
      const index = this.data.files.findIndex((file) => file.path === this.data.current);
      this.currentIndex = index;
    }
  }

  get currentFile(): PreviewFile {
    return this.data.files[this.currentIndex];
  }

  next(): void {
    this.currentIndex = (this.currentIndex + 1) % this.data.files.length;
  }

  previous(): void {
    this.currentIndex = (this.currentIndex - 1 + this.data.files.length) % this.data.files.length;
  }

  isImage(file: PreviewFile): boolean {
    if (!file) {
      return false;
    }
    return ImageUtils.isSupportedImage(file.type) || file.type == 'base64';
  }
}
