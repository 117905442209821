import { Component, Inject } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { filter, switchMap } from 'rxjs/operators';
import { PackageItemModel } from 'src/app/dashboard/payments/models/payments.model';
import { PaymentsService } from 'src/app/dashboard/payments/payments.service';
import { ConfirmModalComponent } from 'src/app/shared/modals/confirm-modal/confirm-modal.component';

@Component({
  selector: 'app-remove-product-popup',
  templateUrl: './remove-product-popup.component.html',
  styleUrls: ['./remove-product-popup.component.scss'],
})
export class RemoveProductPopupComponent {
  countCtrl = new UntypedFormControl(1);
  disabled = false;
  constructor(
    private paymentService: PaymentsService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<ConfirmModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PackageItemModel,
  ) {}

  openRemove() {
    const config = {
      data: {
        key: 'confirmations.add_products',
      },
      panelClass: 'no-dialog-spinner',
    } as MatDialogConfig;

    const dialog = this.dialog.open(ConfirmModalComponent, config);

    dialog
      .afterClosed()
      .pipe(filter((res) => !!res))
      .subscribe(() => this.remove());
  }

  private remove() {
    const { id } = this.data;
    this.disabled = true;
    const count = this.countCtrl.value;
    this.paymentService
      .removeProducts(id, count)
      .pipe(switchMap(() => this.paymentService.getCurrentPackage()))
      .subscribe(() => this.dialogRef.close(true));
  }
}
