import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { ProjectService } from 'src/app/dashboard/project/project.service';
import { TutorialService } from 'src/app/dashboard/tutorial/tutorial.service';
import { FormApiValidationError } from 'src/app/shared/model/errors/formApiError.model';
import { ConnectionsService } from 'src/app/shared/service/connections.service';
import { ConnectionModel } from '../../../../../shared/model/connections.model';
import { FacebookAccount, FacebookBusiness, FacebookPage } from '../../connections.model';
import { ConnectionsServiceEnum } from '../../../../../shared/enums/connections-service.enum';

@Component({
  selector: 'app-facebook-business-service',
  templateUrl: './facebook-business-service.component.html',
})
export class FacebookBusinessServiceComponent implements OnInit, OnDestroy {
  @Input() connections!: ConnectionModel[];
  @Output() connectionAttached = new EventEmitter<boolean>();

  accountsList: FacebookAccount[] | null = null;
  businessesList: FacebookBusiness[] | null = null;
  form!: UntypedFormGroup;
  isLoading = false;
  pagesList: FacebookPage[] | null = null;
  service = ConnectionsServiceEnum.Facebook;

  private onDestroy$ = new Subject<void>();

  constructor(
    private fb: UntypedFormBuilder,
    public connectionsService: ConnectionsService,
    public projectService: ProjectService,
    public tutorialService: TutorialService,
  ) {}

  get connection() {
    return this.form.get('connection') as UntypedFormControl;
  }

  get page() {
    return this.form.get('page') as UntypedFormControl;
  }

  get business() {
    return this.form.get('business') as UntypedFormControl;
  }

  get account() {
    return this.form.get('account') as UntypedFormControl;
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      connection: [null, [Validators.required]],
      page: [null, [Validators.required]],
      business: [null, [Validators.required]],
      account: [null, [Validators.required]],
    });

    this.business.disable();
    this.page.disable();
    this.account.disable();
  }

  changeConnection() {
    this.business.reset();
    this.businessesList = [];
    this.getBusinessesList();
    this.getPagesList();
  }

  changeBusiness() {
    this.account.reset();
    this.accountsList = [];
    this.getAccountsList();
  }

  getBusinessesList() {
    this.business.disable();
    this.connectionsService
      .getBusinessesForFacebook(this.projectService.activeProject$.getValue()!.id, this.connection.value.id)
      .pipe(
        takeUntil(this.onDestroy$),
        tap((businesses) => (this.businessesList = businesses)),
        tap(() => this.business.enable()),
      )
      .subscribe();
  }

  getAccountsList() {
    this.account.disable();
    this.connectionsService
      .getAdAccountsForFacebook(this.projectService.activeProject$.getValue()!.id, this.connection.value.id)
      .pipe(
        takeUntil(this.onDestroy$),
        tap((accounts) => (this.accountsList = accounts)),
        tap(() => this.account.enable()),
      )
      .subscribe();
  }

  getPagesList() {
    this.account.disable();
    this.connectionsService
      .getPagesForFacebook(this.projectService.activeProject$.getValue()!.id, this.connection.value.id)
      .pipe(
        takeUntil(this.onDestroy$),
        tap((pages) => (this.pagesList = pages)),
        tap(() => this.page.enable()),
      )
      .subscribe();
  }

  submit() {
    this.isLoading = true;
    this.connectionsService
      .attachFacebookToProject(
        this.projectService.activeProject$.getValue()!.id,
        this.connection.value.id,
        this.business.value.id || null,
        this.business.value.name || null,
        this.account.value.id || null,
        this.account.value.name || null,
        this.page.value.id || null,
        this.page.value.name || null,
      )
      .pipe(
        tap(() => {
          this.form.reset();
          this.form.markAsUntouched();
          this.isLoading = false;
        }),
        takeUntil(this.onDestroy$),
      )
      .subscribe(
        () => this.connectionAttached.emit(true),
        (er: FormApiValidationError) => {
          er.setOnForm && er.setOnForm(this.form);
          this.connectionAttached.emit(false);
        },
      );
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }
}
