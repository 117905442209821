import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { PackageItemModel } from '../../../models/payments.model';

@Pipe({
  name: 'displayRestriction',
})
export class DisplayRestrictionPipe implements PipeTransform {
  numberPipe: DecimalPipe;
  constructor() {
    this.numberPipe = new DecimalPipe('en-US');
  }

  transform(item: PackageItemModel): string {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { projects, output_products } = item.restrictions;
    const restrictions = {
      output_products: Number(output_products || 1),
      projects: Number(projects || 1),
    };

    const restrictionsScore = item.count * restrictions.output_products * restrictions.projects;

    return `${this.numberPipe.transform(item.count * restrictionsScore)} (${item.count} * ${this.numberPipe.transform(restrictionsScore)})`;
  }
}
