import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { NotificationService } from 'src/app/notification/notification.service';
import { WhiteLabelService } from '../shared/service/whitelabel.service';

@Injectable()
export class WhiteLabelGuard implements CanActivate {
  constructor(
    private notificationService: NotificationService,
    private router: Router,
    private whiteLabelService: WhiteLabelService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    const { isUnavailableForWhiteLabels } = route.data;

    if (isUnavailableForWhiteLabels && this.whiteLabelService.isItWhiteLabel) {
      this.notificationService.warning('no_permission_to_this_resource');
      return this.router.parseUrl('/dashboard');
    }

    return true;
  }
}
