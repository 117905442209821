import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
import ApiUrls from 'src/app/configs/api-urls.config';

@Injectable({
  providedIn: 'root',
})
export class AiService {
  constructor(private http: HttpClient) {}

  generateForProduct(projectId: number, productId: number, param: any): Observable<any> {
    const data = {
      param,
    };

    return this.http
      .post<any>(ApiUrls.aiProjectProductGenerate.prepareUrl({ project: projectId, product: productId }), data)
      .pipe(map((res) => res.data));
  }
}
