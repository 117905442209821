<div class="disabled" *ngIf="isDisabledState$ | async"></div>
<router-outlet></router-outlet>
<ng-container #loader *ngIf="isLoading || (isLoading$ | async)">
  <div class="screen-loader">
    <span class="logo"></span>

    <div *ngIf="isLoadingTooLong" class="relogin-info">
      <app-button text="Click to reload" color="primary" (click)="logout()"></app-button>
    </div>
  </div>
</ng-container>

<app-notification-displayer></app-notification-displayer>
