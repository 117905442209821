import { Component, Input } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { appear } from 'src/app/shared/animation/appear';

@Component({
  selector: 'app-async-template',
  templateUrl: './async-template.component.html',
  styleUrls: ['./async-template.component.scss'],
  animations: [appear],
})
export class AsyncTemplateComponent {
  actualState: 'error' | 'success' | 'pending' = 'pending';
  @Input() id!: string;
  @Input() message!: string;
  @Input() set state(res: Map<string, Promise<void>>) {
    const a = res.get(this.id)!;
    a.then(() => this.onSuccess())
      .catch(() => this.onError())
      .finally(() => this.onResolve());
  }

  constructor(private angularNotifierService: NotifierService) {}

  onResolve() {
    setTimeout(() => this.angularNotifierService.hide(this.id), 2000);
  }

  onError() {
    this.actualState = 'error';
  }

  onSuccess() {
    this.actualState = 'success';
  }
}
