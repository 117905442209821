<mat-form-field appearance="outline" [appFormFieldEditableLabel]="isEditableLabel">
  <ng-container *ngIf="isDisabled; else select">
    <input
      [matTooltip]="'components.project_list_select.disabled_tooltip' | translate"
      [value]="currentProjectName"
      disabled
      matInput
      readonly
    />
  </ng-container>

  <ng-template #select>
    <mat-select [formControl]="formCtrl" (valueChange)="valueChange.emit($event)">
      <mat-option>
        <ngx-mat-select-search
          [formControl]="projectFilterCtrl"
          [noEntriesFoundLabel]="'components.project_list_select.search.no_data_info' | translate"
          [placeholderLabel]="'components.project_list_select.search.placeholder' | translate"
        ></ngx-mat-select-search>
      </mat-option>
      <mat-option [value]="null">{{ noProjectSign }}</mat-option>
      <mat-option *ngFor="let project of filteredProjects" [value]="project.id">{{ project.name }}</mat-option>
    </mat-select>
  </ng-template>
  <app-form-field-error-display matError></app-form-field-error-display>
</mat-form-field>
