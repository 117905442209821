import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
  selector: '[appTemplateId]',
})
export class TemplateWithIdDirective {
  @Input('appTemplateId')
  role!: string;

  constructor(public template: TemplateRef<any>) {}
}
