import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { tap } from 'rxjs/operators';
import { InvoiceDataModel } from '../../models/invoice.model';
import { InvoiceService } from '../invoice.service';

@Component({
  selector: 'app-invoice-data-form',
  templateUrl: './invoice-data-form.component.html',
  styleUrls: ['./invoice-data-form.component.scss'],
})
export class InvoiceDataFormComponent implements OnInit {
  form!: UntypedFormGroup;
  data!: InvoiceDataModel;
  isLoading = true;
  @Output() formCreated: EventEmitter<UntypedFormGroup> = new EventEmitter();

  get company_name() {
    return this.form.get('company_name') as UntypedFormControl;
  }

  get address_1() {
    return this.form.get('address_1') as UntypedFormControl;
  }

  get address_2() {
    return this.form.get('address_2') as UntypedFormControl;
  }

  get postal_code() {
    return this.form.get('postal_code') as UntypedFormControl;
  }

  get city() {
    return this.form.get('city') as UntypedFormControl;
  }

  get tax_id() {
    return this.form.get('tax_id') as UntypedFormControl;
  }

  constructor(
    private fb: UntypedFormBuilder,
    private invoiceService: InvoiceService,
  ) {}

  ngOnInit(): void {
    this.getUserInvoiceData();
  }

  createForm() {
    this.form = this.fb.group({
      company_name: [this.data?.company_name || '', [Validators.required]],
      address_1: [this.data?.address_1 || '', [Validators.required]],
      address_2: [this.data?.address_2 || '', []],
      postal_code: [this.data?.postal_code || '', [Validators.required]],
      city: [this.data?.city || '', [Validators.required]],
      tax_id: [this.data?.tax_id || '', []],
    });
    this.formCreated.emit(this.form);
  }

  private getUserInvoiceData() {
    this.invoiceService
      .getInvoiceData()
      .pipe(
        tap((res) => (this.data = res)),
        tap(() => this.createForm()),
        tap(() => (this.isLoading = false)),
      )
      .subscribe();
  }
}
