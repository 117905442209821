/* eslint-disable no-underscore-dangle */
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { GuidesAvailable } from './GuidesAvailable.enum';
import { Guide } from './models/Guide';
import { InnerAction } from './models/actions/InnerAction';
import { RequestInnerAction } from './models/actions/RequestInnerAction';
import { GuideStep } from './models/steps/GuideStep';
import { TutorialFactoryService } from './tutorial-factory.service';

@Injectable({
  providedIn: 'root',
})
export class TutorialService {
  private guidesAvailable: Guide[] = this.tutorialFactory.guidesAvailable;
  private _activatedGuide$: BehaviorSubject<Guide> = new BehaviorSubject<Guide>(null!);
  activatedGuide$: Observable<Guide> = this._activatedGuide$.asObservable();
  private _activatedStep$: BehaviorSubject<GuideStep> = new BehaviorSubject<GuideStep>(null!);
  activatedStep$: Observable<GuideStep> = this._activatedStep$.asObservable();
  private _activatedAction$: BehaviorSubject<InnerAction> = new BehaviorSubject<InnerAction>(null!);
  activatedAction$: Observable<InnerAction> = this._activatedAction$.asObservable();
  private _guideDeactivated$: Subject<void> = new Subject();

  get activeStepIndex() {
    return this._activatedGuide$.getValue().currentActiveStepIndex;
  }

  get activeStepActionIndex() {
    return this._activatedGuide$.getValue().currentActiveStep.activeActionIndex;
  }

  get activeGuide() {
    return this._activatedGuide$.getValue();
  }

  get guideIsActive() {
    return !!this._activatedGuide$.getValue();
  }

  constructor(private tutorialFactory: TutorialFactoryService) {}

  activateGuide(id: GuidesAvailable, stepToActivate?: number) {
    const guideFound = this.guidesAvailable.find((guide) => guide.id === id);
    if (!guideFound) {
      throw Error('guide not found!');
    }
    if (this._activatedGuide$.getValue()) {
      return;
    }

    this._activatedGuide$.next(guideFound);
    if (stepToActivate) {
      for (let i = 0; i <= stepToActivate; i++) {
        if (i === stepToActivate) {
          guideFound.currentActiveStepIndex = stepToActivate;
          guideFound.currentActiveStep = guideFound.guideSteps[stepToActivate];
        } else {
          guideFound.guideSteps[i].markAsDone();
        }
      }
    }
    this.initLogicOfCurrentStep(guideFound.currentActiveStepIndex);
  }

  deactivateGuide() {
    const activatedGuide = this._activatedGuide$.getValue();
    if (!activatedGuide) {
      throw Error('None of guide has been activated!');
    }

    this._guideDeactivated$.next();
    this._activatedGuide$.next(null!);
    this._activatedStep$.next(null!);
    this._activatedAction$.next(null!);
    this.tutorialFactory.createTutorials();
    this.guidesAvailable = this.tutorialFactory.guidesAvailable;
  }

  private initLogicOfCurrentStep(index: number) {
    const guide = this._activatedGuide$.getValue();
    guide.currentActiveStep = guide.guideSteps[index];
    guide.currentActiveStep.initLogic(this);
    this._activatedStep$.next(guide.currentActiveStep);
    this._activatedAction$.next(guide.currentActiveStep.activeAction);
    // console.log('initLogicOfCurrentStep', index, guide.currentActiveStep.activeAction);
    if (guide.currentActiveStep.innerActions.length) {
      this.iterateStepActionStatus(guide).subscribe();
    }
  }

  iterateStepActionStatus(guide: Guide): any {
    if (guide.currentActiveStep.isDone) {
      guide.currentActiveStepIndex++;
      this.initLogicOfCurrentStep(guide.currentActiveStepIndex);
      return of();
    }

    this._activatedAction$.next(guide.currentActiveStep.activeAction);
    // console.log(guide.currentActiveStep.activeAction, guide.currentActiveStep.activeAction.isDone$);

    return guide.currentActiveStep.activeAction.isDone$.pipe(
      takeUntil(this._guideDeactivated$),
      filter((d) => !!d),
      // tap((d) => console.log(guide.currentActiveStep.activeAction.text, d)),
      tap(() => guide.currentActiveStep.activateNext(this)),
      switchMap(() => this.iterateStepActionStatus(guide)),
    );
  }

  notifyTutorialAboutRequest(requestId: string) {
    const action = this._activatedAction$.getValue() as RequestInnerAction;
    if (action.requestId === requestId) {
      action.markAsDone();
    }
  }
}
