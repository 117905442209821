export enum EnvironmentTypesEnum {
  local = 'local',
  beta = 'beta',
  prod = 'prod',
}

export enum EnvironmentPluginEnum {
  shopify = 'shopify',
}

export interface EnvironmentInterface {
  apiUrl: string;
  appVersion: string;
  dataUrl: string;
  domain: string;
  pluginMode: EnvironmentPluginEnum | null;
  production: boolean;
  shopifyName: string;
  shopifyPublic: string;
  stripePublicKey: string;
  trustedUsers: number[];
  type: EnvironmentTypesEnum;
  whitelabel?: boolean;
  recaptcha?: {
    siteKey: string;
  };
}
