import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NotificationService } from 'src/app/notification/notification.service';

export class FormApiValidationError {
  static errorKey = 'apiError';
  get mainMessage() {
    return this.payload.message;
  }

  get errors() {
    return this.payload.errors;
  }

  constructor(
    private payload: { errors: { [key: string]: string[] }; message: string },
    private notificationService: NotificationService,
  ) {}

  setOnForm(form: UntypedFormGroup, withNotification = false) {
    if (withNotification) {
      this.notify();
    }
    if (!this.errors) {
      return;
    }
    const errorKeys = Object.keys(this.errors);
    const defaultKey = FormApiValidationError.errorKey;

    errorKeys.forEach((key) => {
      const formControl = form.get(key);
      formControl && formControl.setErrors({ [defaultKey]: this.errors[key] });
    });
  }

  setOnControl(control: UntypedFormControl, withNotification = false) {
    if (withNotification) {
      this.notify();
    }
    if (!this.errors) {
      return;
    }
    const errorKeys = Object.keys(this.errors);
    const defaultKey = FormApiValidationError.errorKey;

    errorKeys.forEach((key) => {
      control.setErrors({ [defaultKey]: this.errors[key] });
    });
  }

  notify() {
    // const errors = Object.keys(this.errors).map((key) => this.errors[key]);
    if (Array.isArray(this.mainMessage)) {
      this.notificationService.error(this.mainMessage.join('</br>'), {}, true);
      return;
    }

    this.notificationService.error(this.mainMessage, {}, true);
  }
}
