import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import apiUrlsConfig from 'src/app/configs/api-urls.config';
import { ConnectionModel } from 'src/app/shared/model/connections.model';
import { ResponseV2Interface } from 'src/app/shared/model/response.model';
import { BingAccount, BingCustomer } from '../../connections.model';

@Injectable({
  providedIn: 'root',
})
export class BingConnectionsService {
  constructor(private http: HttpClient) {}

  getCustomers(connection: number): Observable<BingCustomer[]> {
    return this.http
      .get<ResponseV2Interface<BingCustomer[]>>(apiUrlsConfig.bingCustomer.prepareUrl({ connection }))
      .pipe(map((res) => res.data));
  }

  getAccounts(connection: number, customer: number): Observable<BingAccount[]> {
    return this.http
      .get<ResponseV2Interface<BingAccount[]>>(apiUrlsConfig.bingAccounts.prepareUrl({ connection, customer }))
      .pipe(map((res) => res.data));
  }

  attachConnection(
    projectId: number,
    connection: ConnectionModel,
    customer: BingCustomer,
    account: BingAccount,
  ): Observable<ConnectionModel> {
    return this.http
      .post<ConnectionModel>(apiUrlsConfig.projectConnections.prepareUrl({ project: projectId }), {
        connection_id: connection.id,
        service: 'bing',
        customer_id: customer.id,
        account_id: account.id,
        account_name: account.name,
      })
      .pipe
      // map((res) => res.data),
      ();
  }
}
