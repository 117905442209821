import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appVerticalScrollable]',
})
export class VerticalScrollableDirective {
  i = 1;

  @HostListener('mousewheel', ['$event']) scroll(event: WheelEvent) {
    if (this.elementRef.nativeElement.scrollWidth <= this.elementRef.nativeElement.clientWidth) {
      return;
    }
    event.stopPropagation();
    event.preventDefault();
    this.elementRef.nativeElement.scrollLeft += event.deltaY;
  }

  constructor(private elementRef: ElementRef) {}
}
