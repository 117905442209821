import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SetupIntent, StripeError } from '@stripe/stripe-js';
import { Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { NotificationService } from 'src/app/notification/notification.service';
import { routerConfig } from 'src/app/shared/service/router.service';
import { RouterService } from '../../../shared/service/router.service';
import { CardAttachFormComponent } from '../buy-subscription/card-attach-form/card-attach-form.component';
import { PaymentsService } from '../payments.service';

@Component({
  selector: 'app-card-reconnection',
  templateUrl: './card-reconnection.component.html',
  styleUrls: ['./card-reconnection.component.scss'],
})
export class CardReconnectionComponent implements OnInit, OnDestroy {
  @ViewChild(CardAttachFormComponent) cardAttachForm!: CardAttachFormComponent;
  private onDestroy$ = new Subject<void>();

  constructor(
    private paymentsService: PaymentsService,
    private routerService: RouterService,
    private notifierSevice: NotificationService,
    private location: Location,
  ) {}

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit(): void {}

  reconnectCard(res: { setupIntent?: SetupIntent; error?: StripeError }) {
    this.paymentsService
      .reconnectCard(res.setupIntent!.payment_method as string)
      .pipe(
        takeUntil(this.onDestroy$),
        switchMap(() => this.paymentsService.getCurrentPackage()),
      )
      .subscribe(
        () => {
          this.notifierSevice.success('card_reconnection');
          this.routerService.navigate(routerConfig.payments);
        },
        () => this.notifierSevice.success('card_reconnection'),
      );
  }

  back() {
    this.location.back();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
