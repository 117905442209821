import { Component, Input, OnInit } from '@angular/core';
import CommonUrlsConfig from 'src/app/configs/common-urls.config';
import { TranslationService } from '../../service/translation.service';

interface ItemInterface {
  target?: string;
  translateKey: string;
  url: string;
}

@Component({
  selector: 'app-welcome-screen',
  templateUrl: './welcome-screen.component.html',
  styleUrls: ['./welcome-screen.component.scss'],
})
export class WelcomeScreenComponent implements OnInit {
  @Input() mode: string = 'chat_gpt'; // TODO: jak dojdzie więcej mode to dać enuma, sprawdzać i zeswitchować items
  items!: ItemInterface[];

  url = {
    authLogin: 'auth/login',
    howToUseChatGptWithSembot: this.translationService.commonUrls().howToUseChatGptWithSembot,
    openAi: CommonUrlsConfig.chatOpenAi,
    sembotLandingPage: this.translationService.commonUrls().landingPage,
  };

  constructor(private translationService: TranslationService) {}

  ngOnInit(): void {
    this.items = [
      { url: this.url.howToUseChatGptWithSembot, translateKey: 'how_to_use' },
      { url: this.url.sembotLandingPage, translateKey: 'learn_more' },
      { url: this.url.authLogin, target: '_self', translateKey: 'start_app' },
      { url: this.url.openAi, translateKey: 'start_plugin' },
    ];
  }

  gotTo(item: ItemInterface) {
    item.url && window.open(item.url, item.target || '_blank');
  }
}
