import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { ProjectService } from 'src/app/dashboard/project/project.service';
import { TutorialService } from 'src/app/dashboard/tutorial/tutorial.service';
import { ConnectionsService } from 'src/app/shared/service/connections.service';
import { ConnectionModel } from '../../../../../shared/model/connections.model';
import { ConnectionsServiceEnum } from '../../../../../shared/enums/connections-service.enum';

@Component({
  selector: 'app-facebook-service',
  templateUrl: './facebook-service.component.html',
})
export class FacebookServiceComponent implements OnInit, OnDestroy {
  @Input() connections!: ConnectionModel[];
  @Output() connectionAttached = new EventEmitter<void>();
  form!: UntypedFormGroup;
  isLoading = true;
  service = ConnectionsServiceEnum.Facebook;
  public connectionsService = inject(ConnectionsService);
  public projectService = inject(ProjectService);
  public tutorialService = inject(TutorialService);
  private onDestroy$ = new Subject<void>();
  private fb = inject(UntypedFormBuilder);

  get connection() {
    return this.form.get('connection') as UntypedFormControl;
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      connection: [null, [Validators.required]],
    });
  }

  attachConnection() {} // TODO

  submit() {
    this.isLoading = true;
    this.attachConnection();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }
}
