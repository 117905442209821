import { Injectable, inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

const defaultTitle = 'Sembot.com';

@Injectable({ providedIn: 'root' })
export class TranslatedTitleStrategy extends TitleStrategy {
  private readonly translateService = inject(TranslateService);
  private readonly titleService = inject(Title);

  override updateTitle(routerState: RouterStateSnapshot) {
    this.titleService.setTitle(this.getTitle(routerState));
  }

  private getTitle(routerState: RouterStateSnapshot): string {
    const translationKey = this.buildTitle(routerState);

    if (!translationKey) {
      return defaultTitle;
    }

    const title = this.translateService.instant(translationKey);

    return title === translationKey ? defaultTitle : title;
  }
}
