<form [formGroup]="form">
  <!-- Connections -->

  <mat-form-field>
    <mat-label>{{ 'projects.connections.form.fields.connection.label' | translate }}</mat-label>
    <mat-select formControlName="connection" (selectionChange)="changeConnection()" name="connection">
      <mat-option *ngFor="let connection of connections" [value]="connection">
        {{ connection | connectionLabel }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field>
    <mat-label>{{ 'projects.connections.form.fields.site.label' | translate }}</mat-label>
    <mat-select formControlName="site" [disabled]="!connection.value" name="site">
      <mat-option *ngFor="let site of sitesList" [value]="site">
        {{ site.url }}
      </mat-option>
    </mat-select>
    <app-form-field-error-display matError></app-form-field-error-display>
  </mat-form-field>
</form>
