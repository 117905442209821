import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import ApiUrls from 'src/app/configs/api-urls.config';
import { MaintenanceInterface } from '../shared/model/maintenance.model';

@Injectable({
  providedIn: 'root',
})
export class MaintenanceService {
  private http = inject(HttpClient);

  check(): Observable<MaintenanceInterface> {
    return this.http.get<MaintenanceInterface>(ApiUrls.checkMaintenance);
  }
}
