<mat-card appearance="outlined">
  <ng-container *ngIf="data; else loader">
    <div cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
      <mat-card-header>
        <mat-card-title>{{ title }}</mat-card-title>

        <div class="right">
          <div *ngIf="isSearchVisible && ((data && data.data && data.data.length) || (state && state.filterGroups))">
            <mat-form-field class="">
              <button mat-icon-button matIconPrefix><mat-icon>search</mat-icon></button>
              <input matInput placeholder="Search" [formControl]="searchText" name="searchText" cdkFocusInitial />
            </mat-form-field>
          </div>

          <!------------- RIGHT HEADER------------->
          <ng-container
            *ngIf="templatesConst.rightHeader && $any(templatesFields)[templatesConst.rightHeader]"
            [ngTemplateOutlet]="$any(templatesFields)[templatesConst.rightHeader]"
          >
          </ng-container>
        </div>
      </mat-card-header>

      <mat-card-content class="column" *ngIf="(data.data && data.data.length) || (state && state.filterGroups); else empty">
        <mat-table [dataSource]="data.data" class="mat-elevation-z8">
          <ng-container matColumnDef="{{ columnNamePrefix + i }}" *ngFor="let column of state.columns; index as i">
            <mat-header-cell class="cell-type-{{ column.type || 'field' }}" *matHeaderCellDef>
              {{ column.header_key ? (column.header_key | translate) : column.header }}
            </mat-header-cell>

            <mat-cell [ngSwitch]="column.type" *matCellDef="let row" class="cell-type-{{ column.type || 'field' }}">
              <!-- <ng-container *ngSwitchCase="columnTypes.?">...</ng-container> -->
              <span class="small-screen-header">{{ column.header_key ? (column.header_key | translate) : column.header }}:</span>

              <ng-container *ngSwitchCase="columnTypes.bool">
                <mat-icon *ngIf="row[column.field!]; else notChecked" color="primary">check</mat-icon>
                <ng-template #notChecked>
                  <mat-icon color="warn">close</mat-icon>
                </ng-template>
              </ng-container>

              <ng-container *ngSwitchCase="columnTypes.date">
                {{ row[column.field!] ? (row[column.field!] | dateFormat) : noValueStr }}
              </ng-container>

              <ng-container *ngSwitchCase="columnTypes.template">
                <ng-container *ngIf="$any(templatesFields)[column.field!]; else noValue">
                  <ng-container *ngTemplateOutlet="$any(templatesFields)[column.field!]; context: { $implicit: row }"> </ng-container>
                </ng-container>
              </ng-container>

              <ng-container *ngSwitchDefault>
                {{ row[column.field!] || noValueStr }}
              </ng-container>
            </mat-cell>
          </ng-container>

          <!------------- LAST CELL ------------->
          <ng-container *ngIf="templatesConst.lastCell && $any(templatesFields)[templatesConst.lastCell]" matColumnDef="customLastCell">
            <mat-header-cell class="right cell-type-custom-last-cell" *matHeaderCellDef></mat-header-cell>

            <mat-cell *matCellDef="let row" class="right cell-type-custom-last-cell">
              <ng-container
                [ngTemplateOutlet]="$any(templatesFields)[templatesConst.lastCell]"
                [ngTemplateOutletContext]="{ $implicit: row }"
              >
              </ng-container>
            </mat-cell>
          </ng-container>

          <ng-container *ngIf="columnsNames && columnsNames.length">
            <mat-header-row *matHeaderRowDef="columnsNames"></mat-header-row>
          </ng-container>
          <mat-row *matRowDef="let row; columns: columnsNames"> </mat-row>
        </mat-table>

        <mat-paginator
          *ngIf="isPaginatorVisible && data.meta"
          [length]="data.meta.total"
          [pageSize]="data.meta.per_page"
          [pageSizeOptions]="pageSizeOptions"
          [pageIndex]="state.page!.pageIndex!"
          (page)="setPage($event)"
        >
        </mat-paginator>
      </mat-card-content>
    </div>
  </ng-container>
</mat-card>

<ng-template #empty>
  <mat-card-content class="column no-data">
    <mat-card-title> {{ noDataInfo || 'general.no_data' | translate }} </mat-card-title>
  </mat-card-content>
</ng-template>

<ng-template #loader>
  <mat-card-content class="column">
    <mat-spinner class="loader" diameter="50"></mat-spinner>
  </mat-card-content>
</ng-template>

<ng-template #noValue>
  {{ noValueStr }}
</ng-template>
