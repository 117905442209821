import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { InvoiceDataModel } from '../../models/invoice.model';
import { GenerateInvoicePopupComponent } from '../generate-invoice-popup/generate-invoice-popup.component';

@Component({
  selector: 'app-invoice-data',
  templateUrl: './invoice-data.component.html',
  styleUrls: ['./invoice-data.component.scss'],
})
export class InvoiceDataComponent {
  @Input() preparedData!: string[][];
  @Input() data!: InvoiceDataModel;

  constructor(private dialog: MatDialog) {}

  openInvoiceDataDialog(): void {
    const dialogRef = this.dialog.open(GenerateInvoicePopupComponent, { data: this.data });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.data = res;
        this.preparedData = Object.entries(res);
      }
    });
  }
}
