/* eslint-disable no-underscore-dangle */
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NotifierService } from 'angular-notifier';
import { Observable, Subject } from 'rxjs';
import { MessageTypeEnum } from '../dashboard/navigation/dashboard-messages/notification.model';
import { DisableStateService } from '../shared/service/disabled-state.service';
import { NotificationThemeType } from './NotificationConfig';
import { NotificationInformations as NotificationData } from './NotificationInformations';

const NOTIFICATIONS_KEY = 'notifications';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private _newNotificationAppeard$ = new Subject<NotificationData>();
  newNotificationAppeard$: Observable<NotificationData> = this._newNotificationAppeard$.asObservable();

  constructor(
    private angularNotifierServiceTEMP: NotifierService,
    private translateService: TranslateService,
    private disabledStateService: DisableStateService,
  ) {}

  hideAll = () => this.angularNotifierServiceTEMP.hideAll();

  sendNotification(message: string, type: MessageTypeEnum): string {
    switch (type) {
      case MessageTypeEnum.error:
        return this.error(message, null, true);
      case MessageTypeEnum.success:
        return this.success(message, null, true);
      case MessageTypeEnum.info:
        return this.info(message, null, true);
      case MessageTypeEnum.warning:
        return this.warning(message, null, true);
      case MessageTypeEnum.updates:
        return this.updates(message, null, true);
      case MessageTypeEnum.tasks:
        return this.info(message, null, true);
      default:
        return '';
    }
  }

  error(translateKey: string, params: object | null = null, dontCheckKey = false) {
    if (!translateKey) {
      // eslint-disable-next-line no-param-reassign
      translateKey = 'general';
      // eslint-disable-next-line no-param-reassign
      dontCheckKey = false;
    }

    return this.send('error', translateKey, params, dontCheckKey);
  }

  info(translateKey: string, params: object | null = null, dontCheckKey = false) {
    return this.send('info', translateKey, params, dontCheckKey);
  }

  success(translateKey: string, params: object | null = null, dontCheckKey = false) {
    return this.send('success', translateKey, params, dontCheckKey);
  }

  warning(translateKey: string, params: object | null = null, dontCheckKey = false) {
    return this.send('warning', translateKey, params, dontCheckKey);
  }

  updates(translateKey: string, params: object | null = null, dontCheckKey = false) {
    return this.send('updates', translateKey, params, dontCheckKey);
  }

  tasks(translateKey: string, params: object | null = null, dontCheckKey = false) {
    return this.send('tasks', translateKey, params, dontCheckKey);
  }

  /**
   * Open prompt notification which is listening to .
   *
   * @param text Text displayed on notification.
   * @param params Object with values to pass into translation.
   * @param dontCheckKey Flag to decide if we passed message or translation key in text param.
   * @param callback Function reacting on user response, receives boolean response.
   * @param close$ Observable which automaticly close notification.
   * @param requiredAction Decides if should disable app from usage until user response.
   * @return Message displayed in popup.
   */
  prompt(
    text: string,
    params: object,
    dontCheckKey: any,
    callback: Function,
    close$?: Observable<void>,
    requiredAction: boolean = false,
  ): string {
    const message = this.parseTranslationKey('prompt', text, params || null, dontCheckKey || false);
    this._newNotificationAppeard$.next({ message, themeType: 'prompt', type: 'prompt', callback, close$ });
    if (requiredAction) {
      this.disabledStateService.disableAppState();
    }
    return message;
  }

  /**
   * Open async notification which is listening to success or fail of observable.
   *
   * @param text Text displayed on notification.
   * @param params Object with values to pass into translation.
   * @param dontCheckKey Flag to decide if we passed message or translation key in text param.
   * @param obs$ Observable to listen for.
   * @return Message displayed in popup.
   */
  async(text: string, params: object, dontCheckKey: any, obs$: Observable<any>) {
    const message = this.parseTranslationKey('prompt', text, params || null, dontCheckKey || false);
    this._newNotificationAppeard$.next({ message, themeType: 'prompt', type: 'async', close$: obs$ });
    return message;
  }

  private send(themeType: NotificationThemeType, translateKey: string, params: object | null = null, dontCheckKey = false): string {
    if (translateKey) {
      const message = this.parseTranslationKey(themeType, translateKey, params, dontCheckKey);
      this._newNotificationAppeard$.next({ message, themeType, type: 'message' });
      return message;
    }

    return null!;
  }

  private parseTranslationKey(
    themeType: NotificationThemeType,
    translateKey: string,
    params: object | null = null,
    dontCheckKey = false,
  ): string {
    if (dontCheckKey) {
      return translateKey;
    }

    const key = `${NOTIFICATIONS_KEY}.${themeType}.${translateKey}`;
    const message = this.translateService.instant(key, params!);
    if (message === key) {
      // eslint-disable-next-line no-console
      console.warn(`no translation for key ${key}`);
    }
    return message;
  }
}
