import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';
import { ProjectService } from 'src/app/dashboard/project/project.service';
import { ConnectionsServiceEnum } from 'src/app/shared/enums/connections-service.enum';
import { ConnectionModel } from 'src/app/shared/model/connections.model';
import { FormApiValidationError } from 'src/app/shared/model/errors/formApiError.model';
import { BingAccount, BingCustomer } from '../../connections.model';
import { BingConnectionsService } from './bing-connections.service';

@Component({
  selector: 'app-bing-service',
  templateUrl: './bing-service.component.html',
})
export class BingServiceComponent implements OnInit, OnDestroy {
  @Input() connections!: ConnectionModel[];
  @Output() connectionAttached = new EventEmitter<boolean>();

  private onDestroy$ = new Subject<void>();
  accountFilterCtrl = new UntypedFormControl();
  customerFilterCtrl = new UntypedFormControl();
  form!: UntypedFormGroup;
  isLoading = false;
  searching = false;
  service = ConnectionsServiceEnum.Bing;

  get connectionCtrl() {
    return this.form.get('connection') as UntypedFormControl;
  }

  get customerCtrl() {
    return this.form.get('customer') as UntypedFormControl;
  }

  get accountCtrl() {
    return this.form.get('account') as UntypedFormControl;
  }

  customersList: BingCustomer[] = [];
  filteredCustomersList: BingCustomer[] = [];

  accountsList: BingAccount[] = [];
  filteredAccountsList: BingAccount[] = [];

  constructor(
    public projectService: ProjectService,
    private bingConnectionsService: BingConnectionsService,
  ) {}

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit(): void {
    this.form = new UntypedFormGroup({
      connection: new UntypedFormControl(null, [Validators.required]),
      customer: new UntypedFormControl(null, [Validators.required]),
      account: new UntypedFormControl(null, [Validators.required]),
    });
    this.connectionCtrl.setValue(this.connections[0]);
    this.startListening();
  }

  changeConnection(event: MatSelectChange) {
    const connection = event.value as ConnectionModel;
    this.customerCtrl.reset();
    this.customerCtrl.enable();
    this.customersList = [];
    this.accountsList = [];
    this.getCustomersData(connection.id);
  }

  getCustomersData(connectionId: number) {
    this.searching = true;
    this.bingConnectionsService
      .getCustomers(connectionId)
      .pipe(
        takeUntil(this.onDestroy$),
        tap(() => (this.searching = false)),
        tap((customers) => (this.customersList = customers)),
        tap((customers) => (this.filteredCustomersList = customers)),
      )
      .subscribe();
  }

  private startListening() {
    this.customerFilterCtrl.valueChanges.pipe(takeUntil(this.onDestroy$), debounceTime(400)).subscribe(() => this.filterCustomers());
    this.accountFilterCtrl.valueChanges.pipe(takeUntil(this.onDestroy$), debounceTime(400)).subscribe(() => this.filterAccounts());
  }

  private selectFilter(arraytoFilter: any[], formControl: UntypedFormControl, filterFun: (search: string) => (any: any) => boolean) {
    let result;
    if (arraytoFilter) {
      let search = formControl.value;
      if (!search) {
        result = arraytoFilter.slice();
      }
      search = search.toLowerCase();
      result = arraytoFilter.filter(filterFun(search));
    }
    return result;
  }

  private filterCustomers() {
    this.filteredCustomersList = this.selectFilter(this.customersList, this.customerFilterCtrl, this.customersFilterFunction)!;
  }

  private filterAccounts() {
    this.filteredAccountsList = this.selectFilter(this.accountsList, this.accountFilterCtrl, this.customersFilterFunction)!;
  }

  customersFilterFunction = (search: string) => {
    return ({ name, id }: any) =>
      (name && name.toLowerCase().indexOf(search) > -1) || (id && id.toString().toLowerCase().indexOf(search) > -1);
  };

  changeCustomer(event: MatSelectChange) {
    const customer = event.value as BingCustomer;
    this.getAccountsData(this.connectionCtrl.value.id, customer.id);
  }

  getAccountsData(connectionId: number, customerId: number) {
    this.bingConnectionsService
      .getAccounts(connectionId, customerId)
      .pipe(
        takeUntil(this.onDestroy$),
        tap(() => (this.searching = false)),
        tap((accounts) => (this.accountsList = accounts)),
        tap((accounts) => (this.filteredAccountsList = accounts)),
      )
      .subscribe();
  }

  submit() {
    this.isLoading = true;
    const projectId = this.projectService.activeProject$.getValue()!.id;

    this.bingConnectionsService
      .attachConnection(projectId, this.connectionCtrl.value, this.customerCtrl.value, this.accountCtrl.value)
      .pipe(
        tap(() => {
          this.form.reset();
          this.form.markAsUntouched();
          this.isLoading = false;
        }),
        takeUntil(this.onDestroy$),
      )
      .subscribe(
        () => this.connectionAttached.emit(true),
        (er: FormApiValidationError) => {
          er.setOnForm && er.setOnForm(this.form);
          this.connectionAttached.emit(false);
        },
      );
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }
}
