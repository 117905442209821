<table
  mat-table
  matSort
  (matSortChange)="sortData($event)"
  [dataSource]="tableDataSource"
  *ngIf="tableDataSource.length"
  class="mat-elevation-z8"
>
  <ng-container matColumnDef="seller">
    <th mat-sort-header="seller" mat-header-cell class="first-column" *matHeaderCellDef>
      {{ 'projects.monitor.competitors.seller' | translate }}
    </th>
    <td mat-cell class="first-column" *matCellDef="let element">{{ element.seller }}</td>
  </ng-container>

  <ng-container matColumnDef="product_count">
    <th mat-sort-header="product_count" mat-header-cell *matHeaderCellDef>
      {{ 'projects.monitor.competitors.product_count' | translate }}
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element.product_count }}
      <button (click)="openMatchedProductsPopup(element.matched_monitor_products_ids, element.seller)" mat-icon-button>
        <mat-icon>remove_red_eye</mat-icon>
      </button>
    </td>
  </ng-container>

  <ng-container matColumnDef="percentage_avg">
    <th mat-sort-header="percentage_avg" mat-header-cell *matHeaderCellDef>
      {{ 'projects.monitor.competitors.percentage_avg' | translate }}
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element.percentage_avg | abs }}%
      <span [ngStyle]="{ color: element.percentage_avg > 0 ? 'red' : 'green' }">
        {{
          element.percentage_avg > 0
            ? ('projects.monitor.competitors.more_expensive' | translate)
            : ('projects.monitor.competitors.cheaper' | translate)
        }}
      </span>
    </td>
  </ng-container>

  <ng-container matColumnDef="cheaper_products">
    <th mat-sort-header="cheaper_products" mat-header-cell *matHeaderCellDef>
      {{ 'projects.monitor.competitors.cheaper_products' | translate }}
    </th>
    <td mat-cell *matCellDef="let element">{{ element.cheaper_products }}%</td>
  </ng-container>

  <ng-container matColumnDef="more_expensive_products">
    <th mat-sort-header="more_expensive_products" mat-header-cell *matHeaderCellDef>
      {{ 'projects.monitor.competitors.more_expensive_products' | translate }}
    </th>
    <td mat-cell *matCellDef="let element">{{ element.more_expensive_products }}%</td>
  </ng-container>

  <ng-container matColumnDef="same_price_products">
    <th mat-sort-header="same_price_products" mat-header-cell *matHeaderCellDef>
      {{ 'projects.monitor.competitors.same_price_products' | translate }}
    </th>
    <td mat-cell *matCellDef="let element">{{ element.same_price_products }}%</td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
