import { NgModule } from '@angular/core';
import { NotifierModule } from 'angular-notifier';
import { MaterialModule } from 'src/app/shared/material-module/material.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { AsyncTemplateComponent } from './notification-displayer/async-template/async-template.component';
import { DefaultTemplateComponent } from './notification-displayer/default-template/default-template.component';
import { NotificationDisplayerComponent } from './notification-displayer/notification-displayer.component';
import { PromptTemplateComponent } from './notification-displayer/prompt-template/prompt-template.component';

@NgModule({
  declarations: [NotificationDisplayerComponent, DefaultTemplateComponent, PromptTemplateComponent, AsyncTemplateComponent],
  imports: [
    SharedModule,
    MaterialModule,
    NotifierModule.withConfig({
      behaviour: {
        autoHide: false,
      },
      position: {
        horizontal: {
          position: 'right',
          distance: 25,
        },
        vertical: {
          position: 'top',
          distance: 70,
          gap: 10,
        },
      },
      theme: 'material',
    }),
  ],
  exports: [NotificationDisplayerComponent],
})
export class NotificationModule {}
