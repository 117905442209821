import { Injectable } from '@angular/core';
import { PaymentModel } from 'src/app/dashboard/payments/models/payments.model';

@Injectable({
  providedIn: 'root',
})
export class DataLayerService {
  private window;

  constructor() {
    this.window = window;
  }

  private push(obj: any) {
    this.window && (this.window as any).dataLayer && (this.window as any).dataLayer.push(obj);
  }

  startSubscription(payment: PaymentModel) {
    const data = {
      event: 'start_subscription',
      subscribtionName: payment.package_name || null,
      subscribtionPrice: payment.price || null,
      subscribtionCurrency: payment.currency || null,
    };
    this.push(data);
  }
}
