import { Component, OnDestroy, OnInit } from '@angular/core';
import { map } from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { CurrentPackage, PackageItemModel, PaymentDiscountModel } from '../models/payments.model';
import { StripeStatusEnum } from '../models/stripe-status.enum';
import { PaymentsService } from '../payments.service';

@Component({
  selector: 'app-current-package',
  templateUrl: './current-package.component.html',
  styleUrls: ['./current-package.component.scss'],
})
export class CurrentPackageComponent implements OnInit, OnDestroy {
  currentPackage!: CurrentPackage;
  discount!: PaymentDiscountModel;
  isLoading = false;
  items: any;
  mainPackage!: PackageItemModel;
  partItems!: PackageItemModel[];
  stripeStatuses: typeof StripeStatusEnum = StripeStatusEnum;
  summarizedList: any = []; // TODO: type?

  private onDestroy$ = new Subject<void>();

  constructor(public paymentsService: PaymentsService) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.paymentsService
      .getCurrentPackage()
      .pipe(
        takeUntil(this.onDestroy$),
        tap((val) => (this.currentPackage = val)),
        tap((val) => {
          const { restrictions, used } = val;
          const summarizedList = restrictions
            ? map(Object.keys(restrictions), (restrictionKey) => ({
                key: restrictionKey,
                restrictions: restrictions[restrictionKey as keyof typeof restrictions],
                used: used![restrictionKey as keyof typeof used] || 0,
              }))
            : {};

          this.summarizedList = Object.values(summarizedList);
        }),
        tap(() => (this.partItems = this.currentPackage.items.filter((el) => !el.main))),
        tap(() => (this.mainPackage = this.currentPackage.items.find((el) => !!el.main)!)),
      )
      .subscribe(() => (this.isLoading = false));
  }

  refreshCurrentPackage(): void {
    this.ngOnInit();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }
}
