<form [formGroup]="form" id="form">
  <mat-form-field>
    <mat-label>
      {{ 'profile.settings.language.label' | translate }}
    </mat-label>
    <mat-select [formControl]="language" name="language">
      <mat-option *ngFor="let lang of languages" [value]="lang.value">{{ lang.key | translate }}</mat-option>
    </mat-select>
    <app-form-field-error-display matError></app-form-field-error-display>
  </mat-form-field>

  <button (click)="SaveChangeLanguage()" color="primary" mat-raised-button>
    {{ 'general.save' | translate }}
  </button>
</form>
