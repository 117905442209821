import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import isObject from 'lodash/isObject';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss'],
})
export class ConfirmModalComponent {
  params: object | null = null;
  title = '';
  isOK = false;

  constructor(
    public dialogRef: MatDialogRef<ConfirmModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { key: string; params: object; isOK: boolean } | string,
  ) {
    if (isObject(data) && data.key) {
      this.title = data.key;
      data.params && (this.params = data.params);
      data.isOK && (this.isOK = true);
    } else {
      this.title = data.toString();
    }

    this.dialogRef.addPanelClass('confirm-dialog');
  }
}
