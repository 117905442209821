<mat-card appearance="outlined">
  <mat-card-header>
    <mat-card-title>{{ 'welcome_screen.title' | translate }}</mat-card-title>
    <mat-card-subtitle [innerHTML]="'welcome_screen.' + mode + '.description' | translate"></mat-card-subtitle>
  </mat-card-header>

  <mat-card-content>
    <div class="title">{{ 'welcome_screen.what_next' | translate }}</div>

    <div>
      <div class="buttons">
        <ng-container *ngFor="let item of items">
          <button mat-raised-button type="button" (click)="gotTo(item)">
            <div class="name">
              {{ 'welcome_screen.' + mode + '.items.' + item.translateKey | translate }}
            </div>
          </button>
        </ng-container>
      </div>
    </div>
  </mat-card-content>
</mat-card>
