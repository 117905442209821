import { Component, EventEmitter, Output, inject } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { NotificationService } from '../../notification/notification.service';
import { LocalStorageService } from '../../shared/service/local-storage.service';
import { LangEnum, TranslationService } from '../../shared/service/translation.service';

@Component({
  selector: 'app-auth-change-language',
  templateUrl: './auth-change-language.component.html',
})
export class AuthChangeLanguageComponent {
  @Output() closePanel = new EventEmitter<void>();
  protected language: UntypedFormControl;
  protected languages = [
    { value: LangEnum.en, key: 'languages.english' },
    { value: LangEnum.pl, key: 'languages.polish' },
  ];

  private storageHelperService = inject(LocalStorageService);
  private notificationService = inject(NotificationService);
  private translationService = inject(TranslationService);

  constructor() {
    let storageLocale: LangEnum | undefined = this.storageHelperService.get('locale') as LangEnum | undefined;

    if (!storageLocale) {
      const browserLang = navigator.language.split('-')[0];
      const isSupported = Object.values(LangEnum).some((lang) => lang === browserLang);

      storageLocale = isSupported ? (browserLang as LangEnum) : LangEnum.en;
      this.translationService.setCustomLang(storageLocale);
    }
    this.storeAndReload(storageLocale);
    this.language = new UntypedFormControl(storageLocale, Validators.required);
  }

  protected changeLanguage() {
    const lang = this.language.value as LangEnum;

    this.translationService.setCustomLang(lang);
    this.notificationService.success('change_language');
  }

  private storeAndReload(lang: LangEnum) {
    this.translationService.setCustomLang(lang);

    if (lang !== this.translationService.storageLang) {
      window.location.reload();
    }
  }
}
