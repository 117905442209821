import { Component, EventEmitter, OnDestroy, Output, inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NotificationService } from 'src/app/notification/notification.service';
import { SettingsService } from 'src/app/shared/service/settings.service';
import { LangEnum, TranslationService } from 'src/app/shared/service/translation.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormStatusService } from '../../../form-status.service';

@Component({
  selector: 'app-change-language',
  templateUrl: './change-language.component.html',
  styleUrls: ['./change-language.component.scss'],
})
export class ChangeLanguageComponent implements OnDestroy {
  @Output() closePanel = new EventEmitter<void>();
  form: UntypedFormGroup;
  languages = [
    { value: LangEnum.en, key: 'languages.english' },
    { value: LangEnum.pl, key: 'languages.polish' },
  ];

  #destroy$ = new Subject<void>();
  #formBuilder = inject(UntypedFormBuilder);
  #notificationService = inject(NotificationService);
  #settingsService = inject(SettingsService);
  #translationService = inject(TranslationService);
  #formStatusService = inject(FormStatusService);

  constructor() {
    const storageLocale = this.#translationService.storageLang || this.languages[0].value;

    this.form = this.#formBuilder.group({
      language: [storageLocale, [Validators.required]],
    });
    this.form.valueChanges.pipe(takeUntil(this.#destroy$)).subscribe(() => {
      this.#formStatusService.isAnyFormDirty = this.form.dirty;
    });
  }

  get language() {
    return this.form.get('language') as UntypedFormControl;
  }

  ngOnDestroy() {
    this.#destroy$.next();
    this.#destroy$.complete();
  }

  protected SaveChangeLanguage() {
    const lang = this.language.value;

    this.#settingsService.changeLanguage(lang).subscribe(
      () => {
        this.storeAndReload(lang);
        this.#formStatusService.notifySaveChanges();
      },
      () => this.#notificationService.error('change_language'),
    );
  }

  private storeAndReload(lang: LangEnum) {
    this.#translationService.setCustomLang(lang);

    if (lang === this.#translationService.storageLang) {
      this.#notificationService.success('change_language');
    } else {
      // Taki zawór bezpieczeństwa
      window.location.reload();
    }
  }
}
