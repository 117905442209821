import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { NotificationService } from 'src/app/notification/notification.service';

@Component({
  selector: 'app-prompt-template',
  templateUrl: './prompt-template.component.html',
  styleUrls: ['./prompt-template.component.scss'],
})
export class PromptTemplateComponent implements OnInit {
  @Input() id!: string;
  @Input() message!: string;
  @Output() responsed: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private notificationService: NotificationService,
    private angularNotifierService: NotifierService,
  ) {}

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit(): void {}

  private close() {
    this.angularNotifierService.hide(this.id);
  }

  onAgree() {
    this.close();
    this.responsed.emit(true);
  }

  onDisagree() {
    this.close();
    this.responsed.emit(false);
  }
}
