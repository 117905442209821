export const FusionChartsConfig = {
  license: {
    key:
      // eslint-disable-next-line max-len
      'YcC1orxB1D8B1C3G3C1D2E2C2A4A7B6C4xgaC2C5A5qB3D1F3dgrA4B8E2C-11xoG1D2G1lttE6E1C4G2D3B1B9B2B5D6D3kcA-7G2C9HE3sudC1G2B1A-21hD1CA2B4dfwB14B1D8D3D2B1F1A3G1A2A10D2D7E3o==',
    creditLabel: false,
  },
};

export const BaseStyle = {
  text: {
    color: 'black',
    'font-family': 'Poppins',
    'font-size': '14',
  },
};
