/* eslint-disable no-underscore-dangle */
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { Observable, Subject } from 'rxjs';
import { filter, first, takeUntil, tap } from 'rxjs/operators';
import { DisableStateService } from 'src/app/shared/service/disabled-state.service';
import { NotificationThemeType } from '../NotificationConfig';
import { NotificationInformations } from '../NotificationInformations';
import { NotificationService } from '../notification.service';

@Component({
  selector: 'app-notification-displayer',
  templateUrl: './notification-displayer.component.html',
  styleUrls: ['./notification-displayer.component.scss'],
})
export class NotificationDisplayerComponent implements OnInit {
  @ViewChild('promptNotification', { static: true }) promptNotificationRef!: TemplateRef<any>;
  @ViewChild('defaultNotification', { static: true }) defaultNotificationRef!: TemplateRef<any>;
  @ViewChild('asyncNotification', { static: true }) asyncNotificationRef!: TemplateRef<any>;
  private _createdNotificationId = 0;
  private onDestroy$: Subject<void> = new Subject();

  private callbacks: Map<string, Function> = new Map();
  private callbackReturned$: Subject<string> = new Subject();

  asyncsResults: Map<string, Promise<void>> = new Map();

  constructor(
    private angularNotifierService: NotifierService,
    private notificationService: NotificationService,
    private disableStateService: DisableStateService,
  ) {}

  ngOnInit(): void {
    this.notificationService.newNotificationAppeard$
      .pipe(
        takeUntil(this.onDestroy$),
        tap((notification) => this.handleNotification(notification)),
      )
      .subscribe();
  }

  private getNextNotificationId() {
    const id = (this._createdNotificationId + 1).toString();
    this._createdNotificationId++;
    return id;
  }

  private handleNotification(notification: NotificationInformations) {
    if (notification.type === 'message') {
      this.createDefaultNotification(notification.message, notification.themeType!);
    }

    if (notification.type === 'prompt') {
      this.createPromptNotification(notification.message, notification.callback!, notification.close$!);
    }

    if (notification.type === 'async') {
      this.createAsyncNotification(notification.message, notification.close$!);
    }
  }

  private createDefaultNotification(message: string, themeType: NotificationThemeType) {
    this.angularNotifierService.show({
      message,
      type: themeType,
      template: this.defaultNotificationRef,
      id: this.getNextNotificationId(),
    });
  }

  private createPromptNotification(message: string, callback: Function, close$: Observable<void>) {
    const id = this.getNextNotificationId();
    this.parseCallbackFuntion(id, callback);
    this.angularNotifierService.show({
      message,
      type: 'prompt',
      template: this.promptNotificationRef,
      id,
    });

    if (close$) {
      const callbackStream$ = this.callbackReturned$.pipe(filter((notificationId) => notificationId === id));
      close$.pipe(takeUntil(this.onDestroy$), takeUntil(callbackStream$)).subscribe(() => this.angularNotifierService.hide(id));
    }
  }

  private parseCallbackFuntion(id: string, callback: Function) {
    const callbackFuntion = (value: boolean) => {
      this.callbackReturned$.next(id);
      this.disableStateService.enableAppState();
      return callback(value);
    };

    this.callbacks.set(id, callbackFuntion);
  }

  private createAsyncNotification(message: string, close$: Observable<void>) {
    if (!close$) {
      throw Error('Closing stream is not provided in Async Notification!');
    }

    const id = this.getNextNotificationId();
    this.angularNotifierService.show({
      message,
      type: 'prompt',
      template: this.asyncNotificationRef,
      id,
    });

    this.asyncsResults.set(id, close$.pipe(takeUntil(this.onDestroy$), first()).toPromise());
  }

  handlePromptCallback(id: string, response: boolean) {
    const callback = this.callbacks.get(id)!;
    callback(response);
  }
}
