import { DecimalPipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { PaymentDiscountModel } from '../../../models/payments.model';

@Component({
  selector: 'app-discounts-data',
  templateUrl: './discounts-data.component.html',
  providers: [DecimalPipe],
})
export class DiscountsDataComponent {
  @Input() discount!: PaymentDiscountModel;

  get discountValue() {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { amount_off, currency, percent_off } = this.discount;

    if (percent_off) {
      return `${this.decimal(percent_off)}%`;
    } else if (amount_off && currency) {
      return `${this.decimal(amount_off / 100)} ${currency.toUpperCase()}`;
    }

    return null;
  }

  constructor(private decimalPipe: DecimalPipe) {}

  private decimal(num: string | number) {
    return this.decimalPipe.transform(num, '1.2-2');
  }
}
