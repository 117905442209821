import { Pipe, PipeTransform } from '@angular/core';
import { HelperService } from '../service/helper.service';

@Pipe({
  name: 'enumToArray',
})
export class EnumToArrayPipe implements PipeTransform {
  constructor(private helperService: HelperService) {}

  transform(data: {}, parseBy: 'key' | 'value' = 'key', isValueNumeric = false) {
    return this.helperService.enumToArray(data, parseBy, isValueNumeric);
  }
}
