import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import moment from 'moment';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss'],
})
export class DateRangePickerComponent implements OnInit, OnDestroy {
  @Input() initDateEnd!: string;
  @Input() initDateStart!: string;
  @Input() isFutureTime: boolean;
  @Input() isRange: boolean;
  @Input() title!: string;
  readonly daysRange = [1, 7, 30, 90, 365];
  readonly selectedDays = new FormControl<number | null>(null);

  form!: UntypedFormGroup;
  private onDestroy$: Subject<void> = new Subject();
  @Output() dateChanged: EventEmitter<{
    dateEnd: moment.Moment;
    dateStart: moment.Moment;
  }> = new EventEmitter();

  get dateStart() {
    return this.form.get('dateStart') as UntypedFormControl;
  }

  get dateEnd() {
    return this.form.get('dateEnd') as UntypedFormControl;
  }

  constructor(private fb: UntypedFormBuilder) {
    this.isFutureTime = false;
    this.isRange = true;
  }

  ngOnInit() {
    this.form = this.fb.group({
      dateStart: new UntypedFormControl(moment(this.initDateStart) || null, [Validators.required]),
      dateEnd: new UntypedFormControl(moment(this.initDateEnd) || null, []),
    });

    this.isRange ? this.dateEnd.addValidators(Validators.required) : this.dateEnd.clearValidators();
    this.form.valueChanges.pipe(takeUntil(this.onDestroy$), debounceTime(50)).subscribe(() => this.dateChanged.emit(this.form.value));

    if (!this.initDateStart && !this.initDateEnd) {
      this.setDays(7);
    }
  }

  setDays(days: number = 7) {
    const now = moment();

    let startDate = now;
    let endDate = now;

    if (this.isFutureTime) {
      startDate = now;
      endDate = moment().add(days, 'days');
    } else {
      startDate = moment().subtract(days, 'days');
      endDate = now;
    }

    this.dateStart.setValue(startDate);
    this.dateEnd.setValue(endDate);
    this.selectedDays.setValue(days);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
