import { Component, OnInit, inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { delay } from 'rxjs/operators';
import { FormApiValidationError } from 'src/app/shared/model/errors/formApiError.model';
import { AuthService } from 'src/app/shared/service/auth.service';
import { RouterService, routerConfig } from 'src/app/shared/service/router.service';
import { LocalStorageService, storageKey } from '../../shared/service/local-storage.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  protected form!: UntypedFormGroup;
  protected isLoading!: boolean;
  protected isPasswordVisible = false;
  private authService = inject(AuthService);
  private formBuilder = inject(UntypedFormBuilder);
  private routerService = inject(RouterService);
  private storageHelperService = inject(LocalStorageService);

  get password() {
    return this.form.get('password') as UntypedFormControl;
  }

  get email() {
    return this.form.get('email') as UntypedFormControl;
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      email: ['', [Validators.email, Validators.required, Validators.minLength(5)]],
      password: ['', [Validators.required]],
    });
  }

  onSubmit() {
    this.password.updateValueAndValidity();
    this.email.updateValueAndValidity();

    if (this.form.valid) {
      this.isLoading = true;
      this.authService
        .login(this.email.value, this.password.value)
        .pipe(delay(1000))
        .subscribe(
          () => this.redirectToDashboard(),
          (er: FormApiValidationError) => {
            this.isLoading = false;
            er.setOnForm && er.setOnForm(this.form);
          },
        );
    }
  }

  private redirectToDashboard() {
    const { customPageAfterLogin } = this.authService;

    const firstLoginDate = this.storageHelperService.get('firstLogin');
    if (!firstLoginDate) {
      this.storageHelperService.save(storageKey.firstLogin, new Date().toISOString());
    }

    customPageAfterLogin ? (window.location.href = customPageAfterLogin) : this.routerService.navigate(routerConfig.home);
  }
}
