import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import ApiUrls from '../../configs/api-urls.config';
import { GoogleCategoryInterface } from '../model/category.model';

@Injectable({
  providedIn: 'root',
})
export class GoogleCategoryService {
  constructor(private http: HttpClient) {}

  findByName(name: string): Observable<GoogleCategoryInterface[]> {
    let params = new HttpParams();
    if (name) {
      params = params.set('name', name);
    }
    return this.http.get<GoogleCategoryInterface[]>(ApiUrls.merchantCategories, { params });
  }

  pluckIdFromCategory(category: GoogleCategoryInterface | null): string | null {
    return category ? category.id : null;
  }
}
