/* eslint-disable no-underscore-dangle */
import { TutorialService } from '../../tutorial.service';
import { InnerAction } from '../actions/InnerAction';

export enum GuideStepTypes {
  default,
  done,
}

export class GuideStep {
  title: string;
  destription: string;
  innerActions: InnerAction[];
  activeActionIndex = 0;
  activeAction!: InnerAction;
  isDone = false;
  protected _type: GuideStepTypes;

  get type() {
    return this._type;
  }

  constructor(title: string, destription: string, innerActions: InnerAction[] = []) {
    this.title = title;
    this.destription = destription;
    this.innerActions = innerActions;
    this._type = GuideStepTypes.default;
  }

  initLogic(tutorialService: TutorialService) {
    if (this.innerActions.length === 0) {
      return;
    }

    this.innerActions[this.activeActionIndex].activate(tutorialService);
    this.activeAction = this.innerActions[this.activeActionIndex];
  }

  checkIfCanActivateNextAction() {
    return this.innerActions[this.activeActionIndex].checkIfIsCompleted();
  }

  activateNext(tutorialService: TutorialService) {
    if (this.activeActionIndex === this.innerActions.length - 1) {
      this.innerActions[this.activeActionIndex].deactivate();
      this.isDone = true;
    } else {
      this.innerActions[this.activeActionIndex].deactivate();
      this.innerActions[this.activeActionIndex + 1].activate(tutorialService);
      this.activeActionIndex += 1;
      this.activeAction = this.innerActions[this.activeActionIndex];
    }
  }

  markAsDone() {
    this.innerActions.forEach((action) => action.markAsDone());
    this.isDone = true;
  }
}
