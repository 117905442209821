<form [formGroup]="form">
  <!-- Connections -->
  <mat-form-field>
    <mat-label>
      {{ 'projects.connections.form.fields.connection.label' | translate }}
    </mat-label>
    <mat-select [formControl]="connection">
      <mat-option *ngFor="let connection of connections" [value]="connection" name="connection">
        {{ connection | connectionLabel }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</form>
