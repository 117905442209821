<app-dialog [dialogRef]="dialogRef">{{ 'payments.invoices.pass_data' | translate }}</app-dialog>

<ng-container>
  <mat-dialog-content>
    <app-invoice-data-form></app-invoice-data-form>
  </mat-dialog-content>
  <mat-dialog-actions>
    <app-button (click)="submit()" [text]="'payments.invoices.save' | translate" [loading]="isLoading" color="primary"> </app-button>
  </mat-dialog-actions>
</ng-container>
