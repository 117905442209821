<div class="container">
  <span class="title-row">
    <span class="subtitle">
      {{ 'payments.current_package.additional_packages' | translate }}
    </span>
    <button mat-raised-button *ngIf="items.length > 0" color="primary" appRouter="paymentsAddProducts">
      {{ 'payments.current_package.browse_add_packages' | translate }}
    </button>
  </span>

  <div *ngIf="items.length > 0; else noElementsToDisplay" class="element-container">
    <div *ngFor="let element of items; index as id" class="element">
      <div class="row margin">
        <span *ngFor="let name of element.restrictionNames" class="title">{{
          'payments.current_package.' + name | translate | titlecase
        }}</span>
        <button mat-raised-button color="warn" class="remove-btn" (click)="openRemove(element)">Reduce</button>
      </div>

      <span *ngFor="let name of element.restrictionNames" class="description">{{ element.description }}</span>

      <div class="row">
        <span class="label"> {{ 'payments.current_package.count' | translate }} </span><span class="value">{{ element.count }}</span>
      </div>

      <div class="row">
        <span class="label">
          {{ 'payments.current_package.price' | translate }}
        </span>
        <span class="value"
          >{{ (element.count * element.price) / 100 + element.currency | uppercase }} ({{ element.count }} *
          {{ element.price / 100 + element.currency | uppercase }})</span
        >
      </div>

      <div class="row">
        <span class="label"> {{ 'payments.current_package.restrictions' | translate }} </span
        ><span class="value">{{ element | displayRestriction }}</span>
      </div>
    </div>
  </div>
</div>

<ng-template #noElementsToDisplay>
  <div class="browse-btn-wrapper additionalItems">
    <span>
      {{ 'payments.current_package.no_add_packages' | translate }}
    </span>
    <button mat-raised-button color="primary" appRouter="paymentsAddProducts">
      {{ 'payments.current_package.browse_add_packages' | translate }}
    </button>
  </div>
</ng-template>
