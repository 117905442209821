import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, startWith, takeUntil, tap } from 'rxjs/operators';
import { HelperService } from '../../service/helper.service';

export interface ValueChooserEmitted {
  fromItem: boolean;
  itemPropertyName?: string;
  value: string;
}

export interface ValueChooserOption {
  alias: string;
  group?: string;
  value: string;
}

@Component({
  selector: 'app-value-chooser',
  templateUrl: './value-chooser.component.html',
  styleUrls: ['./value-chooser.component.scss'],
})
export class ValueChooserComponent implements OnInit, OnDestroy {
  @Input() options!: ValueChooserOption[];
  @Output() valueEmitted: EventEmitter<ValueChooserEmitted> = new EventEmitter();
  propertiesToChoose!: ValueChooserOption[];
  propertySearchCtrl = new UntypedFormControl();
  propertyCtrl = new UntypedFormControl();
  private onDestroy$: Subject<void> = new Subject();
  @ViewChild('input') input!: ElementRef;

  constructor(
    private helperService: HelperService,
    private cd: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.propertySearchCtrl.valueChanges
      .pipe(
        takeUntil(this.onDestroy$),
        debounceTime(400),
        startWith(''),
        tap((query) => (this.propertiesToChoose = this.findColumn(query))),
      )
      .subscribe(() => this.cd.detectChanges());

    this.propertyCtrl.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe((property) => this.chooseColumn(property));
  }

  chooseValue(value: string) {
    this.valueEmitted.emit({ fromItem: false, value });
    this.input.nativeElement.value = '';
  }

  private findColumn(query: string | null = null): ValueChooserOption[] {
    return this.helperService.search(query!, this.options, 'alias');
  }

  private chooseColumn(option: ValueChooserOption) {
    this.valueEmitted.emit({ fromItem: true, itemPropertyName: option.value, value: option.value });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
