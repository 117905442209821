import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { CurrencyEnum } from 'src/app/dashboard/payments/models/payments.model';

const CURRIENCES = [
  { value: CurrencyEnum.pln, label: 'PLN' },
  { value: CurrencyEnum.usd, label: 'USD' },
  { value: CurrencyEnum.gbp, label: 'GBP' },
  { value: CurrencyEnum.eur, label: 'EUR' },
];

@Component({
  selector: 'app-currency-chooser',
  templateUrl: './currency-chooser.component.html',
  styleUrls: ['./currency-chooser.component.scss'],
})
export class CurrencyChooserComponent implements OnInit {
  @Input() currency!: CurrencyEnum;
  @Output() currencyChange = new EventEmitter();
  @Input() appearance: MatFormFieldAppearance = 'outline';

  curriences = CURRIENCES;
  currenCurrency!: string;

  ngOnInit(): void {
    this.currenCurrency = this.currency;
  }

  select() {
    this.currencyChange.emit(this.currenCurrency);
  }
}
