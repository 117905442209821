/* eslint-disable max-len */
import { Renderer2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GuidesAvailable } from '../GuidesAvailable.enum';
import { ClickInnerAction } from '../models/actions/ClickInnerAction';
import { Guide } from '../models/Guide';
import { GuideStep } from '../models/steps/GuideStep';
import { GuideStepDone } from '../models/steps/GuideStepDone';

export function createConnectionsTutorial(renderer: Renderer2, translateService: TranslateService): Guide {
  // STEP 1
  const clickActionStep1 = new ClickInnerAction(
    translateService.instant('tutorials.connections.step_1_action_1'),
    renderer,
    'tutorial-project-topbar-btn',
  );
  const clickActionStep2a = new ClickInnerAction(
    translateService.instant('tutorials.connections.step_1_action_2'),
    renderer,
    'tutorial-projects-list',
  );
  const clickActionStep2 = new ClickInnerAction(
    translateService.instant('tutorials.connections.step_1_action_3'),
    renderer,
    'tutorial-sidemenu-connections',
  );
  const clickActionStep3 = new ClickInnerAction(
    translateService.instant('tutorials.connections.step_1_action_4'),
    renderer,
    'tutorial-connection-providers',
  );
  const clickActionStep3b = new ClickInnerAction(
    translateService.instant('tutorials.connections.step_1_action_5'),
    renderer,
    'tutorial-attach-connection',
  );

  const step1: GuideStep = new GuideStep(
    translateService.instant('tutorials.connections.step_1_title'),
    translateService.instant('tutorials.connections.step_1_description'),
    [clickActionStep1, clickActionStep2a, clickActionStep2, clickActionStep3, clickActionStep3b],
  );

  // STEP 2
  const clickActionStep4a = new ClickInnerAction(
    translateService.instant('tutorials.connections.step_2_action_1'),
    renderer,
    'tutorial-new-connection-provider',
  );
  const clickActionStep4b = new ClickInnerAction(
    translateService.instant('tutorials.connections.step_2_action_2'),
    renderer,
    'tutorial-new-connection-service-next',
  );

  const clickActionStep4c = new ClickInnerAction(
    translateService.instant('tutorials.connections.step_2_action_3'),
    renderer,
    'tutorial-new-connection-connect-btn',
  );

  const step2: GuideStep = new GuideStep(
    translateService.instant('tutorials.connections.step_2_title'),
    translateService.instant('tutorials.connections.step_2_description'),
    [clickActionStep4a, clickActionStep4b, clickActionStep4c],
  );

  // STEP 3
  const clickActionStep6 = new ClickInnerAction(
    translateService.instant('tutorials.connections.step_3_action_1'),
    renderer,
    'tutorial-connection-providers',
  );
  const clickActionStep6b = new ClickInnerAction(
    translateService.instant('tutorials.connections.step_3_action_2'),
    renderer,
    'tutorial-attach-connection-submit',
  );
  const step3: GuideStep = new GuideStep(
    translateService.instant('tutorials.connections.step_3_title'),
    translateService.instant('tutorials.connections.step_3_description'),
    [clickActionStep6, clickActionStep6b],
  );

  const guide: Guide = new Guide(GuidesAvailable.addingCreatingConnection, translateService.instant('tutorials.connections.guide_desc'), [
    step1,
    step2,
    step3,
  ]);

  const doneStep = new GuideStepDone(
    translateService.instant('tutorials.connections.step_done_title'),
    translateService.instant('tutorials.connections.step_done_description'),
  );
  guide.guideSteps.push(doneStep);
  return guide;
}
