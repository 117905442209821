import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AbsPipe } from './abs.pipe';
import { ConnectionServicePipe } from './connection-service.pipe';
import { ConnectionPipe } from './connection.pipe';
import { DateFormatPipe } from './date-format.pipe';
import { EnumToArrayPipe } from './enum-to-array.pipe';
import { MapColumnValuePipe } from './map-column-value.pipe';
import { NumberZeroStartPipe } from './number-zero-start.pipe';
import { ReplacePipe } from './replace.pipe';
import { SortByPipe } from './sort-by.pipe';
import { SwitchMultiCasePipe } from './switch-multi-case.pipe';
import { TimeAgoPipe } from './time-ago.pipe';
import { TruncatePipe } from './truncate.pipe';
import { RemoveCharacterPipe } from './remove-character.pipe';
import { GenerateImagePatchPipe } from './generate-image-patch.pipe';

@NgModule({
  declarations: [
    AbsPipe,
    ConnectionPipe,
    ConnectionServicePipe,
    DateFormatPipe,
    EnumToArrayPipe,
    EnumToArrayPipe,
    MapColumnValuePipe,
    NumberZeroStartPipe,
    ReplacePipe,
    SortByPipe,
    SwitchMultiCasePipe,
    TimeAgoPipe,
    TruncatePipe,
    RemoveCharacterPipe,
    GenerateImagePatchPipe,
  ],
  imports: [CommonModule],
  exports: [
    AbsPipe,
    ConnectionPipe,
    ConnectionServicePipe,
    DateFormatPipe,
    EnumToArrayPipe,
    EnumToArrayPipe,
    MapColumnValuePipe,
    NumberZeroStartPipe,
    ReplacePipe,
    SortByPipe,
    SwitchMultiCasePipe,
    TimeAgoPipe,
    TruncatePipe,
    RemoveCharacterPipe,
    GenerateImagePatchPipe,
  ],
})
export class PipesModule {}
