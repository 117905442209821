<app-dialog [dialogRef]="dialogRef">{{ 'bing.create_account_form.header' | translate }}</app-dialog>

<ng-container>
  <ng-container *ngIf="isSent; else form">
    <app-info-box type="primary">
      <div>
        {{ 'bing.create_account_form.already_sent' | translate }}
      </div>
    </app-info-box>
  </ng-container>
</ng-container>

<ng-template #form>
  <app-bing-account-form (saved)="close()"></app-bing-account-form>
</ng-template>
