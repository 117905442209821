import { Component, inject } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslateModule } from '@ngx-translate/core';
import moment, { Moment } from 'moment';
import { NotificationService } from 'src/app/notification/notification.service';
import { SettingsService } from 'src/app/shared/service/settings.service';

const format = 'YYYY-MM-DD';

@Component({
  selector: 'app-set-holiday',
  standalone: true,
  imports: [MatFormFieldModule, MatDatepickerModule, MatButtonModule, ReactiveFormsModule, FormsModule, TranslateModule],
  templateUrl: './set-holiday.component.html',
})
export class SetHolidayComponent {
  private readonly settingsService = inject(SettingsService);
  private readonly notificationService = inject(NotificationService);

  readonly form = new FormGroup({
    from: new FormControl<Moment | null>(
      this.settingsService.settings.tableStorages?.holiday_from ? moment(this.settingsService.settings.tableStorages?.holiday_from) : null,
      [Validators.required],
    ),
    to: new FormControl<Moment | null>(
      this.settingsService.settings.tableStorages?.holiday_to ? moment(this.settingsService.settings.tableStorages?.holiday_to) : null,
      [Validators.required],
    ),
  });

  setHoliday() {
    const dateRange = this.form.getRawValue();
    if (!dateRange.from || !dateRange.to) {
      return;
    }

    const notificationKey = 'save_holiday';
    this.settingsService.setHoliday({ holidayFrom: dateRange.from.format(format), holidayTo: dateRange.to.format(format) }).subscribe(
      () => this.notificationService.success(notificationKey),
      () => this.notificationService.error(notificationKey),
    );
  }
}
