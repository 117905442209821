import { ItemModel } from '../../item.model';
import { ActionTypesEnum } from '../ActionTypesEnum';
import { ActionModel } from './ActionModel';

export class AddEndAction extends ActionModel {
  type = ActionTypesEnum.addEnd;
  value!: string;

  isValid(): boolean {
    return !!this.columnName && !!this.value;
  }

  use(item: ItemModel) {
    return item[this.columnName as keyof typeof item]
      ? item[this.columnName as keyof typeof item] + this.value
      : item[this.columnName as keyof typeof item];
  }

  returnForSend() {
    return {
      action: this.type,
      param: this.columnName,
      value: this.value,
      sequence: this.sequence,
    };
  }

  copyValue(item: this, overrideManualChanges: boolean): void {
    this.columnName = item.columnName;
    this.override_manual_changes = overrideManualChanges;
    this.value = item.value;
  }
}
