/* eslint-disable max-len */
import { Renderer2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GuidesAvailable } from '../GuidesAvailable.enum';
import { Guide } from '../models/Guide';
import { GuideStep } from '../models/steps/GuideStep';
import { ClickInnerAction } from '../models/actions/ClickInnerAction';
import { GuideStepDone } from '../models/steps/GuideStepDone';

export function createAddProjectTutorial(renderer: Renderer2, translateService: TranslateService): Guide {
  const clickActionStep1 = new ClickInnerAction(
    translateService.instant('tutorials.add_project.step_1_action_1'),
    renderer,
    'plus-tutorial',
  );
  const clickActionStep2 = new ClickInnerAction(
    translateService.instant('tutorials.add_project.step_1_action_2'),
    renderer,
    'plus-add-project-tutorial',
  );
  const step1: GuideStep = new GuideStep(
    translateService.instant('tutorials.add_project.step_1_title'),
    translateService.instant('tutorials.add_project.step_1_description'),
    [clickActionStep1, clickActionStep2],
  );

  const clickActionStep3 = new ClickInnerAction(
    translateService.instant('tutorials.add_project.step_2_action_1'),
    renderer,
    'add-project-name-tutorial',
  );
  const clickActionStep4 = new ClickInnerAction(
    translateService.instant('tutorials.add_project.step_2_action_2'),
    renderer,
    'add-project-domain-tutorial',
  );
  const clickActionStep5 = new ClickInnerAction(
    translateService.instant('tutorials.add_project.step_2_action_3'),
    renderer,
    'add-project-default-comparator-tutorial',
  );

  const clickActionStep6 = new ClickInnerAction(
    translateService.instant('tutorials.add_project.step_2_action_4'),
    renderer,
    'add-project-submit-form-tutorial',
  );

  const step2: GuideStep = new GuideStep(
    translateService.instant('tutorials.add_project.step_2_title'),
    translateService.instant('tutorials.add_project.step_1_description'),
    [clickActionStep3, clickActionStep4, clickActionStep5, clickActionStep6],
  );

  const guide: Guide = new Guide(GuidesAvailable.addProject, translateService.instant('tutorials.add_project.guide_desc'), [step1, step2]);

  const doneStep = new GuideStepDone(
    translateService.instant('tutorials.add_project.step_done_title'),
    translateService.instant('tutorials.add_project.step_done_description'),
  );
  guide.guideSteps.push(doneStep);
  return guide;
}
