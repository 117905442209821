<table
  mat-table
  matSort
  (matSortChange)="sortData($event)"
  [dataSource]="tableDataSource"
  *ngIf="tableDataSource.length"
  class="mat-elevation-z8"
>
  <span *ngFor="let column of displayedColumns; first as isFirst">
    <ng-container matColumnDef="{{ column }}">
      <th mat-sort-header="{{ column }}" mat-header-cell *matHeaderCellDef>
        {{ 'projects.monitor.competitors.' + column | translate }}
      </th>
      <td mat-cell [class.first-column]="isFirst" [class.column]="!isFirst" *matCellDef="let element">{{ element[column] }}</td>
    </ng-container>
  </span>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
