import { AbstractControl, ValidatorFn } from '@angular/forms';

export class RangeValidation {
  static range(range: { min?: number; max?: number }): ValidatorFn {
    return (c: AbstractControl): { [key: string]: boolean } | null => {
      const minSet: boolean = range.min === 0 || !!range.min;
      const maxSet: boolean = range.max === 0 || !!range.max;

      if (c.value && (Number.isNaN(c.value) || (minSet && c.value < range.min!) || (maxSet && c.value > range.max!))) {
        return { range: true };
      }
      return null;
    };
  }
}
