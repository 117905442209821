import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberZeroStart',
})
export class NumberZeroStartPipe implements PipeTransform {
  transform(value: number): string {
    return value > 9 ? `${value}` : `0${value}`;
  }
}
