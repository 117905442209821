import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { NotificationService } from 'src/app/notification/notification.service';
import { DataLayerService } from 'src/app/shared/service/data-layer.service';
import { CurrentPackage, PaymentModel } from '../models/payments.model';
import { PaymentsService } from '../payments.service';

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss'],
})
export class PaymentsComponent implements OnInit, OnDestroy {
  paymentElements: string[] = [];
  availablePackages: PaymentModel[] = [];
  currentPackage!: CurrentPackage;
  buyingInProgress = false;
  private onDestroy$: Subject<void> = new Subject();

  constructor(
    private dataLayerService: DataLayerService,
    private paymentsService: PaymentsService,
    private notificationService: NotificationService,
  ) {}

  ngOnInit(): void {
    this.paymentsService.currentPackage$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((currentPackage) => (this.currentPackage = currentPackage!));

    this.paymentsService
      .getAvailablePackages()
      .pipe(
        tap((packages) => (this.availablePackages = packages)),
        map((packages) => [...Object.keys(packages[packages.length - 1].elements)]),
        tap((elementsToDisplay) => (this.paymentElements = elementsToDisplay)),
      )
      .subscribe();
  }

  changePackage(packageChosen: PaymentModel) {
    this.buyingInProgress = true;
    this.paymentsService
      .changePackage(packageChosen.id)
      .pipe(switchMap(() => this.paymentsService.getCurrentPackage()))
      .subscribe(
        () => {
          this.notificationService.success('package_changed');
          this.dataLayerService.startSubscription(packageChosen);
          this.buyingInProgress = false;
        },
        () => {
          this.notificationService.error('package_changed');
          this.buyingInProgress = false;
        },
      );
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }
}
