<app-dialog [dialogRef]="dialogRef"
  ><span *ngIf="data.titleKey">{{ data.titleKey | translate }}</span></app-dialog
>

<ng-container>
  <mat-dialog-content *ngIf="data.descriptionKey" [innerHtml]="data.descriptionKey | translate"> </mat-dialog-content>

  <mat-dialog-actions>
    <app-button
      *ngFor="let button of data.buttons"
      (click)="onClick(button.route || null)"
      [text]="button.key | translate"
      [color]="button.color || 'primary'"
    ></app-button>
  </mat-dialog-actions>
</ng-container>
