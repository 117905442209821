import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BrandEnum } from '../../enums/brand.enum';

@Component({
  selector: 'app-button-brand',
  templateUrl: './button-brand.component.html',
  styleUrls: ['./button-brand.component.scss'],
})
export class ButtonBrandComponent implements OnInit {
  @Input() brand!: BrandEnum;
  @Input() isDisabled: boolean = false;
  @Input() text!: string;

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    if (this.brand && !this.text) {
      this.text = this.translateService.instant(`brand.${this.brand}`);
    }
  }

  // #TODO: #TMP?
  getImageSrc() {
    return `/assets/images/brands/buttons/${this.brand}.png`;
  }
}
