import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { AuditModel } from 'src/app/dashboard/project/audit/audit.model';
import { AuditService } from 'src/app/dashboard/project/audit/audit.service';
import { CompetitorsSource } from 'src/app/dashboard/project/monitor/monitor.enum';
import { ProjectService } from 'src/app/dashboard/project/project.service';
import { MonitorProductTaskModel } from 'src/app/shared/model/monitor.model';
import { PaginationInterface } from 'src/app/shared/model/response.model';
import { MonitorService } from 'src/app/shared/service/monitor.service';
import { monitorMatchedProducts as tasksJson } from 'src/app/shared/tableConfigurations/monitorMatchedProducts';
import { TableChangeDataEmittedInterface } from '../../../../shared/sem-table/models/TableChangeDataEmitted.model';
import { TableConfigurationInterface } from '../../../../shared/sem-table/models/TableConfigurationInterface.model';
import { AgregationDataModel } from '../../../../shared/sem-table/models/agregation.model';
import { SemTableComponent } from '../../../../shared/sem-table/sem-table/sem-table.component';

@Component({
  selector: 'app-matched-popup',
  templateUrl: './matched-popup.component.html',
  styleUrls: ['./matched-popup.component.scss'],
})
export class MatchedPopupComponent implements OnInit, OnDestroy {
  @ViewChild('mainTable') mainTable!: SemTableComponent;
  optionsForTable!: TableConfigurationInterface;
  lastData!: TableChangeDataEmittedInterface;
  agregationData: AgregationDataModel = {};
  tasks!: PaginationInterface<MonitorProductTaskModel>;
  private onDestroy$ = new Subject<void>();

  constructor(
    public dialogRef: MatDialogRef<MatchedPopupComponent>,
    @Inject(MAT_DIALOG_DATA)
    public productsData: {
      monitor_products_ids: Array<Number>;
      seller: string;
      source: CompetitorsSource;
      audit?: AuditModel;
      publicView?: boolean;
    },
    private monitorService: MonitorService,
    public projectService: ProjectService,
    private auditService: AuditService,
  ) {}

  ngOnInit(): void {
    this.optionsForTable = tasksJson;
  }

  getMatchedProducts(data: TableChangeDataEmittedInterface = this.lastData) {
    if (this.mainTable) {
      this.mainTable.enableLoadingState();
    }
    if (this.productsData.publicView) {
      this.auditService
        .getAuditMatchedProductsByUuid(
          this.productsData.audit!.project_id!,
          this.productsData.audit!.uuid!,
          this.productsData.seller,
          this.productsData.monitor_products_ids,
          this.productsData.source,
          data.itemsPerPage,
          data.currentPage,
          data.sorting.columnName!,
          data.sorting.direction!,
          data.filterGroups,
          null!,
          null!,
          this.productsData.audit!.id,
        )
        .subscribe(
          (tasks) => {
            if (tasks && tasks.data) {
              this.tasks = tasks;
            }
          },
          () => {},
          () => this.mainTable.disableLoadingState(),
        );
    } else {
      let auditId = null;
      if (this.productsData.audit) {
        auditId = this.productsData.audit.id;
      }
      this.monitorService
        .getMatchedProducts(
          this.projectService.activeProject$.getValue()!.id,
          this.productsData.seller,
          this.productsData.monitor_products_ids,
          this.productsData.source,
          data.itemsPerPage,
          data.currentPage,
          data.sorting.columnName!,
          data.sorting.direction!,
          data.filterGroups,
          null!,
          null!,
          auditId!,
        )
        .subscribe(
          (tasks) => {
            if (tasks && tasks.data) {
              this.tasks = tasks;
            }
          },
          () => {},
          () => this.mainTable.disableLoadingState(),
        );
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }
}
