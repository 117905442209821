import columns from './columns/matchedProducts';
import { TableConfigurationInterface } from '../sem-table/models/TableConfigurationInterface.model';

export const monitorMatchedProducts: TableConfigurationInterface = {
  configuration: {
    storageName: 'monitorMatchedProducts',
  },

  displayedColumns: ['title', 'price_diff', 'my_price', 'seller_price', 'product_gtin', 'google_product_id'],
  tools: {
    quickFilters: ['title'],
    column: true,
    filter: true,
    filterAdvancedGroup: true,
    filterAdvancedLogic: true,
  },
  groupedBy: [],
  columnGroups: [],
  columns,
};
