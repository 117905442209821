<mat-card appearance="outlined">
  <mat-card-header>
    <mat-card-title>
      {{ 'payments.buy.confirm_title' | translate }}
    </mat-card-title>
  </mat-card-header>
  <mat-card-content [ngSwitch]="true" *ngIf="paymentData; else loader">
    <div *ngSwitchCase="paymentData.isCancelled || paymentData.isSucceeded">
      <span>{{ paymentData.isCancelled ? ('payments.buy.canceled' | translate) : ('payments.buy.success' | translate) }}</span>
      <button mat-raised-button color="primary" appRouter="payments">
        {{ 'payments.buy.go_payments' | translate }}
      </button>
    </div>

    <div *ngSwitchCase="paymentData.requiresAction || paymentData.requiresConfirmation">
      <span>
        {{ 'payments.buy.confirm_content' | translate }}
      </span>
      <app-button (click)="onConfirm()" [loading]="isLoading" [text]="'payments.buy.confirm' | translate" color="primary"></app-button>
    </div>

    <div *ngSwitchCase="paymentData.requiresPaymentMethod">
      <app-card-attach-form
        [invoiceAvailable]="true"
        (cardSetupCreated)="onConfirm()"
        [invoiceAvailable]="false"
        [submitTranslationText]="'payments.buy.confirm'"
      ></app-card-attach-form>
    </div>
  </mat-card-content>
</mat-card>

<ng-template #loader>
  <mat-card-content>
    <div>
      <mat-spinner mode="indeterminate" [diameter]="40"></mat-spinner>
    </div>
  </mat-card-content>
</ng-template>
