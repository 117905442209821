/*
 *ngFor="let c of oneDimArray | sortBy:'asc'"
 *ngFor="let c of arrayOfObjects | sortBy:'asc':'propertyName'"
 */
import { Pipe, PipeTransform } from '@angular/core';
import { orderBy } from 'lodash';

@Pipe({ name: 'sortBy' })
export class SortByPipe implements PipeTransform {
  transform(arr: any[], column: string = '', order: boolean | 'asc' | 'desc' = 'asc'): any[] {
    if (!arr || !Array.isArray(arr) || arr.length <= 1) {
      // No array or array with only one item
      return arr;
    }

    if (!column || column === '') {
      // Sort 1d array
      return order === 'asc' ? arr.sort() : arr.sort().reverse();
    }

    return orderBy(arr, [(item) => item[column].toLowerCase()], [order]);
  }
}
