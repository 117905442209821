export const customRangeSelector = {
  style: {
    'title-text': { fill: '#f5c313' },
    'title-icon': { fill: '#f5c313' },
    'title-text:hover': { fill: '#e7b70e' },
    'title-icon:hover': { fill: '#e7b70e' },
    'cal-month': {},
    'cal-month:hover': {},
    'cal-header': { 'background-color': '#f5c313', color: 'black' },
    'cal-header:hover': { 'background-color': '#e7b70e' },
    'cal-subheader': {},
    'cal-subheader:hover': {},
    'cal-body': {},
    'cal-body:hover': {},
    'cal-monthname': {},
    'cal-monthname:hover': {},
    'cal-navprev': {},
    'cal-navprev:hover': {},
    'cal-navnext': {},
    'cal-navnext:hover': {},
    'cal-weekend': {},
    'cal-weekend:hover': {},
    'cal-days': {},
    'cal-days:hover': {},
    'cal-date': {},
    'cal-date:hover': {},
    'cal-activedate': {},
    'cal-activedate:hover': {},
    'cal-selecteddate': { 'background-color': '#f5c313' },
    'cal-selecteddate:hover': { 'background-color': '#e7b70e' },
    'cal-disableddate': {},
    'cal-disableddate:hover': {},
    'title-text:active': {},
    'title-icon:active': {},
    container: { 'border-radius': '4px' },
    label: {},
    'button-apply': { 'background-color': '#f5c313', border: '1px solid #e7b70e', 'border-radius': '4px' },
    'button-cancel': { 'background-color': '#f5c313', border: '1px solid #e7b70e', 'border-radius': '4px' },
    'button-apply:hover': { 'background-color': '#e7b70e' },
    'button-cancel:hover': { 'background-color': '#e7b70e' },
    select: {},
    input: {},
  },
};

export const standardRangeSelector = {
  style: {
    'button-text': {},
    'button-background': {},
    'button-text:hover': { fill: '#e7b70e' },
    'button-background:hover': {},
    'button-text:active': { fill: '#f5c313' },
    'button-background:active': {},
    separator: {},
  },
};
