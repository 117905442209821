import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { ProjectService } from 'src/app/dashboard/project/project.service';
import { TutorialService } from 'src/app/dashboard/tutorial/tutorial.service';
import { ConnectionsServiceEnum } from 'src/app/shared/enums/connections-service.enum';
import { ConnectionsService } from 'src/app/shared/service/connections.service';
import { ConnectionModel } from '../../../../../shared/model/connections.model';
import { FormApiValidationError } from '../../../../../shared/model/errors/formApiError.model';
import { AnalyticsAccount, AnalyticsProfile, AnalyticsProperty } from '../../connections.model';

@Component({
  selector: 'app-analytics-service',
  templateUrl: './analytics-service.component.html',
})
export class AnalyticsServiceComponent implements OnInit, OnDestroy {
  @Input() connections: ConnectionModel[] = [];
  @Output() connectionAttached = new EventEmitter<boolean>();

  accountsList: AnalyticsAccount[] = [];
  form!: UntypedFormGroup;
  isLoading = false;
  profilesList: AnalyticsProfile[] = [];
  propertiesList: AnalyticsProperty[] = [];
  service = ConnectionsServiceEnum.Google;

  private onDestroy$ = new Subject<void>();

  get connection(): UntypedFormControl {
    return this.form.get('connection') as UntypedFormControl;
  }

  get account(): UntypedFormControl {
    return this.form.get('account') as UntypedFormControl;
  }

  get property(): UntypedFormControl {
    return this.form.get('property') as UntypedFormControl;
  }

  get profile(): UntypedFormControl {
    return this.form.get('profile') as UntypedFormControl;
  }

  constructor(
    private fb: UntypedFormBuilder,
    private connectionsService: ConnectionsService,
    public projectService: ProjectService,
    public tutorialService: TutorialService,
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      connection: [null, Validators.required],
      account: [null, Validators.required],
      property: [null, Validators.required],
      profile: [null, Validators.required],
    });
    this.connection.setValue(this.connections[0]);
  }

  changeConnection() {
    const { id } = this.connection.value;
    id &&
      this.connectionsService.getAnalyticsAccounts(id).subscribe(
        (res) => (this.accountsList = res.data),
        () => this.account.setValue(null),
      );
  }

  changeAccount(data: MatSelectChange) {
    this.connectionsService
      .getAnalyticsProperties(this.connection.value.id, data.value.id)
      .subscribe((res) => (this.propertiesList = res.data));
  }

  changeProperty(data: MatSelectChange) {
    this.connectionsService
      .getAnalyticsProfiles(this.connection.value.id, this.account.value.id, data.value.id)
      .subscribe((res) => (this.profilesList = res.data));
  }

  submit() {
    this.isLoading = true;
    this.connectionsService
      .attachAnalyticsToProject(
        this.projectService.activeProject$.getValue()!.id,
        this.connection.value.id,
        this.account.value.id,
        this.property.value.id,
        this.profile.value.id,
      )
      .pipe(
        tap(() => {
          this.form.reset();
          this.form.markAsUntouched();
          this.isLoading = false;
        }),
        takeUntil(this.onDestroy$),
      )
      .subscribe(
        () => this.connectionAttached.emit(true),
        (er: FormApiValidationError) => {
          er.setOnForm && er.setOnForm(this.form);
          this.connectionAttached.emit(false);
        },
      );
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }
}
