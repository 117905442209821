import { NgModule } from '@angular/core';
import { MaterialModule } from 'src/app/shared/material-module/material.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { ChangeLanguageComponent } from './options/change-language/change-language.component';
import { ChangePasswordComponent } from './options/change-password/change-password.component';
import { ChangeUsernameComponent } from './options/change-username/change-username.component';
import { SetHolidayComponent } from './options/set-holiday/set-holiday.component';
import { SettingsComponent } from './settings.component';

@NgModule({
  declarations: [SettingsComponent, ChangeUsernameComponent, ChangeLanguageComponent, ChangePasswordComponent],
  imports: [SharedModule, MaterialModule, SetHolidayComponent],
})
export class SettingsModule {}
