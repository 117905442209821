/* eslint-disable no-underscore-dangle */
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { ItemModel } from '../../item.model';
import { PaginationInterface } from '../../models/entryPagination.model';
import { ConfigService } from '../../services/config.service';

@Injectable({
  providedIn: 'root',
})
export class PaginationService implements OnDestroy {
  pageSizeOptions: number[] = [5, 10, 25, 100];
  private onDestroy$ = new Subject<void>();
  private actualPage!: Pagination;
  private initPage: Pagination = {
    currentPageIndex: 1,
    totalItemsCount: 0,
    itemsPerPage: this.configService.config?.itemsPerPage || this.pageSizeOptions[2],
    pagesCount: null!,
  };

  private _page$: BehaviorSubject<Pagination> = new BehaviorSubject(this.initPage);
  page$: Observable<Pagination> = this._page$.asObservable();

  constructor(private configService: ConfigService) {
    this.configService.config$.pipe(first()).subscribe((config) =>
      this._page$.next({
        ...this.initPage,
        itemsPerPage: config.itemsPerPage || this.initPage.itemsPerPage,
      }),
    );
    this.page$.pipe(takeUntil(this.onDestroy$)).subscribe((page) => (this.actualPage = page));
  }

  configAfterDataLoad(data: PaginationInterface<ItemModel>) {
    this.actualPage.pagesCount = data.last_page!;
    this.actualPage.totalItemsCount = data.total!;
  }

  changePageSize(size: number) {
    this.configService.changePagination(size);
    const newPagesCount = Math.ceil(this.actualPage.totalItemsCount / size);
    this._page$.next({ ...this.actualPage, itemsPerPage: size, pagesCount: newPagesCount, currentPageIndex: 1 });
  }

  goToFirst() {
    this.goToPage(1);
  }

  goBack() {
    const { currentPageIndex } = this.actualPage;
    this.goToPage(currentPageIndex - 1);
  }

  goNext() {
    const { currentPageIndex } = this.actualPage;
    this.goToPage(currentPageIndex + 1);
  }

  goToLast() {
    const { pagesCount } = this.actualPage;
    this.goToPage(pagesCount);
  }

  goToPage(page: number) {
    const { currentPageIndex, pagesCount } = this.actualPage;
    if (currentPageIndex === page || page < 0 || page > pagesCount) {
      return;
    }
    this._page$.next({ ...this.actualPage, currentPageIndex: page });
  }

  reactToFilterChange() {
    this.actualPage.currentPageIndex = 1;
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }
}

export interface Pagination {
  itemsPerPage: number;
  totalItemsCount: number;
  currentPageIndex: number;
  pagesCount: number;
}
