import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { PluginService } from '../service/plugin.service';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[appPluginDisplayHandler]',
})
export class PluginDisplayHandlerDirective implements OnInit {
  @Input() pluginChangeMode?: 'hide' | 'changeCss' | 'display' = 'hide';
  constructor(
    private elementRef: ElementRef,
    private pluginService: PluginService,
    private renderer: Renderer2,
  ) {}

  ngOnInit() {
    if (this.pluginService.isPluginMode) {
      switch (this.pluginChangeMode) {
        case 'changeCss':
          this.addClass();
          break;
        case 'hide':
          this.hideElement();
          break;
        case 'display':
          // this.hideElement();
          break;
        default:
          break;
      }
    } else {
      switch (this.pluginChangeMode) {
        case 'changeCss':
          // this.addClass();
          break;
        case 'hide':
          // this.hideElement();
          break;
        case 'display':
          this.hideElement();
          break;
        default:
          break;
      }
    }
  }

  private hideElement() {
    const el: HTMLElement = this.elementRef.nativeElement;
    el && el.parentNode!.removeChild(el);
  }

  private addClass() {
    const el: HTMLElement = this.elementRef.nativeElement;
    el && this.renderer.addClass(el, 'plugin-mode');
  }
}
