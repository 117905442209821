import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ConnectionUrlInterface } from 'src/app/dashboard/project/connections/connections.model';
import { TutorialService } from 'src/app/dashboard/tutorial/tutorial.service';
import { ConnectionsServiceEnum } from 'src/app/shared/enums/connections-service.enum';

@Component({
  selector: 'app-connection-integration-section',
  templateUrl: './connection-integration-section.component.html',
  styleUrls: ['./connection-integration-section.component.scss'],
})
export class ConnectionIntegrationSectionComponent {
  @Input() service: ConnectionsServiceEnum | null = null;
  @Input() url!: ConnectionUrlInterface;

  @Output() connected = new EventEmitter<void>();

  connectionsServiceEnum = ConnectionsServiceEnum;
  isIntegrateDisabled: boolean = false;

  constructor(public tutorialService: TutorialService) {}

  goToUrl() {
    if (this.url.url) {
      window.location.href = this.url.url;
      this.isIntegrateDisabled = true;
    }
  }

  onConnected() {
    this.connected.emit();
  }
}
