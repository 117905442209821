import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input } from '@angular/core';

const animateTime = '200ms';
const translateYValue = '-6px';

@Component({
  selector: '[custom-error]',
  template: `
    <div [@animation]="increment" *ngIf="show" class="mat-form-error">
      <mat-error>{{ message }}</mat-error>
    </div>
  `,
  // styles: ['.mat-form-error {vertical-align: top; margin: 0; position: absolute; bottom: 0; left: 0; font-size: 12px; }'],
  animations: [
    trigger('animation', [
      transition(':increment', [style({ opacity: 0 }), animate(`${animateTime} ease-in`, style({ opacity: 1 }))]),
      transition(':enter', [
        style({ opacity: 0, transform: `translateY(${translateYValue})` }),
        animate(`${animateTime} ease-in`, style({ opacity: 1, transform: 'translateY(0)' })),
      ]),
      transition(':leave', [animate(`${animateTime} ease-out`, style({ opacity: 0, transform: `translateY(${translateYValue})` }))]),
    ]),
  ],
})
export class MatErrorComponent {
  show!: boolean;
  message!: string;
  increment = 0;

  @Input() set error(value: any) {
    if (value) {
      if (this.message !== value) this.increment++;

      this.message = value;
    }

    this.show = !!value;
  }
}
