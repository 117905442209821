export enum ChartResultTypeValueEnum {
  my_rank = 'my_rank',
  my_rank_change = 'my_rank_change',
  lowest_price = 'lowest_price',
}

export enum ResultTypes {
  organic = 'organic',
  product = 'product',
}

export enum KeywordSources {
  keyword = 'keyword',
  product = 'product',
  duplicate = 'duplicate',
}

export enum CompetitorsSource {
  audit = 'audit',
  monitor = 'monitor',
}

export enum RankTypes {
  organic = 'organic',
  shopping = 'shopping',
  paid = 'paid',
}

export enum VisibilityTypes {
  organic = 'organic',
  shopping = 'shopping',
  paid = 'paid',
  product = 'product',
}

export enum VisibilityKeysLabels {
  top1 = 'Top1',
  top3 = 'Top 3',
  top10 = 'Top 10',
  top30 = 'Top 30',
  top50 = 'Top 50',
  top = 'Top',
  all = 'All',
}

export enum VisibilityKeys {
  top1 = 'top1',
  top3 = 'top3',
  top10 = 'top10',
  top30 = 'top30',
  top50 = 'top50',
  top = 'top',
  all = 'all',
}

export enum RankColumn {
  rank_group = 'rank_group', // for phrases
  rank_price = 'rank_price', // for products
}

export const DefaultCountry = 'Poland';

export const DefaultLanguage = 'Polish';

export const COUNTRIES = [
  'Austria',
  'Belgium',
  'Bulgaria',
  'Croatia',
  'Czechia',
  'Denmark',
  'Estonia',
  'Finland',
  'France',
  'Germany',
  'Greece',
  'Hungary',
  'Ireland',
  'Italy',
  'Japan',
  'Latvia',
  'Lithuania',
  'Malta',
  'Netherlands',
  'Portugal',
  'Romania',
  'Slovakia',
  'Slovenia',
  'Spain',
  'Sweden',
  'United Kingdom',
  'United States',
  DefaultCountry,
];
export const LANGUAGES = [
  'Bulgarian',
  'Croatian',
  'Czech',
  'Danish',
  'Dutch',
  'English',
  'Estonian',
  'Finnish',
  'French',
  'German',
  'Greek',
  'Hungarian',
  'Irish',
  'Italian',
  'Japanese',
  'Latvian',
  'Lithuanian',
  'Maltese',
  'Portuguese',
  'Romanian',
  'Slovak',
  'Slovenian',
  'Spanish',
  'Swedish',
  DefaultLanguage,
];
