import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import ApiUrls from 'src/app/configs/api-urls.config';

// W przypadku problemów z przeładowaniem nowej wersji - czyszczenie storage, wylogowanie i redirect na stronę logowania
@Component({
  selector: 'app-fix',
  templateUrl: './fix.component.html',
  styleUrls: ['./fix.component.scss'],
})
export class FixComponent implements OnInit {
  isLoaded = false;
  errorMessage!: string;

  constructor(
    private http: HttpClient,
    private router: Router,
  ) {}

  ngOnInit(): void {
    try {
      this.http.post(ApiUrls.logout, {}).subscribe(
        () => this.clearStorageAndRedirect(),
        (err) => {
          this.setErrorMessage(err);
          this.clearStorageAndRedirect();
        },
      );
    } catch (err) {
      this.setErrorMessage(err);
      this.clearStorageAndRedirect();
    }
  }

  goToHepCenter() {
    window.location.href = 'https://help.sembot.com/';
  }

  private clearStorageAndRedirect() {
    try {
      localStorage.removeItem('token');
      localStorage.clear();

      setTimeout(() => this.router.navigate(['']), 2000);
    } catch (err) {
      this.setErrorMessage(err);
    }

    this.setLoaded();
  }

  private setErrorMessage(err: any) {
    console.error(err.message);
    err.message && (this.errorMessage = err.message);
    this.setLoaded();
  }

  private setLoaded() {
    setTimeout(() => (this.isLoaded = true), 5000);
  }
}
