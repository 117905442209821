import { environment } from '../../environments/environment';
import packageJson from '../../../package.json';

const BASE_VERSION = 2;
const { appVersion } = environment;
const { versionFilePath } = packageJson;

class GeneralConfig {
  version = {
    app: appVersion,
    base: BASE_VERSION,
    filePath: versionFilePath,
    full: `${BASE_VERSION}.${appVersion}`,
  };
}

export default new GeneralConfig();
