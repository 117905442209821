<div class="buttons-wrapper">
  <!------------------ RECONNECT NEW CARD ------------------------>
  <app-button
    *ngIf="currentPackage.customerPortalUrl"
    mat-raised-button
    (click)="goToStripe()"
    [text]="'payments.current_package.payment_settings' | translate"
    [icon]="'credit_card'"
    appPluginDisplayHandler
  >
  </app-button>

  <!------------------ CAN BE CANCELED ------------------------>
  <app-button
    *ngIf="canBeCanceled"
    (click)="cancelSubscription()"
    [loading]="isLoading"
    [text]="'payments.current_package.cancel_subscription' | translate"
    color="warn"
    appPluginDisplayHandler
  ></app-button>

  <!------------------ NEED TO BE ACTIVATED ------------------------>
  <button *ngIf="canBeActivated" color="primary" mat-raised-button (click)="activateSubscription()">
    {{ 'payments.current_package.activate_subscription' | translate }}
  </button>

  <!------------------ CAN BE RESUMED ------------------------>
  <app-button
    *ngIf="canBeResumed"
    (click)="resumeSubscription()"
    [loading]="isLoading"
    [text]="'payments.current_package.resume_subscription' | translate"
    color="primary"
    appPluginDisplayHandler
  ></app-button>
</div>

<!------------------ SHOPIFY ------------------------>
<app-button
  *ngIf="pluginService.isPluginMode"
  [loading]="isLoading"
  [text]="'payments.current_package.choose_plan' | translate"
  appRouter="paymentsPlan"
  color="primary"
></app-button>

<div class="action-menu" appPluginDisplayHandler>
  <button mat-icon-button [matMenuTriggerFor]="menu">
    <mat-icon>more_vert</mat-icon>
  </button>
</div>

<mat-menu #menu="matMenu">
  <!------------------ NO CARD BRAND - RECONNECT NEW CARD ------------------------>
  <button *ngIf="currentPackage.customerPortalUrl" mat-menu-item (click)="goToStripe()">
    <mat-icon>credit_card</mat-icon>
    <span>{{ 'payments.current_package.payment_settings' | translate }}</span>
  </button>

  <!------------------ CAN BE CANCELED ------------------------>
  <button mat-menu-item *ngIf="canBeCanceled" color="warn" (click)="cancelSubscription()">
    <mat-icon>cancel</mat-icon>
    <span>{{ 'payments.current_package.cancel_subscription' | translate }}</span>
  </button>

  <!------------------ NEED TO BE ACTIVATED ------------------------>
  <button *ngIf="canBeActivated" color="primary" mat-menu-item (click)="activateSubscription()">
    <mat-icon>done</mat-icon>
    <span>
      {{ 'payments.current_package.activate_subscription' | translate }}
    </span>
  </button>

  <!------------------ CAN BE RESUMED ------------------------>
  <button mat-menu-item *ngIf="canBeResumed" (click)="resumeSubscription()">
    <mat-icon>refresh</mat-icon>
    <span>{{ 'payments.current_package.resume_subscription' | translate }}</span>
  </button>
</mat-menu>
