import { Injectable } from '@angular/core';

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum TOOLBAR_STATE {
  default,
  column,
  select,
}

@Injectable({
  providedIn: 'root',
})
export class MainToolbarService {
  private state: TOOLBAR_STATE = TOOLBAR_STATE.default;

  get currentState() {
    return this.state;
  }

  set currentState(state: TOOLBAR_STATE) {
    this.state = state;
  }
}
