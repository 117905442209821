import { Component, EventEmitter, OnDestroy, Output, inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NotificationService } from 'src/app/notification/notification.service';
import { AuthService } from 'src/app/shared/service/auth.service';
import { PasswordValidation } from 'src/app/shared/validators/password-validation.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormStatusService } from '../../../form-status.service';
import { passwordStrengthValidator } from '../../../../../shared/helpers/utils';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnDestroy {
  @Output() closePanel = new EventEmitter<void>();
  isLocked = false;
  form: UntypedFormGroup;
  #destroy$ = new Subject<void>();
  #formStatusService = inject(FormStatusService);

  constructor(
    private authService: AuthService,
    private formBuilder: UntypedFormBuilder,
    private notificationService: NotificationService,
  ) {
    this.form = this.formBuilder.group(
      {
        password: ['', [Validators.required, passwordStrengthValidator]],
        password_confirmation: [''],
      },
      {
        validator: PasswordValidation.matchPassword,
      },
    );
    this.form.valueChanges.pipe(takeUntil(this.#destroy$)).subscribe(() => {
      this.#formStatusService.isAnyFormDirty = this.form.dirty;
    });
  }

  get password() {
    return this.form.get('password') as UntypedFormControl;
  }

  get password_confirmation() {
    return this.form.get('password_confirmation') as UntypedFormControl;
  }

  ngOnDestroy() {
    this.#destroy$.next();
    this.#destroy$.complete();
  }

  protected saveChangePassword() {
    if (this.form.valid) {
      this.isLocked = true;
      this.authService.changePassword(this.password.value, this.password_confirmation.value).subscribe(
        () => {
          this.form.reset();
          this.form.markAsUntouched();
          this.closePanel.emit();
          this.notificationService.success('edit_profile');
          this.isLocked = false;
          this.#formStatusService.notifySaveChanges();
        },
        () => {
          this.notificationService.error('edit_profile');
          this.isLocked = false;
        },
      );
    }
  }
}
