import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LoaderService implements OnDestroy {
  isLoading!: boolean;
  isLoading$ = new BehaviorSubject<boolean>(false);

  private onDestroy$ = new Subject<void>();

  constructor() {
    this.isLoading$
      .pipe(
        takeUntil(this.onDestroy$),
        tap((isLoading) => (this.isLoading = isLoading)),
      )
      .subscribe();
  }

  on() {
    this.isLoading$.next(true);
  }

  off() {
    this.isLoading$.next(false);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }
}
