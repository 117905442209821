import { Component } from '@angular/core';
import { BrandLogo } from '../../shared/model/brand-logos.model';

@Component({
  selector: 'app-brand-logos',
  templateUrl: './brand-logos.component.html',
  styleUrls: ['./brand-logos.component.scss'],
})
export class BrandLogosComponent {
  protected basePath: string = 'assets/images/register/';
  protected brandLogos: BrandLogo[] = [
    { url: 'empik-logo.svg', title: 'empik-logo' },
    { url: 'lpp-logo.svg', title: 'lpp-logo' },
    { url: 'selsey-logo.svg', title: 'selsey-logo' },
    { url: 'wakacje-pl-logo.svg', title: 'wakacje-pl-logo' },
    { url: 'homebook-logo.svg', title: 'homebook-logo' },
  ];
}
