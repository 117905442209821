import { ColumnTypesEnum, innerColumnName } from '../../ColumnTypesEnum';
import { mainTableColumnDefaultGroup } from '../../../enums';
import { ColumnModel } from './column.model';

export class InnerColumn extends ColumnModel {
  alias = innerColumnName;
  type = ColumnTypesEnum.INNER;
  group = mainTableColumnDefaultGroup;
  actionsAvailableTypes = [];

  returnPossibleValues() {
    return null;
  }

  returnValueForDisplay() {
    return null;
  }

  returnValue() {
    return null;
  }

  isEditable() {
    return null;
  }

  isRequired() {
    return null;
  }

  isAbleToSort() {
    return false;
  }
}
