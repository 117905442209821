import { DOCUMENT } from '@angular/common';
import { Directive, ElementRef, EventEmitter, Inject, OnDestroy, OnInit, Optional, Output } from '@angular/core';
import { Subscription, fromEvent } from 'rxjs';
import { filter } from 'rxjs/operators';

// @TODO: Dorzucić w ramach tej dyrektywy "kliknięcie entera"
@Directive({
  selector: '[appOutsideClick]',
})
export class OutsideClickDirective implements OnInit, OnDestroy {
  @Output('appOutsideClick') outsideClick = new EventEmitter<MouseEvent>();

  private subscription!: Subscription;

  constructor(
    private element: ElementRef,
    @Optional() @Inject(DOCUMENT) private document: any,
  ) {}

  ngOnInit() {
    setTimeout(() => {
      this.subscription = fromEvent<MouseEvent>(this.document, 'click')
        .pipe(
          filter((event) => {
            const clickTarget = event.target as HTMLElement;
            return !this.isOrContainsClickTarget(this.element.nativeElement, clickTarget);
          }),
        )
        .subscribe(() => this.outsideClick.emit());
    }, 0);
  }

  private isOrContainsClickTarget(element: HTMLElement, clickTarget: HTMLElement) {
    return element == clickTarget || element.contains(clickTarget);
  }

  ngOnDestroy() {
    if (this.subscription) this.subscription.unsubscribe();
  }
}
