import { Component, Input } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { filter } from 'rxjs/operators';
import { ServicesSlugsEnum } from 'src/app/dashboard/project/connections/connections.model';
import { ProjectModel } from 'src/app/dashboard/project/project.model';
import { ProjectService } from 'src/app/dashboard/project/project.service';
import { BingCreateAccountPopupComponent } from 'src/app/shared/modals/bing-create-account-popup/bing-create-account-popup.component';
import { ServiceModel } from 'src/app/shared/model/connections.model';
import { RouterService, routerConfig } from 'src/app/shared/service/router.service';

@Component({
  selector: 'app-connection-new-services-account',
  templateUrl: './connection-new-services-account.component.html',
})
export class ConnectionNewServicesAccountComponent {
  @Input() service!: ServiceModel;

  allowedServices = [ServicesSlugsEnum.bing];

  constructor(
    private projectService: ProjectService,
    private routerService: RouterService,
    private dialog: MatDialog,
  ) {}

  openDialog() {
    const data: ProjectModel = this.projectService.activeProject$.getValue()!;
    const config = {
      data,
      maxWidth: '775px',
      panelClass: 'no-dialog-spinner',
    } as MatDialogConfig;

    switch (this.service.slug) {
      case ServicesSlugsEnum.bing:
        this.openBingCreate(config);
        break;
    }
  }

  private openBingCreate(config: MatDialogConfig) {
    this.dialog
      .open(BingCreateAccountPopupComponent, config)
      .afterClosed()
      .pipe(filter((r) => !!r))
      .subscribe(() => this.routerService.navigate(routerConfig.postBing, { project: this.projectService.activeProject$.getValue()!.id }));
  }
}
