import { Component, Input } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { filter } from 'rxjs/operators';
import { NotificationService } from 'src/app/notification/notification.service';
import { PackageItemModel } from '../../../models/payments.model';
import { RemoveProductPopupComponent } from './remove-product-popup/remove-product-popup.component';

@Component({
  selector: 'app-part-restrictions',
  templateUrl: './part-restrictions.component.html',
  styleUrls: ['./part-restrictions.component.scss'],
})
export class PartRestrictionsComponent {
  items!: PackageItemModel[];

  @Input() set partItems(items: PackageItemModel[]) {
    this.items = items.map((item) => {
      const restrictionNames = Object.keys(item.restrictions);
      return { ...item, restrictionNames };
    });
  }

  constructor(
    private dialog: MatDialog,
    private notificationService: NotificationService,
  ) {}

  openRemove(data: PackageItemModel) {
    const config = {
      data,
      maxWidth: '450px',
      panelClass: 'no-dialog-spinner',
    } as MatDialogConfig;

    const dialog = this.dialog.open(RemoveProductPopupComponent, config);

    dialog
      .afterClosed()
      .pipe(filter((v) => !!v))
      .subscribe(() => this.notificationService.success('package_reduce'));
  }
}
