import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input() color: string | null = null;
  @Input() type = 'button';
  @Input() text = 'empty';
  @Input() id?: string = '';
  @Input() loading = false;
  @Input() icon: string | null = null;
  @Input() disabled = false;
  @Input() fullWidth? = false;
  @Input() size?: 'small' | 'medium' | 'large' = 'small';
  @Input() marginSize?: string | null = null;
  @Input() onClick = () => {};
}
