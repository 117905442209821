import { ItemModel } from '../../item.model';
import { ActionTypesEnum } from '../ActionTypesEnum';
import { ActionModel } from './ActionModel';

export class CaseChangeAction extends ActionModel {
  type = ActionTypesEnum.caseChange;
  value = null;

  isValid(): boolean {
    return !!this.columnName;
  }

  use(item: ItemModel) {
    let str = item[this.columnName as keyof typeof item] as string;
    if (!str) {
      return str;
    }
    str = str.toLowerCase();
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  returnForSend() {
    return {
      action: this.type,
      param: this.columnName,
      sequence: this.sequence,
    };
  }

  copyValue(item: this, overrideManualChanges: boolean): void {
    this.columnName = item.columnName;
    this.override_manual_changes = overrideManualChanges;
  }
}
