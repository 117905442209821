import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RouterService, routerConfig } from '../../service/router.service';

export interface ClientVersionInterface {
  env: string;
  header: string;
}
@Component({
  selector: 'app-refresh-app-modal',
  templateUrl: './refresh-app-modal.component.html',
  styleUrls: ['./refresh-app-modal.component.scss'],
})
export class RefreshAppModalComponent {
  constructor(
    public dialogRef: MatDialogRef<RefreshAppModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ClientVersionInterface,
    private routerService: RouterService,
  ) {}

  refresh() {
    this.dialogRef.close(true);
  }

  alternativeRefresh() {
    this.routerService.navigate(routerConfig.fix)!.then(() => this.dialogRef.close(true));
  }
}
