import { Component, OnInit, inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { delay } from 'rxjs/operators';
import { NotificationService } from 'src/app/notification/notification.service';
import { AuthService } from 'src/app/shared/service/auth.service';
import { RouterService, routerConfig } from 'src/app/shared/service/router.service';
import { FormApiValidationError } from '../../shared/model/errors/formApiError.model';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  isLoading = false;
  protected form!: UntypedFormGroup;
  private authService = inject(AuthService);
  private formBuilder = inject(UntypedFormBuilder);
  private notificationService = inject(NotificationService);
  private routerService = inject(RouterService);
  private route = inject(ActivatedRoute);

  get email() {
    return this.form.get('email') as UntypedFormControl;
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      email: ['', [Validators.email, Validators.required, Validators.minLength(5)]],
    });
  }

  onSubmit() {
    if (this.form.valid) {
      this.isLoading = true;
      this.authService
        .remindPassword(this.email.value)
        .pipe(delay(1000))
        .subscribe(
          () => {
            this.notificationService.success('remind_password');
            this.routerService.navigate(routerConfig.authLogin);
          },
          (er: FormApiValidationError) => {
            er.setOnForm && er.setOnForm(this.form);
            this.isLoading = false;
          },
        );
    }
  }
}
