import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DisableStateService {
  private disabled$ = new BehaviorSubject<boolean>(false);
  isDisabled$ = this.disabled$.asObservable();

  disableAppState() {
    this.disabled$.next(true);
  }

  enableAppState() {
    this.disabled$.next(false);
  }
}
