import { UntypedFormGroup } from '@angular/forms';
import { CommunicationService } from '../services/communication.service';

export enum ApiResponseTypesMainTableEnum {
  success = 1,
  error,
}

export interface ApiResponseInterface {
  type: ApiResponseTypesMainTableEnum;
  message: string;
  invalidFields?: ApiInvalidFieldResponse;
}
export interface ApiInvalidFieldResponse {
  [key: string]: string[];
}

export class ApiResponseSuccessModel implements ApiResponseInterface {
  constructor(
    public type: ApiResponseTypesMainTableEnum,
    public message: string,
    private communicationService: CommunicationService,
  ) {
    this.notify();
  }

  notify() {
    this.communicationService.customMessage$.next({ message: this.message, type: 'success', case: 'DEFAULT' });
  }
}

export class ApiResponseErrorModel implements ApiResponseInterface {
  constructor(
    public type: ApiResponseTypesMainTableEnum,
    public message: string,
    private communicationService: CommunicationService,
    public invalidFields?: ApiInvalidFieldResponse,
  ) {
    this.notify();
  }

  notify() {
    this.communicationService.customMessage$.next({ message: this.message, type: 'error', case: 'DEFAULT' });
  }

  setOnForm(form: UntypedFormGroup) {
    if (this.invalidFields) {
      const fieldsKeys = Object.keys(this.invalidFields);
      fieldsKeys.forEach((key) => form.get(key)!.setErrors({ apiError: this.invalidFields![key] }));
    }
  }
}
