import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import routerConfig from 'src/app/configs/router.config';
import { AddProductsComponent } from './add-products/add-products.component';
import { BuySubscriptionComponent } from './buy-subscription/buy-subscription.component';
import { ConfirmPaymentComponent } from './buy-subscription/confirm-payment/confirm-payment.component';
import { CurrentPackageComponent } from './current-package/current-package.component';
import { InovoiceComponent } from './invoice/invoice.component';
import { PlanComponent } from './plan/plan.component';

const routes: Routes = [
  {
    path: '',
    title: 'payments.current_package.current_package',
    pathMatch: 'full',
    component: CurrentPackageComponent,
    data: routerConfig.projects,
  },
  {
    path: ':id/buy',
    title: 'payments.buy.fill_data',
    component: BuySubscriptionComponent,
    data: routerConfig.paymentsBuy,
  },
  {
    path: 'plan',
    title: 'payments.plan.no_premission.title',
    component: PlanComponent,
    data: routerConfig.paymentsPlan,
  },
  {
    path: 'products',
    title: 'payments.products.title',
    component: AddProductsComponent,
    data: routerConfig.paymentsAddProducts,
  },
  {
    path: 'invoices',
    title: 'payments.invoices.title',
    component: InovoiceComponent,
    data: routerConfig.paymentsAddProducts,
  },
  {
    path: 'confirm/:id',
    title: 'payments.buy.confirm_title',
    component: ConfirmPaymentComponent,
    data: routerConfig.vatGenerate,
  },
  // {
  //   path: 'reconnect',
  //   component: CardReconnectionComponent,
  //   data: routerConfig.cardReconnection,
  // },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PaymentsRoutingModule {}
