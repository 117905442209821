import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { filter, tap } from 'rxjs/operators';
import { ServicesSlugsEnum } from 'src/app/dashboard/project/connections/connections.model';
import { ProjectService } from 'src/app/dashboard/project/project.service';
import { AnalyticsDataModel, AnalyticsFiltersModalComponent } from '../../modals/analytics-filters-modal/analytics-filters-modal.component';
import { LocalStorageService } from '../../service/local-storage.service';

@Component({
  selector: 'app-analytics-picker',
  templateUrl: './analytics-picker.component.html',
  styleUrls: ['./analytics-picker.component.scss'],
})
export class AnalyticsPickerComponent implements OnInit {
  @Input() isMultiselect = false;
  @Input() storageKey!: string;
  @Input() services: ServicesSlugsEnum[] = [ServicesSlugsEnum.ads, ServicesSlugsEnum.analytics, ServicesSlugsEnum.analytics4];
  @Output() saved: EventEmitter<AnalyticsDataModel> = new EventEmitter();

  currentData!: AnalyticsDataModel;
  projectId!: string;

  constructor(
    private dialog: MatDialog,
    private projectService: ProjectService,
    private storageHelper: LocalStorageService,
  ) {}

  ngOnInit(): void {
    this.projectId = this.projectService.activeProject$.getValue()!.id.toString();
    this.currentData = this.storageKey ? this.getDataFromStorage() : null!;
  }

  openDialog() {
    this.dialog
      .open(AnalyticsFiltersModalComponent, {
        data: {
          isMultiselect: this.isMultiselect,
          data: this.currentData,
          params: { services: this.services },
        },
        maxWidth: '420px',
        panelClass: ['no-dialog-spinner', 'analytics-modal'],
      })
      .afterClosed()
      .pipe(
        filter((res) => res || res === null),
        tap((data: AnalyticsDataModel) => (this.currentData = data)),
        tap(() => this.storageKey && this.saveDataToStorage()),
      )
      .subscribe((data: AnalyticsDataModel) => this.saved.emit(data));
  }

  private getDataFromStorage(): AnalyticsDataModel {
    return this.projectId ? this.storageHelper.getForCurrentProject(this.storageKey) : null;
  }

  private saveDataToStorage() {
    this.currentData && this.projectId
      ? this.storageHelper.saveForCurrentProject(this.storageKey, this.currentData)
      : this.storageHelper.removeForCurrentProject(this.storageKey);
  }
}
