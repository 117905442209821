import { ColumnModel } from '../../sem-table/table-display/columns-switch/columns/column.model';
import { SimpleColumn } from '../../sem-table/table-display/columns-switch/columns/simple-column/SimpleColumn';
import { ColumnValueTypesEnum } from '../../sem-table/table-display/ColumnTypesEnum';
import { filterTypesGroups } from '../../sem-table/enums';

const columns: {
  [key: string]: ColumnModel;
} = {
  title: new SimpleColumn({
    alias: 'product',
    valueType: ColumnValueTypesEnum.text,
    ableToFilter: true,
    filtersAvailable: filterTypesGroups.allFilters,
  }),
  price_diff: new SimpleColumn({
    alias: 'price difference',
    valueType: ColumnValueTypesEnum.number,
    ableToFilter: true,
    filtersAvailable: filterTypesGroups.allFiltersNumeric,
    canBeSorted: true,
  }),
  my_price: new SimpleColumn({
    alias: 'my price',
    valueType: ColumnValueTypesEnum.number,
    ableToFilter: true,
    filtersAvailable: filterTypesGroups.allFiltersNumeric,
    canBeSorted: true,
  }),
  seller_price: new SimpleColumn({
    alias: 'seller price',
    valueType: ColumnValueTypesEnum.number,
    ableToFilter: true,
    filtersAvailable: filterTypesGroups.allFiltersNumeric,
    canBeSorted: true,
  }),
  product_gtin: new SimpleColumn({
    alias: 'gtin',
    valueType: ColumnValueTypesEnum.number,
    ableToFilter: true,
    filtersAvailable: filterTypesGroups.allFilters,
    canBeSorted: true,
  }),
  google_product_id: new SimpleColumn({
    alias: 'google id',
    valueType: ColumnValueTypesEnum.text,
    ableToFilter: true,
    filtersAvailable: filterTypesGroups.allFilters,
    canBeSorted: true,
  }),
};

export default columns;
