import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
})
export class ChartComponent {
  @Input() dataSource!: Object;
  @Input() width: string;
  @Input() height: string;
  @Input() type!: string;

  constructor() {
    this.width = '100%';
    this.height = '100%';
  }
}
