import { NgIf } from '@angular/common';
import { Component, Pipe, PipeTransform, inject } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { MatFormField } from '@angular/material/form-field';
import { TranslateService } from '@ngx-translate/core';
import { FormApiValidationError } from '../../model/errors/formApiError.model';
import { errorTranslationKeyPerError } from '../../utils/error-messages';

@Pipe({
  name: 'errorDisplay',
  standalone: true,
})
export class SemFormFieldErrorDisplayPipe implements PipeTransform {
  private readonly translateService = inject(TranslateService);

  transform(errors: ValidationErrors | null): string {
    if (!errors) {
      return '';
    }

    const keys = Object.keys(errors);

    if (keys.length) {
      const translationKey = errorTranslationKeyPerError[keys[0]];
      if (translationKey) {
        return this.translateService.instant(translationKey);
      }
    }

    return errors[FormApiValidationError.errorKey] ?? '';
  }
}

@Component({
  selector: 'app-form-field-error-display',
  standalone: true,
  imports: [SemFormFieldErrorDisplayPipe, NgIf],
  templateUrl: './app-form-field-error-display.component.html',
})
export class AppFormFieldErrorDisplayComponent {
  readonly matFormField = inject(MatFormField);
}
