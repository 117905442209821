import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
  selector: '[appFormFieldEditableLabel]',
})
export class FormFieldEditableLabelDirective {
  @HostBinding('class.form-field-editable-label') get valid() {
    return this.status;
  }

  @Input('appFormFieldEditableLabel') status!: boolean;
}
