import { ItemModel } from '../../item.model';
import { ActionTypesEnum } from '../ActionTypesEnum';
import { ActionModel } from './ActionModel';

export class AddFoundFromFieldAction extends ActionModel {
  type = ActionTypesEnum.addFoundFromField;
  values: unknown[] | null = null;
  source!: string;

  isValid(): boolean {
    return !!(this.values && this.values[0] && this.source && this.columnName);
  }

  setValue(data: { target: string; source: string; values: string[] }) {
    this.values = data.values;
    this.source = data.source;
  }

  use(item: ItemModel) {
    const str = item[this.columnName as keyof typeof item] as string;
    return str.toUpperCase();
  }

  returnForSend() {
    return {
      action: this.type,
      source: this.source,
      target: this.columnName,
      sequence: this.sequence,
      values: this.values,
    };
  }

  copyValue(item: this, overrideManualChanges: boolean): void {
    this.columnName = item.columnName;
    this.override_manual_changes = overrideManualChanges;
  }
}
