<button
  mat-raised-button
  [color]="color"
  [type]="type"
  class="button-with-spinner"
  [disabled]="loading"
  (click)="onClick()"
  [id]="id"
  [ngStyle]="{ margin: marginSize }"
  [ngClass]="{
    'full-width': fullWidth,
    'button-small': size === 'small',
    'button-medium': size === 'medium',
    'button-large': size === 'large',
  }"
>
  <span [ngClass]="{ 'is-loading': loading }">{{ text }}</span>
  <mat-icon [ngClass]="{ 'is-loading': loading }" *ngIf="icon">{{ icon }}</mat-icon>

  <div class="spinner-wrapper" *ngIf="loading">
    <mat-spinner diameter="20"></mat-spinner>
  </div>
</button>
