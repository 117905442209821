import { FilterGroupModel } from '../../filters/models/filter.model';
import { ItemModel } from '../../item.model';
import { ColumnTypesEnum } from '../../table-display/ColumnTypesEnum';
import { ColumnModel } from '../../table-display/columns-switch/columns/column.model';
import { ActionTypesEnum } from '../ActionTypesEnum';

export abstract class ActionModel {
  sequence?: number; // Maybe we should remove it
  global: boolean;
  filterGroups: FilterGroupModel[];
  type!: ActionTypesEnum;
  columnName: string;
  columnType: ColumnTypesEnum;
  override_manual_changes = false;

  param!: string;
  from?: any;
  to?: any;

  value?: any;
  values?: any;
  action?: any;

  constructor(column: ColumnModel, filterGroups: FilterGroupModel[], isGlobal: boolean) {
    this.columnName = column.param;
    this.columnType = column.type;
    this.filterGroups = filterGroups || [];
    this.global = isGlobal;
  }

  setValue(value: any) {
    this.value = value;
  }

  abstract use(item: ItemModel): any;

  abstract isValid(): boolean;

  abstract returnForSend(): any;

  abstract copyValue(value: any, overrideManualChanges: boolean): void;
}
