import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
})
// #TODO: zrobić obsługę wszystkich ikon, z czego svg obsługiwać z predefiniowanego arraya?
export class IconComponent {
  @Input() isSvg: boolean = false;
  @Input() name!: string;
}
