import { FilterTypesEnum } from '../FilterTypesEnum';
import { FilterModel } from './filter.model';

export class IsUppercaseFilterModel extends FilterModel {
  symbol: FilterTypesEnum = FilterTypesEnum.is_uppercase;

  isValid() {
    return true;
  }
}
