import { Component, Input } from '@angular/core';
import FusionCharts from 'fusioncharts';
import { BaseStyle } from 'src/app/configs/fusion-charts.config';
import { customRangeSelector, standardRangeSelector } from 'src/app/shared/components/chart/styles/timeseriesStyle';
import { ChartResultTypeInterface } from '../../model/monitor.model';

@Component({
  selector: 'app-timeseries-chart-select',
  templateUrl: './timeseries-chart-select.component.html',
  styleUrls: ['./timeseries-chart-select.component.scss'],
})
export class TimeseriesChartSelectComponent {
  private _resultTypes!: ChartResultTypeInterface[];

  // chart
  dataSource: any;
  selectedResultType!: ChartResultTypeInterface;
  dataset: Array<any> = [];

  defaultFormat = {
    format: {
      defaultFormat: 0,
      round: 0,
    },
  };

  selected: any;

  @Input() set resultTypes(data: ChartResultTypeInterface[]) {
    this._resultTypes = data;
    this.selected = data[0].value;
    this.updateChart();
  }

  get resultTypes(): ChartResultTypeInterface[] {
    return this._resultTypes;
  }

  constructor() {
    this.dataSource = {
      data: null,
      chart: {
        multicanvas: false,
        style: BaseStyle,
      },
      yaxis: {
        plot: {
          connectnulldata: true,
          aggregation: 'Min',
        },
      },
      navigator: {
        enabled: 0,
      },
      extensions: {
        customRangeSelector,
        standardRangeSelector,
      },
    };
  }

  updateChart() {
    this.selectedResultType = this.resultTypes.find((resultType) => resultType.value == this.selected)!;
    this.dataSource.series = this.selectedResultType.series;

    if (this.selectedResultType.dataset) {
      this.dataset = this.selectedResultType.dataset.map((item) => Object.values(item));
    }

    this.dataSource.yaxis = { ...this.dataSource.yaxis, ...(this.selectedResultType.format ?? this.defaultFormat) };
    if (this.dataset.length) {
      let schema = [
        {
          name: 'Time',
          type: 'date',
          format: '%Y-%m-%d',
        },
        {
          name: this.selectedResultType.yAxisName,
          type: 'number',
        },
        {
          name: this.selectedResultType.series,
          type: 'string',
        },
      ];

      const fusionDataStore = new FusionCharts.DataStore();
      const fusionTable = fusionDataStore.createDataTable(this.dataset, schema);
      this.dataSource.data = fusionTable;
    }
  }
}
