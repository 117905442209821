<div class="summary-wrapper">
  <div class="section-title section">
    <button color="primary" mat-raised-button (click)="openInvoiceDataDialog()" [disabled]="!preparedData">
      {{ 'payments.invoices.change' | translate }}
    </button>

    {{ 'payments.invoices.data' | translate }}
  </div>
  <div class="section" *ngFor="let el of preparedData">
    <span class="element">{{ 'payments.invoices.' + el[0] | translate }}</span>
    <span>{{ el[1] }} </span>
  </div>
</div>
