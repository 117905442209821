import { Injectable, OnDestroy } from '@angular/core';
import { isEqual } from 'lodash';
import { BehaviorSubject, Subject } from 'rxjs';
import { distinctUntilChanged, filter, takeUntil, tap } from 'rxjs/operators';
import { ItemModel } from '../item.model';
import { MainToolbarService, TOOLBAR_STATE } from '../main-toolbar/main-toolbar.service';
import { HelperService } from './helper.service';

@Injectable({
  providedIn: 'root',
})
export class SelectedService implements OnDestroy {
  configurationCompleted = false;
  isAllAtPageSelected$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isAnyItemSelected$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isSelectedGlobally!: boolean;
  itemsOnCurrentPage: ItemModel[] = [];
  itemsTotalCounter!: number;
  selectedGlobally$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  selectedItems: ItemModel[] = [];
  selectedItems$: BehaviorSubject<ItemModel[]> = new BehaviorSubject(this.selectedItems);

  private onDestroy$ = new Subject<void>();

  constructor(
    private helperService: HelperService,
    private mainToolbarService: MainToolbarService,
  ) {
    this.isAnyItemSelected$
      .pipe(
        takeUntil(this.onDestroy$),
        filter(() => !!this.configurationCompleted),
        distinctUntilChanged(),
        tap((res) => (this.mainToolbarService.currentState = res ? TOOLBAR_STATE.select : TOOLBAR_STATE.default)),
      )
      .subscribe();

    this.selectedGlobally$
      .pipe(
        takeUntil(this.onDestroy$),
        filter(() => !!this.configurationCompleted),
        distinctUntilChanged(),
      )
      .subscribe((res) => (this.isSelectedGlobally = res));

    this.isAllAtPageSelected$
      .pipe(
        takeUntil(this.onDestroy$),
        filter(() => !!this.configurationCompleted),
        distinctUntilChanged(),
        filter(() => this.selectedItems.length === this.itemsTotalCounter),
      )
      .subscribe(() => this.selectedGlobally$.next(true));

    this.selectedItems$
      .pipe(
        takeUntil(this.onDestroy$),
        filter(() => !!this.configurationCompleted),
        tap((items) => (this.selectedItems = items)),
        tap((items) => this.selectedGlobally$.next(items.length === this.itemsTotalCounter)),
        tap((items) => {
          const isAnyItemSelected = items.length > 0;
          this.isAnyItemSelected$.next(isAnyItemSelected);
          if (isAnyItemSelected && this.mainToolbarService.currentState !== TOOLBAR_STATE.select) {
            this.mainToolbarService.currentState = TOOLBAR_STATE.select;
          }
        }),
        tap(() => this.isAllAtPageSelected$.next(this.checkIfAllItemPerPageIsSelected())),
      )
      .subscribe();
  }

  changeItemInTable(changedItem: ItemModel | null = null) {
    this.itemsOnCurrentPage = this.itemsOnCurrentPage.map((item) => (item.id === changedItem!.id ? changedItem! : item));
    this.selectedItems.find((item) => item.id === changedItem!.id && !isEqual(changedItem, item)) && this.updateItem(changedItem!);
  }

  mainCheckboxClick() {
    if (this.checkIfAllItemPerPageIsSelected()) {
      this.itemsOnCurrentPage.forEach((item) => this.unselectItem(item));
    } else {
      this.selectAllItemPerPage();
    }
  }

  recalculate(itemsOnPage: ItemModel[], totalItemsCount: number) {
    this.itemsOnCurrentPage = itemsOnPage;
    this.itemsTotalCounter = totalItemsCount;

    this.isAllAtPageSelected$.next(itemsOnPage.length ? this.checkIfAllItemPerPageIsSelected() : false);
    this.configurationCompleted = true;
  }

  selectItem(item: ItemModel) {
    if (!this.selectedItems.some((selectedItem) => selectedItem.id === item.id)) {
      this.selectedItems.push(item);
    }
    this.selectedItems$.next(this.selectedItems);
  }

  unselectAll() {
    this.selectedItems$.next([]);
  }

  unselectItem(item: ItemModel) {
    if (this.isSelectedGlobally) {
      this.selectedGlobally$.next(false);
    }
    this.helperService.removeElementFromArray(item, this.selectedItems);
    this.selectedItems$.next(this.selectedItems);
  }

  updateItem(item: ItemModel) {
    this.helperService.removeElementFromArray(item, this.selectedItems);
    this.selectItem(item);
  }

  updateIsAnyItemSelected(value: boolean) {
    this.isAnyItemSelected$.next(value);
  }

  resetSelection() {
    this.selectedItems = [];
    this.selectedItems$.next(this.selectedItems);
    this.updateIsAnyItemSelected(false);
    this.selectedGlobally$.next(false);
    this.isAllAtPageSelected$.next(false);
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  private checkIfAllItemPerPageIsSelected(): boolean {
    let res = true;
    this.itemsOnCurrentPage.forEach((item) => {
      if (!this.helperService.isInArray(item, this.selectedItems)) {
        res = false;
      }
    });

    return res;
  }

  private selectAllItemPerPage() {
    this.itemsOnCurrentPage.forEach((item) => {
      if (!this.helperService.isInArray(item, this.selectedItems)) {
        this.selectItem(item);
      }
    });
  }
}
