import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { UserModel } from '../../model/user.model';
import { EditableColumnsComponent } from '../../sem-table/table-display/columns-switch/columns/ColumnsComponent.interface';

@Component({
  selector: 'app-user-tag',
  templateUrl: './user-tag.component.html',
  styleUrls: ['./user-tag.component.scss'],
})
export class UserTagComponent implements OnInit, EditableColumnsComponent {
  @Input() isUserBold = false;
  @Input() holidayInfoType: 'icon' | 'untilLabel' | null = null;
  @Input() set data(user: UserModel) {
    this.user = user;
    const { email, name } = user || {};
    this.email = email || '';
    this.name = name || this.email.split('@')[0].replace(/\./g, ' ');
  }

  @Output() editStateExited: EventEmitter<any> = new EventEmitter();
  @Output() valueChanged: EventEmitter<any> = new EventEmitter();

  @HostListener('document:click', ['$event']) clickout(event: Event): void {
    if (this.edit && !this.eRef.nativeElement.contains(event.target)) {
      this.exitEditableState();
    }
  }

  constructor(private readonly eRef: ElementRef) {}

  user!: UserModel;
  circleColor!: string;
  email!: string;
  initials!: string;
  name!: string;

  edit!: boolean;

  // private readonly colors = [
  //   '#EB7181', // red
  //   '#468547', // green
  //   '#FFD558', // yellow
  //   '#3670B2', // blue
  // ];

  ngOnInit() {
    this.createInititals();

    // W tej chwili wyświetlamy tylko jeden kolor
    // const randomIndex = Math.floor(Math.random() * Math.floor(this.colors.length));
    // this.circleColor = this.colors[randomIndex];
    this.circleColor = '#7E7E7E';
  }

  enterEditableMode(): void {
    this.edit = true;
  }

  exitEditableState(): void {
    this.edit = false;
    this.editStateExited.emit();
  }

  private createInititals(): void {
    let initials = '';

    if (!this.name) {
      return;
    }

    initials = this.name[0].toUpperCase();

    for (let i = 1; i < this.name.length; i++) {
      if (this.name.charAt(i) === ' ') {
        continue;
      }

      if (this.name.charAt(i) === this.name.charAt(i).toUpperCase()) {
        initials += this.name.charAt(i);

        if (initials.length == 2) {
          break;
        }
      }
    }

    this.initials = initials;
  }
}
