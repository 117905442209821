import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeCharacter',
})
export class RemoveCharacterPipe implements PipeTransform {
  transform(value: string, characterToRemove: string = '_'): string {
    if (!value) return '';
    return value.replace(new RegExp(characterToRemove, 'g'), ' ');
  }
}
