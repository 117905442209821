import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { ServicesSlugsEnum } from 'src/app/dashboard/project/connections/connections.model';
import { ServicesPopupComponent } from 'src/app/dashboard/project/connections/modals/services-popup/services-popup.component';
import { TutorialService } from 'src/app/dashboard/tutorial/tutorial.service';
import { ConnectionsServiceSlugIconEnum } from 'src/app/shared/enums/connections-service.enum';
import { ConnectionsService } from 'src/app/shared/service/connections.service';
import { RouterService } from 'src/app/shared/service/router.service';
import { ServiceModel } from '../../../model/connections.model';

@Component({
  selector: 'app-connection-cards',
  templateUrl: './connection-cards.component.html',
  styleUrls: ['./connection-cards.component.scss'],
})
export class ConnectionCardsComponent implements OnDestroy {
  @Output() connectionAttached = new EventEmitter<boolean>();

  private onDestroy$: Subject<void> = new Subject();

  services!: ServiceModel[];
  connectionsServiceIcon = ConnectionsServiceSlugIconEnum;
  service = new UntypedFormControl();

  constructor(
    private activeRoute: ActivatedRoute,
    private connectionsService: ConnectionsService,
    private dialog: MatDialog,
    private routerService: RouterService,
    public tutorialService: TutorialService,
  ) {
    this.getServices();
  }

  changeService(service: ServiceModel) {
    this.openConnectionDialog(service);
  }

  svgIcon(slug: ServicesSlugsEnum) {
    return this.connectionsServiceIcon[slug as keyof typeof this.connectionsServiceIcon] || null;
  }

  private openConnectionDialog(service: ServiceModel) {
    const config = {
      data: service,
      maxWidth: '600px',
      panelClass: 'no-dialog-spinner',
    } as MatDialogConfig;

    this.dialog
      .open(ServicesPopupComponent, config)
      .afterClosed()
      .pipe(filter((res) => !!res))
      .subscribe(() => this.reactToConnectionAttached());
  }

  private reactToConnectionAttached() {
    this.connectionAttached.emit(true);
  }

  private getCurrentConnectionSlug() {
    // Query param slug format: GOOGLE_ANALYTICS_4,GOOGLE_ANALYTICS,...
    const currentSlugs = (this.activeRoute.snapshot.queryParamMap.get('slug') || '').split(',');
    const currentSlug = currentSlugs && currentSlugs[0] ? currentSlugs[0] : null;

    if (currentSlug) {
      this.routerService.skipQueryParams();

      const currentService = this.services.find((service) => service.slug === currentSlug);
      if (currentService) {
        this.changeService(currentService);
      }
    }
  }

  private getServices() {
    this.connectionsService
      .getServices()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((services) => {
        this.services = services;
        this.getCurrentConnectionSlug();
      });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
