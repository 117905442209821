<div *ngIf="translateKey && !edit" class="badge badge-{{ type }}" [ngClass]="{ 'badge-outlined': isOutlined }">
  {{ translateKey | translate }}
</div>
<mat-form-field *ngIf="edit">
  <mat-select [value]="valueKey" (selectionChange)="valueChanged.emit($event.value)">
    <mat-option *ngFor="let priority of prioritiesList" [value]="priority">
      {{ 'tasks.priority.' + priority | translate }}
    </mat-option>
  </mat-select>
</mat-form-field>
