import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { FormFieldEditableLabelDirective } from '../../directives/form-field-editable-label.directive';
import { MaterialModule } from '../../material-module/material.module';
import { ProjectsListSelectComponent } from './projects-list-select/projects-list-select.component';

@NgModule({
  declarations: [FormFieldEditableLabelDirective, ProjectsListSelectComponent],
  imports: [CommonModule, FormsModule, MaterialModule, NgxMatSelectSearchModule, ReactiveFormsModule, TranslateModule],
  exports: [FormFieldEditableLabelDirective, ProjectsListSelectComponent],
})
export class FormFieldsModule {}
