import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-date-range-badge',
  templateUrl: './date-range-badge.component.html',
  styleUrls: ['./date-range-badge.component.scss'],
})
export class DateRangeBadgeComponent {
  @Input() isFutureTime!: boolean;
  @Output() selectRange: EventEmitter<number> = new EventEmitter();
  readonly daysArray = [7, 30, 90];

  setDays(range: number): void {
    this.selectRange.emit(range);
  }
}
