import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { isShopifyEmbedded } from '@shopify/app-bridge-utils';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import routerConfig from '../configs/router.config';
import { ProfileService } from '../dashboard/profile/profile.service';
import { ProjectService } from '../dashboard/project/project.service';
import { AuthService } from '../shared/service/auth.service';
import { PluginService } from '../shared/service/plugin.service';
import { RouterService } from '../shared/service/router.service';
import { LocalStorageService, StartPageStorageEnum, storageKey } from '../shared/service/local-storage.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private pluginService: PluginService,
    private profileService: ProfileService,
    private projectService: ProjectService,
    private router: Router,
    private routerService: RouterService,
    private storageHelperService: LocalStorageService,
  ) {}

  canActivate(): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if (environment.pluginMode && isShopifyEmbedded()) {
      return this.pluginService.getAuthTokenForShopify().pipe(
        map(() => true),
        catchError(() => of(false)),
      );
    }

    this.authService.customPageAfterLogin = null;

    const { token } = this.authService;
    if (token) {
      return this.profileService.profile().pipe(
        tap(() => this.startPage()),
        // tap((profile) => (this.pluginService.isPluginMode ? this.handleShopifyPlugin(profile.settings.activeProject) : null)),
        map(() => true),
        catchError(() => of(false)),
      );
    } else if (window.location?.pathname) {
      const { pathname, search } = window.location;
      const customPageAfterLogin = pathname + (search || '');
      this.authService.customPageAfterLogin = customPageAfterLogin;
    }

    return this.router.parseUrl('/');
  }

  private startPage() {
    const startPage = this.storageHelperService.get(storageKey.startPage);

    if (startPage) {
      const currentProjectId = this.projectService.activeProject$.getValue()?.id || null;
      let navigateRoute = null;

      switch (startPage) {
        case StartPageStorageEnum.projectConnections:
          navigateRoute = routerConfig.projectConnections;
          break;

        case StartPageStorageEnum.projectAudit:
          navigateRoute = routerConfig.projectAudit;
          break;
      }

      if (navigateRoute && currentProjectId) {
        this.storageHelperService.remove(storageKey.startPage);
        this.routerService.navigate(navigateRoute, { project: currentProjectId });
      }
    }
  }
}
