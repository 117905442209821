import { Injectable, OnDestroy } from '@angular/core';
import { cloneDeep } from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { FilterGroupModel } from '../filters/models/filter.model';
import { ItemModel } from '../item.model';
import { ChangeModel } from '../models/change.model';
import { ChangesService } from '../services/changes.service';
import { CommunicationService } from '../services/communication.service';
import { SelectedService } from '../services/selected.service';
import { ColumnModel } from '../table-display/columns-switch/columns/column.model';
import { ActionTypesEnum } from './ActionTypesEnum';
import { ActionModel } from './models/ActionModel';
import { AddAction } from './models/AddAction';
import { AddBeginningAction } from './models/AddBeginningAction';
import { AddEndAction } from './models/AddEndAction';
import { AddFoundFromFieldAction } from './models/AddFoundFromFieldAction';
import { CapitalizedAction } from './models/CapitalizedCaseAction';
import { CaseChangeAction } from './models/CaseCaseAction';
import { DivideAction } from './models/DivideAction';
import { FixUppercaseAction } from './models/FixUppercase';
import { LowerCaseAction } from './models/LowerCaseAction';
import { ModifyLinkAction } from './models/ModifyLinkAction';
import { MultiplyAction } from './models/MultiplyAction';
import { OverwriteAction } from './models/OverwriteAction';
import { OverwriteExtendedAction } from './models/OverwriteExtendedAction';
import { RegexpReplaceAction } from './models/RegexpReplaceAction';
import { ReplaceAction } from './models/ReplaceAction';
import { SetEmptyAction } from './models/SetEmptyAction';
import { SubtractAction } from './models/SubtractAction';
import { UpperCaseAction } from './models/UpperCaseAction';

@Injectable({
  providedIn: 'root',
})
export class ActionService implements OnDestroy {
  globalActions: ActionModel[] = [];
  globalActions$: Subject<ActionModel[]> = new Subject();
  actionEmitted$: Subject<ActionModel> = new Subject();
  clearActions$: Subject<void> = new Subject();
  newGlobalActionAdded$: Subject<ActionModel> = new Subject();
  private onDestroy$ = new Subject<void>();

  constructor(
    private selectService: SelectedService,
    public changesService: ChangesService,
    private communicationService: CommunicationService,
  ) {
    this.actionEmitted$.pipe(takeUntil(this.onDestroy$)).subscribe((action) => {
      if (!action.global) {
        this.useActionOnData(action);
      } else {
        this.newGlobalActionAdded$.next(action);
      }
    });

    this.newGlobalActionAdded$
      .pipe(
        takeUntil(this.onDestroy$),
        tap((action) => this.globalActions.push(action)),
        tap((globalAction) => this.changesService.newChange$.next(new ChangeModel({ globalAction }))),
      )
      .subscribe(() => this.globalActions$.next(this.globalActions));

    this.communicationService.revertAction$.pipe(takeUntil(this.onDestroy$)).subscribe(() => this.revertAction());
    this.communicationService.restoreAction$.pipe(takeUntil(this.onDestroy$)).subscribe((action) => this.restoreAction(action));

    this.clearActions$.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.globalActions = [];
      this.globalActions$.next(this.globalActions);
    });
  }

  returnDataChangedByAction(data: ItemModel, action: ActionModel) {
    const item = cloneDeep(data);
    (item[action.columnName as keyof typeof item] as any) = action.use(item);
    return item;
  }

  createAction(type: ActionTypesEnum, column: ColumnModel, filterGroups: FilterGroupModel[]): ActionModel {
    const isGlobal = this.selectService.selectedGlobally$.getValue();

    switch (type) {
      case ActionTypesEnum.override:
        return new OverwriteAction(column, filterGroups, isGlobal);
      case ActionTypesEnum.generateValue:
        return new OverwriteExtendedAction(column, filterGroups, isGlobal);
      case ActionTypesEnum.caseChange:
        return new CaseChangeAction(column, filterGroups, isGlobal);
      case ActionTypesEnum.lower:
        return new LowerCaseAction(column, filterGroups, isGlobal);
      case ActionTypesEnum.capitalized:
        return new CapitalizedAction(column, filterGroups, isGlobal);
      case ActionTypesEnum.upper:
        return new UpperCaseAction(column, filterGroups, isGlobal);
      case ActionTypesEnum.addEnd:
        return new AddEndAction(column, filterGroups, isGlobal);
      case ActionTypesEnum.addStart:
        return new AddBeginningAction(column, filterGroups, isGlobal);
      case ActionTypesEnum.replace:
        return new ReplaceAction(column, filterGroups, isGlobal);
      case ActionTypesEnum.regexpReplace:
        return new RegexpReplaceAction(column, filterGroups, isGlobal);
      case ActionTypesEnum.setEmpty:
        return new SetEmptyAction(column, filterGroups, isGlobal);
      case ActionTypesEnum.add:
        return new AddAction(column, filterGroups, isGlobal);
      case ActionTypesEnum.divide:
        return new DivideAction(column, filterGroups, isGlobal);
      case ActionTypesEnum.multiply:
        return new MultiplyAction(column, filterGroups, isGlobal);
      case ActionTypesEnum.subtract:
        return new SubtractAction(column, filterGroups, isGlobal);
      case ActionTypesEnum.modifyLink:
        return new ModifyLinkAction(column, filterGroups, isGlobal);
      case ActionTypesEnum.fixUppercase:
        return new FixUppercaseAction(column, filterGroups, isGlobal);
      case ActionTypesEnum.addFoundFromField:
        return new AddFoundFromFieldAction(column, filterGroups, isGlobal);
      default: {
        throw new Error(`Unknown type: ${type}`);
      }
    }
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  private revertAction() {
    this.globalActions.splice(this.globalActions.length - 1, 1);
    this.globalActions$.next(this.globalActions);
  }

  private restoreAction(action: ActionModel) {
    this.globalActions.push(action);
    this.globalActions$.next(this.globalActions);
  }

  private useActionOnData(action: ActionModel) {
    const items = cloneDeep(this.selectService.selectedItems);
    const values: unknown[] = [];

    items.forEach((item) => values.push(action.use(item))); // dodaj do values wartośc zmienioną przez akcje
    this.changesService.newChange$.next(this.changesService.createChange(items, action.columnName, values)); // strzórz Change
    this.selectService.unselectAll();
  }
}
