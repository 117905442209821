import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { Observable, Subject, Subscription, timer } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { NotificationService } from 'src/app/notification/notification.service';
import { CLOSE_TIME_MS } from '../../NotificationConfig';

@Component({
  selector: 'app-default-template',
  templateUrl: './default-template.component.html',
  styleUrls: ['./default-template.component.scss'],
})
export class DefaultTemplateComponent implements OnInit, OnDestroy {
  @Input() id!: string;
  @Input() message!: string;
  private onDestroy$: Subject<void> = new Subject();
  private timerSub$!: Subscription;
  private timer$: Observable<null> = timer(CLOSE_TIME_MS).pipe(
    takeUntil(this.onDestroy$),
    tap(() => this.close()),
    map(() => null),
  );

  @HostListener('mouseenter') onMouseEnter() {
    this.timerSub$.unsubscribe();
    this.timerSub$.remove(this.timerSub$);
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.timerSub$ = this.timer$.subscribe();
  }

  constructor(
    private notificationService: NotificationService,
    private angularNotifierService: NotifierService,
  ) {}

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit(): void {
    this.timerSub$ = this.timer$.pipe(takeUntil(this.onDestroy$)).subscribe();
  }

  close() {
    this.angularNotifierService.hide(this.id);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }
}
