import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '../shared/service/auth.service';
import { PluginService } from '../shared/service/plugin.service';

@Injectable()
export class AuthIntercecptorService implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private pluginService: PluginService,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const { token } = this.authService;
    if (token) {
      const headers = {
        Authorization: `Bearer ${token}`,
        account: this.authService.authUser ? this.authService.authUser!.last_account!.toString() : '',
      };

      this.pluginService.pluginMode && ((headers['Plugin-Mode' as keyof typeof headers] as any) = this.pluginService.pluginMode);

      const reqClone = req.clone({
        setHeaders: headers,
      });

      return next.handle(reqClone);
    }

    return next.handle(req);
  }
}
