<div class="container">
  <mat-card appearance="outlined" *ngIf="!isLoading; else loader">
    <ng-container *ngIf="isNoPermission; else planContent">
      <mat-card-header>
        <mat-card-title>{{ 'payments.plan.no_premission.title' | translate }}</mat-card-title>
        <mat-card-subtitle>{{ 'payments.plan.no_premission.description' | translate }}</mat-card-subtitle>
      </mat-card-header>

      <mat-card-content class="change">
        <button class="btn" (click)="changeAccount()" color="primary" mat-raised-button>
          {{ 'payments.plan.no_premission.btn_txt' | translate }}
        </button>
      </mat-card-content>
    </ng-container>

    <ng-template #planContent>
      <mat-card-header>
        <mat-card-title>
          <h1 class="plan-title">{{ 'payments.plan.title' | translate }}</h1>
        </mat-card-title>
        <mat-card-subtitle>
          <span *ngIf="plan.support">{{ 'payments.plan.subtitle' | translate }}</span>
        </mat-card-subtitle>
      </mat-card-header>
      <app-currency-chooser
        class="currency-chooser"
        (currencyChange)="currencyChange($event)"
        *ngIf="isAnonymous"
        [(currency)]="currency"
      ></app-currency-chooser>

      <mat-card-content class="support-container">
        <div class="plan-content">
          <div class="restrictions">
            <div *ngIf="selectedSupportProduct" class="support">
              <div class="boxes">
                <app-box
                  (selected)="selectSupport($event)"
                  (showLimits)="setShowLimits()"
                  (showCss)="setShowCss()"
                  [currency]="plan.currency"
                  [basicPlan]="plan.basic ?? null"
                  [isSelected]="selectedSupportProduct && selectedSupportProduct.key === paymentPlanSupportEnum.free"
                  [isTrial]="isTrialAvailable"
                  [key]="paymentPlanSupportEnum.free"
                  [showSub]="plan.support ? true : false"
                  class="box"
                ></app-box>
                <app-box
                  *ngIf="plan.support"
                  (selected)="selectSupport($event)"
                  [currency]="plan.currency"
                  [data]="plan.support.active_service"
                  [basicPlan]="plan.basic ?? null"
                  [isSelected]="selectedSupportProduct && selectedSupportProduct.key === paymentPlanSupportEnum.activeService"
                  [isTrial]="isTrialAvailable"
                  [key]="paymentPlanSupportEnum.activeService"
                  class="box"
                ></app-box>
              </div>
            </div>
          </div>
        </div>
      </mat-card-content>
    </ng-template>
  </mat-card>

  <mat-card id="limits" appearance="outlined" *ngIf="!isLoading && (!isNoPermission || isAnonymous) && plan.sembot && showLimits">
    <mat-card-content class="limits-container">
      <div class="plan-content">
        <div class="restrictions">
          <div class="limits">
            <h2>{{ 'payments.plan.limit.title' | translate }}</h2>
            <p class="desc">{{ 'payments.plan.limit.description' | translate }}</p>

            <div *ngFor="let key of sembotOrder" class="slider">
              <ng-container *ngIf="getFormControl(key)">
                <div class="label">{{ 'payments.plan.limit.items.' + key + '.label' | translate }}:</div>

                <div class="value">
                  <mat-slider [max]="sembotConfig[key].max" [min]="sembotConfig[key].min" [step]="sembotConfig[key].step" discrete>
                    <input matSliderThumb [value]="getFormControl(key).value" (valueChange)="getFormControl(key).setValue($event)" />
                  </mat-slider>

                  <div class="field">
                    <mat-icon (click)="subtractOneStep(key)">do_not_disturb_on</mat-icon>
                    <mat-form-field subscriptSizing="dynamic" appearance="outline">
                      <input [formControl]="getFormControl(key)" matInput maxlength="6" />
                      <app-form-field-error-display matError></app-form-field-error-display>
                    </mat-form-field>
                    <mat-icon (click)="addOneStep(key)">add_circle</mat-icon>
                  </div>
                </div>
              </ng-container>
            </div>
            <a class="underlined-btn" (click)="setDefaultLimits()">{{ 'payments.plan.set_defaults' | translate }}</a>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card
    id="css"
    appearance="outlined"
    *ngIf="
      !isLoading && (!isNoPermission || isAnonymous) && selectedCssProduct && getFormControl(paymentPlanCssEnum.whitelabel).value !== 0
    "
  >
    <mat-card-content class="limits-container">
      <div class="plan-content">
        <div class="restrictions">
          <div *ngIf="selectedCssProduct" class="limits">
            <span class="css-title">{{ 'payments.plan.css.title' | translate }}</span>
            <p class="desc">{{ 'payments.plan.css.description' | translate }}</p>
            <div class="tw-flex tw-justify-between tw-mt-4">
              <div class="price">
                <div class="price-description">
                  <p class="!tw-mb-4">
                    {{ 'payments.plan.support.items.free.price_description' | translate }}
                  </p>
                  <span class="price-info"
                    >{{ 'general.from' | translate }}
                    <span class="css-price">{{
                      selectedCssWhitelabelPackage && selectedCssWhitelabelPackage!.price
                        ? selectedCssWhitelabelPackage!.price / 100
                        : getWhitelabelCssProductByValue(1)?.price! / 100
                    }}</span>
                    <span class="currency"
                      >{{ 'payments.plan.currencies.' + plan.currency | translate }}/{{ 'payments.plan.month' | translate }}</span
                    >
                  </span>
                </div>
                <div *ngIf="selectedCssProduct && selectedCssWhitelabelPackage?.setup" class="price-description extra">
                  <p class="!tw-mb-4">
                    {{ 'payments.plan.support.items.active_service.start_fee' | translate }}
                    <mat-icon [matTooltip]="'payments.plan.support.items.active_service.tooltip' | translate">info</mat-icon>
                  </p>
                  <span class="price-info"
                    ><span class="css-price"
                      >+{{
                        selectedCssWhitelabelPackage?.setup?.price
                          ? selectedCssWhitelabelPackage?.setup?.price! / 100
                          : getWhitelabelCssProductByValue(1)?.setup?.price! / 100
                      }}</span
                    >
                    <span class="currency">{{ 'payments.plan.currencies.' + plan.currency | translate }}</span></span
                  >
                </div>
              </div>
            </div>
            <div *ngIf="getFormControl(paymentPlanCssEnum.whitelabel).value !== 0">
              <div class="slider">
                <div *ngIf="getFormControl(paymentPlanCssEnum.whitelabel)">
                  <div class="label">{{ 'payments.plan.css.markets' | translate }}:</div>

                  <div class="value">
                    <mat-slider
                      [max]="cssConfig.max"
                      [min]="cssConfig.min"
                      [step]="cssConfig.step"
                      discrete
                      [displayWith]="formatLabel.bind(this)"
                    >
                      <input
                        matSliderThumb
                        [value]="getFormControl(paymentPlanCssEnum.whitelabel).value"
                        (valueChange)="getFormControl(paymentPlanCssEnum.whitelabel).setValue($event)"
                      />
                    </mat-slider>

                    <div class="field">
                      <mat-icon (click)="subtractOneStep(paymentPlanCssEnum.whitelabel, true)">do_not_disturb_on</mat-icon>
                      <mat-form-field subscriptSizing="dynamic" appearance="outline">
                        <input
                          (input)="valueChange($event)"
                          [formControl]="getFormControl(paymentPlanCssEnum.whitelabel)"
                          [value]="formatLabel(getFormControl(paymentPlanCssEnum.whitelabel).value)"
                          matInput
                        />
                      </mat-form-field>
                      <mat-icon (click)="addOneStep(paymentPlanCssEnum.whitelabel, true)">add_circle</mat-icon>
                    </div>
                  </div>
                </div>
              </div>
              <a class="underlined-btn" (click)="deleteCss()">{{ 'payments.plan.css.delete' | translate }}</a>
            </div>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>

  <div class="summary" *ngIf="!isLoading && (!isNoPermission || isAnonymous)">
    <app-summary (buy)="buy()" *ngIf="price" [isLoadingPlan]="isLoadingPlan" [isTrial]="isTrialAvailable" [price]="price"></app-summary>
  </div>

  <ng-template #loader>
    <div class="spinner-wrapper">
      <mat-spinner [diameter]="40" mode="indeterminate"></mat-spinner>
    </div>
  </ng-template>
</div>
