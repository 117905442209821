/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
/* eslint-disable @typescript-eslint/no-useless-constructor */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, Input, OnInit } from '@angular/core';
import { CurrentPackage, PackageItemModel } from '../../models/payments.model';

@Component({
  selector: 'app-display-package-statistics',
  templateUrl: './display-package-statistics.component.html',
  styleUrls: ['./display-package-statistics.component.scss'],
})
export class DisplayPackageStatisticsComponent implements OnInit {
  @Input() currentPackage!: CurrentPackage;
  @Input() partItems!: PackageItemModel[];
  @Input() mainPackage!: PackageItemModel;
  @Input() withoutAdditionalResources = false;

  constructor() {}

  ngOnInit(): void {}
}
