import { NgModule } from '@angular/core';
import { NgxStripeModule, STRIPE_PUBLISHABLE_KEY } from 'ngx-stripe';
import { PluginService } from 'src/app/shared/service/plugin.service';
import { environment } from '../../../environments/environment';
import { MaterialModule } from '../../shared/material-module/material.module';
import { SharedModule } from '../../shared/shared.module';
import { AddProductsComponent } from './add-products/add-products.component';
import { BuySubscriptionComponent } from './buy-subscription/buy-subscription.component';
import { CardAttachFormComponent } from './buy-subscription/card-attach-form/card-attach-form.component';
import { ConfirmPaymentComponent } from './buy-subscription/confirm-payment/confirm-payment.component';
import { CardReconnectionComponent } from './card-reconnection/card-reconnection.component';
import { CurrentPackageButtonsComponent } from './current-package/current-package-buttons/current-package-buttons.component';
import { CurrentPackageComponent } from './current-package/current-package.component';
import { DiscountsDataComponent } from './current-package/display-package-statistics/discounts-data/discounts-data.component';
import { DisplayPackageStatisticsComponent } from './current-package/display-package-statistics/display-package-statistics.component';
import { DisplayRestrictionPipe } from './current-package/display-package-statistics/part-restrictions/display-restriction.pipe';
import { PartRestrictionsComponent } from './current-package/display-package-statistics/part-restrictions/part-restrictions.component';
import { RemoveProductPopupComponent } from './current-package/display-package-statistics/part-restrictions/remove-product-popup/remove-product-popup.component';
import { SummarizedListComponent } from './current-package/display-package-statistics/summarized-list/summarized-list.component';
import { SummarizedRestrictionsComponent } from './current-package/display-package-statistics/summarized-restrictions/summarized-restrictions.component';
import { GenerateInvoicePopupComponent } from './invoice/generate-invoice-popup/generate-invoice-popup.component';
import { InvoiceDataFormComponent } from './invoice/invoice-data-form/invoice-data-form.component';
import { InvoiceDataComponent } from './invoice/invoice-data/invoice-data.component';
import { InovoiceComponent } from './invoice/invoice.component';
import { PaymentPackageComponent } from './payments-packages/payment-package/payment-package.component';
import { PaymentsComponent } from './payments-packages/payments.component';
import { PaymentsRoutingModule } from './payments-routing.module';
import { BoxComponent } from './plan/box/box.component';
import { PlanComponent } from './plan/plan.component';
import { SummaryComponent } from './plan/summary/summary.component';
import { StripeKeyService } from './stripe-key.service';

export function NgxStripeFactory(pluginService: PluginService): string {
  if (pluginService.isPluginMode) {
    return '';
  }
  return StripeKeyService.stripePublicKey;
}

@NgModule({
  declarations: [
    AddProductsComponent,
    BoxComponent,
    BuySubscriptionComponent,
    CardAttachFormComponent,
    CardReconnectionComponent,
    ConfirmPaymentComponent,
    CurrentPackageButtonsComponent,
    CurrentPackageComponent,
    DiscountsDataComponent,
    DisplayPackageStatisticsComponent,
    DisplayRestrictionPipe,
    GenerateInvoicePopupComponent,
    InovoiceComponent,
    InvoiceDataComponent,
    InvoiceDataFormComponent,
    PartRestrictionsComponent,
    PaymentPackageComponent,
    PaymentsComponent,
    PlanComponent,
    RemoveProductPopupComponent,
    SummarizedListComponent,
    SummarizedRestrictionsComponent,
    SummaryComponent,
  ],
  imports: [PaymentsRoutingModule, SharedModule, MaterialModule, NgxStripeModule, NgxStripeModule.forRoot(environment.stripePublicKey)],
  exports: [DisplayPackageStatisticsComponent, PlanComponent],
  providers: [
    {
      provide: STRIPE_PUBLISHABLE_KEY,
      deps: [PluginService],
      useFactory: NgxStripeFactory,
    },
  ],
})
export class PaymentsModule {}
