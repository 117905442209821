import { ItemModel } from '../../item.model';
import { ActionTypesEnum } from '../ActionTypesEnum';
import { ActionModel } from './ActionModel';

export class RegexpReplaceAction extends ActionModel {
  type = ActionTypesEnum.regexpReplace;

  value: {
    from: string;
    to: string;
  } = {
    from: '',
    to: '',
  };

  isValid(): boolean {
    return !!this.columnName && !!this.value && !!this.value.from;
  }

  use(item: ItemModel) {
    const regex = new RegExp(this.value.from);

    const value = (item[this.columnName as keyof typeof item] as string).replace(regex, this.value.to);
    return value;
  }

  returnForSend() {
    return {
      action: this.type,
      param: this.columnName,
      from: this.value.from,
      to: this.value.to,
      sequence: this.sequence,
    };
  }

  copyValue(item: this, overrideManualChanges: boolean): void {
    this.columnName = item.columnName;
    this.override_manual_changes = overrideManualChanges;
    this.value.from = item.from;
    this.value.to = item.to;
  }
}
