import { map, tap } from 'rxjs/operators';
import ApiUrls from 'src/app/configs/api-urls.config';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StripeKeyService {
  static stripePublicKey = '';
  constructor(public http: HttpClient) {}

  public static get getStripePublicKey() {
    return StripeKeyService.stripePublicKey;
  }

  getClientSecret(): Observable<string> {
    return this.http.get<{ clientSecret: string }>(ApiUrls.paymentsClientSercet).pipe(map((response) => response.clientSecret));
  }

  initPublicKey() {
    return new Promise<boolean>((resolve: (res: boolean) => void) => {
      this.getPublicKey()
        .pipe(tap((res) => (StripeKeyService.stripePublicKey = res)))
        .subscribe(() => resolve(true));
    });
  }

  getPublicKey(): Observable<string> {
    return this.http.get<{ key: string }>(ApiUrls.paymentsStripeKey).pipe(map((res) => res.key));
  }
}
