import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { filter } from 'rxjs/operators';
import { ConfirmModalComponent } from 'src/app/shared/modals/confirm-modal/confirm-modal.component';
import { PluginService } from 'src/app/shared/service/plugin.service';
import { RouterService, routerConfig } from 'src/app/shared/service/router.service';
import { CurrentPackage } from '../../models/payments.model';
import { PaymentsService } from '../../payments.service';

@Component({
  selector: 'app-current-package-buttons',
  templateUrl: './current-package-buttons.component.html',
  styleUrls: ['./current-package-buttons.component.scss'],
})
export class CurrentPackageButtonsComponent {
  @Input() currentPackage!: CurrentPackage;
  @Input() isLoading!: boolean;
  @Output() refreshCurrentPackage: EventEmitter<void> = new EventEmitter();

  get canBeActivated(): boolean {
    return this.currentPackage.isIncomplete || this.currentPackage.isPastDue;
  }

  get canBeCanceled(): boolean {
    return (this.currentPackage.isActive || this.currentPackage.isTrialling) && !this.currentPackage.isCancelled;
  }

  get canBeResumed(): boolean {
    return this.currentPackage.isCancelled && (this.currentPackage.isActive || this.currentPackage.isTrialling);
  }

  constructor(
    private routerService: RouterService,
    public paymentsService: PaymentsService,
    private dialog: MatDialog,
    public pluginService: PluginService,
  ) {}

  activateSubscription() {
    this.routerService.navigate(routerConfig.paymentsConfirm, { paymentId: this.currentPackage.last_payment_id });
  }

  // removeCard() {
  //   this.isLoading = true;
  //   this.paymentsService.removeCard().subscribe(
  //     () => this.refreshCurrentPackage.emit(),
  //     () => this.refreshCurrentPackage.emit(),
  //   );
  // }

  cardReconnection() {
    this.routerService.navigate(routerConfig.cardReconnection);
  }

  cancelSubscription() {
    const config = {
      data: {
        key: 'confirmations.cancel_subscription',
      },
      panelClass: 'no-dialog-spinner',
    } as MatDialogConfig;

    const dialog = this.dialog.open(ConfirmModalComponent, config);

    dialog
      .afterClosed()
      .pipe(filter((res) => !!res))
      .subscribe(() => this.sendCancelSubscription());
  }

  goToStripe() {
    this.currentPackage.customerPortalUrl && (window.location.href = this.currentPackage.customerPortalUrl);
  }

  resumeSubscription() {
    const config = {
      data: {
        key: 'confirmations.resume_subscription',
      },
      panelClass: 'no-dialog-spinner',
    } as MatDialogConfig;

    const dialog = this.dialog.open(ConfirmModalComponent, config);

    dialog
      .afterClosed()
      .pipe(filter((res) => !!res))
      .subscribe(() => this.sendResumeSubscription());
  }

  private sendCancelSubscription() {
    this.isLoading = true;
    this.paymentsService.cancelSubscription().subscribe(
      () => this.refreshCurrentPackage.emit(),
      () => this.refreshCurrentPackage.emit(),
    );
  }

  private sendResumeSubscription() {
    this.isLoading = true;
    this.paymentsService.resumeSubscription().subscribe(
      () => this.refreshCurrentPackage.emit(),
      () => this.refreshCurrentPackage.emit(),
    );
  }
}
