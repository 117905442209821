<mat-card appearance="outlined" *ngIf="currentPackage; else loader">
  <mat-card-header>
    <mat-card-title>
      {{ 'payments.current_package.current_package' | translate }}
    </mat-card-title>

    <div class="right">
      <ng-container *ngIf="currentPackage.isCancelled">
        <mat-icon color="warn" matTooltip="Subscription is cancelled">cancel</mat-icon>
        <div class="active-until">
          <span>{{ 'payments.current_package.active_to' | translate }} </span>
          <span>{{ currentPackage.ends_at | date: 'HH:mm:ss dd-MM-yyyy' }}</span>
        </div>
      </ng-container>

      <mat-icon *ngIf="currentPackage.isIncomplete" color="warn" matTooltip="Subscription is inactive">error_outline</mat-icon>

      <app-current-package-buttons
        [isLoading]="isLoading"
        [currentPackage]="currentPackage"
        *ngIf="currentPackage.items.length > 0"
        (refreshCurrentPackage)="refreshCurrentPackage()"
      ></app-current-package-buttons>
    </div>
  </mat-card-header>

  <mat-card-content>
    <ng-container *ngIf="summarizedList.length; else noItems">
      <app-discounts-data *ngIf="currentPackage.discount?.name" [discount]="currentPackage.discount!"></app-discounts-data>

      <app-summarized-list [items]="summarizedList" style="margin-top: 40px"></app-summarized-list>
    </ng-container>

    <ng-template #noItems>
      <div class="browse-btn-wrapper">
        <button mat-raised-button color="primary" appRouter="paymentsPlan">
          {{ 'payments.current_package.choose_plan' | translate }}
        </button>
      </div>
    </ng-template>
  </mat-card-content>
</mat-card>

<ng-template #loader>
  <div class="spinner-wrapper">
    <mat-spinner mode="indeterminate" [diameter]="40"></mat-spinner>
  </div>
</ng-template>
