<div class="tw-flex tw-gap-4">
  <mat-form-field [formGroup]="form">
    <mat-date-range-input [rangePicker]="picker">
      <input matStartDate [placeholder]="'general.from' | translate" formControlName="from" />
      <input matEndDate [placeholder]="'general.to' | translate" formControlName="to" />
    </mat-date-range-input>
    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>
  </mat-form-field>
  <button [disabled]="!form.valid" mat-raised-button (click)="setHoliday()">{{ 'general.save' | translate }}</button>
</div>
