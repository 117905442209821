import { Component, EventEmitter, OnDestroy, OnInit, Output, inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NotificationService } from 'src/app/notification/notification.service';
import { AuthService } from 'src/app/shared/service/auth.service';
import { FormStatusService } from '../../../form-status.service';
import { ProfileService } from '../../../profile.service';

@Component({
  selector: 'app-change-username',
  templateUrl: './change-username.component.html',
  styleUrls: ['./change-username.component.scss'],
})
export class ChangeUsernameComponent implements OnInit, OnDestroy {
  @Output() closePanel = new EventEmitter<void>();
  isLocked = false;
  form: UntypedFormGroup;
  #destroy$ = new Subject<void>();
  #authService = inject(AuthService);
  #formBuilder = inject(UntypedFormBuilder);
  #notificationService = inject(NotificationService);
  #profileService = inject(ProfileService);
  #formStatusService = inject(FormStatusService);

  constructor() {
    this.form = this.#formBuilder.group({
      username: ['', [Validators.required]],
    });
    this.form.valueChanges.pipe(takeUntil(this.#destroy$)).subscribe(() => {
      this.#formStatusService.isAnyFormDirty = this.form.dirty;
    });
  }

  get username() {
    return this.form.get('username') as UntypedFormControl;
  }

  ngOnInit() {
    this.username.setValue(this.#authService.authUser!.name);
  }

  ngOnDestroy() {
    this.#destroy$.next();
    this.#destroy$.complete();
  }

  protected saveChangeUsername() {
    if (this.form.valid) {
      this.isLocked = true;
      this.#profileService.editProfile(this.username.value).subscribe(
        (response) => {
          this.closePanel.emit();
          this.#notificationService.success('edit_profile');
          this.#authService.authUser$.next(response.user);
          this.isLocked = false;
          this.#formStatusService.notifySaveChanges();
        },
        () => {
          this.#notificationService.error('edit_profile');
          this.isLocked = false;
        },
      );
    }
  }
}
