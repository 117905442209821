import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { filter, tap } from 'rxjs/operators';
import { PluginService } from 'src/app/shared/service/plugin.service';
import { RouterService, routerConfig } from 'src/app/shared/service/router.service';
import { ConfirmModalComponent } from '../../../../shared/modals/confirm-modal/confirm-modal.component';
import { CurrentPackage, PaymentModel } from '../../models/payments.model';
import { PaymentsService } from '../../payments.service';

@Component({
  selector: 'app-payment-package',
  templateUrl: './payment-package.component.html',
  styleUrls: ['./payment-package.component.scss'],
})
export class PaymentPackageComponent {
  packageIsBought!: boolean;
  currentPackageIsInProgress!: boolean;
  currentPackage!: CurrentPackage;
  @Output() packageChoosen = new EventEmitter<void>();
  @Input() buyingInProgress!: boolean;
  @Input() package!: PaymentModel;
  @Input() paymentElements: string[] = [];
  @Input() set current(current: CurrentPackage) {
    this.currentPackage = current;
    this.packageIsBought = current.name === this.package.package_name || (!current.name && this.package.price === 0);
    this.currentPackageIsInProgress = current.isActive || current.isTrialling;
  }

  constructor(
    public routerService: RouterService,
    private dialog: MatDialog,
    private pluginService: PluginService,
    private paymentsService: PaymentsService,
  ) {}

  selectPackage(): void {
    if (this.pluginService.isPluginMode) {
      this.shopifyPayment();
      return;
    }
    this.routerService.navigate(routerConfig.paymentsBuy, { package: this.package.id });
  }

  changePackage(): void {
    const config = {
      data: {
        key: 'confirmations.change_package',
        params: { current_package: this.currentPackage.name.toUpperCase(), selected_package: this.package.package_name.toUpperCase() },
      },
      panelClass: 'no-dialog-spinner',
    } as MatDialogConfig;

    const dialog = this.dialog.open(ConfirmModalComponent, config);
    dialog
      .afterClosed()
      .pipe(filter((res) => !!res))
      .subscribe(() => {
        if (this.pluginService.isPluginMode) {
          this.shopifyPayment();
          return;
        }
        this.packageChoosen.emit();
      });
  }

  shopifyPayment() {
    this.buyingInProgress = true;
    this.pluginService
      .buyPackageShopify(this.package.id)
      .pipe(tap((res) => (res && res.confirmation_url ? window.open(res.confirmation_url, '_parent') : this.refreshCurrentPackage())))
      .subscribe();
  }

  private refreshCurrentPackage(): void {
    this.paymentsService.getCurrentPackage().subscribe(() => this.routerService.navigate(routerConfig.payments));
  }
}
