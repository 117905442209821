<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <mat-form-field>
    <input matInput name="api_key" [placeholder]="'connections.form.fields.api_key.placeholder' | translate" [formControl]="apiKey" />
    <app-form-field-error-display matError></app-form-field-error-display>
  </mat-form-field>

  <mat-form-field>
    <input
      matInput
      name="api_access_token"
      [placeholder]="'connections.form.fields.api_access_token.placeholder' | translate"
      [formControl]="apiAccessToken"
    />
    <app-form-field-error-display matError></app-form-field-error-display>
  </mat-form-field>

  <mat-form-field>
    <input
      matInput
      name="store_domain"
      [placeholder]="'connections.form.fields.store_domain.placeholder' | translate"
      [formControl]="storeDomain"
    />
    <app-form-field-error-display matError></app-form-field-error-display>
  </mat-form-field>

  <button mat-raised-button [disabled]="isFormLocked" color="primary" type="submit" class="connect-btn">
    {{ 'connections.form.connect_btn_txt' | translate }}
  </button>
</form>
