import { Component } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-go-back-arrow',
  templateUrl: './go-back-arrow.component.html',
  styleUrls: ['./go-back-arrow.component.scss'],
})
export class GoBackArrowComponent {
  constructor(private location: Location) {}

  back() {
    this.location.back();
  }
}
