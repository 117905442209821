<form class="form" (ngSubmit)="submit()" [formGroup]="stripeForm">
  <mat-form-field>
    <label for="name" class="required">
      {{ 'payments.buy.name' | translate }}
    </label>
    <input matInput type="text" [formControl]="nameCtrl" required #name cdkFocusInitial />
    <app-form-field-error-display matError></app-form-field-error-display>
  </mat-form-field>

  <ngx-stripe-card [options]="stripeConfig.cardElementsOptions" [elementsOptions]="stripeConfig.elementsOptions" #card></ngx-stripe-card>

  <mat-slide-toggle [formControl]="isCouponVisible" (change)="changeCouponVisible()">
    {{ 'payments.buy.have_coupon' | translate }}
  </mat-slide-toggle>

  <mat-form-field *ngIf="isCouponVisible.value">
    <mat-label>
      {{ 'payments.buy.coupon' | translate }}
    </mat-label>
    <input matInput [formControl]="couponCtrl" cdkFocusInitial (keyup)="keyupCoupon()" />
    <button *ngIf="!isCurrentCouponChecked" mat-button matSuffix type="button" (click)="validateCoupon()">
      {{ 'general.apply' | translate }}
    </button>
    <app-form-field-error-display matError></app-form-field-error-display>
  </mat-form-field>

  <form class="invoice" *ngIf="invoiceAvailable">
    <mat-slide-toggle [formControl]="needInvoiceCtrl">
      {{ 'payments.buy.invoice' | translate }}
    </mat-slide-toggle>

    <div class="form-container" *ngIf="needInvoiceCtrl.value">
      <span class="form-header">{{ 'payments.buy.invoice_data' | translate }}</span>
      <app-invoice-data-form (formCreated)="watchForInvoice($event)" #invoiceForm></app-invoice-data-form>
    </div>
  </form>
  <div class="btns-container">
    <app-button
      [disabled]="submitDisabled"
      [loading]="isLoading"
      [text]="submitTranslationText ? (submitTranslationText | translate) : ('payments.buy.subscribe' | translate)"
      color="primary"
      type="submit"
    ></app-button>
  </div>
</form>
