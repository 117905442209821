import { DataTableColumnTypesEnum } from '../enums/data-table.enum';
import { FilterGroupModel } from '../sem-table/filters/models/filter.model';

const PAGE_DEFAULT_VALUES = {
  length: 0,
  pageIndex: 0,
  pageSize: 10,
  previousPageIndex: null!,
};

interface DataTableColumnsInterface {
  field?: string;
  header?: string;
  header_key?: string;
  type?: DataTableColumnTypesEnum;
}

export interface DataTablePageInterface {
  length: number;
  pageIndex: number;
  pageSize: number;
  previousPageIndex?: number;
}

export class DataTableStateModel {
  columns?: DataTableColumnsInterface[];
  filterGroups?: FilterGroupModel[];
  page?: DataTablePageInterface = PAGE_DEFAULT_VALUES;
  searchFields?: string[];
}

export interface AdditionalFiltersInterface {
  only_current_project?: boolean;
  only_my_account?: boolean;
  parent_id?: number | null;
  section_id?: number;
}
