import { Component, Inject, OnInit } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-tutorial-info-box',
  templateUrl: './tutorial-info-box.component.html',
  styleUrls: ['./tutorial-info-box.component.scss'],
})
export class TutorialInfoBoxComponent implements OnInit {
  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public text: string) {}

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit(): void {}
}
