<mat-card appearance="outlined">
  <mat-card-header>
    <mat-card-title>
      {{ 'payments.invoices.title' | translate }}
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div *ngIf="!isLoading; else loader" class="container">
      <div class="invoices-wrapper" *ngIf="invoices?.length! > 0; else noItems">
        <div class="invoice" *ngFor="let invoice of invoices; index as id">
          <div>{{ id + 1 }}.</div>
          <div class="invoice-data">
            <div>{{ invoice.date }}</div>
            <div>{{ invoice.total }}</div>
            <button mat-icon-button (click)="download(invoice.pdf)">
              <mat-icon>download</mat-icon>
            </button>
          </div>
        </div>
      </div>
      <app-invoice-data [preparedData]="preparedInvoiceData" [data]="invoiceData" *ngIf="invoices?.length! > 0"></app-invoice-data>
    </div>
  </mat-card-content>
</mat-card>

<ng-template #loader>
  <div class="spinner-wrapper">
    <mat-spinner mode="indeterminate" [diameter]="40"></mat-spinner>
  </div>
</ng-template>

<ng-template #noItems>
  <div class="no-item-wrapper">
    <div>
      {{ 'payments.invoices.no_invoices' | translate }}
    </div>
  </div>
</ng-template>
