import { Directive, ElementRef, OnInit } from '@angular/core';
import { WhiteLabelService } from '../service/whitelabel.service';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[appWhiteLabelRestrictions]',
})
export class WhiteLabelDirective implements OnInit {
  constructor(
    private elementRef: ElementRef,
    private whiteLabelService: WhiteLabelService,
  ) {}

  ngOnInit() {
    if (this.whiteLabelService.isItWhiteLabel) {
      const el: HTMLElement = this.elementRef.nativeElement;
      el && el.parentNode!.removeChild(el);
    }
  }
}
