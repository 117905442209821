import { ActionTypesEnum } from '../../../shared/sem-table/action/ActionTypesEnum';
import { FilterTypesEnum } from '../../../shared/sem-table/filters/FilterTypesEnum';

export interface ProductAdditionalField {
  name: string;
  value: string;
  can_override?: boolean;
}

export const PRODUCT_ADDITIONAL_ATTR_PREFIX = 'product_additional_attributes.';

export const actionTypesForAdditionalsField: ActionTypesEnum[] = [
  ActionTypesEnum.addStart,
  ActionTypesEnum.addEnd,
  ActionTypesEnum.override,
  ActionTypesEnum.upper,
  ActionTypesEnum.lower,
  ActionTypesEnum.caseChange,
  // ActionTypesEnum.setEmpty,
];

export const filterTypesForAdditionalsField = [
  FilterTypesEnum.is_empty,
  FilterTypesEnum.not_empty,
  FilterTypesEnum.equal,
  FilterTypesEnum.num_bigger,
  FilterTypesEnum.num_biggerrEqual,
  FilterTypesEnum.num_equal,
  FilterTypesEnum.num_smaller,
  FilterTypesEnum.num_smallerEqual,
];
