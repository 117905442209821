import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { StyleService } from './styles.service';

type BrandStyle = { input: string; bundleName: string; inject: boolean };

@Injectable({
  providedIn: 'root',
})
export class WhiteLabelService {
  isItWhiteLabel = false;

  constructor(
    private styleService: StyleService,
    private titleService: Title,
  ) {}

  // disable() {
  //   this.isItWhiteLabel = false;
  // }

  enable(brand: BrandStyle) {
    this.isItWhiteLabel = true;
    this.styleService.setStyle('theme', `${brand.bundleName}.css`);
    this.titleService.setTitle(brand.bundleName);
    const favIcon = document.querySelector<HTMLLinkElement>('#favIcon');
    favIcon && (favIcon.href = `favicons/${brand.bundleName}.png`); // Change favicon
  }
}
