import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RouterService } from '../../service/router.service';

@Component({
  selector: 'app-info-dialog',
  templateUrl: './info-dialog.component.html',
})
export class InfoDialogComponent {
  constructor(
    private routerService: RouterService,
    public dialogRef: MatDialogRef<InfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  onClick(route: any | null = null) {
    if (route) {
      route.url ? this.routerService.navigate(route) : window.open(route, '_blank');
    } else {
      this.dialogRef.close();
    }
  }
}
