import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { tap } from 'rxjs/operators';
import { NotificationService } from 'src/app/notification/notification.service';
import { ProjectService } from '../../../dashboard/project/project.service';
import { AuthService } from '../../service/auth.service';
import { BingService } from '../../service/bing.service';
import { CountriesService } from '../../service/countries.service';

@Component({
  selector: 'app-bing-account-form',
  templateUrl: './bing-account-form.component.html',
  styleUrls: ['./bing-account-form.component.scss'],
})
export class BingAccountFormComponent implements OnInit {
  locked!: boolean;
  form = new UntypedFormGroup({
    accountName: new UntypedFormControl(this.projectService.activeProject$.getValue()!.name, Validators.required),
    addressLine1: new UntypedFormControl('', Validators.required),
    addressLine2: new UntypedFormControl(null),
    addressLine3: new UntypedFormControl(null),
    addressLine4: new UntypedFormControl(null),
    businessName: new UntypedFormControl('', Validators.required),
    city: new UntypedFormControl('', Validators.required),
    countryCode: new UntypedFormControl(null, Validators.required),
    currencyCode: new UntypedFormControl(null, Validators.required),
    customerName: new UntypedFormControl(this.projectService.activeProject$.getValue()!.name, Validators.required),
    email: new UntypedFormControl(this.authService.authUser!.email, [Validators.required, Validators.email]),
    industry: new UntypedFormControl(null, Validators.required),
    language: new UntypedFormControl(this.projectService.activeProject$.getValue()!.language_name, Validators.required),
    manageAds: new UntypedFormControl(false),
    name: new UntypedFormControl(null, Validators.required),
    postalCode: new UntypedFormControl('', [Validators.required]),
    stateOrProvince: new UntypedFormControl(null, Validators.required),
    surname: new UntypedFormControl(null, Validators.required),
    tagConversionSettings: new UntypedFormControl(false),
  });

  get businessNameCtrl() {
    return this.form.get('businessName') as UntypedFormControl;
  }

  get cityCtrl() {
    return this.form.get('city') as UntypedFormControl;
  }

  get countryCodeCtrl() {
    return this.form.get('countryCode') as UntypedFormControl;
  }

  get postalCodeCtrl() {
    return this.form.get('postalCode') as UntypedFormControl;
  }

  get addressLine1Ctrl() {
    return this.form.get('addressLine1') as UntypedFormControl;
  }

  get addressLine2Ctrl() {
    return this.form.get('addressLine2') as UntypedFormControl;
  }

  get addressLine3Ctrl() {
    return this.form.get('addressLine3') as UntypedFormControl;
  }

  get addressLine4Ctrl() {
    return this.form.get('addressLine4') as UntypedFormControl;
  }

  get stateOrProvinceCtrl() {
    return this.form.get('stateOrProvince') as UntypedFormControl;
  }

  get accountNameCtrl() {
    return this.form.get('accountName') as UntypedFormControl;
  }

  get languageCtrl() {
    return this.form.get('language') as UntypedFormControl;
  }

  get currencyCodeCtrl() {
    return this.form.get('currencyCode') as UntypedFormControl;
  }

  get customerNameCtrl() {
    return this.form.get('customerName') as UntypedFormControl;
  }

  get industryCtrl() {
    return this.form.get('industry') as UntypedFormControl;
  }

  get emailCtrl() {
    return this.form.get('email') as UntypedFormControl;
  }

  get nameCtrl() {
    return this.form.get('name') as UntypedFormControl;
  }

  get surnameCtrl() {
    return this.form.get('surname') as UntypedFormControl;
  }

  get tagConversionSettingsCtrl() {
    return this.form.get('tagConversionSettings') as UntypedFormControl;
  }

  get manageAdsCtrl() {
    return this.form.get('manageAds') as UntypedFormControl;
  }

  industries!: string[];
  languages!: string[];
  currencies!: string[];
  countryCodes: any;
  @Output() saved: EventEmitter<void> = new EventEmitter();

  constructor(
    private bingService: BingService,
    private projectService: ProjectService,
    private countryService: CountriesService,
    private authService: AuthService,
    private notificationService: NotificationService,
  ) {}

  ngOnInit(): void {
    this.bingService.currencies().subscribe((r) => (this.currencies = r.data));
    this.bingService.languages().subscribe((r) => (this.languages = r.data));
    this.bingService.industries().subscribe((r) => (this.industries = r.data));
    this.countryService.getCountries().subscribe((r) => (this.countryCodes = r.map((d) => d.id)));
  }

  onSubmit() {
    if (this.form.invalid) {
      return;
    }

    this.bingService
      .createAccount(this.form.value, this.projectService.activeProject$.getValue()!.id)
      .pipe(
        tap(
          () => this.notificationService.success('bing_account_create'),
          () => this.notificationService.error('bing_account_create'),
        ),
      )
      .subscribe(() => this.saved.emit());
  }
}
