import { Component, Input, OnInit } from '@angular/core';
import { UrlVariablesType } from '../../model/helpers.model';
import { RouteInterface } from '../../model/router-config.model';
import { NavigateParams, RouterService } from '../../service/router.service';

@Component({
  selector: 'app-page-wrapper',
  templateUrl: './page-wrapper.component.html',
  styleUrls: ['./page-wrapper.component.scss'],
})
export class PageWrapperComponent implements OnInit {
  @Input() isBeta = false;
  @Input() subtitle!: string;
  @Input() title!: string;
  @Input() backButton: {
    route: RouteInterface;
    urlVariables?: UrlVariablesType;
    params?: NavigateParams;
  };

  constructor(private routerService: RouterService) {
    this.backButton = { route: null! };
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit(): void {}

  onBackClick() {
    const { route, urlVariables = null, params = new NavigateParams() } = this.backButton;
    route && this.routerService.navigate(route, urlVariables!, params);
  }
}
