import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject, combineLatest, defer } from 'rxjs';
import { debounceTime, map, startWith, takeUntil, tap } from 'rxjs/operators';
import { ProjectService } from 'src/app/dashboard/project/project.service';
import { TutorialService } from 'src/app/dashboard/tutorial/tutorial.service';
import { ConnectionsServiceEnum } from 'src/app/shared/enums/connections-service.enum';
import { FormApiValidationError } from 'src/app/shared/model/errors/formApiError.model';
import { ConnectionsService } from 'src/app/shared/service/connections.service';
import { ConnectionModel } from '../../../../../shared/model/connections.model';
import { AdwordsCustomer } from '../../connections.model';

const DEFAULT_MAX_ITEMS_LENGTH = 100;

@Component({
  selector: 'app-adwords-service',
  templateUrl: './adwords-service.component.html',
  styleUrls: ['./adwords-service.component.scss'],
})
export class AdwordsServiceComponent implements OnInit, OnDestroy {
  @Input() connections!: ConnectionModel[];
  @Output() connectionAttached = new EventEmitter<boolean>();

  customersList: AdwordsCustomer[] | null = null;
  filteredCustomersList: AdwordsCustomer[] = [];
  filteredInnerCustomersList: AdwordsCustomer[] = [];
  readonly form = new FormGroup({
    connection: new FormControl<ConnectionModel>(null!, [Validators.required]),
    customer: new FormControl<AdwordsCustomer>(null!, [Validators.required]),
    innerCustomer: new FormControl<AdwordsCustomer>(null!),
  });

  readonly customerFilter = new FormControl('');
  readonly innerCustomersFilter = new FormControl('');
  isLoading = false;
  searching = false;
  service = ConnectionsServiceEnum.Google;

  private onDestroy$ = new Subject<void>();

  filteredInnerCustomersList$ = defer(() =>
    combineLatest([
      this.form.controls.customer.valueChanges.pipe(
        startWith(this.form.controls.customer.value),
        map((x) => Array.from(this.getNonManagerCustomers(x?.accounts ?? []))),
      ),
      this.innerCustomersFilter.valueChanges.pipe(
        debounceTime(200),
        map(() => this.innerCustomersFilter),
        startWith(this.innerCustomersFilter),
      ),
    ]).pipe(
      map(([customers, filter]) => this.selectFilter(customers, filter, this.customersFilterFunction).slice(0, DEFAULT_MAX_ITEMS_LENGTH)),
    ),
  );

  get connection() {
    return this.form.controls.connection;
  }

  get customer() {
    return this.form.controls.customer;
  }

  get innerCustomer() {
    return this.form.controls.innerCustomer;
  }

  customersFilterFunction = (search: string | null) => {
    return ({ name, id }: AdwordsCustomer) => name?.toLowerCase()?.includes(search!) || id?.toString()?.toLowerCase()?.includes(search!);
  };

  constructor(
    public connnectionsService: ConnectionsService,
    public projectService: ProjectService,
    public tutorialService: TutorialService,
  ) {}

  ngOnInit(): void {
    this.customer.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(() => this.innerCustomer.setValue(null!));
    this.customerFilter.valueChanges.pipe(takeUntil(this.onDestroy$), debounceTime(400)).subscribe(() => this.filterCustomers());
    this.connection.setValue(this.connections[0]);
  }

  changeConnection() {
    this.customersList = [];
    this.getCustomersData();
  }

  getCustomersData() {
    this.searching = true;
    this.connnectionsService
      .getAdwordsCustomer(this.connection.value!.id, this.customerFilter.value!)
      .pipe(
        takeUntil(this.onDestroy$),
        tap(() => (this.searching = false)),
        tap((res) => (this.customersList = res.data)),
        tap((res) => (this.filteredCustomersList = res.data)),
      )
      .subscribe();
  }

  submit() {
    this.isLoading = true;
    this.connnectionsService
      .attachAdwordsToProject(
        this.projectService.activeProject$.getValue()!.id,
        this.customer.value!.id,
        this.innerCustomer.value?.name ?? this.customer.value!.name,
        this.innerCustomer.value?.id ?? this.customer.value!.id,
        this.connection.value!.id,
      )
      .pipe(
        tap(() => {
          this.form.reset();
          this.form.markAsUntouched();
          this.isLoading = false;
        }),
        takeUntil(this.onDestroy$),
      )
      .subscribe(
        () => this.connectionAttached.emit(true),
        (er: FormApiValidationError) => {
          er.setOnForm && er.setOnForm(this.form);
          this.connectionAttached.emit(false);
        },
      );
  }

  protected selectFilter<T>(
    arraytoFilter: T[],
    formControl: FormControl<string | null>,
    filterFun: (search: string) => (any: any) => boolean,
  ) {
    let result: T[] = [];
    if (arraytoFilter) {
      let search = formControl.value;
      if (!search) {
        result = arraytoFilter.slice();
      }
      search = search!.toLowerCase();
      result = arraytoFilter.filter(filterFun(search));
    }
    return result;
  }

  protected filterCustomers() {
    this.filteredCustomersList = this.selectFilter(this.customersList!, this.customerFilter, this.customersFilterFunction);
  }

  private *getNonManagerCustomers(customers: AdwordsCustomer[]): Generator<AdwordsCustomer> {
    for (const customer of customers) {
      if (!customer.manager) {
        yield customer;
      } else {
        yield* this.getNonManagerCustomers(customer.accounts ?? []);
      }
    }
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }
}
