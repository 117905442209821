export interface BadgeInterface {
  isOutlined: boolean;
  translateKey?: string;
  type: BadgeTypeEnum;
}

export enum BadgeTypeEnum {
  danger = 'danger',
  dark = 'dark',
  info = 'info',
  light = 'light',
  primary = 'primary',
  secondary = 'secondary',
  success = 'success',
  warning = 'warning',

  veryHigh = 'very_high',
  high = 'high',
  medium = 'medium',
  low = 'low',
  veryLow = 'very_low',
}

export type SetType = 'active-status' | 'task-job-status' | 'task-priority' | 'task-status' | 'seo-checks';

export type SetsConfig = {
  [key in SetType]: { [key: string]: BadgeInterface };
};
