import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable()
export class FormStatusService {
  saveChanges$ = new Subject<void>();
  #isAnyFormDirty = new BehaviorSubject<boolean>(false);

  set isAnyFormDirty(value: boolean) {
    this.#isAnyFormDirty.next(value);
  }

  get isAnyFormDirty$() {
    return this.#isAnyFormDirty.asObservable();
  }

  notifySaveChanges() {
    this.saveChanges$.next();
  }
}
