import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NotificationService } from 'src/app/notification/notification.service';
import { ConnectionsService } from 'src/app/shared/service/connections.service';

@Component({
  selector: 'app-connection-form-shopify',
  templateUrl: './connection-form-shopify.component.html',
  styleUrls: ['./connection-form-shopify.component.scss'],
})
export class ConnectionFormShopifyComponent implements OnInit {
  @Output() connected = new EventEmitter<void>();

  form!: UntypedFormGroup;

  get apiKey() {
    return this.form.get('api_key') as UntypedFormControl;
  }

  get apiAccessToken() {
    return this.form.get('api_access_token') as UntypedFormControl;
  }

  get isFormLocked() {
    return this.form.invalid;
  }

  get storeDomain() {
    return this.form.get('store_domain') as UntypedFormControl;
  }

  constructor(
    private connectionsService: ConnectionsService,
    private formBuilder: UntypedFormBuilder,
    private notificationService: NotificationService,
  ) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      api_key: ['', [Validators.required]],
      api_access_token: ['', [Validators.required]],
      store_domain: ['', [Validators.required]],
    });
  }

  onSubmit() {
    if (this.form.valid) {
      this.connectionsService.shopifyConnect(this.apiKey.value, this.apiAccessToken.value, this.storeDomain.value).subscribe(() => {
        this.notificationService.success('add_connection');
        this.connected.emit();
      });
    }
  }
}
