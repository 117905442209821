<div
  *appSpinner="!services"
  class="services-cards restricted-content"
  appTutorialElement="tutorial-connection-providers"
  [activatedAction$]="tutorialService.activatedAction$"
>
  <mat-card appearance="outlined" *ngFor="let service of services">
    <mat-card-header>
      <mat-card-title>
        <mat-icon *ngIf="service.slug && svgIcon(service.slug)" [svgIcon]="svgIcon(service.slug)"></mat-icon>
        {{ service | connectionServiceLabel }} <app-beta-label *ngIf="service._isBeta"></app-beta-label
      ></mat-card-title>
    </mat-card-header>

    <mat-card-content>
      <button mat-button (click)="changeService(service)">
        <mat-icon>link</mat-icon> {{ 'projects.connections.form.connect_btn_txt' | translate }}
      </button>

      <app-connection-new-services-account [service]="service"></app-connection-new-services-account>
    </mat-card-content>
  </mat-card>
</div>
