<app-dialog [dialogRef]="dialogRef">
  <h2>
    {{ 'components.product_ai_modal.title' | translate }} (product ID: {{ item.id }}) <app-beta-label [isStatic]="true"></app-beta-label>
  </h2>
</app-dialog>

<ng-container>
  <mat-dialog-content>
    <form [formGroup]="form" (ngSubmit)="submit()">
      <mat-tab-group [selectedIndex]="selectedTabIndex" (selectedTabChange)="tabChanged($event.index)">
        <mat-tab *ngFor="let column of columns; let i = index" [label]="column.alias | titlecase">
          <div class="tab-content">
            <mat-form-field appearance="outline">
              <mat-label>
                {{ 'components.product_ai_modal.form.fields.text.label' | translate }}
              </mat-label>
              <textarea matInput rows="12" [formControl]="formControl(column._key)"></textarea>
              <app-form-field-error-display matError></app-form-field-error-display>
            </mat-form-field>

            <mat-spinner *ngIf="isLoading" class="loader" diameter="50"></mat-spinner>
          </div>
        </mat-tab>
      </mat-tab-group>
    </form>

    <button color="primary" (click)="generate()" [disabled]="isLoading" cdkFocusInitial mat-raised-button type="submit">
      {{ 'components.product_ai_modal.generate_new' | translate }} {{ columns[selectedTabIndex].alias }}
    </button>

    <div class="options">
      <span *ngIf="$any(previouslyGeneratedTexts)[currentColumnName]" (click)="undo()"
        ><mat-icon>undo</mat-icon>{{ 'components.product_ai_modal.undo' | translate }}</span
      >
      <span *ngIf="valueChanged" (click)="setDefault()"
        ><mat-icon>autorenew</mat-icon>{{ 'components.product_ai_modal.set_default' | translate }}</span
      >
    </div>
  </mat-dialog-content>

  <mat-dialog-actions cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
    <button
      (click)="submit()"
      [disabled]="form.invalid || isLoading"
      cdkFocusInitial
      class="submit"
      color="primary"
      mat-raised-button
      type="submit"
    >
      {{ 'general.apply' | translate }}
    </button>
  </mat-dialog-actions>
</ng-container>
