import { Pipe, PipeTransform, inject } from '@angular/core';
import { AuthService } from '../service/auth.service';

@Pipe({
  name: 'generateImagePatch',
})
export class GenerateImagePatchPipe implements PipeTransform {
  readonly authService = inject(AuthService);

  transform(path: string): string {
    return `${path}?token=${encodeURIComponent(this.authService.token)}`;
  }
}
