import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-info-box',
  templateUrl: './info-box.component.html',
  styleUrls: ['./info-box.component.scss'],
})
export class InfoBoxComponent {
  @Input() type!: string;
  @Input() customClass!: string;

  constructor() {
    this.type = this.type ? this.type : 'default';
    this.customClass = this.customClass ? this.customClass : '';
  }
}
