/* eslint-disable @typescript-eslint/no-redeclare */
import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import createApp, { AppBridgeState, ClientApplication } from '@shopify/app-bridge';
import { AppLink, History, NavigationMenu, Redirect } from '@shopify/app-bridge/actions';
import { Observable } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { ProfileService } from 'src/app/dashboard/profile/profile.service';
import { ProjectModel } from 'src/app/dashboard/project/project.model';
import { EnvironmentPluginEnum } from 'src/environments/env.model';
import { environment } from 'src/environments/environment';
import ApiUrls from '../../configs/api-urls.config';
import { ProfileModel } from '../model/profile.model';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class PluginService {
  pluginMode: EnvironmentPluginEnum | null = environment.pluginMode;
  shopifySercret: string = environment.shopifyPublic;
  testMode = false;

  private app!: ClientApplication<AppBridgeState>;
  private appNavigation!: NavigationMenu.NavigationMenu;
  private appNavigationLinks!: AppLink.AppLink[];
  private shopifyBrowserHistory!: History.History;
  private shopifyRedirect!: Redirect.Redirect;

  get isPluginMode(): boolean {
    return !!this.pluginMode;
  }

  constructor(
    private router: Router,
    private authService: AuthService,
    private location: Location,
    private http: HttpClient,
    private profileService: ProfileService,
  ) {}

  prepareAppBridge() {
    if (this.testMode) {
      return;
    }
    const url = new URL(document.location.href);
    const host = url.searchParams.get('host');
    if (!host) {
      // eslint-disable-next-line no-console
      console.warn('host not provided');
      return;
    }
    console.log('prepareAppBridge');

    this.app = createApp({ apiKey: this.shopifySercret, host, forceRedirect: true });
    this.shopifyRedirect = Redirect.create(this.app);
    this.shopifyBrowserHistory = History.create(this.app);
  }

  redirectToHomeInShopify() {
    this.shopifyRedirect.dispatch(Redirect.Action.APP, '/front/dashboard');
  }

  // method used to login after app init
  getAuthTokenForShopify(): Observable<ProfileModel> {
    const url = new URL(window.location.href);
    const parameters = Array.from(url.searchParams.entries()).reduce(
      (previous, [key, value]) => {
        previous[key] = value;
        return previous;
      },
      {} as Record<string, string>,
    );
    const { host, shop, code } = parameters;

    const request = code
      ? this.http.post<{ bearer_token: string }>(ApiUrls.shopifyAuth, parameters).pipe(
          catchError(() => {
            return this.http.post<{ bearer_token: string }>(ApiUrls.shopifyLogin, {
              shop,
              host,
            });
          }),
        )
      : this.http.post<{ bearer_token: string }>(ApiUrls.shopifyLogin, {
          shop,
          host,
        });

    return request.pipe(
      map((res) => res.bearer_token),
      tap((token) => this.authService.saveToken(token)),
      switchMap(() => this.profileService.profile()),
      tap((profile) => this.navigationCreate(profile.settings.activeProject)),
    );
  }

  // method used after navigation change in app
  addToHistory(path: string) {
    if (this.testMode || !this.app || !this.appNavigationLinks) {
      return;
    }
    const active = this.appNavigationLinks.find((item) => item.destination === `/front${path}`);
    if (active) {
      this.appNavigation.set({ active });
    }

    this.shopifyBrowserHistory.dispatch(History.Action.PUSH, `/front${path}`);
  }

  // method used after get projectId
  private navigationCreate(project: ProjectModel) {
    if (this.testMode || !project) {
      return;
    }
    this.appNavigationLinks = this.prepareLinksForShopify(project.id);
    const path = this.location.path().split('?')[0];
    const active = this.appNavigationLinks.find((item) => item.destination === `/front${path}`) || this.appNavigationLinks[0];

    this.appNavigation = NavigationMenu.create(this.app, {
      items: this.appNavigationLinks,
      active,
    });
    this.listenToRedirect();
  }

  private prepareLinksForShopify(projectId: number) {
    const prefix = '/front';
    const home = AppLink.create(this.app, {
      label: 'Homepage',
      destination: `${prefix}/dashboard/home`,
    });
    const products = AppLink.create(this.app, {
      label: 'Products',
      destination: `${prefix}/dashboard/project/${projectId}/products`,
    });
    const outputFeed = AppLink.create(this.app, {
      label: 'Output feeds',
      destination: `${prefix}/dashboard/project/${projectId}/feed`,
    });
    const rules = AppLink.create(this.app, {
      label: 'Rules',
      destination: `${prefix}/dashboard/project/${projectId}/rules`,
    });
    const connections = AppLink.create(this.app, {
      label: 'Connections',
      destination: `${prefix}/dashboard/project/${projectId}/connections`,
    });
    const payments = AppLink.create(this.app, {
      label: 'Payments',
      destination: `${prefix}/dashboard/payments`,
    });
    return [home, products, rules, connections, outputFeed, payments];
  }

  // method used to listen for shopify menu click event
  private listenToRedirect() {
    if (this.testMode) {
      return;
    }

    this.app.subscribe(Redirect.Action.APP, (redirectData) => {
      const urlWithoutPrefix = redirectData.path.replace('/front', '');
      this.router.navigate([urlWithoutPrefix]);
    });
  }

  buyPackageShopify(packageId: number): Observable<{ confirmation_url: string }> {
    return this.http.post<{ confirmation_url: string }>(ApiUrls.paymentsSunscriptionShopifyBuy, { package_pricing_id: packageId });
  }

  sendAfterPackageShopify(packageId: number, chargeId: number, userId: number): Observable<{ confirmation_url: string }> {
    return this.http.post<{ confirmation_url: string }>(ApiUrls.paymentsAfterSubscriptionShopify, {
      package_pricing_id: packageId,
      user_id: userId,
      charge_id: chargeId,
    });
  }
}
