import { Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2 } from '@angular/core';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { ClickSemTablePopupInnerAction } from './models/actions/ClickSemTablePopupInnerAction';
import { InnerAction } from './models/actions/InnerAction';
import { TutorialInfoBoxComponent } from './tutorial-info-box/tutorial-info-box.component';

const PULSE_CLASS = 'pulse-tutorial';
@Directive({
  selector: '[appTutorialElement]',
})
export class TutorialElementDirective implements OnInit, OnDestroy {
  @Input('appTutorialElement') actionId!: string;
  @Input() activatedAction$!: Observable<InnerAction>;
  @Output() activated: EventEmitter<void> = new EventEmitter();
  private onDestroy$: Subject<void> = new Subject();
  private listenerFn!: Function;
  private bottomSheetRef!: MatBottomSheetRef;
  private actualActionId: string | null = null;

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private bottomSheet?: MatBottomSheet,
  ) {}

  ngOnInit(): void {
    this.actionId &&
      this.activatedAction$
        .pipe(
          takeUntil(this.onDestroy$),
          tap((action) => (this.actualActionId = action && action.idToHighlight!)),
        )
        .subscribe((action) => this.checkIfElementIsPartOfCurrentTutorial(action));
  }

  checkIfElementIsPartOfCurrentTutorial(action: InnerAction) {
    if (!action) {
      this.unhighlight();
      return;
    }

    const shouldBeHighlighted = action.idToHighlight === this.actionId;
    if (shouldBeHighlighted) {
      this.activated.emit();
      this.highlight(action);
      this.listenOnElementEvent(action);
    } else {
      this.unhighlight();
      this.listenerFn && this.listenerFn();
    }
  }

  private isClickActionWithBottomSheet(action: any): action is ClickSemTablePopupInnerAction {
    return !!action.textOnTooltip;
  }

  private highlight(action: InnerAction) {
    setTimeout(() => this.elementRef.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' }), 700);
    this.renderer.addClass(this.elementRef.nativeElement, PULSE_CLASS);
    if (this.isClickActionWithBottomSheet(action)) {
      setTimeout(() => this.openBottomSheet(action), 500);
    }
  }

  private unhighlight() {
    this.renderer.removeClass(this.elementRef.nativeElement, PULSE_CLASS);
  }

  private listenOnElementEvent(action: InnerAction) {
    this.listenerFn = this.renderer.listen(this.elementRef.nativeElement, action.eventListened, () => {
      if (this.actualActionId === this.actionId) {
        this.unhighlight();
        action.markAsDone();
        if (this.bottomSheetRef) {
          this.bottomSheetRef.dismiss();
        }
        this.listenerFn();
      }
    });
  }

  private openBottomSheet(action: ClickSemTablePopupInnerAction): void {
    this.bottomSheetRef = this.bottomSheet!.open(TutorialInfoBoxComponent, { hasBackdrop: false, data: action.textOnTooltip });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    if (this.bottomSheetRef) {
      this.bottomSheetRef.dismiss();
    }
  }
}
