import { StripeCardElementOptions, StripeElementsOptions } from '@stripe/stripe-js';

class StripeConfig {
  cardElementsOptions: StripeCardElementOptions = {
    hidePostalCode: true,
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        fontWeight: '300',
        fontSize: '18px',
        '::placeholder': {
          color: '#CFD7E0',
        },
        backgroundColor: '#FFF',
        lineHeight: '40px',
      },
    },
  };

  elementsOptions: StripeElementsOptions = {
    locale: 'en',
  };
}

export default new StripeConfig();
