import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-beta-label',
  templateUrl: './beta-label.component.html',
  styleUrls: ['./beta-label.component.scss'],
})
export class BetaLabelComponent {
  @Input() isStatic: boolean = false;
}
