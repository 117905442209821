import { Directive, HostListener, Input } from '@angular/core';

// tslint:disable: deprecation
@Directive({
  selector: '[appOnlyNumber]',
})
export class OnlyNumberDirective {
  // tslint:disable-next-line: no-input-rename
  @Input('appOnlyNumber') onlyNumber!: boolean;

  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    if (!this.onlyNumber) {
      return;
    }
    const e = event as KeyboardEvent;
    if (
      [46, 8, 9, 27, 13, 110, 190, 188].indexOf(e.keyCode) !== -1 ||
      (e.keyCode === 65 && e.ctrlKey === true) ||
      (e.keyCode === 67 && e.ctrlKey === true) ||
      (e.keyCode === 88 && e.ctrlKey === true) ||
      (e.keyCode >= 35 && e.keyCode <= 39)
    ) {
      return;
    }
    if ((e.shiftKey || e.keyCode < 48 || e.keyCode > 57) && (e.keyCode < 96 || e.keyCode > 105) && e.keyCode !== 188) {
      e.preventDefault();
    }
  }
}
