import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { ComparatorModel } from 'src/app/dashboard/comparator/models/comparator.model';
import { ShopRequestInterface } from 'src/app/dashboard/comparator/models/shopRequest.model';
import { ProjectModel } from 'src/app/dashboard/project/project.model';
import { PaginationInterface, ResponseInterface } from 'src/app/shared/model/response.model';
import ApiUrls from '../../configs/api-urls.config';
import { PaginationResourceInterface, ResponseV2Interface } from '../../shared/model/response.model';
import { FilterGroupModel } from '../../shared/sem-table/filters/models/filter.model';
import { TableChangeDataEmittedInterface } from '../../shared/sem-table/models/TableChangeDataEmitted.model';
import { ComparatorConfigModel } from './models/comparatorConfig.model';

@Injectable({
  providedIn: 'root',
})
export class ComparatorService {
  ableToGenerate$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  ableToParse$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  activeComparator$ = new BehaviorSubject<ComparatorModel | null>(null);
  activeComparator!: ComparatorModel;
  activeComparatorName$ = new BehaviorSubject<string>('');
  checkingGenerateSub!: Subscription;
  checkingParseSub!: Subscription;
  sub: Subscription = new Subscription();

  constructor(private http: HttpClient) {}

  getConfig(comparatorId: number): Observable<ComparatorConfigModel> {
    return this.http
      .get<ResponseInterface<ComparatorConfigModel>>(ApiUrls.comparatorConfig.prepareUrl({ comparator: comparatorId }))
      .pipe(map((res) => res.data.item!));
  }

  saveConfig(comparatorId: number, config: ComparatorConfigModel): Observable<ComparatorConfigModel> {
    return this.http
      .post<ResponseInterface<ComparatorConfigModel>>(ApiUrls.comparatorConfig.prepareUrl({ comparator: comparatorId }), config)
      .pipe(map((res) => res.data.item!));
  }

  getCsvData(comparatorId: number): Observable<any> {
    return this.http.get<ResponseInterface<ComparatorConfigModel>>(ApiUrls.comparatorDownloadCSV.prepareUrl({ comparator: comparatorId }));
  }

  setActiveComparator(comparator: ComparatorModel) {
    if (comparator && comparator.id) {
      this.activeComparator = comparator;
      this.activeComparator$.next(this.activeComparator);
      this.activeComparatorName$.next(this.activeComparator.domain!);
    }
  }

  unsetActiveComparator() {
    this.activeComparator = null!;
    this.activeComparator$.next(this.activeComparator);
    this.activeComparatorName$.next(null!);
  }

  getComparatorShopRequests(comparatorId: number): Observable<PaginationInterface<ShopRequestInterface>> {
    return this.http
      .get<ResponseInterface<ShopRequestInterface>>(ApiUrls.comparatorContactMessage.prepareUrl({ comparator: comparatorId }))
      .pipe(map((res) => res.data.items!));
  }

  getComparator(comparatorId: number): Observable<ComparatorModel> {
    return this.http
      .get<ResponseV2Interface<ComparatorModel>>(ApiUrls.comparator.prepareUrl({ comparator: comparatorId }))
      .pipe(map((res) => res.data));
  }

  getComparators(
    page: number = 0,
    filterGroups: FilterGroupModel[] | null = null,
  ): Observable<PaginationResourceInterface<ComparatorModel>> {
    return this.http.post<PaginationResourceInterface<ComparatorModel>>(ApiUrls.comparatorSearch, {
      per_page: 99,
      page,
      filterGroups,
    });
  }

  getComparatorsForProject(project: ProjectModel): Observable<ComparatorModel[]> {
    return this.http
      .get<ResponseV2Interface<ComparatorModel[]>>(ApiUrls.projectComparators.prepareUrl({ project: project.id }))
      .pipe(map((res) => res.data));
  }

  getProjectsAttached(comparatorId: number, data: TableChangeDataEmittedInterface): Observable<PaginationResourceInterface<ProjectModel>> {
    let params = new HttpParams();
    const page = data ? data.currentPage : 1;
    params = params.set('page', page.toString());
    params = params.set('limit', data && data.itemsPerPage ? data.itemsPerPage.toString() : '5');
    return this.http.get<PaginationResourceInterface<ProjectModel>>(ApiUrls.comparatorProjects.prepareUrl({ comparator: comparatorId }), {
      params,
    });
  }

  getProjectsNotAttached(comparatorId: number): Observable<ProjectModel[]> {
    return this.http
      .get<ResponseV2Interface<ProjectModel[]>>(ApiUrls.comparatorProjectsToAppend.prepareUrl({ comparator: comparatorId }))
      .pipe(map((response) => response.data));
  }

  attachProject(comparatorId: number, projectId: number): Observable<void> {
    return this.http.post<void>(
      ApiUrls.comparatorProject.prepareUrl({
        comparator: comparatorId,
        project: projectId,
      }),
      {},
    );
  }

  dettachProject(comparatorId: number, projectId: number): Observable<void> {
    return this.http.delete<void>(ApiUrls.comparatorProject.prepareUrl({ comparator: comparatorId, project: projectId }));
  }

  attachProjectFromAccountId(comparatorId: number, accountId: number, amount: number) {
    return this.http.post(ApiUrls.comparatorAttachProjects.prepareUrl({ comparator: comparatorId }), {
      accountId,
      qty: amount,
    });
  }

  addComparator(domain: string, main_comparator: string, languages: { [key: string]: string[] }): Observable<void> {
    return this.http.post<void>(ApiUrls.comparators, { domain, main_comparator, languages });
  }
}
