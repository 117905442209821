<div class="container">
  <div class="arrow">
    <button mat-icon-button aria-label="Prev" (click)="previous()">
      <mat-icon outline>arrow_circle_left</mat-icon>
    </button>
  </div>
  <div class="tip">
    <div class="tip-header">
      <h4>{{ 'tips.header' | translate }}</h4>
    </div>
    <div class="tip-content">{{ 'tips.tips.' + tips[selectedIndex] | translate }}</div>
  </div>
  <div class="arrow">
    <button mat-icon-button aria-label="Next" (click)="next()">
      <mat-icon outline>arrow_circle_right</mat-icon>
    </button>
  </div>
</div>
