import { Component, OnInit } from '@angular/core';
import { isShopifyEmbedded } from '@shopify/app-bridge-utils';
import { EnvironmentPluginEnum } from 'src/environments/env.model';
import { environment } from 'src/environments/environment';
import { PluginService } from '../shared/service/plugin.service';

@Component({
  selector: 'app-shopify',
  template: '',
})
export class ShopifyComponent implements OnInit {
  constructor(private pluginService: PluginService) {}

  ngOnInit(): void {
    this.handleOnInit();
  }

  handleOnInit() {
    const url = new URL(window.location.href);
    const shop = url.searchParams.get('shop');

    if (!isShopifyEmbedded()) {
      const permissionUrl = this.createPermissionUrl(shop!);
      this.redirectToPermissionPage(permissionUrl);
    } else {
      this.pluginService.redirectToHomeInShopify();
    }

    this.pluginService.pluginMode = EnvironmentPluginEnum.shopify;
  }

  private createPermissionUrl(shop: string) {
    const accessMode = 'value';
    const redirectUri = `${environment.dataUrl}/front/dashboard`;
    // eslint-disable-next-line max-len
    return `https://${shop}/admin/oauth/authorize?client_id=${this.pluginService.shopifySercret}&scope=read_products&redirect_uri=${redirectUri}&grant_options[]=${accessMode}`;
  }

  private redirectToPermissionPage(permissionUrl: string) {
    window.location.assign(permissionUrl);
  }
}
