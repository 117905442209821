import { Component, OnInit, inject } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { tap } from 'rxjs/operators';
import { InvoiceDataModel, InvoiceModel } from '../models/invoice.model';

import { InvoiceService } from './invoice.service';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss'],
})
export class InovoiceComponent implements OnInit {
  form!: UntypedFormGroup;
  isLoading = false;
  invoices!: InvoiceModel[];
  invoiceData!: InvoiceDataModel;
  preparedInvoiceData!: string[][];

  private readonly invoiceService = inject(InvoiceService);

  ngOnInit(): void {
    this.isLoading = true;

    this.invoiceService
      .getInvoices()
      .pipe(tap((res) => (this.invoices = res)))
      .subscribe(() => (this.isLoading = false));

    this.invoiceService
      .getInvoiceData()
      .pipe(tap((res) => (this.invoiceData = res)))
      .subscribe((res) => (this.preparedInvoiceData = Object.entries(res)));
  }

  onSubmit(): void {
    if (this.form.valid) {
      this.isLoading = true;
    }
  }

  download(url: string) {
    window.open(url, '_blank');
  }
}
